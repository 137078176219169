import { AzureRMService } from "./AzureRMService"
import { SETTINGS_EXTENSION_NAME } from "../util/Constants";

export interface OrgSettings {
    // Need these values to complete setup
    adminConsentTime?: string;
    adminConsentUser?: string;
    sparkAdministratorEmail?: string;
    defaultAzureSubscriptionId?: string;
    sparkAppObjectId?: string;
    sparkAppAppId?: string;
    sparkAppIdentifierUri?: string;

    sparkAdminPermissionAssigned?: boolean | string;
    sparkProvisioningAppIsAuthorized?: boolean | string;

    // option - TODO
    siteRequestPowerAppId?: string;
    sparkTechnicalLiasonEmail?: string;
    // TODO - better deployment status
    status?: string;
    selectedAzureSubscriptionId?: string;
    selectedAzureResourceGroupName?: string;

    peSetupStatus?: string;
    peSubscriptionId?: string;
    peResourceGroupName?: string;
    peDeploymentId?: string;
    peFunctionAppName?: string;
}

export interface UserSettings {
    firstSeen?: string;
    lastSeen?: string;
    originalProfileImageId?: string;
    themeName?: string;
    userCurrentAADDirectory?: string;
    enableMeganav?: boolean;
    showOnlySparkResources?: boolean;
    lastSeenProfileETag?: string;

    // provisioning
    getTemplateId?: string;
    getTemplateSite?: string;
}

class SettingsPersistenceService {

    azureService = new AzureRMService();

    public async getOrEnsureExtensionCreatedForMe(): Promise<UserSettings> {
        let extensionData;
        try {
            extensionData = await this.azureService.getGraphExtensionForMe(SETTINGS_EXTENSION_NAME);
        }
        catch (err) {
            console.log(err);
            this.azureService.addGraphExtension(SETTINGS_EXTENSION_NAME, {});
            extensionData = await this.azureService.getGraphExtensionForMe(SETTINGS_EXTENSION_NAME);
        }
        return extensionData;
    }
    public async persistSettingsToExtensionForMe(objectToPersist: UserSettings): Promise<void> {
        const previousExtensionData = await this.azureService.getGraphExtensionForMe(SETTINGS_EXTENSION_NAME);
        const newData = Object.assign(previousExtensionData, objectToPersist)
        await this.azureService.updateGraphExtensionForMe(SETTINGS_EXTENSION_NAME, newData)
    }

    public async deleteSettingsExtensionForMe() {
        await this.azureService.deleteGraphExtensionForMe(SETTINGS_EXTENSION_NAME)
    }

    public async clearOrganizationSettingsData(organizationId: string) {
        await this.azureService.updateGraphExtensionForOrganization(SETTINGS_EXTENSION_NAME, organizationId, {});
    }

    public async persistSettingsToOrganizationExtension(organizationId: string, objectToPersist: OrgSettings) {
        const previousExtensionData = await this.azureService.getGraphExtensionForOrganization(SETTINGS_EXTENSION_NAME);
        const newData = Object.assign(previousExtensionData, objectToPersist)
        await this.azureService.updateGraphExtensionForOrganization(SETTINGS_EXTENSION_NAME, organizationId, newData);
    }

    public async getOrganizationSettingsData(): Promise<OrgSettings> {
        const previousExtensionData = await this.azureService.getGraphExtensionForOrganization(SETTINGS_EXTENSION_NAME);
        return previousExtensionData as OrgSettings;
    }

    public async deleteExtensionForOrganization() {
        await this.azureService.deleteGraphExtensionForOrganization(SETTINGS_EXTENSION_NAME);
    }

    public async deleteKeyAndValueFromOrganizationExtension(organizationId: string, key: string) {
        const oldValue = await this.azureService.getGraphExtensionForOrganization(SETTINGS_EXTENSION_NAME);
        delete oldValue[key];
        await this.azureService.updateGraphExtensionForOrganization(SETTINGS_EXTENSION_NAME, organizationId, oldValue);
    }
}

export { SettingsPersistenceService }
import React from "react";

import { Router, Route, Switch, useRouter } from "./util/router";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import HomePage from "./pages/home";
import AboutPage from "./pages/about";
import FaqPage from "./pages/faq";
import PricingPage from "./pages/pricing";
import ProfilePage from "./pages/profile";
import SetupPage from "./pages/setup";
import SparkAdministratorsPage from "./pages/sparkadministrators";
import TenantCheckPage from "./pages/tenantcheck";
import { BrowserRouter } from "react-router-dom";
import { ProvideAuth } from "./util/auth";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { authProvider } from "./AuthProvider";
import AppsPage from "./pages/apps";
import FlowConnectorPage from "./pages/flowconnector";
import SitesPage from "./pages/sites";
import AdminConsentPage from "./pages/adminconsent";
import TenantPage from "./pages/tenant";
import TenantsPage from "./pages/tenants";
import RequestPage from "./pages/request";

import { NotificationStoreProvider } from "./stores/NotificationStore";
import { AuthenticationStoreProvider } from "./stores/AuthenticationStore";
import { Notifications } from "./components/Notification";
import SubscriptionsPage from "./pages/subscriptions";
import TeamsAppInstallPage from "./pages/teamsappinstall";
import LogPage from "./pages/log";
import AzureADAppsPage from "./pages/azureadapps";

import TeamsSearchPage from "./pages/teamssearch";
import TermStorePage from "./pages/termstore";

import ErrorPage from "./pages/error";
import { ThemeProvider, CSSReset, Flex, Button, Progress } from "@chakra-ui/core";
import { theme } from "@chakra-ui/core";
import { globalStore } from "./stores/GlobalStore";
import { observer } from "mobx-react";
import { SparkDrawer } from "./components/Drawer/Drawer";
import { AuthenticationInProgress } from "./components/AuthenticationInProgress/AuthenticationInProgress";
import { ThemeManager } from "./util/ThemeManager";
import { AppAuthorizeSection } from "./components/AppAuthorizeSection/AppAuthorizeSection";
import { Toaster } from "./components/Toaster/Toaster";
import { AppHeader } from "./components/AppHeader/AppHeader";
import AdminPage from "./pages/admin";
import TrendingPage from "./pages/trending";
import PeoplePage from "./pages/people";
import TeamsPage from "./pages/teams";
import Meganav from "./components/Meganav/Meganav";
import BadgingPage from "./pages/badging";

import { toJS } from "mobx";
import SiteRequestsPage from "./pages/siterequests";
import { GridLayout } from "./components/GridLayout/GridLayout";
import { TeamsNavigation } from "./components/TeamsNavigation/TeamsNavigation";

const App: React.FC = observer(() => {
  const TeamsRoutes = observer(() => {
    return (
      <>{globalStore.microsoftTeamsStore.isInMicrosoftTeams && <Route path="/teams" component={SubscriptionsPage} />}</>
    );
  });

  // const params = new URLSearchParams(window.location.search);
  // const admin_consent = params.get("admin_consent");
  // const tenant = params.get("tenant");

  // if (admin_consent && tenant) {
  //   return <div>Admin Consent</div>;
  // }

  return (
    <ThemeManager>
      <Router>
        <AzureAD forceLogin={false} provider={authProvider}>
          {({ accountInfo, authenticationState }) => {
            if (authenticationState === AuthenticationState.InProgress) {
              return <AuthenticationInProgress />;
            }

            if (authenticationState === AuthenticationState.Unauthenticated) {
              return (
                <div>
                  <AppAuthorizeSection />
                </div>
              );
            }

            const LoadingIndicator = observer(() => {
              return <Progress color={"red"} size="sm" value={globalStore.fetchStore.requestCount * 20} />;
            });
            return (
              <ProvideAuth>
                <AuthenticationStoreProvider>
                  <NotificationStoreProvider>
                    <div className="App">
                      <LoadingIndicator />
                      <Notifications />
                      <>
                        <Meganav />

                        {globalStore.microsoftTeamsStore.isInMicrosoftTeams && (
                          <>
                            {globalStore.microsoftTeamsStore.shouldShowNav && <TeamsNavigation />}
                            {JSON.stringify(globalStore.microsoftTeamsStore.subEntityId)}
                          </>
                        )}
                        {!globalStore.microsoftTeamsStore.isInMicrosoftTeams && <Navbar color="white" spaced={true} />}
                        <Switch>
                          <Route exact path="/" component={HomePage} />
                          <Route exact path={`/profile`} component={ProfilePage} />
                          <Route exact path={`/trending`} component={TrendingPage} />
                          <Route exact path={`/adminconsent`} component={AdminConsentPage} />

                          <Route path={`/people`} component={PeoplePage} />
                          <Route path={`/sites`} component={SitesPage} />
                          <Route path={`/teams`} component={TeamsPage} />
                          <Route path={`/badging`} component={BadgingPage} />
                          <Route path={`/siterequests`} component={SiteRequestsPage} />

                          <Route path="/admin" component={AdminPage} />

                          <Route
                            component={({ location }) => {
                              return (
                                <div
                                  style={{
                                    padding: "50px",
                                    width: "100%",
                                    textAlign: "center"
                                  }}>
                                  The page <code>{location.pathname}</code> could not be found.
                                </div>
                              );
                            }}
                          />
                        </Switch>
                      </>
                    </div>
                  </NotificationStoreProvider>
                </AuthenticationStoreProvider>
              </ProvideAuth>
            );
          }}
        </AzureAD>
      </Router>
    </ThemeManager>
  );
});

const GriddedUpApp = () => {
  return <GridLayout headerComponent={null} centeredComponent={<App />} />;
};

export default App;

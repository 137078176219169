import { AzureRMService } from "../../services/AzureRMService";

import { SharePointService } from "../../services/SharePointService";

import { SettingsPersistenceService } from "../../services/SettingsPersistanceService";

import Poller from "./Poller";

import { authProvider } from "../../AuthProvider";

export class GetTemplatePoller {

    azureService = new AzureRMService();
    sharePointService = new SharePointService();
    settingsService = new SettingsPersistenceService();

    _poller: Poller<() => Promise<boolean>>

    constructor(intervalInMilliseconds: number, functionApiUrl: string, requestId: string, functionAppKey: string, callbackFunction: (xmlText) => void) {
        this._poller = new Poller(async () => {
            // get the user account id
            const account = authProvider.getAccount();
            const userId = account.idToken.oid;

            const statusRequest = await fetch(`${functionApiUrl}/GetTemplateStatus?id=${requestId}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "x-functions-key": functionAppKey
                }
            }
            );
            const header = statusRequest.headers.get("Content-Type");
            console.log("STATUS HEADER", header);
            let status;
            if (header?.indexOf('application/xml') !== -1) {
                status = await statusRequest.text();
                callbackFunction(status)
                return true;
            }
            else {
                status = await statusRequest.json();
                return false;
            }

        }, intervalInMilliseconds)
    }
}
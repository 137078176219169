import React from "react";
import { useLocalStore, observer } from "mobx-react-lite";
import { toJS } from "mobx";

export type TFriend = {
  name: string;
  isFavorite: boolean;
  isSingle: boolean;
};

export type Notification = {
  id: string;
  title: string;
  text: string;
  isDismissed: boolean;
  notificationType: "success" | "danger" | "error" | "info";
};
export function createNotificationStore() {
  const savedNotifications = localStorage.getItem("NotificationStore");
  let prevNotifications;
  if (savedNotifications) {
    prevNotifications = JSON.parse(savedNotifications) as Notification[];
  } else {
    prevNotifications = [];
  }
  return {
    notifications: prevNotifications,
    createNotification(notification: Notification) {
      this.notifications.push(notification);
      localStorage.setItem("NotificationStore", JSON.stringify(toJS(this.notifications)));

      // setTimeout(() => {
      //   this.dismissNotification(id);
      // }, 4000);
    },
    get notDismissedNotifications() {
      return this.notifications.filter(n => !n.isDismissed);
    },

    dismissNotification(id: string) {
      const nIndex = this.notifications.findIndex(n => n.id === id);
      this.notifications.splice(nIndex, 1);
      localStorage.setItem("NotificationStore", JSON.stringify(toJS(this.notifications)));
    }
  };
}

export type TStore = ReturnType<typeof createNotificationStore>;

const storeContext = React.createContext<TStore | null>(null);

export const NotificationStoreProvider = ({ children }) => {
  const store = useLocalStore(createNotificationStore);
  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useNotificationStore = () => {
  const store = React.useContext(storeContext);
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error("useNotificationStore must be used within a StoreProvider.");
  }
  return store;
};

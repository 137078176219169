import React from "react";
import HeroSection from "./../../components/HeroSection";
import ClientsSection from "./../../components/ClientsSection";
import FeaturesSection from "./../../components/FeaturesSection";
import TestimonialsSection from "./../../components/TestimonialsSection";
import NewsletterSection from "./../../components/NewsletterSection";
import { useRouter, withRouter } from "./../../util/router";
import "./styles.scss";
import SectionButton from "../../components/SectionButton";
import { Columns, Loader } from "react-bulma-components/full";
import SparkMenu, { SparkMenuPage } from "../../components/SparkMenu";
import { SharePointService } from "../../services/SharePointService";
import { SparkTenantState } from "../setup/index";
import { observable, action, computed } from "mobx";
import { AzureRMService } from "../../services/AzureRMService";
import AzureStorageService from "../../services/AzureStorageService";
import { Button } from "react-bulma-components/full";
import { observer } from "mobx-react";
import { generateClientSidePassword } from "../../util/Utility";
import { FlowConnectorDisplay } from "./FlowConnectorDisplay";
@observer
class FlowConnectorStatefulPage extends React.Component<{ match; history }> {
  azureService = new AzureRMService();
  sharePointService = new SharePointService();

  @observable installState: SparkTenantState = {
    status: "loading",
  };

  @action setInstallState(state) {
    this.installState = state;
  }

  @observable connectors;
  @action setConnectors(connectors) {
    this.connectors = connectors;
  }

  @computed get isSparkProvisioningSolutionInstalled() {
    return this.connectors && this.connectors.find((c) => c.displayname === "Spark Provisioning");
  }

  @observable functionAppName;
  @action setFunctionAppName(name) {
    this.functionAppName = name;
  }
  @observable functionAppKey;
  @action setFunctionAppKey(key) {
    this.functionAppKey = key;
  }

  async componentDidMount() {
    const rootUrl = (await this.sharePointService.getSharePointRootUrl()).webUrl;
    try {
      const stateKey: any = await this.sharePointService.getOrCreateHiddenStateStoreListKey(rootUrl);
      this.setInstallState(stateKey as SparkTenantState);
    } catch (err) {
      console.log(err);
    }

    const services = await this.azureService.discoverCommonDataServiceUrls();

    try {
      const flowConnectors = await this.azureService.getFlowConnectors(services[0].ApiUrl);
      this.setConnectors(flowConnectors);
    } catch (err) {
      this.props.history.push("/error");
    }

    // if (
    //   !this.installState.selectedAzureSubscriptionId ||
    //   !this.installState.selectedAzureResourceGroupName ||
    //   !this.installState.functionAppName
    // ) {
    //   console.log("invalid install state");
    //   return;
    // }

    // await this.fetchFunctionAppFlowConnector();

    // const newKey = generateClientSidePassword();
    // this.azureService.updateFunctionAppMasterKey(
    //   this.installState.selectedAzureSubscriptionId,
    //   this.installState.selectedAzureResourceGroupName,
    //   this.installState.functionAppName,
    //   newKey
    // );

    // this.azureService.updateFunctionAppMasterKey(
    //   this.installState.selectedAzureSubscriptionId,
    //   this.installState.selectedAzureResourceGroupName,
    //   this.installState.functionAppName + "-v2",
    //   newKey
    // );
    // this.setFunctionAppName(this.installState.functionAppName);
    // // const functionKey = await this.azureService.getFunctionAppMasterKey(
    // //   this.installState.selectedAzureSubscriptionId,
    // //   this.installState.selectedAzureResourceGroupName,
    // //   this.installState.functionAppName
    // // );
    // this.setFunctionAppKey(newKey);
  }

  private async fetchFunctionAppFlowConnector() {
    if (!this.installState.functionAppName) {
      console.log("invalid install state");
      return;
    }

    const flowConnectorSolutionFileText = await fetch(
      `https://${this.installState.functionAppName}.azurewebsites.net/api/flowconnector`,
      { method: "GET", mode: "cors" }
    );
    let text = await flowConnectorSolutionFileText.text();
    return text;
  }

  private async runSolutionImport() {
    const rootUrl = (await this.sharePointService.getSharePointRootUrl()).webUrl;
    const services = await this.azureService.discoverCommonDataServiceUrls();
    const apiUrl = services[0].ApiUrl;

    await this.sharePointService.addLogEntryToHiddenList(
      rootUrl,
      "AttemptedFlowSolutionImport",
      JSON.stringify({ serviceUrl: apiUrl })
    );

    try {
      const connectorEncoded = await this.fetchFunctionAppFlowConnector();
      if (!connectorEncoded) return;

      await this.azureService.importSolution(apiUrl, connectorEncoded);
      await this.sharePointService.addLogEntryToHiddenList(
        rootUrl,
        "SuccessfulFlowSolutionImport",
        JSON.stringify({ serviceUrl: apiUrl })
      );
    } catch (err) {
      await this.sharePointService.addLogEntryToHiddenList(
        rootUrl,
        "FailedFlowSolutionImport",
        JSON.stringify({ errorMessage: err.message })
      );
    }
  }

  render() {
    let controlToRender;
    switch (this.installState.status) {
      case "loading":
        controlToRender = (
          <Loader
            style={{
              width: 300,
              height: 300,
              border: "4px solid blue",
              borderTopColor: "transparent",
              borderRightColor: "transparent",
            }}
          />
        );
        break;
      case "done":
        controlToRender = (
          <div>
            {/* {this.isSparkProvisioningSolutionInstalled && <div>Flow Connector is installed.</div>}
            <pre>{JSON.stringify(this.connectors, null, 2)}</pre> */}
            <Button
              color="success"
              onClick={() => {
                this.runSolutionImport();
              }}>
              Install Flow Connector
            </Button>
            <p>Function Key: {this.functionAppKey}</p>
            <p>Function App Name: {this.functionAppName}</p>
          </div>
        );
        break;
      case "starting":
        controlToRender = (
          <div>
            <Button
              color="success"
              onClick={() => {
                this.runSolutionImport();
              }}>
              Install Flow Connector
            </Button>
            <br />
            <br />
            <Button
              color="success"
              onClick={() => {
                this.runSolutionImport();
              }}>
              Share Flow Connector
            </Button>
            {JSON.stringify(this.connectors)}
            {this.connectors &&
              this.connectors.map((connector) => {
                return <FlowConnectorDisplay connector={connector} />;
              })}
            {this.isSparkProvisioningSolutionInstalled && <div>Flow Connector is installed.</div>}
          </div>
        );
        break;
      default:
        controlToRender = <div>Can't determine installation state</div>;
    }

    return controlToRender;
  }
}

export default withRouter(FlowConnectorStatefulPage);

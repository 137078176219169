import { action, observable } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Button, Columns, Section, Progress, Table, Heading, Pagination, Loader } from "react-bulma-components/full";

import { SparkMenuPage } from "../../components/SparkMenu";
import { AzureRMService } from "../../services/AzureRMService";
import { SharePointService } from "../../services/SharePointService";

@observer
class LogPage extends React.Component {
  sharePointService = new SharePointService();
  azureService = new AzureRMService();

  @observable isLoading: boolean = false;
  @action setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @observable logEntries: Array<any> = [];
  @action setLogEntries(log) {
    this.logEntries = log;
  }

  async componentDidMount() {
    this.setIsLoading(true);
    const rootUrl = (await this.sharePointService.getSharePointRootUrl()).webUrl;

    // await this.sharePointService.setHiddenStateStoreDataByKey(
    //   rootUrl,
    //   "LogEntry",
    //   "Log",
    //   JSON.stringify({ one: "two" })
    // );

    const logEntries = await this.sharePointService.getLogEntriesFromHiddenList(rootUrl);
    this.setLogEntries(logEntries);
    this.setIsLoading(false);
  }
  render() {
    return (
      <>
        {this.isLoading ? (
          <Loader
            style={{
              width: 300,
              height: 300,
              border: "4px solid blue",
              borderTopColor: "transparent",
              borderRightColor: "transparent"
            }}
          />
        ) : (
          <Table>
            <thead>
              <tr>
                <th>
                  <abbr title="Event">Event</abbr>
                </th>
                <th>Data</th>
                <th>Date</th>
                <th>User</th>
              </tr>
            </thead>
            <tbody>
              {this.logEntries.length > 0 &&
                this.logEntries.map(entry => {
                  return (
                    <tr key={entry.Created}>
                      <td>{entry.Title}</td>
                      <td>{entry.Data}</td>
                      <td>{entry.Created}</td>
                      <td>{entry.Author.Title}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        )}
      </>
    );
  }
}

export default LogPage;

import { CLIENT_ID } from "./Constants"
import { getGUID } from "@pnp/common"

export const getProvisioningAzureAdAppSetup = (displayName: string, tags: string[], redirectUris: string[]) => {

    const appIdentifierGuid = getGUID();
    const permissionScopeId = getGUID();

    return {
        displayName: displayName,
        tags: tags,
        web: {
            implicitGrantSettings: {
                enableIdTokenIssuance: true,
                enableAccessTokenIssuance: true
            },
            redirectUris: redirectUris
        },
        "identifierUris": [`api://${appIdentifierGuid}`],

        "api": {
            "requestedAccessTokenVersion": 2,
            "acceptMappedClaims": null,
            knownClientApplications: [CLIENT_ID],
            "oauth2PermissionScopes": [
                {
                    "adminConsentDescription": "Allow the app to access resources on behalf of the signed-in user.",
                    "adminConsentDisplayName": "Access resource1",
                    "id": permissionScopeId,
                    "isEnabled": true,
                    "type": "User",
                    "userConsentDescription": "Allow the app to access resource1 on your behalf.",
                    "userConsentDisplayName": "Access resources",
                    "value": "SiteProvisioning.All"
                }
            ],
            "preAuthorizedApplications": [
                {
                    "appId": CLIENT_ID,
                    "permissionIds": [
                        permissionScopeId
                    ]
                }
            ]
        },

        requiredResourceAccess: [
            {
                resourceAppId: "00000003-0000-0ff1-ce00-000000000000",
                resourceAccess: [
                    {
                        id: "678536fe-1083-478a-9c59-b99265e6b0d3",
                        type: "Role"
                    }
                ]
            },
            {
                resourceAppId: "00000003-0000-0000-c000-000000000000",
                resourceAccess: [
                    {
                        id: "e1fe6dd8-ba31-4d61-89e7-88639da4683d",
                        type: "Scope"
                    },
                    {
                        id: "62a82d76-70ea-41e2-9197-370581804d09",
                        type: "Role"
                    },
                    {
                        id: "0c458cef-11f3-48c2-a568-c66751c238c0",
                        type: "Role"
                    },
                    {
                        id: "741f803b-c850-494e-b5df-cde7c675a1ca",
                        type: "Role"
                    }
                ]
                // signInAudience: "AzureADMyOrg"
            }
        ]
    }
}
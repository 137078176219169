import React from "react";
import { observer } from "mobx-react";
import { Flex, Text, Box, Link, Stack, PseudoBox } from "@chakra-ui/core";
import RightpointSVG from "../SVG/RightpointSVG";
import { withRouter } from "../../util/router";
import { TaskInfo } from "@microsoft/teams-js";
import * as microsoftTeams from "@microsoft/teams-js";
import { globalStore } from "../../stores/GlobalStore";
import { toJS } from "mobx";

const Link2: any = Link;
@observer
class MainMenu extends React.Component<{ history }> {
  render() {
    return (
      <Stack paddingY={10} paddingX={5} align={"center"}>
        <PseudoBox
          as={"a"}
          onClick={() => {
            this.props.history.push("/trending");
          }}
          bg="themePrimary"
          w={{ base: "100%", md: "80%", lg: "50%", xl: "30%" }}
          p={4}
          color="white"
          _hover={{ cursor: "pointer" }}>
          News
        </PseudoBox>
        <PseudoBox
          as={"a"}
          onClick={() => {
            this.props.history.push("/sites");
          }}
          bg="themePrimary"
          w={{ base: "100%", md: "80%", lg: "50%", xl: "30%" }}
          p={4}
          color="white"
          _hover={{ cursor: "pointer" }}>
          Demo - Multitenant Site Search
        </PseudoBox>

        <PseudoBox
          as={"a"}
          onClick={() => {
            this.props.history.push("/people");
          }}
          bg="themePrimary"
          w={{ base: "100%", md: "80%", lg: "50%", xl: "30%" }}
          p={4}
          color="white"
          _hover={{ cursor: "pointer" }}>
          Search People
        </PseudoBox>
        <PseudoBox
          bg="themePrimary"
          w={{ base: "100%", md: "80%", lg: "50%", xl: "30%" }}
          p={4}
          color="white"
          _hover={{ cursor: "pointer" }}>
          Create a Request
        </PseudoBox>
        <PseudoBox
          bg="themePrimary"
          onClick={() => {
            this.props.history.push("/profile");
          }}
          w={{ base: "100%", md: "80%", lg: "50%", xl: "30%" }}
          p={4}
          color="white"
          _hover={{ cursor: "pointer" }}>
          My Profile
        </PseudoBox>
        <PseudoBox
          as={"a"}
          onClick={() => {
            this.props.history.push("/admin");
          }}
          bg="themePrimary"
          w={{ base: "100%", md: "80%", lg: "50%", xl: "30%" }}
          p={4}
          color="white"
          _hover={{ cursor: "pointer", color: "white" }}>
          Administration
        </PseudoBox>
        <PseudoBox
          as={"a"}
          onClick={() => {
            this.props.history.push("/admin/termstore");
          }}
          bg="themePrimary"
          w={{ base: "100%", md: "80%", lg: "50%", xl: "30%" }}
          p={4}
          color="white"
          _hover={{ cursor: "pointer", color: "white" }}>
          Term Store
        </PseudoBox>
        <PseudoBox
          as={"a"}
          onClick={() => {
            this.props.history.push("/siterequests");
          }}
          bg="themePrimary"
          w={{ base: "100%", md: "80%", lg: "50%", xl: "30%" }}
          p={4}
          color="white"
          _hover={{ cursor: "pointer", color: "white" }}>
          Site Requests PowerApp
        </PseudoBox>
        <PseudoBox
          as={"a"}
          onClick={() => {
            this.props.history.push("/badging");
          }}
          bg="themePrimary"
          w={{ base: "100%", md: "80%", lg: "50%", xl: "30%" }}
          p={4}
          color="white"
          _hover={{ cursor: "pointer", color: "white" }}>
          Badging
        </PseudoBox>
        <PseudoBox
          as={"a"}
          onClick={() => {
            const task: TaskInfo = {
              url: process.env.PUBLIC_URL + "/teams/task1",
              completionBotId: "24160f9f-bc26-438c-862a-847fcc971db6"
            };
            microsoftTeams.initialize();
            microsoftTeams.tasks.startTask(task);
            //  this.props.history.push("/teams/startTask");
          }}
          bg="themePrimary"
          w={{ base: "100%", md: "80%", lg: "50%", xl: "30%" }}
          p={4}
          color="white"
          _hover={{ cursor: "pointer", color: "white" }}>
          Teams Task Module
        </PseudoBox>
        <PseudoBox
          as={"a"}
          onClick={() => {
            this.props.history.push("/teams");
          }}
          bg="themePrimary"
          w={{ base: "100%", md: "80%", lg: "50%", xl: "30%" }}
          p={4}
          color="white"
          _hover={{ cursor: "pointer", color: "white" }}>
          Teams
        </PseudoBox>
      </Stack>
    );
  }
}

export default withRouter(MainMenu);

const createLinkedSignal = (...signals) => {
  signals = signals.filter(s => !!s);

  if (signals.length === 1) {
    return signals[0]; // Debugging is easier when we can avoid wrapping
  }

  let controller = new AbortController();
  for (let signal of signals) {
    signal.addEventListener("abort", () => controller.abort());
  }
  return controller.signal;
};

const cancelFetchOnReentrySync = wrappedFunc => {
  let currentAbort = new AbortController();

  return (...args) => {
    currentAbort.abort();
    currentAbort = new AbortController();

    let mySignal = currentAbort.signal;

    const injectedFetch = (input, init = {} as any) =>
      fetch(input, {
        ...init,
        signal: createLinkedSignal(mySignal, init.signal)
      });

    return wrappedFunc(injectedFetch)(...args);
  };
};

const swallowCancellation = wrappedFunc => async (...args) => {
  try {
    return await wrappedFunc(...args);
  } catch (ex) {
    if (ex.name === "AbortError") {
      debugger;
      return; // Request has been canceled, so do nothing
    }

    throw ex;
  }
};

export const cancelFetchOnReentry = wrappedFunc =>
  cancelFetchOnReentrySync(fetch => swallowCancellation(wrappedFunc(fetch))) as typeof wrappedFunc;

export async function cancelFetchOnReentry1<T>(wrappedFunc) {
  return cancelFetchOnReentrySync(fetch => swallowCancellation(wrappedFunc(fetch) as T)) as typeof wrappedFunc;
}

import React, { useEffect } from "react";
import DashboardSection from "./../../components/DashboardSection";

import { useRouter, Switch, Route, withRouter } from "./../../util/router";
import { useAuth } from "../../util/auth";
import SectionHeader from "../../components/SectionHeader";
import { SparkMenuPage } from "../../components/SparkMenu";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import { SharePointService } from "../../services/SharePointService";
import { AzureRMService } from "../../services/AzureRMService";
import { Button, Table } from "react-bulma-components/full";
import { APPS_BLOB_STORAGE_LIST } from "../../util/Constants";
import AzureStorageService from "../../services/AzureStorageService";
var convert = require("xml-js");

@observer
class SharePointAppsPage extends React.Component<{ match; history }> {
  sharePointService = new SharePointService();
  azureService = new AzureRMService();
  storageService = new AzureStorageService();

  _spRootUrl: string = "";
  _appCatalogUrl: string = "";

  @observable apps: Array<{ Name: { _text: string } }> = [];

  @observable uploadInProgress: boolean = false;
  @action setUploadInProgress(uploadInProgress) {
    this.uploadInProgress = uploadInProgress;
  }

  @action loadApps() {
    fetch(APPS_BLOB_STORAGE_LIST, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
      .then(response => response.text())
      .then(apps => {
        var result1 = convert.xml2json(apps, { compact: true, spaces: 4 });

        this.apps = JSON.parse(result1).EnumerationResults.Blobs.Blob;
      });
  }

  @action async deployApp(appName) {
    this.setUploadInProgress(true);
    await this.sharePointService.addLogEntryToHiddenList(
      this._spRootUrl,
      "AttemptedAppInstall",
      JSON.stringify({ appName: appName })
    );

    const blob = await this.storageService.downloadBlob(appName);
    const context = await this.sharePointService.getContextInfo(this._spRootUrl, this._appCatalogUrl);

    this.sharePointService
      .uploadAppToStore(appName, this._spRootUrl, this._appCatalogUrl, context.FormDigestValue, blob, true)
      .then(async resp => {
        this.sharePointService.deployApp(
          resp.UniqueId,
          this._spRootUrl,
          this._appCatalogUrl,
          context.FormDigestValue,
          false,
          true
        );

        await this.sharePointService.addLogEntryToHiddenList(
          this._spRootUrl,
          "AppDeployment",
          JSON.stringify({ appName: appName })
        );
        this.setUploadInProgress(false);
      });
  }

  async componentDidMount() {
    const sharePointInfo = await this.sharePointService.getSharePointRootUrl();
    const rootUrl = sharePointInfo.webUrl;
    const id = sharePointInfo.id;
    const siteUrl = (await this.sharePointService.getSiteUrlFromId(rootUrl, this.props.match.params.id)).d.SiteUrl;
    await this.sharePointService.getAvailableAppsFromSiteCollectionAppCatalog(rootUrl, siteUrl);

    let appCatalogUrl = await this.sharePointService.getSharePointAppCatalogUrl(rootUrl, "");
    await this.sharePointService.getSharePointAppCatalogUrlWithGraph(id);
    await this.sharePointService.getContextInfo(rootUrl, rootUrl);
    this._spRootUrl = rootUrl;
    this._appCatalogUrl = siteUrl; //+ "/AppCatalog";

    //  const context = await this.sharePointService.getContextInfo(rootUrl, appCatalogUrl);
    this.loadApps();
  }
  render() {
    const AppsTable = observer(() => {
      return (
        <Table>
          <thead>
            <tr>
              <th>
                <abbr title="Id">Name</abbr>
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {this.apps.map(app => {
              return (
                <tr>
                  <th>{app.Name._text}</th>

                  <td>
                    <Button
                      disabled={this.uploadInProgress}
                      onClick={() => this.deployApp(app.Name._text)}
                      color="link">
                      Add
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      );
    });
    const Page = observer(() => (
      <SparkMenuPage>
        <AppsTable />
      </SparkMenuPage>
    ));

    return <Page />;
  }
}

export default withRouter(SharePointAppsPage);

import React from "react";
import { withRouter, Switch, Route } from "../../util/router";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { AzureRMService } from "../../services/AzureRMService";
import { SparkMenuPage } from "../../components/SparkMenu";
import NewResourceGroupModal from "../../components/NewResourceGroupModal";
import { SharePointService } from "../../services/SharePointService";
import { Box, Text, Heading, Link, Stack } from "@chakra-ui/core";
import { Link as RouterLink } from "../../util/router";
import { PasswordResetPage } from "./PasswordResetPage";

const Linky: any = Link;
@observer
class RequestPage extends React.Component<{ match; history }> {
  azureService = new AzureRMService();
  sharePointService = new SharePointService();

  async componentDidMount() {}
  render() {
    return (
      <>
        <Switch>
          <Route
            exact
            path={`${this.props.match.path}/`}
            component={() => {
              return (
                <Box color={"themePrimary"}>
                  <Heading size={"sm"}>Create a Request</Heading>
                  <Stack>
                    <Linky as={RouterLink} to={`${this.props.match.path}/newuser`}>
                      New User Request
                    </Linky>
                    <Linky as={RouterLink} to={`${this.props.match.path}/passwordreset`}>
                      Password Reset Request
                    </Linky>
                    <Linky as={RouterLink} to={`${this.props.match.path}/userlicenses`}>
                      Request a License
                    </Linky>
                  </Stack>
                </Box>
              );
            }}
          />
          <Route
            exact
            path={`${this.props.match.path}/newuser`}
            component={() => {
              return (
                <Box>
                  <Heading size={"sm"}>Request a User</Heading>
                </Box>
              );
            }}
          />
          <Route exact path={`${this.props.match.path}/passwordreset`} component={PasswordResetPage} />

          <Route
            exact
            path={`${this.props.match.path}/userlicenses`}
            component={() => {
              return (
                <Box>
                  <Heading size={"sm"}>User Licenses</Heading>
                  <p>This page will create requests for your company administrators to fulfill.</p>
                  <p>Install the Spark Provisioning Engine</p>
                  <p>Create Spark Admin User</p>
                  <p>Create a demo Spark Site Collection</p>
                </Box>
              );
            }}
          />
        </Switch>
      </>
    );
  }
}

export default withRouter(RequestPage);

import React, { useEffect } from "react";
import DashboardSection from "./../../components/DashboardSection";

import { useRouter, Switch, Route, withRouter } from "./../../util/router";
import { useAuth } from "../../util/auth";
import SectionHeader from "../../components/SectionHeader";
import { SparkMenuPage } from "../../components/SparkMenu";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import { SharePointService } from "../../services/SharePointService";
import { AzureRMService } from "../../services/AzureRMService";
import { Button, Table } from "react-bulma-components/full";
import { APPS_BLOB_STORAGE_LIST } from "../../util/Constants";
import AzureStorageService from "../../services/AzureStorageService";
var convert = require("xml-js");

@observer
class CreateSitesPage extends React.Component<{ match; history }> {
  sharePointService = new SharePointService();
  azureService = new AzureRMService();
  storageService = new AzureStorageService();

  @observable newSiteUrlInput: string = "";
  @action setNewSiteUrlInput(input) {
    this.newSiteUrlInput = input;
  }

  @observable newSiteNameInput: string = "";
  @action setNewSiteNameInput(input) {
    this.newSiteNameInput = input;
  }
  async componentDidMount() {
    //  const rootUrl = (await this.sharePointService.getSharePointRootUrl()).webUrl;
  }
  render() {
    const Page = observer(() => (
      <>
        Create Sites
        <br />
        <input
          className="input is-medium"
          type={"text"}
          value={this.newSiteUrlInput}
          placeholder={"url"}
          onChange={e => {
            this.setNewSiteUrlInput(e.target.value);
          }}
        />
        <input
          className="input is-medium"
          type={"text"}
          value={this.newSiteNameInput}
          placeholder={"name"}
          onChange={e => {
            this.setNewSiteNameInput(e.target.value);
          }}
        />
        <Button
          onClick={async () => {
            const rootUrl = (await this.sharePointService.getSharePointRootUrl()).webUrl;
            const result = await this.sharePointService.createGroupSite(
              rootUrl,
              this.newSiteUrlInput,
              this.newSiteNameInput
            );
            if (result.d.CreateGroupEx.SiteUrl) {
              window.open(result.d.CreateGroupEx.SiteUrl, "_blank");
            }
          }}>
          Create Group Site
        </Button>
        <Button
          onClick={async () => {
            const rootUrl = (await this.sharePointService.getSharePointRootUrl()).webUrl;
            this.sharePointService.createTeamSite(rootUrl, "testsitetwhite4", "Test Title");
          }}>
          Create Team Site
        </Button>
      </>
    ));

    return <Page />;
  }
}

export default withRouter(CreateSitesPage);

import { persist, create } from "mobx-persist";

import { observable, action, computed } from "mobx";
import { AzureRMService } from "../services/AzureRMService";
import { SettingsPersistenceService } from "../services/SettingsPersistanceService";

export class ThemeStore {

  settingsPersistanceService = new SettingsPersistenceService();

  @persist("list") @observable tenantThemes: Array<{ name: string; themeJson: any }> = [];
  @action setTenantThemes(themes) {
    this.tenantThemes = themes;
  }

  @persist @observable selectedThemeName: string | null = null;
  @action setSelectedThemeByName(themeName: string) {
    this.selectedThemeName = themeName;
  }
  @computed get selectedTheme() {
    if (!this.selectedThemeName) return null;

    return this.tenantThemes.find(theme => theme.name === this.selectedThemeName);
  }
}

export const themeStore = new ThemeStore();

const hydrate = create({
  storage: window.localStorage, // or AsyncStorage in react-native.
  // default: localStorage
  jsonify: true // if you use AsyncStorage, here shoud be true
  // default: true
});

hydrate("ThemeStore", themeStore).then(() => {
  console.log("ThemeStore has been hydrated");
});

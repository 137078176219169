import { action, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
// import { Button, Loader, Table } from "react-bulma-components/full";

import { SparkMenuPage } from "../../components/SparkMenu";
import { AzureRMService } from "../../services/AzureRMService";
import { Link, Route, Switch, withRouter } from "../../util/router";
import ResourceGroupPage from "../resourcegroup";
import ResourceGroupsPage from "../resourcegroups";
import AssignAccessPage from "./AssignAccessPage";
import { Spinner, Stack, Box, Text, Button, Tooltip, Link as Link2 } from "@chakra-ui/core";

@observer
class SubscriptionsPage extends React.Component<{ match; history }> {
  azureService = new AzureRMService();

  @observable isLoading: boolean = false;
  @action setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }
  @observable subscriptions: Array<{ subscriptionId: string; displayName: string }> = [];
  @action setSubscriptions(subscriptions) {
    this.subscriptions = subscriptions;
  }

  async componentDidMount() {
    this.setIsLoading(true);
    const subscriptions = await this.azureService.getSubscriptions();
    this.setSubscriptions(subscriptions);

    this.setIsLoading(false);
  }
  render() {
    const SubscriptionItem = observer(({ name, id, onSelect }: { name: string; id: string; onSelect: () => void }) => {
      return (
        <Box>
          <Box alignItems={"space-around"} paddingRight={10}>
            <Link2> {name}</Link2>
            <Button backgroundColor={"themePrimary"} color={"white"} onClick={onSelect}>
              Select
            </Button>
            {/* TODO: better hover */}
          </Box>
        </Box>
      );
    });
    const SubscriptionsTable = observer(
      (): JSX.Element => {
        if (this.isLoading) {
          return <Spinner />;
        }
        return (
          <React.Fragment>
            {this.subscriptions.length === 0 && (
              <div>
                You don't have any available Azure Subscriptions.{" "}
                <Link to={"/tenantcheck"}>Check your list of administrators</Link> to request access to one.
              </div>
            )}
            <Stack>
              {/* // TODO: subscriptions vs subscription when you have 1 */}
              <Text>You have access to {this.subscriptions.length} Azure Subscriptions.</Text>
              <Box>
                {this.subscriptions.length > 0 &&
                  this.subscriptions?.map(sub => (
                    <SubscriptionItem
                      name={sub.displayName}
                      id={sub.subscriptionId}
                      onSelect={() => {
                        this.props.history.push(this.props.match.path + "/" + sub.subscriptionId);
                      }}
                    />
                  ))}
              </Box>
            </Stack>
          </React.Fragment>
        );
      }
    );

    return (
      <>
        <Switch>
          <Route exact path={`${this.props.match.path}/`} component={SubscriptionsTable} />
          <Route exact path={`${this.props.match.path}/:id`} component={ResourceGroupsPage} />
          <Route exact path={`${this.props.match.path}/:id/access`} component={AssignAccessPage} />
          <Route exact path={`${this.props.match.path}/:id/:name`} component={ResourceGroupPage} />
        </Switch>
      </>
    );
  }
}

export default withRouter(SubscriptionsPage);

import { persist, create } from "mobx-persist";

import { observable, action } from "mobx";
import { authProvider } from "../AuthProvider";
import { SettingsPersistenceService } from "../services/SettingsPersistanceService";

export class SettingsStore {

  settingsPersistanceService = new SettingsPersistenceService();

  @persist @observable showOnlySparkResources: boolean = true;
  @action setShowOnlySparkResources(showOnlySparkResources: boolean) {
    this.showOnlySparkResources = showOnlySparkResources;
  }

  @persist @observable showDebugPanel: boolean = true;
  @action setShowDebugPanel(showDebugPanel) {
    this.showDebugPanel = showDebugPanel;
  }

  @persist @observable enableMeganav: boolean = false;
  @action setEnableMeganav(enableMeganav: boolean) {
    this.enableMeganav = enableMeganav;
  }

  @action async persistSettingsToExtension() {
    await this.settingsPersistanceService.persistSettingsToExtensionForMe({
      showOnlySparkResources: this.showOnlySparkResources,
      enableMeganav: this.enableMeganav
    })
  }
}

export const settingsStore = new SettingsStore();

const hydrate = create({
  storage: window.localStorage, // or AsyncStorage in react-native.
  // default: localStorage
  jsonify: true // if you use AsyncStorage, here shoud be true
  // default: true
});

hydrate("SettingsStore", settingsStore).then(() => {
  console.log("SettingsStore has been hydrated");
});

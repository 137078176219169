import React from "react";
import {
  Box,
  Heading,
  Stack,
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  InputRightElement,
  Button,
  List,
  ListItem,
  Spinner,
  Flex,
  PseudoBox,
  Text,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  InputLeftAddon,
  Avatar,
  AvatarBadge
} from "@chakra-ui/core";
import { observable, action, toJS, computed, extendObservable } from "mobx";
import { AzureRMService } from "../../services/AzureRMService";
import { observer } from "mobx-react";
import { DelveImage } from "../UserPhotoEditor/DelveImage";
import { BatchingClient } from "../../services/AzureService";

export interface UserSearchBoxProps {
  onSelectUser: (user: microsoftgraph.User) => void;
}

interface IUser extends microsoftgraph.User {
  photoUrl?: string;
}

@observer
export class UserSearch extends React.Component<UserSearchBoxProps> {
  azureService = new AzureRMService();
  batchingClient = new BatchingClient();

  @observable isSearching: boolean = false;
  @action setIsSearching(isSearching: boolean) {
    this.isSearching = isSearching;
  }
  @observable searchText: string = "";
  @action setSearchText(text: string) {
    this.searchText = text;
  }
  @observable users: Array<Required<IUser>> = [];
  @action setUsers(users) {
    this.users = users;
  }
  @action setUserPhoto(userId: string, userPhotoUrl: string) {
    const userIndex = this.users.findIndex(user => user.id === userId);
    this.users[userIndex].photoUrl = userPhotoUrl;
  }

  @computed get displayedUsers(): Array<Required<IUser>> {
    return this.users;
  }

  @action async executeSearch() {
    this.setIsSearching(true);
    const users = await this.azureService.getUsersByDisplayNameStartsWith(this.searchText);

    // const batchedImages = await this.batchingClient.getUserImagesBatched(
    //   users.map(u => {
    //     return { id: u.id as string, width: "96", height: "96" };
    //   })
    // );

    // batchedImages.forEach(res => {
    //   const userIndex = users.findIndex(r => r.id === res.id);
    //   (users[userIndex] as any).photoUrl = res.url;
    // });
    this.setUsers(users);

    // //  batchedImages.forEach(result => this.setUserPhoto(result.id, result.url));

    console.log("USERS", users);
    await this.getPhotosForDisplayedUsers();
    this.setIsSearching(false);
  }

  @action async getPhotosForDisplayedUsers() {
    this.users.map(async user => {
      const photo = await this.azureService.getUserPhotoById(user.id, 96, 96);
      user.photoUrl = photo;
    });
  }

  render() {
    const User = observer(({ user, name, subTitle, src }) => {
      return (
        <Flex
          mt={5}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          onClick={() => this.props.onSelectUser(user)}>
          <Avatar size="md" src={src}>
            {/* <AvatarBadge bg="green.500" size="1.25em" borderColor="white" /> */}
          </Avatar>
          <Flex display="flex" flexDirection="column" alignItems="flex-start" justifyContent="flex-start" ml={3}>
            <Text>{name}</Text>
            <Text fontSize="xs">{subTitle}</Text>
          </Flex>
        </Flex>
      );
    });

    return (
      <Stack shouldWrapChildren spacing={5} p={5}>
        <Box>
          <InputGroup mb={3}>
            <InputLeftAddon>Search for a user</InputLeftAddon>
            <Input
              size="md"
              as="input"
              variant="outline"
              isFullWidth
              focusBorderColor="blue.500"
              errorBorderColor="red.500"
              onChange={(e: any) => {
                this.setSearchText(e.target.value);
              }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  this.executeSearch();
                }
              }}
            />
            <InputRightElement>
              <Icon name="search" />
            </InputRightElement>
          </InputGroup>
          {!this.isSearching && (
            <Box mt={5}>
              {this.users.map(user => {
                return <User user={user} name={user.displayName} subTitle={user.companyName} src={user.photoUrl} />;
              })}
            </Box>
          )}
        </Box>
      </Stack>
    );
  }
}

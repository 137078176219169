import { observable, action, computed } from "mobx";
import * as microsoftTeams from "@microsoft/teams-js";
import { TaskInfo } from "@microsoft/teams-js";
import { authProvider } from "../AuthProvider";
import jwt_decode from "jwt-decode"
import { CLIENT_ID } from "../util/Constants"

class MicrosoftTeamsStore {

  @observable teamsContext: microsoftTeams.Context | null = null;
  @action setTeamsContext(context: microsoftTeams.Context) {
    this.teamsContext = context;
  }

  @computed get isInMicrosoftTeams() {
    return !!this.teamsContext;
  }

  @observable subEntityId: Object | undefined;
  @action setSubEntityId(subEntityId) {
    this.subEntityId = subEntityId;

    if (subEntityId.route) {
      //  window.location.href = `${process.env.REACT_APP_PUBLIC_URL}/${subEntityId.route}`
    }
  }

  @computed get shouldShowNav() {
    return this.teamsContext && this.teamsContext.entityId;
  }

}

export const microsoftTeamsStore = new MicrosoftTeamsStore();

microsoftTeams.initialize(() => {
  console.log("INSIDE INITIALIZE CALLBACK");

  //microsoftTeams.sendMessageEventToChild("TomsActionName", ["these", "are", "args"]);
  //console.log(`Sent custom event to child`);
}, [process.env["REACT_APP_PUBLIC_URL"] as string]);
microsoftTeams.getContext(async (context) => {
  microsoftTeamsStore.setTeamsContext(context);
  console.log("TEAMS CONTEXT", context)
  // if (window.opener) {
  //   window.opener.msal = null;
  // }
  if (context.subEntityId) {
    const decodedSubEntity = decodeURIComponent(context.subEntityId);
    microsoftTeamsStore.setSubEntityId(JSON.parse(decodedSubEntity));
  }
  if (microsoftTeamsStore.isInMicrosoftTeams) {

    if (!authProvider.getAccount()) {
      await authProvider.loginRedirect();
    }

    // microsoftTeams.authentication.notifySuccess("success");

    // const url = new URL(`${process.env.REACT_APP_PUBLIC_URL}/authtab.html`, new URL(window.location.href));
    // url.searchParams.append('clientId', CLIENT_ID);

    // if (context.loginHint) {
    //   url.searchParams.append('loginHint', context.loginHint);
    // }

    // var scopes = [CLIENT_ID]
    // if (scopes) {
    //   url.searchParams.append('scopes', scopes.join(','));
    // }

    // var authTokenRequest = {
    //   successCallback: async function (result) {
    //     console.log("Success: " + result);
    //     const decodedToken = jwt_decode(result);
    //     console.log(decodedToken);

    //     // const onBehalfOfToken = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/token?token=${result}`, { method: 'GET' });
    //     // const j = await onBehalfOfToken.text();
    //     // console.log("ON BEHALF OF TOKEN", j);
    //     // const decodedONBehalfOfToken = jwt_decode(j);
    //     // console.log(decodedONBehalfOfToken)

    //     if (context && context.loginHint) {
    //       console.log("LOGIN HINT", context.loginHint);
    //       //     accessTokenRequest.loginHint = this.teamsContext.loginHint;
    //     }

    //     // const currentParent = window.parent;
    //     // if (document.referrer.startsWith('https://teams.microsoft.com/')) {
    //     //   (window as any).parent = window;
    //     // }
    //     try {
    //       const tokens = await authProvider.acquireTokenSilent({
    //         // authority: `https://login.microsoftonline.com/${decodedToken.tid}`,

    //         // scopes: [`754ad215-b82e-40e3-aa11-ac6b7d84b8d0`],
    //         scopes: [decodedToken.aud + "/" + decodedToken.scp],
    //         // loginHint: context.loginHint,
    //         //  redirectUri: `${process.env.REACT_APP_PUBLIC_URL}/auth.html`,
    //         //  sid: decodedToken.sid

    //       })
    //       console.log(`SILENT TOKEN ACQUIRE 4`, tokens)
    //     }
    //     catch (err) {
    //       console.log("ERROR", err);
    //       await authProvider.loginPopup({ scopes: [decodedToken.aud + "/" + decodedToken.scp] })
    //       console.log("aftger popo")
    //     }

    //     // const tokens2 = await authProvider.acquireTokenSilent({ scopes: ["https://graph.microsoft.com/.default"], sid: decodedToken.sid })
    //     // console.log("TOKENS2", tokens2);

    //   },
    //   failureCallback: function (error) { console.log("Failure: " + error); },
    //   //  url: url.href
    //   resources: [`api://sparkintranet.com/754ad215-b82e-40e3-aa11-ac6b7d84b8d0`]
    // };

    // if (!authProvider.getLoginInProgress()) {
    //   microsoftTeams.authentication.getAuthToken(authTokenRequest);
    // }
  }


});




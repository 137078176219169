
import { globalStore } from "../stores/GlobalStore";
import { authProvider } from "../AuthProvider";
import { taxonomy, ITerm } from "@pnp/sp-taxonomy";

export interface IMeganavLink {
    url: string;
    text: string;
    isSecured: boolean;
    target: string;
}
export interface IMeganavSubLink {
    heading: string;
    url: string;
    links: IMeganavLink[];
    isSecured?: boolean;
    target: string;
}
export interface IMeganavTopLink {
    heading: string;
    guid: string;
    subLinks: IMeganavSubLink[];
    isSecured?: boolean;
    url?: string;
}

export interface ITermLite {
    parent?: ITermLite | null;
    parentId?: string | null;
    rawTerm: ITerm | null;
    title: string;
    guid: string;
    customProperties?: any;
    localProperties?: any;
    labels?: Array<string>;
    children?: ITermLite[];
    depth: number;
    path: string;
    customSortOrder: string | null;
}

export default class TaxonomyAPI {

    public static async getTermSetAsTree(siteCollectionURL: string, termsetId: string, authority?: string): Promise<ITermLite> {

        authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

        const token = await authProvider.getAccessToken({ scopes: [`${siteCollectionURL}/TermStore.Read.All`], authority: authority });
        taxonomy.setup({
            sp: {
                headers: {
                    Authorization: `Bearer ${token.accessToken}`
                    // "X-RequestDigest": formDigestValue
                },
                baseUrl: siteCollectionURL
            }
        });

        const store = await taxonomy.getDefaultSiteCollectionTermStore();
        //store.getTermSetById(termsetId).terms.select('Name', 'Id', 'Parent').get()
        let termset = await store.getTermSetById(termsetId).terms.select('Name', 'Id', 'Parent', 'PathOfTerm', 'CustomProperties', 'CustomSortOrder', 'LocalCustomProperties').get()

        let rawTerms = termset;
        let maxDepth = 0;
        (rawTerms as any).forEach(x => {
            x.PathDepth = x.PathOfTerm.split(';').length;

            if (x["Parent"]) {
                x.ParentId = TaxonomyAPI.cleanGuid(x["Parent"].Id);
            }

            if (x.PathDepth > maxDepth) {
                maxDepth = x.PathDepth;
            }
        });
        let terms = (rawTerms as any).map(x => TaxonomyAPI.createTerm(x));
        let root = terms.filter(x => x.depth == 1)[0];
        root.parent = null;
        root.parentId = null;

        for (let i = 2; i <= maxDepth; i++) {
            let items = terms.filter(x => x.depth == i);

            if (i == 2) {
                items.forEach(item => {
                    item.parent = root;
                });
                root.children = items;
            } else {
                // get previous depth items
                let previousDepthItems = terms.filter(x => x.depth == i - 1);

                // get current depth items
                let currentDepthItems = terms.filter(x => x.depth == i);
                currentDepthItems.forEach(x => {
                    // find which parent the child belongs to, should only be 1 parent
                    let parent = previousDepthItems.filter(y => y.guid == x.parentId);
                    if (parent.length > 0) {
                        // attach itself to parent
                        x.parent = parent[0];
                        x.parentId = parent[0].guid;
                        parent[0].children.push(x);
                    } else {
                        console.log("Could not find parent for", x);
                    }
                });
            }
        }

        TaxonomyAPI.sortTree(root);
        return root;
    }

    public static walkTree(termsToSearch: ITermLite[], fn: Function, fnCancel?: Function) {
        for (let i = 0; i < termsToSearch.length; i++) {
            let item = termsToSearch[i];

            if (fn) {
                fn(item);
            }

            if (fnCancel && fnCancel()) {
                break;
            }

            TaxonomyAPI.walkTree(item.children as ITermLite[], fn, fnCancel);
        }
    }


    private static createTerm(term: any) {
        let item: ITermLite = {
            rawTerm: term,
            title: term.Name,
            guid: TaxonomyAPI.cleanGuid(term.Id),
            customProperties: term["CustomProperties"],
            localProperties: term["LocalCustomProperties"],
            children: [],
            depth: term.PathDepth || 0,
            path: term.PathOfTerm,
            parentId: term.ParentId,
            customSortOrder: term["CustomSortOrder"]
        };

        return item;
    }

    private static sortTree(tree: ITermLite) {
        if (!tree.children) {
            throw new Error("Had no children.")
        }

        // if not null, the custom sort order is a string of GUIDs, delimited by a :
        if (tree.customSortOrder) {
            let sortOrder = tree.customSortOrder.split(":");

            tree.children.sort(function (a, b) {
                let indexA = sortOrder.indexOf(a.guid);
                let indexB = sortOrder.indexOf(b.guid);

                if (indexA > indexB) {
                    return 1;
                } else if (indexA < indexB) {
                    return -1;
                }

                return 0;
            });
        }
        // if null, terms are just sorted alphabetically
        else {
            tree.children.sort(function (a, b) {
                if (a.title > b.title) {
                    return 1;
                } else if (a.title < b.title) {
                    return -1;
                }

                return 0;
            });
        }

        for (let i = 0; i < tree.children.length; i++) {
            tree.children[i] = TaxonomyAPI.sortTree(tree.children[i]);
        }

        return tree;
    }

    private static cleanGuid(guid: string): string {
        if (guid !== undefined) {
            return guid.replace('/Guid(', '').replace('/', '').replace(')', '');
        } else {
            return '';
        }
    }
}

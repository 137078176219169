import { authProvider } from "../AuthProvider";
import * as pnp from "@pnp/sp";
import { SparkTenantState } from "../pages/setup";
import { escapeXml } from "../util/Utility";
import { Session, taxonomy, ITermSet, ITermStore, ITerm } from "@pnp/sp-taxonomy";
import termstore from "../pages/termstore";
import { cancelFetchOnReentry } from "../util/FetchCanceller";
import { globalStore } from "../stores/GlobalStore";
import { SharePointApp } from "./types";

export class SharePointService {
  public async getSharePointRootUrl(authority?: string): Promise<{ webUrl: string; id: string }> {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;
    try {
      const token = await authProvider.getAccessToken({
        scopes: [`https://graph.microsoft.com/user.read`],
        authority: authority
      });
      const resp = await fetch("https://graph.microsoft.com/beta/sites/root", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json"
        }
      });
      if (resp.status === 401) {
        throw new Error("Can't access SP root url")
      }
      let responseJson = await resp.json();
      return { webUrl: responseJson.webUrl, id: responseJson.id };
    } catch (err) {
      throw err;
    }
  }

  public async getTenantThemes(tenantUrl: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;


    const token = await authProvider.getAccessToken({
      scopes: [`${tenantUrl}/Sites.FullControl.All`],
      authority: authority,
    });

    const requestDigest = await this.getContextInfo(tenantUrl, tenantUrl);
    const themesResponse = await fetch(`${tenantUrl}/_api/thememanager/GetTenantThemingOptions`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${token.accessToken}`,
        accept: "application/json; odata.metadata=minimal",
        "content-type": "application/json;odata=nometadata;charset=utf-8",
        "X-RequestDigest": requestDigest.FormDigestValue,
        "ODATA-VERSION": "4.0"
      }
    });
    const themes = await themesResponse.json();
    console.log("THEMES", themes);
    if (themes["@odata.null"]) {
      return []
    }
    return themes.themePreviews.map(theme => {
      return { name: theme.name, themeJson: JSON.parse(theme.themeJson) };
    });
  }

  public getSharePointAdminUrl(tenantUrl: string) {
    return tenantUrl.replace(/(https:\/\/)([^\.]+)(.*)/, "$1$2-admin$3");
  }

  public async getTenantSettings(tenantUrl, appCatalogUrl, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/Sites.FullControl.All`], authority: authority });

    const resp = await fetch(`${appCatalogUrl}/_api/web/AllProperties?$select=storageentitiesindex`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,

        accept: "application/json;odata=nometadata"
      }
    });
    let responseJson = await resp.json();
    let storageentitiesindex = responseJson.storageentitiesindex;

    return JSON.parse(storageentitiesindex);
  }

  public async getTenantSetting(tenantUrl: string, keyName: string, authority?: string): Promise<string> {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/Sites.FullControl.All`], authority: authority });

    const resp = await fetch(`${tenantUrl}/_api/web/GetStorageEntity('${encodeURIComponent(keyName)}')`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,

        accept: "application/json;odata=nometadata"
      }
    });
    let responseJson = await resp.json();
    return JSON.parse(responseJson);
  }

  async getContextInfo(
    tenantUrl,
    siteToGetContextFor: string,
    authority?: string
  ): Promise<{ FormDigestValue: string }> {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/AllSites.Manage`], authority: authority });

    const resp = await fetch(`${siteToGetContextFor}/_api/contextinfo`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        accept: "application/json;odata=nometadata"
      }
    });

    let responseJson = await resp.json();
    if (responseJson["odata.error"]) {
      throw new Error(responseJson["odata.error"].message.value);
    }
    return responseJson;
  }

  async getSharePointAppCatalogUrl(tenantUrl, requestDigest, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    console.log("AUTHORITY", authority);
    const token = await authProvider.getAccessToken({
      scopes: [`${tenantUrl}/AllSites.Manage`],
      authority: authority
      // authority: `https://login.microsoftonline.com/common`,
      // account: authProvider.getAccount(),
      // prompt: "consent"
    });
    const resp = await fetch(`${tenantUrl}/_api/SP_TenantSettings_Current`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json",
        Accept: "application/json;odata=nometadata",
        "X-RequestDigest": requestDigest
      }
    });
    let responseJson = await resp.json();
    return responseJson.CorporateCatalogUrl;
  }

  async getSharePointAppCatalogUrlWithGraph(id) {
    const authority = globalStore.userCurrentAADDirectory
      ? `https://login.microsoftonline.com/${globalStore.userCurrentAADDirectory}`
      : authProvider.authority;
    console.log("AUTHORITY", authority);
    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Sites.FullControl.All`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/beta/sites/${id}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });
    let responseJson = await resp.json();
    return responseJson;
  }

  async searchSharePoint(tenantUrl: string, searchQuery: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`${tenantUrl}/Sites.FullControl.All`],
      authority: authority
    });

    pnp.sp.setup({
      sp: {
        headers: {
          authorization: `Bearer ${token.accessToken}`
        },
        baseUrl: tenantUrl
      }
    });

    return await pnp.sp.search(searchQuery);
  }

  public async setTenantSetting(
    tenantUrl: string,
    appCatalogUrl: string,
    formDigestValue: string,
    keyName: string,
    keyDescription: string,
    keyValue: string,
    comment: string
  ) {

    const adminUrl = this.getSharePointAdminUrl(tenantUrl);
    const requestOptions: any = {
      method: "POST",
      url: `${appCatalogUrl}/_vti_bin/client.svc/ProcessQuery`,
      headers: {
        "X-RequestDigest": formDigestValue
      },
      body: `<Request AddExpandoFieldTypeSuffix="true" SchemaVersion="15.0.0.0" LibraryVersion="16.0.0.0" ApplicationName="SparkProvisioning" xmlns="http://schemas.microsoft.com/sharepoint/clientquery/2009"><Actions><ObjectPath Id="24" ObjectPathId="23" /><ObjectPath Id="26" ObjectPathId="25" /><ObjectPath Id="28" ObjectPathId="27" /><Method Name="SetStorageEntity" Id="29" ObjectPathId="27"><Parameters><Parameter Type="String">${keyName}</Parameter><Parameter Type="String">${keyValue}</Parameter><Parameter Type="String">${keyDescription}</Parameter><Parameter Type="String">${comment}</Parameter></Parameters></Method></Actions><ObjectPaths><Constructor Id="23" TypeId="{268004ae-ef6b-4e9b-8425-127220d84719}" /><Method Id="25" ParentId="23" Name="GetSiteByUrl"><Parameters><Parameter Type="String">${appCatalogUrl}</Parameter></Parameters></Method><Property Id="27" ParentId="25" Name="RootWeb" /></ObjectPaths></Request>`
    };

    return fetch(requestOptions.url, requestOptions);
  }

  async uploadAppToStore(
    appName,
    tenantUrl,
    appCatalogUrl,
    requestDigest,
    requestBody,
    siteCollectionAppCatalog?: boolean,
    authority?: string
  ) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/Sites.FullControl.All`], authority: authority });

    const solutionFileName = appName;

    let appCatalogType = "tenantappcatalog";
    if (siteCollectionAppCatalog) {
      appCatalogType = "sitecollectionappcatalog";
    }
    return fetch(`${appCatalogUrl}/_api/web/${appCatalogType}/Add(overwrite=true, url='${solutionFileName}')`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${token.accessToken}`,
        accept: "application/json;odata=nometadata",
        "X-RequestDigest": requestDigest,
        binaryStringRequestBody: "true"
      },
      body: requestBody
    }).then(resp => {
      return resp.json();
    });
  }

  async getAppsOnTenant(tenantUrl: string, appCatalogUrl: string, requestDigest: string, authority?: string): Promise<Array<SharePointApp>> {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/Sites.FullControl.All`], authority: authority });

    const appsResp = await fetch(`${appCatalogUrl}/_api/web/tenantappcatalog/AvailableApps`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token.accessToken}`,
        accept: "application/json;odata=nometadata",
        "content-type": "application/json;odata=nometadata;charset=utf-8",
        "X-RequestDigest": requestDigest
      }
    });

    const json = await appsResp.json();
    return json.value;
  }

  async deployApp(
    solutionId,
    tenantUrl,
    appCatalogUrl,
    requestDigest,
    skipFeatureDeployment,
    siteCollectionAppCatalog?: boolean,
    authority?: string
  ) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/Sites.FullControl.All`], authority: authority });

    let appCatalogType = "tenantappcatalog";
    if (siteCollectionAppCatalog) {
      appCatalogType = "sitecollectionappcatalog";
    }

    return fetch(`${appCatalogUrl}/_api/web/${appCatalogType}/AvailableApps/GetById('${solutionId}')/deploy`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${token.accessToken}`,
        accept: "application/json;odata=nometadata",
        "content-type": "application/json;odata=nometadata;charset=utf-8",
        "X-RequestDigest": requestDigest
      },
      body: JSON.stringify({ skipFeatureDeployment: skipFeatureDeployment })
    });
  }

  async getHiddenStateStoreDataByKey(tenantUrl: string, key: string, authority?: string) {
    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/Sites.FullControl.All`], authority: authority });

    pnp.sp.setup({
      sp: {
        headers: {
          authorization: `Bearer ${token.accessToken}`
        },
        baseUrl: tenantUrl
      }
    });

    const web = new pnp.Web(tenantUrl);

    try {
      const data = await web.lists
        .getByTitle("Spark")
        .items.select("Title,Data")
        .filter(`Title eq '${key}'`)
        .top(1)
        .get<{ Data: string }>();
    } catch (err) {
      alert("err");
    }
  }

  async setHiddenStateStoreDataByKey(tenantUrl: string, key: string, entryType: string, data: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/Sites.FullControl.All`], authority: authority });
    pnp.sp.setup({
      sp: {
        headers: {
          authorization: `Bearer ${token.accessToken}`
        },
        baseUrl: tenantUrl
      }
    });

    try {
      const web = new pnp.Web(tenantUrl);

      const list = await web.lists.getByTitle("Spark");

      await list.items.add({ Title: key, Data: data, EntryType: entryType });
    } catch (err) {
      alert("err");
    }
  }

  async addLogEntryToHiddenList(tenantUrl: string, title: string, data: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/Sites.FullControl.All`], authority: authority });

    pnp.sp.setup({
      sp: {
        headers: {
          authorization: `Bearer ${token.accessToken}`
        },
        baseUrl: tenantUrl
      }
    });

    const web = new pnp.Web(tenantUrl);

    return await web.lists.getByTitle("Spark").items.add({ Title: title, Data: data, EntryType: "Log" });
  }

  async getLogEntriesFromHiddenList(tenantUrl: string, authority?: string) {
    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/AllSites.Manage`], authority: authority });

    pnp.sp.setup({
      sp: {
        headers: {
          authorization: `Bearer ${token.accessToken}`
        },
        baseUrl: tenantUrl
      }
    });

    const web = new pnp.Web(tenantUrl);

    const data = await web.lists
      .getByTitle("Spark")
      .items.select("Title,Data,EntryType,Created,Author/Title")
      .expand("Author/Title")
      .filter(`EntryType eq 'Log'`)
      .orderBy(`Created`, false)
      .get<Array<{ Title: string; Data: string; Created: Date; EntryType: string; Author: { Title: string } }>>();

    return data;
  }

  async getSparkAdministratorEntriesFromHiddenList(tenantUrl: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/AllSites.Manage`], authority: authority });

    pnp.sp.setup({
      sp: {
        headers: {
          authorization: `Bearer ${token.accessToken}`
        },
        baseUrl: tenantUrl
      }
    });

    const web = new pnp.Web(tenantUrl);

    const data = await web.lists
      .getByTitle("Spark")
      .items.select("ID,Title,Data,EntryType,Created,Author/Title")
      .expand("Author/Title")
      .filter(`EntryType eq 'SparkAdministrators'`)
      .orderBy(`Created`, false)
      .get<
        Array<{ ID: number; Title: string; Data: string; Created: Date; EntryType: string; Author: { Title: string } }>
      >();

    return data;
  }

  async deleteSparkAdministratorFromHiddenList(tenantUrl: string, userEmail: string, authority?: string): Promise<void> {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/AllSites.Manage`], authority });

    pnp.sp.setup({
      sp: {
        headers: {
          authorization: `Bearer ${token.accessToken}`
        },
        baseUrl: tenantUrl
      }
    });

    const web = new pnp.Web(tenantUrl);

    const data = await web.lists
      .getByTitle("Spark")
      .items.select("ID,Title,Data,EntryType,Created,Author/Title")
      .expand("Author/Title")
      .filter(`EntryType eq 'SparkAdministrators'`)
      .orderBy(`Created`, false)
      .get<
        Array<{ ID: number; Title: string; Data: string; Created: Date; EntryType: string; Author: { Title: string } }>
      >();

    let userToDelete = data.find(adminUser => {
      const userData = JSON.parse(adminUser.Data);
      if (userData.user === userEmail) {
        return true;
      }
    });

    if (!userToDelete) {
      throw Error("There should be a user to delete here.");
    }

    await web.lists
      .getByTitle("Spark")
      .items.getById(userToDelete.ID)
      .delete();
  }

  async addSparkAdministratorsEntryToHiddenList(tenantUrl: string, title: string, data: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/AllSites.Manage`], authority: authority });

    pnp.sp.setup({
      sp: {
        headers: {
          authorization: `Bearer ${token.accessToken}`
        },
        baseUrl: tenantUrl
      }
    });

    const web = new pnp.Web(tenantUrl);

    return await web.lists
      .getByTitle("Spark")
      .items.add({ Title: title, Data: data, EntryType: "SparkAdministrators" });
  }

  async ensureUserOnRootTenantUrl(tenantUrl: string, userName: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/Sites.FullControl.All`], authority: authority });

    pnp.sp.setup({
      sp: {
        headers: {
          authorization: `Bearer ${token.accessToken}`
        },
        baseUrl: tenantUrl
      }
    });
    try {
      await pnp.sp.web.ensureUser(userName);
    } catch (err) {
      throw Error("User isn't found on tenant yet.");
    }

    const groups = await pnp.sp.web.siteGroups.get();
    const ownersGroup = groups.find(g => g.Title.indexOf("Owners") !== -1);
    if (!ownersGroup) {
      throw new Error("Owners group not found.");
    }

    return await pnp.sp.web.siteGroups.getByName(ownersGroup.Title).users.add("i:0#.f|membership|" + userName);
  }

  async sendEmail(tenantUrl: string, to: Array<string>, from: string, subject: string, body: string) {
    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/AllSites.Manage`] });

    pnp.sp.setup({
      sp: {
        headers: {
          authorization: `Bearer ${token.accessToken}`
        },
        baseUrl: tenantUrl
      }
    });

    const emailProps: pnp.EmailProperties = {
      To: to,
      From: from,
      Subject: subject,
      Body: body
    };
    await pnp.sp.utility.sendEmail(emailProps);
  }

  async addColumnToHiddenList(tenantUrl: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/Sites.FullControl.All`], authority: authority });
    pnp.sp.setup({
      sp: {
        headers: {
          authorization: `Bearer ${token.accessToken}`
        },
        baseUrl: tenantUrl
      }
    });

    const web = new pnp.Web(tenantUrl);
    const list = await web.lists.getByTitle("Spark");
    await list.fields.addChoice("EntryType", ["Log"]);
  }

  async addChoiceField(tenantUrl: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;
    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/Sites.FullControl.All`], authority: authority });
    pnp.sp.setup({
      sp: {
        headers: {
          authorization: `Bearer ${token.accessToken}`
        },
        baseUrl: tenantUrl
      }
    });

    var addChoicesToListsChoiceField = (listName, choiceFieldName, newChoices, authority?: string) => {
      return new Promise((resolve, reject) => {
        authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

        // Field object
        let field = pnp.sp.web.lists.getByTitle(listName).fields.getByInternalNameOrTitle(choiceFieldName);

        // Getting current choice values
        field
          .select("Choices")
          .get()
          .then(fieldData => {
            // Append only unique choices
            let choices = fieldData.Choices.results || fieldData.Choices; // For verbose & minimalmetadata
            let newUniqueChoices = newChoices.reduce((res, val) => {
              if (choices.indexOf(choices) === -1) {
                res.push(val);
              }
              return res;
            }, []);

            // Update choice field with new array of choices
            field
              .update(
                {
                  Choices: {
                    results: choices.concat(newUniqueChoices)
                  }
                } as any,
                "SP.FieldChoice"
              )
              .then(results => {
                resolve(results);
              });
          });
      });
    };

    addChoicesToListsChoiceField("Spark", "EntryType", ["SparkAdministrators"]).then(console.log);
  }

  getOrCreateHiddenStateStoreListKey = cancelFetchOnReentry(() => async (tenantUrl: string, authority?: string): Promise<
    { Data: string } | undefined
  > => {

    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`${tenantUrl}/AllSites.Manage`],
      authority: authority
    });
    pnp.sp.setup({
      sp: {
        headers: {
          authorization: `Bearer ${token.accessToken}`
        },
        baseUrl: tenantUrl
      }
    });

    const web = new pnp.Web(tenantUrl);

    try {
      const data = await web.lists
        .getByTitle("Spark")
        .items.select("Title,Data")
        .filter("Title eq 'SparkSetup'")
        .top(1)
        .get<{ Data: string }>();

      const dataJson = data[0]["Data"];
      return JSON.parse(dataJson);
    } catch (err) {
      console.log(err);

      const requestDigest = await this.getContextInfo(tenantUrl, tenantUrl);
      var data = {
        // __metadata: {
        //   type: "SP.List"
        // },
        BaseTemplate: 100,
        Description: "Stores Spark data",
        Title: "Spark",
        Hidden: true
      };

      try {
        const resp = await fetch(`${tenantUrl}/_api/web/lists`, {
          method: "POST",
          headers: {
            authorization: `Bearer ${token.accessToken}`,
            accept: "application/json;odata=nometadata",
            "content-type": "application/json;odata=nometadata;charset=utf-8",
            "X-RequestDigest": requestDigest.FormDigestValue
          },
          body: JSON.stringify(data)
        });

        console.log("RESP", resp);

        if (resp.status === 403) {
          throw new Error("User can't create list");
        }

        console.log("Created Config List", data);

        const list = await web.lists.getByTitle("Spark");
        await list.fields.addText("Data");
        await list.fields.addChoice("EntryType", ["Log"]);
        await list.fields.addChoice("EntryType", ["SparkAdministrators"]);

        await list.items.add({ Title: "SparkSetup", Data: JSON.stringify({ status: "starting" }) });
      } catch (err) {
        throw Error("User probably doesn't have site permissions");
      }
    }
  });

  async getOrCreateList(tenantUrl: string, listName: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`${tenantUrl}/AllSites.Manage`],
      authority: authority
    });

    pnp.sp.setup({
      sp: {
        headers: {
          authorization: `Bearer ${token.accessToken}`
        },
        baseUrl: tenantUrl
      }
    });

    const web = new pnp.Web(tenantUrl);

    try {
      return await web.lists.getByTitle(listName).get();
    } catch (err) {
      return await web.lists.add(listName);
    }
  }

  async deleteList(tenantUrl: string, listName: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`${tenantUrl}/AllSites.Manage`],
      authority: authority
    });

    pnp.sp.setup({
      sp: {
        headers: {
          authorization: `Bearer ${token.accessToken}`
        },
        baseUrl: tenantUrl
      }
    });

    const web = new pnp.Web(tenantUrl);

    return await web.lists.getByTitle(listName).delete();
  }

  async persistTenantStateToHiddenList(tenantUrl: string, newState: SparkTenantState, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/Sites.FullControl.All`], authority: authority });

    pnp.sp.setup({
      sp: {
        headers: {
          authorization: `Bearer ${token.accessToken}`
        },
        baseUrl: tenantUrl
      }
    });

    const web = new pnp.Web(tenantUrl);

    const items = await web.lists
      .getByTitle("Spark")
      .items.select("Title,Data,Id")
      .filter("Title eq 'SparkSetup'")
      .top(1)
      .get<Array<{ Data: string; Id: number }>>();

    return await pnp.sp.web.lists
      .getByTitle("Spark")
      .items.getById(items[0].Id)
      .update({
        Data: JSON.stringify(newState)
      });
  }

  async getExistingDeploymentStatus(tenantUrl: string, authority?: string): Promise<{ Data: string } | undefined> {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/Sites.FullControl.All`], authority: authority });

    pnp.sp.setup({
      sp: {
        headers: {
          authorization: `Bearer ${token.accessToken}`
        },
        baseUrl: tenantUrl
      }
    });

    const web = new pnp.Web(tenantUrl);

    const data = await web.lists
      .getByTitle("Spark")
      .items.select("Title,Data")
      .filter("Title eq 'Deployment'")
      .top(1)
      .get<{ Data: string }>();

    //await web.lists.getByTitle("Spark").delete();
    console.log("DATA", data);

    const dataJson = data[0]["Data"];
    return JSON.parse(dataJson);
  }

  public async createDeploymentRecordInHiddenList(
    tenantUrl: string,
    deployment: { deploymentId: string; deploymentStartTime: string; deploymentStatus: string },
    authority?: string
  ) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/Sites.FullControl.All`], authority: authority });

    pnp.sp.setup({
      sp: {
        headers: {
          authorization: `Bearer ${token.accessToken}`
        },
        baseUrl: tenantUrl
      }
    });

    const web = new pnp.Web(tenantUrl);

    const items = await web.lists
      .getByTitle("Spark")
      .items.add({ Title: "Deployment", Data: JSON.stringify(deployment) });
  }

  public async updateDeploymentRecord(
    tenantUrl: string,
    deployment: {
      deploymentId?: string;
      deploymentStartTime?: string;
      deploymentStatus?: string;
      functionAppName?: string;
    },
    authority?: string
  ) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/Sites.FullControl.All`], authority: authority });

    pnp.sp.setup({
      sp: {
        headers: {
          authorization: `Bearer ${token.accessToken}`
        },
        baseUrl: tenantUrl
      }
    });
    const web = new pnp.Web(tenantUrl);

    const items = await web.lists
      .getByTitle("Spark")
      .items.select("Title,Data,Id")
      .filter("Title eq 'Deployment'")
      .top(1)
      .get<Array<{ Data: string; Id: number }>>();

    return await pnp.sp.web.lists
      .getByTitle("Spark")
      .items.getById(items[0].Id)
      .update({
        Data: JSON.stringify(deployment)
      });
  }

  public async createSiteCollectionAppCatalog(tenantUrl: string, siteUrl: string) {
    const adminUrl = this.getSharePointAdminUrl(tenantUrl);
    const requestDigest = await this.getContextInfo(tenantUrl, siteUrl);

    const authority = globalStore.userCurrentAADDirectory
      ? `https://login.microsoftonline.com/${globalStore.userCurrentAADDirectory}`
      : authProvider.authority;
    console.log("AUTHORITY", authority);
    const token = await authProvider.getAccessToken({ scopes: [`${adminUrl}/AllSites.Manage`], authority: authority });

    await fetch(`${adminUrl}/_vti_bin/client.svc/ProcessQuery`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
        "X-RequestDigest": requestDigest.FormDigestValue
      },
      body: `<Request AddExpandoFieldTypeSuffix="true" SchemaVersion="15.0.0.0" LibraryVersion="16.0.0.0" ApplicationName="SharePoint Online PowerShell (16.0.7206.0)" xmlns="http://schemas.microsoft.com/sharepoint/clientquery/2009"><Actions><ObjectPath Id="38" ObjectPathId="37" /><ObjectPath Id="40" ObjectPathId="39" /><ObjectPath Id="42" ObjectPathId="41" /><ObjectPath Id="44" ObjectPathId="43" /><ObjectPath Id="46" ObjectPathId="45" /><ObjectPath Id="48" ObjectPathId="47" /></Actions><ObjectPaths><Constructor Id="37" TypeId="{268004ae-ef6b-4e9b-8425-127220d84719}" /><Method Id="39" ParentId="37" Name="GetSiteByUrl"><Parameters><Parameter Type="String">${escapeXml(
        siteUrl
      )}</Parameter></Parameters></Method><Property Id="41" ParentId="39" Name="RootWeb" /><Property Id="43" ParentId="41" Name="TenantAppCatalog" /><Property Id="45" ParentId="43" Name="SiteCollectionAppCatalogsSites" /><Method Id="47" ParentId="45" Name="Add"><Parameters><Parameter Type="String">${escapeXml(
        siteUrl
      )}</Parameter></Parameters></Method></ObjectPaths></Request>`
    });
  }

  public async createGroupSite(
    tenantUrl: string,
    siteLeaf: string,
    siteTitle: string
  ): Promise<{ d: { CreateGroupEx: { SiteId: string; SiteStatus: string; SiteUrl: string } } }> {
    // /_api/SPSiteManager/Create
    const adminUrl = this.getSharePointAdminUrl(tenantUrl);
    const requestDigest = await this.getContextInfo(tenantUrl, tenantUrl);

    const authority = globalStore.userCurrentAADDirectory
      ? `https://login.microsoftonline.com/${globalStore.userCurrentAADDirectory}`
      : authProvider.authority;
    console.log("AUTHORITY", authority);
    const token = await authProvider.getAccessToken({
      scopes: [`${tenantUrl}/Sites.FullControl.All`],
      authority: authority
    });

    const metadata = {
      // request: {
      //     __metadata: { type: "Microsoft.SharePoint.Portal.SPSiteCreationRequest" },
      //     WebTemplate: "STS#3",
      //     Title: siteTitle,
      //     Url: tenantUrl + "/sites/" + siteLeaf,
      //     Description: "",
      //     Classification: "",
      //     Lcid: 1033,
      //     ShareByEmailEnabled: false,
      //     WebTemplateExtensionId: "00000000-0000-0000-0000-000000000000"
      // }
      displayName: siteTitle,
      alias: siteTitle,
      isPublic: true
    };

    const resp = await fetch(`${tenantUrl}/_api/GroupSiteManager/CreateGroupEx`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
        Accept: "application/json;odata=verbose",
        "Content-Type": "application/json;odata=verbose",
        "X-RequestDigest": requestDigest.FormDigestValue
      },
      body: JSON.stringify(metadata)
    });

    return resp.json();
  }


  public async createTeamSite(
    tenantUrl: string,
    siteLeaf: string,
    siteTitle: string
  ): Promise<{ d: { Create: { SiteId: string; SiteStatus: string; SiteUrl: string } } }> {
    // /_api/SPSiteManager/Create
    const adminUrl = this.getSharePointAdminUrl(tenantUrl);
    //  const requestDigest = await this.getContextInfo(tenantUrl, adminUrl);

    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/Sites.FullControl.All`] });

    const metadata = {
      request: {
        __metadata: { type: "Microsoft.SharePoint.Portal.SPSiteCreationRequest" },
        WebTemplate: "STS#3",
        Title: siteTitle,
        Url: tenantUrl + "/sites/" + siteLeaf,
        Description: "",
        Classification: "",
        Lcid: 1033,
        ShareByEmailEnabled: false,
        WebTemplateExtensionId: "00000000-0000-0000-0000-000000000000"
      }
    };

    const resp = await fetch(`${tenantUrl}/_api/SPSiteManager/Create`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
        Accept: "application/json;odata=verbose",
        "Content-Type": "application/json;odata=verbose"
        //    "X-RequestDigest": requestDigest.FormDigestValue
      },
      body: JSON.stringify(metadata)
    });

    return resp.json();
  }

  public async getSiteStatus(
    tenantUrl: string,
    url: string
  ): Promise<{ d: { Status: { SiteId: string; SiteStatus: string; SiteUrl: string } } }> {
    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/Sites.FullControl.All`] });
    const resp = await fetch(`${tenantUrl}/_api/spsitemanager/Status`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
        Accept: "application/json;odata=verbose",
        "Content-Type": "application/json;odata=verbose"
        //    "X-RequestDigest": requestDigest.FormDigestValue
      },
      body: JSON.stringify({ url: url })
    });

    return resp.json();
  }

  public async getSiteUrlFromId(tenantUrl: string, siteId: string): Promise<{ d: { SiteUrl: string } }> {
    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/Sites.FullControl.All`] });
    const resp = await fetch(`${tenantUrl}/_api/spsitemanager/SiteUrl`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
        Accept: "application/json;odata=verbose",
        "Content-Type": "application/json;odata=verbose"
        //    "X-RequestDigest": requestDigest.FormDigestValue
      },
      body: JSON.stringify({ siteId: siteId })
    });

    return resp.json();
  }

  public async getAvailableAppsFromSiteCollectionAppCatalog(tenantUrl: string, siteUrl: string) {
    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/Sites.FullControl.All`] });
    const resp = await fetch(`${siteUrl}/_api/web/SiteCollectionAppCatalog/AvailableApps`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
        Accept: "application/json;odata=verbose",
        "Content-Type": "application/json;odata=verbose"
        //    "X-RequestDigest": requestDigest.FormDigestValue
      }
    });

    return resp.json();
  }

  public async getHomeServiceInfo(tenantUrl: string) {
    //  https://contoso.sharepoint.com/_api/sphomeservice/context?$expand=Token,Payload

    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/Sites.FullControl.All`] });
    const resp = await fetch(`${tenantUrl}/_api/sphomeservice/context?$expand=Token,Payload`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
        Accept: "application/json;odata=verbose",
        "Content-Type": "application/json;odata=verbose"
        //    "X-RequestDigest": requestDigest.FormDigestValue
      }
    });
    return await resp.json();
  }

  public async getTaxonomy(tenantUrl: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/TermStore.Read.All`], authority: authority });
    taxonomy.setup({
      sp: {
        headers: {
          Authorization: `Bearer ${token.accessToken}`
          // "X-RequestDigest": formDigestValue
        },
        baseUrl: tenantUrl
      }
    });

    let taxonomyReturnObject: Array<any> = [];
    const termStore = await taxonomy.getDefaultSiteCollectionTermStore();

    const group = await termStore.groups.getByName("SPARK");
    const siteMap = await group.termSets.getByName("SiteMap");
    const siteMapTerms = await siteMap.terms.select("Id", "Labels").get();
    // groups.forEach(async group => {
    //   const termSets = await group.termSets.get();
    //   console.log("termSets", termSets);

    //   termSets.forEach(async termSet => {
    //     const terms = await termSet.terms.get();
    //     console.log("TERMS", terms);
    //     taxonomyReturnObject = taxonomyReturnObject.concat(...terms);
    //   });
    // });

    return siteMapTerms;
  }

  public async getTermsetWithChildren(siteCollectionURL: string, termsetId: string, authority?: string) {

    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({ scopes: [`${siteCollectionURL}/TermStore.Read.All`], authority: authority });
    taxonomy.setup({
      sp: {
        headers: {
          Authorization: `Bearer ${token.accessToken}`
          // "X-RequestDigest": formDigestValue
        },
        baseUrl: siteCollectionURL
      }
    });


    return new Promise(async (resolve, reject) => {
      // const taxonomy = new Session(siteCollectionURL);
      const store = await taxonomy.getDefaultSiteCollectionTermStore();
      store.getTermSetById(termsetId).terms.select('Name', 'Id', 'Parent').get()
        .then((data: any[]) => {

          let result: Array<any> = [];
          // build termset levels
          debugger;
          do {
            for (let index = 0; index < data.length; index++) {
              debugger;
              console.log("INDEX", index);
              console.log("LENGTH", data.length);
              let currTerm = data[index];
              if (currTerm.Parent) {
                console.log("PARENT", currTerm.Parent)
                let parentGuid = currTerm.Parent.Id;
                insertChildInParent(result, parentGuid, currTerm, index);
                index = index - 1;
              } else {
                console.log("ELSE", currTerm)
                data.splice(index, 1);
                index = index - 1;
                result.push(currTerm);
              }
            }
            debugger;
            console.log("DATA LENGTH", data.length)
          } while (data.length !== 0);
          // recursive insert term in parent and delete it from start data array with index
          function insertChildInParent(searchArray, parentGuid, currTerm, orgIndex) {
            searchArray.forEach(parentItem => {
              if (parentItem.Id == parentGuid) {
                if (parentItem.children) {
                  parentItem.children.push(currTerm);
                } else {
                  parentItem.children = [];
                  parentItem.children.push(currTerm);
                }
                debugger;
                data.splice(orgIndex, 1);
              } else if (parentItem.children) {
                // recursive is recursive is recursive
                insertChildInParent(parentItem.children, parentGuid, currTerm, orgIndex);
              }
            });
          }
          resolve(result);
        }).catch(fail => {
          console.warn(fail);
          reject(fail);
        });
    });
  }

  public async getTermsetWithChildren2(siteCollectionURL: string, termsetId: string, authority?: string): Promise<any[]> {

    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({ scopes: [`${siteCollectionURL}/TermStore.Read.All`], authority: authority });
    taxonomy.setup({
      sp: {
        headers: {
          Authorization: `Bearer ${token.accessToken}`
          // "X-RequestDigest": formDigestValue
        },
        baseUrl: siteCollectionURL
      }
    });

    let tms: any[] = [];

    const store = await taxonomy.getDefaultSiteCollectionTermStore();
    store.getTermSetById(termsetId).terms.select('Name', 'Id', 'Parent').get()

    return new Promise<any[]>((resolve, reject) => {
      const tbatch = taxonomy.createBatch();
      return taxonomy.termStores.getByName("Taxonomy_QIZ6CnYZOilsu9mYNOvmjg==").get().then((resp1: ITermStore) => {
        return resp1.getTermGroupById("b4e8bf5f-4416-4516-9c8e-31fec9d36e09").termSets.get().then((resp2: ITermSet[]) => {
          resp2.forEach((ele: ITermSet) => {
            ele.terms.select('Name', 'Id').inBatch(tbatch).get().then((r3: ITerm[]) => {
              r3.forEach((t1: ITerm) => {
                let ip1 = {
                  parent: ele['Name'],
                  name: t1['Name'],
                  id: t1['Id'].replace("/Guid(", "").replace(")/", "")
                };
                tms.push(ip1);
              });
            });
          });
          tbatch.execute().then(_r => {
            resolve(tms);
          });
        });
      });
    });
  }

  public async createTerm(tenantUrl: string) {
    const token = await authProvider.getAccessToken({ scopes: [`${tenantUrl}/TermStore.ReadWrite.All`] });
    taxonomy.setup({
      sp: {
        headers: {
          Authorization: `Bearer ${token.accessToken}`
          // "X-RequestDigest": formDigestValue
        },
        baseUrl: tenantUrl
      }
    });

    const termStores = await taxonomy.termStores.get();
    const group = termStores[0].groups.getByName("SPARK");
    await group.createTermSet("NEWTERMSET", 1033);
  }

  public async getSPOSuiteLinksData(tenantUrl: string, authority?: string) {
    // https://rightpoint.sharepoint.com/_layouts/15/online/handlers/SpoSuiteLinks.ashx?Locale=en-US&v=2&mobilereq=0&msajax=1
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`${tenantUrl}/Sites.FullControl.All`],
      authority: authority
    });

    const requestDigest = await this.getContextInfo(tenantUrl, tenantUrl);
    const suiteLinksResponse = await fetch(
      `${tenantUrl}/_layouts/15/online/handlers/SpoSuiteLinks.ashx?Locale=en-US&v=2&mobilereq=0&msajax=1`,
      {
        method: "POST",
        headers: {
          authorization: `Bearer ${token.accessToken}`,
          accept: "application/json; odata.metadata=minimal",
          "content-type": "application/json;odata=nometadata;charset=utf-8",
          "X-RequestDigest": requestDigest.FormDigestValue,
          "ODATA-VERSION": "4.0"
        }
      }
    );
    const suiteLinksData = await suiteLinksResponse.json();
    console.log("suiteLinksData", suiteLinksData);
  }

  public async updateTermDescription(siteCollectionURL: string, termId: string, description: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({ scopes: [`${siteCollectionURL}/TermStore.Read.All`], authority: authority });
    taxonomy.setup({
      sp: {
        headers: {
          Authorization: `Bearer ${token.accessToken}`
          // "X-RequestDigest": formDigestValue
        },
        baseUrl: siteCollectionURL
      }
    });

    const store = await taxonomy.getDefaultSiteCollectionTermStore();
    //store.getTermSetById(termsetId).terms.select('Name', 'Id', 'Parent').get()

    await store.getTermById(termId).setDescription(description, 1033)
  }
}

import * as React from "react";
import {
  Button,
  Columns,
  Progress,
  Table,
  Heading,
  Pagination,
  Loader,
  Modal,
  Section,
  Box,
  Media,
  Image,
  Content,
  Level
} from "react-bulma-components/full";
import { observer } from "mobx-react";
import FormField from "../FormField";
import { observable, action } from "mobx";

export type ResourceGroupDeleteResult = {
  shouldDelete: boolean;
};

@observer
export default class ResourceGroupDeleteModal extends React.Component<{
  resourceGroupName: string;
  modal: Object;
  isOpen: boolean;
  onSubmit: (result: ResourceGroupDeleteResult) => void;
  onClose?: Function;
}> {
  render() {
    return (
      <div>
        <Modal
          show={this.props.isOpen}
          onClose={() => this.props.onClose && this.props.onClose()}
          {...this.props.modal}>
          <Modal.Card>
            <Modal.Card>
              <Modal.Card.Head>
                <Modal.Card.Title>{`Delete Resource Group?`}</Modal.Card.Title>
              </Modal.Card.Head>
              <Modal.Card.Body>
                <Section style={{ backgroundColor: "white" }}>
                  <Heading size={4}>{`Delete Resource Group?`}</Heading>
                  <Heading subtitle size={6} renderAs="h2">
                    {`Resource Group - ${this.props.resourceGroupName} will be deleted.`}
                  </Heading>

                  <p>All resources inside the group will be deleted.</p>
                  <br />
                  <br />
                  <Box>
                    <Button
                      color={"danger"}
                      onClick={() => {
                        this.props.onSubmit({ shouldDelete: true });
                        this.props.onClose && this.props.onClose();
                      }}>
                      Confirm
                    </Button>
                    <Button
                      color={"success"}
                      onClick={() => {
                        this.props.onSubmit({ shouldDelete: false });
                        this.props.onClose && this.props.onClose();
                      }}>
                      Cancel
                    </Button>
                  </Box>
                </Section>
              </Modal.Card.Body>
              <Modal.Card.Foot style={{ alignItems: "center", justifyContent: "center" }}>
                <p>Lorem Ipsum...</p>
              </Modal.Card.Foot>
            </Modal.Card>
          </Modal.Card>
        </Modal>
      </div>
    );
  }
}

import React from "react";
import { AzureRMService } from "../../services/AzureRMService";
import {
  Button,
  Text,
  InputGroup,
  Input,
  Box,
  Flex,
  Icon,
  Heading,
  Stack,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionIcon,
  AccordionPanel,
  Image,
  Tag,
  InputLeftAddon,
  Switch
} from "@chakra-ui/core";
import { ICanvasOptions, Canvas } from "fabric/dist/fabric";
import { convertDataURIToBinary } from "../../util/Utility";
import {
  JOUMANA,
  CROWN_SVG,
  MUSTACHE,
  RIGHTPOINT,
  BEARD_SVG,
  STAR_SVG,
  FLAME_SVG,
  SUNGLASSES_SVG
} from "../../util/Constants";
import { observable, action, autorun } from "mobx";
import { observer } from "mobx-react";
import { DelveImage } from "../UserPhotoEditor/DelveImage";
import { CanvasUserImage } from "./CanvasUserImage";
import RightpointSVG from "../SVG/RightpointSVG";
import { CircleUtility } from "./CircleUtility";

declare var fabric: any;
export interface IBadgeCreatorProps {
  startingImageUrl?: string;
  fromJson?: Object;
  canvasId: string;
  imageHeight: number;
  imageWidth: number;
  onAfterSave: (badgeName: string, canvasJson: any) => void;
  onPreviewUpdate: (dataUrl: string) => void;
}

@observer
export class BadgeCreator extends React.Component<IBadgeCreatorProps> {
  _ref;
  _canvas: Canvas;
  _deleteListener;
  azureService = new AzureRMService();
  _circleRef;

  constructor(props) {
    super(props);
  }

  @observable userId: string | undefined;
  @action setUserId(id: string) {
    this.userId = id;
  }

  @action async loadFromJson() {
    this._canvas.loadFromJSON(
      this.props.fromJson,
      () => {
        this._canvas.renderAll();
      },
      function(o, object) {
        console.log(o, object);
      }
    );
  }

  //   @action async loadUserOriginalImage() {
  //     this.setProfilePhoto(this.props.startingImageUrl);

  //     fabric.Image.fromURL(this.props.startingImageUrl, img => {
  //       img.scaleToHeight(this.props.imageHeight);
  //       img.scaleToWidth(this.props.imageWidth);
  //       img.name = "profilephoto";
  //       img.selectable = false;
  //       img.excludeFromExport = true;
  //       this._canvas.add(img);
  //       img.sendToBack();
  //     });
  //   }

  @observable profilePhoto;
  @action setProfilePhoto(src) {
    this.profilePhoto = src;
  }

  @observable previewImage;
  @action setPreviewImage(url: string) {
    this.previewImage = url;
  }

  @observable badgeName: string = "";
  @action setBadgeName(name: string) {
    this.badgeName = name;
  }

  @observable drawingModeEnabled: boolean = false;
  @action setDrawingMode(isEnabled) {
    this.drawingModeEnabled = isEnabled;
    this._canvas.isDrawingMode = isEnabled;
  }

  @observable availableFilters = [
    "grayscale",
    "invert",
    "remove-color",
    "sepia",
    "brownie",
    "brightness",
    "contrast",
    "saturation",
    "noise",
    "vintage",
    "pixelate",
    "blur",
    "sharpen",
    "emboss",
    "technicolor",
    "polaroid",
    "blend-color",
    "gamma",
    "kodachrome",
    "blackwhite",
    "blend-image",
    "hue",
    "resize"
  ];

  @observable appliedFilters: Array<string> = [];
  @action applyFilter(filterName: string) {
    this.appliedFilters.push(filterName);
  }

  @action applyFilterV() {
    const canvasObjects = this._canvas.getObjects();
    const rootImage = canvasObjects[0];
    if (rootImage) {
      var filter = new fabric.Image.filters.Grayscale();
      rootImage.filters.push(filter);
      rootImage.applyFilters();
    }

    canvasObjects.forEach(obj => {
      if (obj.filters) {
        var filter = new fabric.Image.filters.Grayscale();
        obj.filters.push(filter);
      }
    });
    this._canvas.renderAll();
    this.renderPreview();
    // obj.filters['grayscale'] = filter;
    // var timeStart = +new Date();
    // obj.applyFilters();
    // var timeEnd = +new Date();
    // var dimString = canvas.getActiveObject().width + ' x ' +
    //   canvas.getActiveObject().height;
    // $('bench').innerHTML = dimString + 'px ' +
    //   parseFloat(timeEnd-timeStart) + 'ms';
    // canvas.renderAll();
  }

  @action clearCanvas() {
    this._canvas.clear();
  }

  @action deleteSelectedObjectsFromCanvas() {
    var selection = this._canvas.getActiveObject();
    if (!selection) {
      return;
    }
    if (selection.type === "activeSelection") {
      selection.forEachObject(element => {
        console.log(element);
        this._canvas.remove(element);
      });
    } else {
      this._canvas.remove(selection);
    }
    this._canvas.discardActiveObject();
    this._canvas.requestRenderAll();
  }

  @action async renderPreview() {
    var dataURL = this._canvas.toDataURL({
      format: "png"
      // multiplier: 2
    });

    this.setPreviewImage(dataURL);
    this.props.onPreviewUpdate(dataURL);
  }

  @action async addCircle(percent: number) {
    if (!this._circleRef) {
      const circle = CircleUtility.createFabricSemiCircle(percent, "yellow");
      circle.lockMovementX = true;
      circle.lockMovementY = true;
      circle.hasControls = false;
      this._canvas.add(circle);
      this._canvas.setActiveObject(circle);
      this._circleRef = circle;
      this._canvas.renderAll();
    } else {
      this._circleRef.set({
        endAngle: percent * (360 / 100) * (Math.PI / 180)
      });
      this._canvas.renderAll();
    }
    this.renderPreview();
  }

  @action removeCircle() {
    if (this._circleRef) {
      this._canvas.remove(this._circleRef);
      this._circleRef = null;
    }
  }

  @action async addText() {
    var t1 = new fabric.Textbox("My Text", {
      // width: 200,
      top: 0,
      left: 0,
      fontSize: 16,
      textAlign: "center",
      backgroundColor: "red"
    });

    this._canvas.add(t1);
  }

  componentDidMount() {
    this._deleteListener = document.addEventListener("keyup", e => {
      if (e.keyCode === 46) {
        this.deleteSelectedObjectsFromCanvas();
      }
    });

    const canvas = new fabric.Canvas(this.props.canvasId, {
      width: this.props.imageWidth,
      height: this.props.imageHeight,
      backgroundColor: "transparent",
      isDrawingMode: false,
      preserveObjectStacking: true
    } as ICanvasOptions);
    this._canvas = canvas;

    if (this.props.fromJson) {
      this.loadFromJson();
      return;
    }

    this._canvas.on("object:added", () => this.renderPreview());
    this._canvas.on("object:removed", () => this.renderPreview());
    this._canvas.on("object:modified", () => this.renderPreview());
    this.renderPreview();
  }
  @action async loadUserOriginalImage() {
    this.setProfilePhoto(this.props.startingImageUrl);

    const canvasObjects = this._canvas.getObjects();
    if (canvasObjects.length > 0) {
      this._canvas.remove(canvasObjects[0]);
    }
    // this.clearCanvas();
    fabric.Image.fromURL(this.props.startingImageUrl, img => {
      img.scaleToHeight(this.props.imageHeight);
      img.scaleToWidth(this.props.imageWidth);
      img.name = "profilephoto";
      img.selectable = false;
      img.excludeFromExport = true;
      this._canvas.add(img);
      img.sendToBack();
    });
  }

  componentDidUpdate(prevProps, nextProps) {
    console.log(prevProps, nextProps);
    if (prevProps.startingImageUrl !== this.props.startingImageUrl) {
      this.loadUserOriginalImage();
    }
    if (prevProps.fromJson !== this.props.fromJson) {
      this.loadFromJson();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this._deleteListener);
  }

  render() {
    const SelectableSVG = ({ svg, ...props }) => {
      return (
        <div
          dangerouslySetInnerHTML={{ __html: svg }}
          onClick={() => {
            this._canvas.isDrawingMode = false;
            fabric.loadSVGFromString(svg, svg => {
              var obj = fabric.util.groupSVGElements(svg, {});
              this._canvas.add(obj);
              obj.bringToFront();
              this._canvas.renderAll();
            });
          }}
          {...props}></div>
      );
    };

    return (
      <Box backgroundColor="white" shadow="sm" borderRadius="lg" pl={3} pr={3} pt={5} pb={5}>
        <Flex display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" pb={2}>
          <Heading size="md" as="h2" lineHeight="shorter" fontWeight="bold" fontFamily="heading">
            Badge Editor
          </Heading>
        </Flex>
        <Stack ml={4} spacing={2} shouldWrapChildren mt={4} mr={4}>
          <Stack shouldWrapChildren={true} isInline={true} flexWrap={"wrap"} spacing={5}>
            <canvas
              id={this.props.canvasId}
              ref={node => {
                this._ref = node;
              }}
              style={{ border: "1px dashed black" }}
            />
            <Button
              onClick={() => {
                const canvasObjects = this._canvas.getObjects();
                canvasObjects.forEach(obj => {
                  if (obj.name !== "profilephoto") {
                    this._canvas.remove(obj);
                  }
                });
                this._circleRef = null;
              }}>
              Clear
            </Button>
            <Box flexBasis={20}>
              <DelveImage height={"96px"} width={"96px"} src={this.previewImage} />
            </Box>
            <Box>
              <DelveImage height={"32px"} width={"32px"} src={this.previewImage} />
            </Box>
          </Stack>
          <Accordion allowToggle allowMultiple>
            <AccordionItem defaultIsOpen>
              <AccordionHeader>
                <Text>Add items</Text>
                <AccordionIcon />
              </AccordionHeader>
              <AccordionPanel>
                <Flex
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-start"
                  justifyContent="space-around"
                  wrap="wrap"
                  margin="1">
                  <SelectableSVG svg={MUSTACHE}></SelectableSVG>
                  <SelectableSVG svg={BEARD_SVG}></SelectableSVG>
                  <SelectableSVG svg={STAR_SVG}></SelectableSVG>
                  <SelectableSVG svg={FLAME_SVG}></SelectableSVG>
                  <SelectableSVG svg={SUNGLASSES_SVG}></SelectableSVG>
                  <SelectableSVG svg={RIGHTPOINT}></SelectableSVG>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem defaultIsOpen>
              <AccordionHeader>
                <Text>Effects</Text>
                <AccordionIcon />
              </AccordionHeader>
              <AccordionPanel>
                <Flex
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-start"
                  justifyContent="space-around"
                  wrap="wrap"
                  margin="1">
                  <Switch
                    color="blue"
                    size="md"
                    onClick={() => {
                      this.applyFilterV();
                    }}
                  />
                </Flex>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem defaultIsOpen>
              <AccordionHeader>
                <Text>Add Text</Text>
                <AccordionIcon />
              </AccordionHeader>
              <AccordionPanel>
                <Flex
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-start"
                  justifyContent="space-around"
                  wrap="wrap"
                  margin="1">
                  <Button
                    onClick={() => {
                      this.addText();
                    }}>
                    Add
                  </Button>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem defaultIsOpen>
              <AccordionHeader>
                <Text>More</Text>
                <AccordionIcon />
              </AccordionHeader>
              <AccordionPanel>
                <Flex display="flex" margin="1">
                  <Box>
                    <Text>Drawing Mode</Text>
                    <Switch
                      color="blue"
                      size="md"
                      isChecked={this.drawingModeEnabled}
                      onChange={() => {
                        this.setDrawingMode(!this.drawingModeEnabled);
                      }}
                    />
                  </Box>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem defaultIsOpen>
              <AccordionHeader>
                <Text>Circle</Text>
                <AccordionIcon />
              </AccordionHeader>
              <AccordionPanel>
                <Stack margin="1">
                  <Stack isInline shouldWrapChildren>
                    <Button
                      onClick={() => {
                        this.removeCircle();
                      }}>
                      Remove Circle
                    </Button>
                    <Button
                      onClick={() => {
                        this.addCircle(25);
                      }}>
                      Add Circle 25%
                    </Button>
                    <Button
                      onClick={() => {
                        this.addCircle(50);
                      }}>
                      Add Circle 50%
                    </Button>
                    <Button
                      onClick={() => {
                        this.addCircle(75);
                      }}>
                      Add Circle 75%
                    </Button>
                    <Button
                      onClick={() => {
                        this.addCircle(100);
                      }}>
                      Add Circle 100%
                    </Button>
                  </Stack>
                  <Box>
                    <Text>
                      Add a circle around the profile image for an unobtrusive indicator. You could show progress
                      towards something with how much of the circle is complete.
                    </Text>
                  </Box>
                </Stack>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem defaultIsOpen>
              <AccordionHeader>
                <Text>Save Badge</Text>
                <AccordionIcon />
              </AccordionHeader>
              <AccordionPanel>
                <Stack spacing={2} shouldWrapChildren>
                  <Tag size="md" variant="subtle" variantColor="yellow">
                    Save Badge
                  </Tag>
                  <InputGroup>
                    <InputLeftAddon>Name</InputLeftAddon>
                    <Input
                      size="md"
                      as="input"
                      variant="outline"
                      isFullWidth
                      focusBorderColor="blue.500"
                      errorBorderColor="red.500"
                      value={this.badgeName}
                      onChange={e => {
                        this.setBadgeName(e.target.value);
                      }}
                    />
                  </InputGroup>
                  <Icon
                    name="small-add"
                    fontSize="2xl"
                    onClick={() => {
                      const badgeToSave = Object.assign(
                        { badgeName: this.badgeName },
                        { canvasData: this._canvas.toJSON() }
                      );
                      this.props.onAfterSave(this.badgeName, JSON.stringify(badgeToSave));
                    }}
                  />
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Stack>
      </Box>
    );
  }
}

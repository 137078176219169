import React from "react";
import { withRouter, Switch, Route } from "../../util/router";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { AzureRMService } from "../../services/AzureRMService";
import { SparkMenuPage } from "../../components/SparkMenu";
import NewResourceGroupModal from "../../components/NewResourceGroupModal";
import { SharePointService } from "../../services/SharePointService";
import { Box, Text, Heading, Link, Stack } from "@chakra-ui/core";
import { Link as RouterLink } from "../../util/router";

import FaqPage from "../../pages/faq";
import PricingPage from "../../pages/pricing";
import ProfilePage from "../../pages/profile";
import SetupPage from "../../pages/setup";
import SparkAdministratorsPage from "../../pages/sparkadministrators";
import TenantCheckPage from "../../pages/tenantcheck";
import AppsPage from "../../pages/apps";
import FlowConnectorPage from "../../pages/flowconnector";
import SitesPage from "../../pages/sites";
import AdminConsentPage from "../../pages/adminconsent";
import TenantPage from "../../pages/tenant";
import TenantsPage from "../../pages/tenants";
import RequestPage from "../../pages/request";
import TeamsAppInstallPage from "../../pages/teamsappinstall";
import LogPage from "../../pages/log";
import AzureADAppsPage from "../../pages/azureadapps";
import DefaultAzureSubscription from "../../pages/defaultazuresubscription";
import SparkAzureADApp from "../../pages/sparkazureadapp";

import TeamsSearchPage from "../../pages/teamssearch";
import TermStorePage from "../../pages/termstore";
import SubscriptionsPage from "../../pages/subscriptions";
import ExtensionPage from "../extensions";
import SettingsPage from "../settings";
import ProvisioningEnginePage from "../provisioning";
import { authProvider } from "../../AuthProvider";

const Linky: any = Link;
@observer
class AdminPage extends React.Component<{ match; history }> {
  azureService = new AzureRMService();
  sharePointService = new SharePointService();

  async componentDidMount() {}
  render() {
    return (
      <SparkMenuPage>
        <Switch>
          <Route
            exact
            path={`${this.props.match.path}/`}
            component={() => {
              return <div>Welcome to the Spark Admin Center</div>;
            }}
          />

          <Route exact path={`${this.props.match.path}/setup`} component={SetupPage} />
          <Route path={`${this.props.match.path}/azuresubscription`} component={DefaultAzureSubscription} />

          <Route path={`${this.props.match.path}/sparkazureadapp`} component={SparkAzureADApp} />

          <Route path={`${this.props.match.path}/request`} component={RequestPage} />
          <Route path={`${this.props.match.path}/subscriptions`} component={SubscriptionsPage} />
          <Route path={`${this.props.match.path}/azureadapps`} component={AzureADAppsPage} />

          <Route exact path={`${this.props.match.path}/tenants`} component={TenantsPage} />
          <Route exact path={`${this.props.match.path}/log`} component={LogPage} />
          <Route exact path={`${this.props.match.path}/teamssearch`} component={TeamsSearchPage} />
          <Route exact path={`${this.props.match.path}/teamsappinstall`} component={TeamsAppInstallPage} />
          <Route exact path={`${this.props.match.path}/apps`} component={AppsPage} />
          <Route exact path={`${this.props.match.path}/termstore`} component={TermStorePage} />
          <Route exact path={`${this.props.match.path}/extensions`} component={ExtensionPage} />
          <Route exact path={`${this.props.match.path}/settings`} component={SettingsPage} />
          <Route path={`${this.props.match.path}/provisioning`} component={ProvisioningEnginePage} />

          <Route exact path={`${this.props.match.path}/flowconnector`} component={FlowConnectorPage} />
          <Route exact path={`${this.props.match.path}/sparkadministrators`} component={SparkAdministratorsPage} />
          <Route exact path={`${this.props.match.path}/tenantcheck`} component={TenantCheckPage} />
          <Route exact path={`${this.props.match.path}/faq`} component={FaqPage} />
          <Route exact path={`${this.props.match.path}/pricing`} component={PricingPage} />

          <Route
            component={({ location }) => {
              return (
                <div
                  style={{
                    padding: "50px",
                    width: "100%",
                    textAlign: "center"
                  }}>
                  The page <code>{location.pathname}</code> could not be found.
                </div>
              );
            }}
          />
        </Switch>
      </SparkMenuPage>
    );
  }
}

export default withRouter(AdminPage);

export const generateClientSidePassword = () => {
  return (
    Array(10)
      .fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz")
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("") +
    Array(10)
      .fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz")
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("") +
    Array(10)
      .fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz")
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("")
  );
};

export const escapeXml = (s: any | undefined) => {
  if (!s) {
    return s;
  }

  return s.toString().replace(/[<>&"]/g, (c: string): string => {
    let char: string = c;

    switch (c) {
      case "<":
        char = "&lt;";
        break;
      case ">":
        char = "&gt;";
        break;
      case "&":
        char = "&amp;";
        break;
      case '"':
        char = "&quot;";
        break;
    }

    return char;
  });
};

var BASE64_MARKER = ";base64,";

export function convertDataURIToBinary(dataURI) {
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for (var i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}

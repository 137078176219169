import React from "react";
import { Button, Input, Stack, Text } from "@chakra-ui/core";
import { AzureRMService } from "../../services/AzureRMService";
import { SettingsPersistenceService, OrgSettings } from "../../services/SettingsPersistanceService";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

@observer
class SettingsPage extends React.Component {
  azureService = new AzureRMService();
  settingsService = new SettingsPersistenceService();

  @observable orgExtensionData: OrgSettings = {};
  @action setOrgExtensionData(data) {
    this.orgExtensionData = data;
  }
  @action setOrgExtensionValue(key: string, val: string) {
    this.orgExtensionData[key] = val;
  }

  @action async deleteExtensionKeyAndValue(key: string) {
    await this.settingsService.deleteKeyAndValueFromOrganizationExtension(this.organizationId, key);
  }
  @observable organizationId: string = "";
  @action setOrganizationId(orgId: string) {
    this.organizationId = orgId;
  }

  @observable siteRequestPowerAppId: string = "";
  @action setSiteRequestPowerAppId(id: string) {
    this.siteRequestPowerAppId = id;
  }

  @observable sparkTechnicalLiasonEmail: string = "";
  @action setSparkTechnicalLiasonEmail(email: string) {
    this.sparkTechnicalLiasonEmail = email;
  }

  private async persistSettings() {
    const objectToPersist = Object.assign(this.orgExtensionData, {
      siteRequestPowerAppId: this.siteRequestPowerAppId,
      sparkTechnicalLiasonEmail: this.sparkTechnicalLiasonEmail
    });
    await this.settingsService.persistSettingsToOrganizationExtension(this.organizationId, objectToPersist);
  }

  private async deleteAllOrganizationExtensionData() {
    await this.settingsService.deleteExtensionForOrganization();
  }

  async componentDidMount() {
    const data = await this.settingsService.getOrganizationSettingsData();
    this.setOrgExtensionData(data);

    const organizations = await this.azureService.getOrganizationInfo();
    const organization = organizations[0];
    const orgId = organization.id as string;
    this.setOrganizationId(orgId);

    if (data.siteRequestPowerAppId) {
      this.setSiteRequestPowerAppId(data.siteRequestPowerAppId);
    }
    if (data.sparkTechnicalLiasonEmail) {
      this.setSparkTechnicalLiasonEmail(data.sparkTechnicalLiasonEmail);
    }
  }

  render() {
    return (
      <div>
        Site Request PowerApp Id
        <Input
          value={this.siteRequestPowerAppId}
          onChange={e => {
            this.setSiteRequestPowerAppId(e.target.value);
          }}></Input>
        {this.orgExtensionData && (
          <div>
            {Object.keys(this.orgExtensionData).map(k => (
              <Stack isInline>
                <Text>{k}</Text>
                <Input
                  value={this.orgExtensionData[k]}
                  onChange={e => {
                    this.setOrgExtensionValue(k, e.target.value);
                  }}></Input>
                <Button onClick={() => this.deleteExtensionKeyAndValue(k)}>Delete</Button>
              </Stack>
            ))}
          </div>
        )}
        <Button
          onClick={() => {
            this.persistSettings();
          }}>
          Save
        </Button>
        <Button
          onClick={() => {
            this.deleteAllOrganizationExtensionData();
          }}>
          Delete All Organization Extension Data
        </Button>
      </div>
    );
  }
}

export default SettingsPage;

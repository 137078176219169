import React from "react";
import { AzureRMService } from "../../services/AzureRMService";
import { observable, action, toJS } from "mobx";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import { Box, Stack, Image, Badge, Text, Link, Icon, List, ListItem, Button, Heading } from "@chakra-ui/core";
import { observer } from "mobx-react";
import moment from "moment";
import JSONTree from "react-json-tree";
import { DebugBox } from "../DebugBox/DebugBox";

@observer
class TrendingAroundMe extends React.Component {
  azureService = new AzureRMService();

  @observable trendingAroundMeItems: Array<MicrosoftGraph.Trending> = [];
  @action setSharedWithMeItems(items: Array<MicrosoftGraph.Trending>) {
    this.trendingAroundMeItems = items;
  }

  async componentDidMount() {
    const trendingAroundMe = await this.azureService.getItemsTrendingAroundMe();
    this.setSharedWithMeItems(trendingAroundMe);
  }

  render() {
    const PreviewImage = ({ item }: { item: MicrosoftGraph.Trending }) => {
      if (item.resourceVisualization?.mediaType !== "image/png") {
        return <Image src={"https://placehold.it/100x100"} alt={item.resourceVisualization?.title} />;
      }

      return <Image src={item.resourceVisualization?.previewImageUrl} alt={item.resourceVisualization?.title} />;
    };

    const SharedItemBox = observer(({ item }: { item: MicrosoftGraph.Trending }) => {
      return (
        <DebugBox debugJson={item} maxW="sm" borderWidth="1px" rounded="lg" overflow="hidden">
          <PreviewImage item={item} />

          <Box p="6">
            <Box d="flex" alignItems="baseline">
              <Badge rounded="full" px="2" variantColor="teal">
                New
              </Badge>
              <Box
                color="gray.500"
                fontWeight="semibold"
                letterSpacing="wide"
                fontSize="xs"
                textTransform="uppercase"
                ml="2">
                {item.resourceVisualization?.mediaType}
              </Box>
            </Box>

            <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated>
              {item.resourceVisualization?.title}
            </Box>

            <Box>{item.resourceVisualization?.type}</Box>
            <Link href={item.resourceReference?.webUrl} target={"_blank"} isExternal>
              Link <Icon name="external-link" mx="2px" />
            </Link>

            {/* <Box d="flex" mt="2" alignItems="center">
             {Array(5)
               .fill("")
               .map((_, i) => (
                 <StarIcon
                   key={i}
                   color={i < property.rating ? "teal.500" : "gray.300"}
                 />
               ))}
             <Box as="span" ml="2" color="gray.600" fontSize="sm">
               {property.reviewCount} reviews
             </Box>
           </Box> */}
          </Box>
        </DebugBox>
      );
    });

    return (
      <Stack>
        <Heading size={"sm"}>Trending Around Me</Heading>

        <Stack direction={"row"} spacing={10}>
          {this.trendingAroundMeItems.map((item: MicrosoftGraph.Trending) => {
            return <SharedItemBox item={item} />;
          })}
        </Stack>
      </Stack>
    );
  }
}

export { TrendingAroundMe };

import {
  Aborter,
  BlobURL,
  BlockBlobURL,
  ContainerURL,
  ServiceURL,
  StorageURL,
  SharedKeyCredential,
  AnonymousCredential,
  TokenCredential
} from "@azure/storage-blob";
import { APPS_BLOB_STORAGE_URL } from "../util/Constants";

export default class AzureStorageService {
  async downloadBlob(blobName) {
    // Use AnonymousCredential when url already includes a SAS signature
    const anonymousCredential = new AnonymousCredential();

    // Use sharedKeyCredential, tokenCredential or anonymousCredential to create a pipeline
    const pipeline = StorageURL.newPipeline(anonymousCredential);

    // List containers
    const serviceURL = new ServiceURL(
      // When using AnonymousCredential, following url should include a valid SAS or support public access
      APPS_BLOB_STORAGE_URL,
      pipeline
    );

    const containerURL = ContainerURL.fromServiceURL(serviceURL, "");

    const blobURL = BlobURL.fromContainerURL(containerURL, blobName);

    const blockBlobURL = BlockBlobURL.fromBlobURL(blobURL);

    const downloadBlockBlobResponse = await blockBlobURL.download(Aborter.none, 0);
    console.log("Downloaded blob content", downloadBlockBlobResponse.blobBody);

    const b = await downloadBlockBlobResponse.blobBody;
    return b;
  }

  static async getFromAzureStorageAsBase64(storageUrl: string, blobName: string) {
    const anonymousCredential = new AnonymousCredential();

    // Use sharedKeyCredential, tokenCredential or anonymousCredential to create a pipeline
    const pipeline = StorageURL.newPipeline(anonymousCredential);

    // List containers
    const serviceURL = new ServiceURL(
      // When using AnonymousCredential, following url should include a valid SAS or support public access
      storageUrl,
      pipeline
    );

    const containerURL = ContainerURL.fromServiceURL(serviceURL, "");

    const blobURL = BlobURL.fromContainerURL(containerURL, blobName);

    const blockBlobURL = BlockBlobURL.fromBlobURL(blobURL);

    const downloadBlockBlobResponse = await blockBlobURL.download(Aborter.none, 0);

    const b = await downloadBlockBlobResponse.blobBody;

    return await blobToBase64(b);
  }

  static async getFromAzureStorage(storageUrl: string, blobName: string) {
    const anonymousCredential = new AnonymousCredential();

    // Use sharedKeyCredential, tokenCredential or anonymousCredential to create a pipeline
    const pipeline = StorageURL.newPipeline(anonymousCredential);

    // List containers
    const serviceURL = new ServiceURL(
      // When using AnonymousCredential, following url should include a valid SAS or support public access
      storageUrl,
      pipeline
    );

    const containerURL = ContainerURL.fromServiceURL(serviceURL, "");

    const blobURL = BlobURL.fromContainerURL(containerURL, blobName);

    const blockBlobURL = BlockBlobURL.fromBlobURL(blobURL);

    const downloadBlockBlobResponse = await blockBlobURL.download(Aborter.none, 0);

    const b = await downloadBlockBlobResponse.blobBody;

    return b;
  }
}

var blobToBase64 = function(blob): Promise<string> {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = function() {
      var dataUrl = reader.result as any;
      var base64 = dataUrl.split(",")[1];
      resolve(base64);
    };
    reader.readAsDataURL(blob);
  });
};

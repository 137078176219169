import React from "react";
import { withRouter } from "../../util/router";
import { Stack, IconButton, Text } from "@chakra-ui/core";

function TeamsNavigationComponent({ history }) {
  return (
    <>
      <Stack isInline>
        <IconButton
          onClick={() => {
            history.goBack();
          }}
          aria-label="go back"
          icon="chevron-left"
        />
        <IconButton
          onClick={() => {
            history.goForward();
          }}
          aria-label="go forward"
          icon="chevron-right"
        />
      </Stack>
    </>
  );
}

export const TeamsNavigation = withRouter(TeamsNavigationComponent);

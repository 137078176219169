import React, { useEffect } from "react";
import DashboardSection from "./../../components/DashboardSection";

import { useRouter, Link, Route, withRouter } from "./../../util/router";
import "./styles.scss";
import { useAuth } from "../../util/auth";
import SectionHeader from "../../components/SectionHeader";
import { SparkMenuPage } from "../../components/SparkMenu";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import { SharePointService } from "../../services/SharePointService";
import { AzureRMService } from "../../services/AzureRMService";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";

import {
  SHAREPOINT_SERVICE_ADMINISTRATOR_ROLETEMPLATEID,
  COMPANY_ADMINISTRATOR_ROLETEMPLATEID
} from "../../util/Constants";
import { authProvider } from "../../AuthProvider";
import { OrganizationInfo } from "./types";
import SetupPage from "../setup";
import { Loader, Heading } from "react-bulma-components/full";
import { CancellableComponent } from "../../util/CancellableComponent";
import { globalStore } from "../../stores/GlobalStore";

@observer
class TenantCheckPage extends CancellableComponent<{ match; location; history }, {}> {
  sharePointService = new SharePointService();
  azureService = new AzureRMService();

  @observable isLoading: boolean = false;
  @action setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }
  @observable organizationInfo: MicrosoftGraph.Organization | null = null;
  @action setOrganizationInfo(orgInfo: MicrosoftGraph.Organization) {
    this.organizationInfo = orgInfo;
  }

  @observable companyAdmins: [] = [];
  @action setCompanyAdmins(admins) {
    this.companyAdmins = admins;
  }

  @observable sharePointAdmins: [] = [];
  @action setSharePointAdmins(admins) {
    this.sharePointAdmins = admins;
  }

  @observable userRoles: [] = [];
  @action setUserRoles(roles) {
    this.userRoles = roles;
  }

  async componentDidMount() {
    this.setIsLoading(true);
    const orgInfo = await this.azureService.getOrganizationInfo();
    this.setOrganizationInfo(orgInfo[0]);
    console.log(orgInfo);

    const accountInfo = authProvider.getAccountInfo();
    if (accountInfo === null || accountInfo.account === null) {
      alert("account can't be null");
      return;
    }

    // const user = await this.azureService.getUserByMail(accountInfo.account.userName);
    // // const tenants = await this.azureService.getTenants();
    // // console.log("TENANTS", tenants);
    // const userRoles = await this.azureService.getUserDirectoryRolesTransitive(
    //   user.id
    //   //   `https://login.microsoftonline.com/2c70ddde-a0db-42b5-98ef-0ee5d08465b1`
    // );

    // console.log("USER ROLES", userRoles);
    // this.setUserRoles(userRoles);
    // const companyAdministrator = userRoles.find(ur => ur.roleTemplateId === COMPANY_ADMINISTRATOR_ROLETEMPLATEID);
    // if (companyAdministrator) {
    //   console.log("YOU ARE A COMPANY ADMINISTRATOR");
    // } else {
    //   console.log("You are not a Company Administrator");
    // }

    // const sharePointAdministrator = userRoles.find(
    //   ur => ur.roleTemplateId === SHAREPOINT_SERVICE_ADMINISTRATOR_ROLETEMPLATEID
    // );
    // if (sharePointAdministrator) {
    //   console.log("YOU ARE A SHAREPOINT ADMINISTRATO");
    // } else {
    //   console.log("You are not a SHAREPOINT ADMINISTRATOR");
    // }
    const directoryRoles = await this.azureService.getDirectoryRoles();

    console.log(directoryRoles, "DIR ROLES");
    const sharePointAdminRole = directoryRoles.find(role => {
      return role.roleTemplateId === SHAREPOINT_SERVICE_ADMINISTRATOR_ROLETEMPLATEID;
    });

    const companyAdminRole = directoryRoles.find(role => {
      return role.roleTemplateId === COMPANY_ADMINISTRATOR_ROLETEMPLATEID;
    });
    // TODO: if you arent a company admin or sharepoint admin this blocks
    if (!sharePointAdminRole || !companyAdminRole) return;

    const sharePointAdminMembers = await this.azureService.getMembersInDirectoryRole(sharePointAdminRole.id);
    console.log("SHAREPOINT ADMIN MEMBERS", sharePointAdminMembers);
    console.log(`You have ${sharePointAdminMembers.length} SharePoint admins`, sharePointAdminMembers);
    this.setSharePointAdmins(sharePointAdminMembers);
    const companyAdminMembers = await this.azureService.getMembersInDirectoryRole(companyAdminRole.id);
    console.log("SHAREPOINT ADMIN MEMBERS", companyAdminMembers);
    this.setCompanyAdmins(companyAdminMembers);
    console.log(`You have ${companyAdminMembers.length} Company Admins`, companyAdminMembers);
    this.setIsLoading(false);
  }
  render() {
    const OrgInfoSection = observer(({ orgInfo }: { orgInfo: MicrosoftGraph.Organization }) => {
      return (
        <div>
          <h1>Organization Info</h1>
          <p>Display Name: {orgInfo.displayName}</p>
        </div>
      );
    });
    return (
      <>
        {this.isLoading ? (
          <Loader
            style={{
              width: 300,
              height: 300,
              border: "4px solid blue",
              borderTopColor: "transparent",
              borderRightColor: "transparent"
            }}
          />
        ) : (
          <>
            {globalStore.isUserCompanyAdministrator ? (
              <div>✅ You are a Company Administrator.</div>
            ) : (
              <div>❌ You are not a Company Administrator</div>
            )}

            <br />
            {globalStore.isUserSharePointAdministrator ? (
              <div>✅ You are a SharePoint Administrator.</div>
            ) : (
              <div>❌ You are not a SharePoint Administrator</div>
            )}
            <br />
            <Heading size={6}>SharePoint Administrators for your tenant:</Heading>
            <span className="icon has-text-success">
              <i className="fas fa-check-square"></i>
            </span>
            {this.sharePointAdmins.length > 0 &&
              this.sharePointAdmins.map((spAdmin: any) => {
                return <p key={spAdmin.displayName}>{spAdmin.displayName}</p>;
              })}
            <br />
            <Heading size={6}>Company Administrators for your tenant:</Heading>

            {this.companyAdmins.length > 0 &&
              this.companyAdmins.map((companyAdmin: any) => {
                return <p key={companyAdmin.displayName}>{companyAdmin.displayName}</p>;
              })}

            <br />
            <br />
            {this.organizationInfo && <OrgInfoSection orgInfo={this.organizationInfo} />}
            <Route path={`${this.props.match.path}/:topicId`} component={SetupPage} />
          </>
        )}
      </>
    );
  }
}

export default withRouter(TenantCheckPage);

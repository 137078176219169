import React, { useEffect } from "react";
import DashboardSection from "./../../components/DashboardSection";

import { useRouter, Switch, Route, withRouter } from "./../../util/router";
import { useAuth } from "../../util/auth";
import SectionHeader from "../../components/SectionHeader";
import { SparkMenuPage } from "../../components/SparkMenu";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import { SharePointService } from "../../services/SharePointService";
import { AzureRMService } from "../../services/AzureRMService";
import { Button, Table } from "react-bulma-components/full";

@observer
class SitePage extends React.Component<{ match; history }> {
  sharePointService = new SharePointService();
  azureService = new AzureRMService();

  @observable siteUrl: string = "";
  @action setSiteUrl(url) {
    this.siteUrl = url;
  }
  async componentDidMount() {
    const rootUrl = (await this.sharePointService.getSharePointRootUrl()).webUrl;
    const siteUrl = (await this.sharePointService.getSiteUrlFromId(rootUrl, this.props.match.params.id)).d.SiteUrl;

    this.setSiteUrl(siteUrl);
  }
  render() {
    const Page = observer(() => (
      <SparkMenuPage>
        <a href={this.siteUrl}>{this.siteUrl}</a>
        <Button
          onClick={async () => {
            const rootUrl = (await this.sharePointService.getSharePointRootUrl()).webUrl;
            const siteUrl = (await this.sharePointService.getSiteUrlFromId(rootUrl, this.props.match.params.id)).d
              .SiteUrl;
            this.sharePointService.createSiteCollectionAppCatalog(rootUrl, siteUrl);
          }}>
          Create Site Collection App Catalog
        </Button>
        <Button
          onClick={() => {
            this.props.history.push(`${this.props.match.url}apps`);
          }}>
          Go to Apps
        </Button>
      </SparkMenuPage>
    ));

    return <Page />;
  }
}

export default withRouter(SitePage);

import * as React from "react";

export default () => {
    return (
        <svg className="rightpoint-logo-full-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 134.06 22.9">
            {/* <defs>
    <style>.svg-rightpoint-logo-full{fill:#010101;}</style>
    </defs> */}
            <path
                className="svg-rightpoint-logo-full"
                d="M5148.63,38.25h-2.91v4.18h2.91a2.09,2.09,0,1,0,0-4.18m2.4,13.06L5147.94,45h-2.22v6.31h-3.09V35.5h6.2a4.78,4.78,0,0,1,5.13,4.84,4.26,4.26,0,0,1-2.89,4.15l3.53,6.82H5151Z"
                transform="translate(-5142.63 -35.37)"
            />
            <rect className="svg-rightpoint-logo-full" x="15.8" y="0.13" width="3.09" height="15.81" />
            <polygon
                className="svg-rightpoint-logo-full"
                points="47.78 0.13 47.78 6.6 42.43 6.6 42.43 0.13 39.34 0.13 39.34 15.95 42.43 15.95 42.43 9.33 47.78 9.33 47.78 15.95 50.87 15.95 50.87 0.13 47.78 0.13"
            />
            <polygon
                className="svg-rightpoint-logo-full"
                points="54.68 0.13 54.68 2.89 58.81 2.89 58.81 15.95 61.9 15.95 61.9 2.89 66.03 2.89 66.03 0.13 54.68 0.13"
            />
            <path
                className="svg-rightpoint-logo-full"
                d="M5218.49,38.25h-2.89v4.33h2.89a2.17,2.17,0,1,0,0-4.33m0.16,7.11h-3v6h-3.09V35.5h6.13a4.94,4.94,0,1,1,0,9.86"
                transform="translate(-5142.63 -35.37)"
            />
            <path
                className="svg-rightpoint-logo-full"
                d="M5234.49,39a2.57,2.57,0,0,0-2-.84,2.62,2.62,0,0,0-2,.84c-0.6.67-.76,1.4-0.76,4.44s0.16,3.78.76,4.44a2.62,2.62,0,0,0,2,.84,2.57,2.57,0,0,0,2-.84c0.6-.67.78-1.4,0.78-4.44s-0.18-3.78-.78-4.44m2.29,10.75a6.14,6.14,0,0,1-8.55,0c-1.6-1.6-1.55-3.58-1.55-6.31s0-4.71,1.55-6.31a6.14,6.14,0,0,1,8.55,0c1.6,1.6,1.58,3.58,1.58,6.31s0,4.71-1.58,6.31"
                transform="translate(-5142.63 -35.37)"
            />
            <rect className="svg-rightpoint-logo-full" x="99.34" y="0.13" width="3.09" height="15.81" />
            <polygon
                className="svg-rightpoint-logo-full"
                points="115.82 0.13 115.82 9.82 109.56 0.13 106.8 0.13 106.8 15.95 109.89 15.95 109.89 6.24 116.15 15.95 118.91 15.95 118.91 0.13 115.82 0.13"
            />
            <polygon
                className="svg-rightpoint-logo-full"
                points="122.72 0.13 122.72 2.89 126.85 2.89 126.85 15.95 129.93 15.95 129.93 2.89 134.06 2.89 134.06 0.13 122.72 0.13"
            />
            <path
                className="svg-rightpoint-logo-full"
                d="M5148.63,38.25h-2.91v4.18h2.91a2.09,2.09,0,1,0,0-4.18m2.4,13.06L5147.94,45h-2.22v6.31h-3.09V35.5h6.2a4.78,4.78,0,0,1,5.13,4.84,4.26,4.26,0,0,1-2.89,4.15l3.53,6.82H5151Z"
                transform="translate(-5142.63 -35.37)"
            />
            <rect className="svg-rightpoint-logo-full" x="15.8" y="0.13" width="3.09" height="15.81" />
            <polygon
                className="svg-rightpoint-logo-full"
                points="47.78 0.13 47.78 6.6 42.43 6.6 42.43 0.13 39.34 0.13 39.34 15.95 42.43 15.95 42.43 9.33 47.78 9.33 47.78 15.95 50.87 15.95 50.87 0.13 47.78 0.13"
            />
            <polygon
                className="svg-rightpoint-logo-full"
                points="54.68 0.13 54.68 2.89 58.81 2.89 58.81 15.95 61.9 15.95 61.9 2.89 66.03 2.89 66.03 0.13 54.68 0.13"
            />
            <path
                className="svg-rightpoint-logo-full"
                d="M5218.49,38.25h-2.89v4.33h2.89a2.17,2.17,0,1,0,0-4.33m0.16,7.11h-3v6h-3.09V35.5h6.13a4.94,4.94,0,1,1,0,9.86"
                transform="translate(-5142.63 -35.37)"
            />
            <path
                className="svg-rightpoint-logo-full"
                d="M5234.49,39a2.57,2.57,0,0,0-2-.84,2.62,2.62,0,0,0-2,.84c-0.6.67-.76,1.4-0.76,4.44s0.16,3.78.76,4.44a2.62,2.62,0,0,0,2,.84,2.57,2.57,0,0,0,2-.84c0.6-.67.78-1.4,0.78-4.44s-0.18-3.78-.78-4.44m2.29,10.75a6.14,6.14,0,0,1-8.55,0c-1.6-1.6-1.55-3.58-1.55-6.31s0-4.71,1.55-6.31a6.14,6.14,0,0,1,8.55,0c1.6,1.6,1.58,3.58,1.58,6.31s0,4.71-1.58,6.31"
                transform="translate(-5142.63 -35.37)"
            />
            <rect className="svg-rightpoint-logo-full" x="99.34" y="0.13" width="3.09" height="15.81" />
            <polygon
                className="svg-rightpoint-logo-full"
                points="115.82 0.13 115.82 9.82 109.56 0.13 106.8 0.13 106.8 15.95 109.89 15.95 109.89 6.24 116.15 15.95 118.91 15.95 118.91 0.13 115.82 0.13"
            />
            <polygon
                className="svg-rightpoint-logo-full"
                points="122.72 0.13 122.72 2.89 126.85 2.89 126.85 15.95 129.93 15.95 129.93 2.89 134.06 2.89 134.06 0.13 122.72 0.13"
            />
            <rect className="rightpoint-logo-full-svg__line" y="19.93" width="11.4" height="2.97" />
            <path
                className="svg-rightpoint-logo-full"
                d="M5171.66,35.37a5.75,5.75,0,0,0-4.26,1.73c-1.6,1.6-1.55,3.58-1.55,6.31s0,4.71,1.55,6.31a6.28,6.28,0,0,0,8.73-.07c1.11-1.15,1.51-2.53,1.51-4.82V42.52h-5.27v2.58h2.21v0.6a3,3,0,0,1-.71,2.11,2.9,2.9,0,0,1-2.2.89,2.55,2.55,0,0,1-2-.82c-0.6-.67-0.76-1.42-0.76-4.46s0.16-3.78.76-4.44a2.57,2.57,0,0,1,2-.84,2.62,2.62,0,0,1,2.73,1.91h3.15a5.64,5.64,0,0,0-5.88-4.67"
                transform="translate(-5142.63 -35.37)"
            />
        </svg>
    );
};

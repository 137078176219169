import React from "react";
import {
  Box,
  Heading,
  Stack,
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  InputRightElement,
  Button
} from "@chakra-ui/core";
import { observable, action } from "mobx";
import { AzureRMService } from "../../services/AzureRMService";
import { observer } from "mobx-react";
import { UserSearchBox } from "../../components/UserSearchBox/UserSearchBox";

@observer
export class PasswordResetPage extends React.Component {
  azureService = new AzureRMService();

  @observable searchText: string = "";
  @action setSearchText(text: string) {
    this.searchText = text;
  }
  @observable displayedUsers: Array<any> = [];
  @action setDisplayedUsers() {}

  private _onSelectUser(user) {
    console.log("SELECTED USER", user);
  }
  render() {
    return (
      <Box>
        <Heading size={"sm"}>Reset a Password for a User</Heading>
        <UserSearchBox onSelectUser={this._onSelectUser} />
      </Box>
    );
  }
}

import { getGUID } from "@pnp/common";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import * as MicrosoftGraphBeta from "@microsoft/microsoft-graph-types-beta"


import { authProvider } from "../AuthProvider";
import {
  FUNCTIONAPP_BLOB_STORAGE_URL,
  FUNCTIONAPPV2_BLOB_STORAGE_URL,
  QUEUE_LISTENER_BLOB_STORAGE_URL,
  TEAMS_APP_ARMTEMPLATE_URL,
  PROVISIONING_ENGINE_ARMTEMPLATE_URL,
  getSparkTeamsAzureAdApp,
} from "../util/Constants";
import { PKService } from "./PKService";
import { cancelFetchOnReentry, cancelFetchOnReentry1 } from "../util/FetchCanceller";
import { globalStore } from "../stores/GlobalStore";
import { Client, AuthenticationProvider } from "@microsoft/microsoft-graph-client";
import { Stream } from "stream";
import { AzureSubscription } from "./types";
import { AzureDeployment } from "../models/AzureDeployment";

const compatibleAuthProvider: AuthenticationProvider = {
  getAccessToken: async authOptions => {
    console.log("AUTHOPTIONS", authOptions);
    const token = await authProvider.getAccessToken({ scopes: authOptions?.scopes });
    return token.accessToken;
  }
};
const options2 = {
  authProvider: compatibleAuthProvider
};
const client = Client.initWithMiddleware(options2);

export const postRequest = async (url, body, authority?: string) => {
  authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

  const token = await authProvider.getAccessToken({
    scopes: [
      `https://graph.microsoft.com/Group.ReadWrite.All`,
      `https://graph.microsoft.com/Directory.AccessAsUser.All`
    ],
    authority: authority
  });
  const resp = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token.accessToken,
      "Content-Type": "application/json"
    },
    body: body
  });
  return resp.json();
};

export const patchRequest = async (url, body, authority?: string) => {
  authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

  const token = await authProvider.getAccessToken({
    scopes: [
      `https://graph.microsoft.com/Group.ReadWrite.All`,
      `https://graph.microsoft.com/Directory.AccessAsUser.All`
    ],
    authority: authority
  });
  const resp = await fetch(url, {
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + token.accessToken,
      "Content-Type": "application/json"
    },
    body: body
  });
};

export const createAzureRmPutRequest = async (url, body, authority?) => {
  authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

  const token = await authProvider.getAccessToken({
    scopes: [`https://management.azure.com/.default`],
    authority: authority
  });
  const resp = await fetch(url, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token.accessToken,
      "Content-Type": "application/json"
    },
    body: body
  });
  return resp.json();
};

export const createAzureRmPatchRequest = async (url, body) => {
  const token = await authProvider.getAccessToken({
    scopes: [`https://management.azure.com/.default`]
  });
  const resp = await fetch(url, {
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + token.accessToken,
      "Content-Type": "application/json"
    },
    body: body
  });
  return resp.json();
};

export const createAzureRmDeleteRequest = async url => {
  const authority = globalStore.userCurrentAADDirectory
    ? `https://login.microsoftonline.com/${globalStore.userCurrentAADDirectory}`
    : authProvider.authority;
  console.log("AUTHORITY", authority);

  const token = await authProvider.getAccessToken({
    scopes: [`https://management.azure.com/.default`],
    authority: authority
  });
  const resp = await fetch(url, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token.accessToken,
      "Content-Type": "application/json"
    }
  });
};

export const createAzureRmHeadRequest = async url => {
  const authority = globalStore.userCurrentAADDirectory
    ? `https://login.microsoftonline.com/${globalStore.userCurrentAADDirectory}`
    : authProvider.authority;
  console.log("AUTHORITY", authority);
  const token = await authProvider.getAccessToken({
    scopes: [`https://management.azure.com/.default`],
    authority: authority
  });
  return await fetch(url, {
    method: "HEAD",
    headers: {
      Authorization: "Bearer " + token.accessToken,
      "Content-Type": "application/json"
    }
  });
};

export const requestSP = async (url, spUrl) => {
  const token = await authProvider.getAccessToken({ scopes: [`${spUrl}/.default`] });
  const resp = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token.accessToken,
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  });
  return resp.json();
};

export const createAzureRmGet = async url => {
  const authority = globalStore.userCurrentAADDirectory
    ? `https://login.microsoftonline.com/${globalStore.userCurrentAADDirectory}`
    : authProvider.authority;
  console.log("AUTHORITY", authority);

  const token = await authProvider.getAccessToken({
    scopes: [`https://management.azure.com/user_impersonation`],
    authority: authority
  });
  //const token = await authProvider.getAccessToken({ scopes: [`https://management.azure.com/user_impersonation`] });

  const resp = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token.accessToken,
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  });
  return resp.json();
};

export const createAzureRmPost = async url => {
  const token = await authProvider.getAccessToken({ scopes: [`https://management.azure.com/.default`] });
  const resp = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token.accessToken,
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  });
  return await resp.json();
};

export type SparkProvisioningAppSettings = {
  clientId: string;
  clientSecret: string;
  spTemplateSiteUrl: string;
  spUsername: string;
  spTenantUrl: string;
  spAdminUrl: string;
  installOnStart: boolean;
};

export type TeamsAppProvisioningSettings = {
  WebAppName: string;
  WebAppPlanName: string;
  FunctionAppName: string;
  FunctionAppDynamicPlanName: string;
  StorageAccountName: string;
};

export const putTemplate = async (
  subscriptionId: string,
  resourceGroupName: string,
  provisioningAppSettings: SparkProvisioningAppSettings,
  deploymentName: string,
  authority?: string
) => {
  authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

  const token = await authProvider.getAccessToken({
    scopes: [`https://management.azure.com/.default`],
    authority: authority
  });
  const resp = await fetch(
    `https://management.azure.com/subscriptions/${subscriptionId}/resourcegroups/${resourceGroupName}/providers/Microsoft.Resources/deployments/${deploymentName}?api-version=2019-05-10`,
    {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify({
        properties: {
          //  parametersLink: { uri: "https://rightpointmi.blob.core.windows.net/sparkflowconnector/arm-parameters.json" },
          parameters: {
            appName: {
              value: "spfuncs-app-"
            },
            webAppName: {
              value: "spfuncs-webapp-"
            },
            storageAcctName: {
              value: "spfuncsstor"
            },
            clientId: { value: provisioningAppSettings.clientId },
            clientSecret: { value: provisioningAppSettings.clientSecret },
            packageUrl: {
              value: QUEUE_LISTENER_BLOB_STORAGE_URL
            },
            functionv1packageUrl: {
              value: FUNCTIONAPP_BLOB_STORAGE_URL
            },
            functionv2packageUrl: {
              value: FUNCTIONAPPV2_BLOB_STORAGE_URL
            },
            spUsername: {
              value: provisioningAppSettings.spUsername
            },
            spAdminUrl: {
              value: provisioningAppSettings.spAdminUrl
            },
            spTenantUrl: {
              value: provisioningAppSettings.spTenantUrl
            },
            spTemplateSiteUrl: {
              value: provisioningAppSettings.spTemplateSiteUrl
            }
          },
          templateLink: { uri: PROVISIONING_ENGINE_ARMTEMPLATE_URL },
          //    parameters: {},
          mode: "Complete"
          //   onErrorDeployment: {
          //     type: "LastSuccessful"
          //   }
        }
      })
    }
  );
  if (resp.status === 409) {
    throw new Error("Unable to deploy template");
  }
  return resp.json();
};

export const putTeamsAppTemplate = async (
  subscriptionId: string,
  resourceGroupName: string,
  teamsAppProvisioningSettings: TeamsAppProvisioningSettings,
  deploymentName: string
) => {
  const token = await authProvider.getAccessToken({ scopes: [`https://management.azure.com/.default`] });
  const resp = await fetch(
    `https://management.azure.com/subscriptions/${subscriptionId}/resourcegroups/${resourceGroupName}/providers/Microsoft.Resources/deployments/${deploymentName}?api-version=2019-05-10`,
    {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify({
        properties: {
          parameters: {
            WebAppName: {
              value: teamsAppProvisioningSettings.WebAppName
            },
            WebAppPlanName: {
              value: teamsAppProvisioningSettings.WebAppPlanName
            },
            FunctionAppName: {
              value: teamsAppProvisioningSettings.FunctionAppName
            },
            FunctionAppDynamicPlanName: {
              value: teamsAppProvisioningSettings.FunctionAppDynamicPlanName
            },
            StorageAccountName: {
              value: teamsAppProvisioningSettings.StorageAccountName
            }
          },
          templateLink: { uri: TEAMS_APP_ARMTEMPLATE_URL },
          //    parameters: {},
          mode: "Complete"
          //   onErrorDeployment: {
          //     type: "LastSuccessful"
          //   }
        }
      })
    }
  );
  return resp.json();
};

export const getOrgInfo2 = cancelFetchOnReentry(() => async () => {
  const token = await authProvider.getAccessToken({
    scopes: [`https://graph.microsoft.com/User.Read`],
    state: "thisismystate"
    //  forceRefresh: true
    //    authority: "https://login.microsoftonline.com/d48fcabb-ff44-4836-a560-b2634a7f6cb1"
  });
  const resp = await fetch("https://graph.microsoft.com/v1.0/organization", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token.accessToken,
      "Content-Type": "application/json"
    }
  });
  const returnVal = await resp.json();
  return returnVal.value;
});

export class AzureRMService {
  constructor() { }

  public async getOrganizationInfo(authority?: string): Promise<MicrosoftGraph.Organization[]> {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;


    const token = await authProvider.getAccessToken({
      authority: authority,
      scopes: [`https://graph.microsoft.com/User.Read`]
    });
    const resp = await fetch("https://graph.microsoft.com/v1.0/organization", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });
    const returnVal = await resp.json();
    return returnVal.value;
  }

  public async getSubscribedSkus() {
    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Organization.Read.All`]
    });
    const resp = await fetch("https://graph.microsoft.com/v1.0/subscribedSkus", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });
    const returnVal = await resp.json();
    return returnVal.value;
  }

  public async getAzureADApps(authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.AccessAsUser.All`],
      authority: authority
    });
    const resp = await fetch("https://graph.microsoft.com/beta/applications", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });
    const returnVal = await resp.json();
    return returnVal.value;
  }

  public async deleteAzureAdApp(appId: string, authority?: string) {
    // https://graph.microsoft.com/beta/applications/{id}
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.AccessAsUser.All`,
      ], authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/beta/applications/${appId}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });
  }

  public async getTags(subscriptionId: string) {
    return createAzureRmGet(
      `https://management.azure.com/subscriptions/${subscriptionId}/tagNames?api-version=2019-05-10`
    );
  }
  public async createTag(subscriptionId: string, tagName: string) {
    return createAzureRmPutRequest(
      `https://management.azure.com/subscriptions/${subscriptionId}/tagNames/${tagName}?api-version=2019-05-10`,
      {}
    );
  }

  public async createTagValue(subscriptionId: string, tagName: string, tagValue: string) {
    return createAzureRmPutRequest(
      `https://management.azure.com/subscriptions/${subscriptionId}/tagNames/${tagName}/tagValues/${tagValue}?api-version=2019-05-10`,
      {}
    );
  }

  public getApplicationSettings(subscriptionId: string, resourceGroupName: string, name: string) {
    return createAzureRmPost(
      `https://management.azure.com/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/providers/Microsoft.Web/sites/${name}/config/appsettings/list?api-version=2016-08-01`
    );
  }
  public async updateResource(
    subscriptionId: string,
    resourceGroupName: string,
    resourceProviderNamespace: string,
    parentResourcePath: string,
    // /resourceType: string,
    resourceName: string,
    body: Object
  ) {
    return createAzureRmPutRequest(
      `https://management.azure.com/subscriptions/${subscriptionId}/resourcegroups/${resourceGroupName}/providers/${resourceProviderNamespace}/${parentResourcePath}/${resourceName}/config/appsettings?api-version=2016-08-01`,
      JSON.stringify(body)
    );
  }

  public async getServicePrincipals(): Promise<Array<{ appId: string; id: string; displayName: string }>> {
    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.AccessAsUser.All`]
    });
    const fetchFunction = async url => {
      return await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json"
        }
      });
    };

    const servicePrincipalResponse = await fetchFunction("https://graph.microsoft.com/beta/servicePrincipals");
    const servicePrincipals = await servicePrincipalResponse.json();
    if (servicePrincipals["@odata.nextLink"]) {
      return await this.getMoreFromNextLink(
        servicePrincipals.value,
        fetchFunction,
        servicePrincipals["@odata.nextLink"]
      );
    }
    return servicePrincipals.value;
  }

  private async getMoreFromNextLink(previousResults, fetchFunction, nextLinkUrl: string) {
    let response = await fetchFunction(nextLinkUrl);
    let json = await response.json();
    if (json["@odata.nextLink"]) {
      console.log(`calling for more after ${previousResults.length} `);
      const r = await this.getMoreFromNextLink(json.value, fetchFunction, json["@odata.nextLink"]);
      let newResults = previousResults.concat(r);
      return newResults;
    }

    return json.value;
  }

  public async getAzureADAppOauthPermissionsGrants(appId: string) {
    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.AccessAsUser.All`]
    });
    const resp = await fetch(`https://graph.microsoft.com/beta/oAuth2Permissiongrants/${appId}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    const permissionGrants = await resp.json();
  }

  public async getTenants() {
    return createAzureRmGet("https://management.azure.com/tenants?api-version=2019-06-01");
  }

  public async getSubscriptions(authority?: string): Promise<Array<AzureSubscription>> {

    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://management.azure.com/user_impersonation`],
      authority: authority
    });

    const resp = await fetch(`https://management.azure.com/subscriptions?api-version=2016-06-01`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    });
    return (await resp.json()).value;

  }

  public async getRoleAssignmentsForSubscription(subscriptionId: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    // GET https://management.azure.com/subscriptions/{subscriptionId}/providers/Microsoft.Authorization/roleAssignments?api-version=2015-07-01
    const token = await authProvider.getAccessToken({
      scopes: [`https://management.azure.com/user_impersonation`],
      authority: authority
    });

    const resp = await fetch(`https://management.azure.com/subscriptions/${subscriptionId}/providers/Microsoft.Authorization/roleAssignments?api-version=2015-07-01`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    });

    return (await resp.json()).value;

  }

  public async setRoleAssignmentForUserOnSubscription(userId: string, roleDefinitionId: string, subscriptionId: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://management.azure.com/user_impersonation`],
      authority: authority
    });

    const id = getGUID();
    const resp = await fetch(`https://management.azure.com/subscriptions/${subscriptionId}/providers/Microsoft.Authorization/roleAssignments/${id}?api-version=2015-07-01`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify({
        "properties": {
          "roleDefinitionId": `/subscriptions/${subscriptionId}/providers/Microsoft.Authorization/roleDefinitions/${roleDefinitionId}`,
          "principalId": userId
        }
      })
    });

    if (resp.status !== 201) {
      const json = await resp.json();
      return Promise.reject(json);
    }

    return (await resp.json()).value;

  }

  public async getResourceGroups(subscriptionId: string) {
    return createAzureRmGet(
      `https://management.azure.com/subscriptions/${subscriptionId}/resourcegroups?api-version=2019-05-10`
    ).then(rgs => {
      return rgs.value;
    });
  }

  public async getResourceGroupsByTag(subscriptionId: string, tagName: string, tagValue: string) {
    return createAzureRmGet(
      `https://management.azure.com/subscriptions/${subscriptionId}/resourcegroups?api-version=2019-05-10&$filter=tagName eq '${tagName}' and tagValue eq '${tagValue}'`
    ).then(rgs => {
      return rgs.value;
    });
  }

  public async getResourceGroup(subscriptionId: string, resourceGroupName: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://management.azure.com/user_impersonation`],
      authority: authority
    });

    const resp = await fetch(
      `https://management.azure.com/subscriptions/${subscriptionId}/resourcegroups/${resourceGroupName}?api-version=2019-05-10`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }
    );

    return await resp.json();
  }

  public async getDeploymentStatus(subscriptionId: string, resourceGroupName: string, deploymentName: string, authority?: string): Promise<AzureDeployment> {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({ scopes: [`https://management.azure.com/.default`], authority: authority });

    let deploymentStatusResponse = await fetch(
      `https://management.azure.com/subscriptions/${subscriptionId}/resourcegroups/${resourceGroupName}/providers/Microsoft.Resources/deployments/${deploymentName}?api-version=2019-05-10`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }
    );

    return await deploymentStatusResponse.json();
  }

  public async getDeploymentsForSubscription(subscriptionId: string) {
    const authority = globalStore.userCurrentAADDirectory
      ? `https://login.microsoftonline.com/${globalStore.userCurrentAADDirectory}`
      : authProvider.authority;
    console.log("AUTHORITY", authority);

    const token = await authProvider.getAccessToken({
      scopes: [`https://management.azure.com/.default`],
      authority: authority
    });
    //GET https://management.azure.com/subscriptions/{subscriptionId}/providers/Microsoft.Resources/deployments/?api-version=2019-05-10
    let deploymentStatusResponse = await fetch(
      `https://management.azure.com/subscriptions/${subscriptionId}/providers/Microsoft.Resources/deployments/?api-version=2019-05-10`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }
    );

    return await deploymentStatusResponse.json();
  }

  public async getDeploymentsForResourceGroup(
    subscriptionId: string,
    resourceGroupName,
    authority?: string
  ): Promise<Array<AzureDeployment>> {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://management.azure.com/.default`],
      authority: authority
    });
    let deploymentStatusResponse = await fetch(
      `https://management.azure.com/subscriptions/${subscriptionId}/resourcegroups/${resourceGroupName}/providers/Microsoft.Resources/deployments/?api-version=2019-05-10`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }
    );

    const deployments = await deploymentStatusResponse.json();
    return deployments.value;
  }

  public async createResourceGroup(subscriptionId: string, resourceGroupName: string, tags: Object) {
    return await createAzureRmPutRequest(
      `https://management.azure.com/subscriptions/${subscriptionId}/resourcegroups/${resourceGroupName}?api-version=2019-05-10`,
      JSON.stringify({
        location: "eastus",
        tags: tags
      })
    );
  }

  public async deleteResourceGroup(subscriptionId: string, resourceGroupName: string) {
    // DELETE https://management.azure.com/subscriptions/{subscriptionId}/resourcegroups/{resourceGroupName}?api-version=2019-05-10
    return createAzureRmDeleteRequest(
      `https://management.azure.com/subscriptions/${subscriptionId}/resourcegroups/${resourceGroupName}?api-version=2019-05-10`
    );
  }

  public async checkResourceGroupExistence(subscriptionId: string, resourceGroupName: string): Promise<boolean> {
    // HEAD https://management.azure.com/subscriptions/{subscriptionId}/resourcegroups/{resourceGroupName}?api-version=2019-05-10

    let resourceGroupResponse = await createAzureRmHeadRequest(
      `https://management.azure.com/subscriptions/${subscriptionId}/resourcegroups/${resourceGroupName}?api-version=2019-05-10`
    );
    if (resourceGroupResponse.ok) {
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }
  }

  public async forcePowerappsLogin() {
    const token = await authProvider.getAccessToken({
      scopes: [`https://management.core.windows.net/user_impersonation`]
    });
  }

  public async discoverCommonDataServiceUrls(): Promise<
    Array<{ ApiUrl: string; FriendlyName: string; Id: string; State: number; UniqueName: string; Url: string }>
  > {
    // const token = await authProvider.getAccessToken({
    //   scopes: [`https://globaldisco.crm.dynamics.com`]
    // });

    const authority = globalStore.userCurrentAADDirectory
      ? `https://login.microsoftonline.com/${globalStore.userCurrentAADDirectory}`
      : authProvider.authority;
    console.log("AUTHORITY", authority);

    const token = await authProvider.getAccessToken({
      scopes: ["https://globaldisco.crm.dynamics.com/user_impersonation"],
      authority: authority
    });

    const discoverResponse = await fetch("https://globaldisco.crm.dynamics.com/api/discovery/v1.0/Instances", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    const discoverJson = await discoverResponse.json();
    console.log("DISCOVER RESPONSE", discoverJson);
    return discoverJson.value;
  }

  public async getFlowSolutions(serviceUrl) {
    const token = await authProvider.getAccessToken({
      scopes: [`${serviceUrl}/user_impersonation`]
    });

    const discoverResponse = await fetch(`${serviceUrl}/api/data/v9.1/solutions`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    const discoverJson = await discoverResponse.json();
    console.log("SOLUTIONS RESPONSE", discoverJson);
    return discoverJson.value;
  }

  public async getFlowConnectors(
    serviceUrl
  ): Promise<
    Array<{
      iconblob: string;
      displayname: string;
      connectorid: string;
      connectoridunique: string;
      connectorinternalid: string;
    }>
  > {
    const authority = globalStore.userCurrentAADDirectory
      ? `https://login.microsoftonline.com/${globalStore.userCurrentAADDirectory}`
      : authProvider.authority;
    console.log("AUTHORITY", authority);

    const token = await authProvider.getAccessToken({
      scopes: [`${serviceUrl}/user_impersonation`],
      authority: authority
    });

    const discoverResponse = await fetch(`${serviceUrl}/api/data/v9.1/connectors`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    const discoverJson = await discoverResponse.json();

    if (discoverJson.error) {
      throw Error("User doesn't have Flow license?");
    }
    return discoverJson.value;
  }

  public async exportSolution(serviceUrl: string) {
    const token = await authProvider.getAccessToken({
      scopes: [`${serviceUrl}/user_impersonation`]
    });

    const exportedSolutionResp = await fetch(`${serviceUrl}/api/data/v9.1/ExportSolution`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        SolutionName: "SparkProvisioning",
        Managed: true
      })
    });

    const exportedSolution = await exportedSolutionResp.json();
    console.log("EXPORTED SOLUTION", exportedSolution);
  }

  public async importSolution(serviceUrl: string, base64String: string) {
    const token = await authProvider.getAccessToken({
      scopes: [`${serviceUrl}/user_impersonation`]
    });

    const importedSolutionResp = await fetch(`${serviceUrl}/api/data/v9.1/ImportSolution`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        OverwriteUnmanagedCustomizations: true,
        PublishWorkflows: true,
        ImportJobId: getGUID(),
        CustomizationFile: base64String
      })
    });

    if (importedSolutionResp.status === 500) {
      throw new Error("Unable to import solution");
    }
  }

  public async tryPowerAppsWay(envName: string) {
    const token = await authProvider.getAccessToken({
      scopes: [`https://api.powerapps.com/.default`]
    });
    debugger;
    const importedSolutionResp = await fetch(
      `${`https://api.powerapps.com/providers/Microsoft.PowerApps/apis?api-version=2016-11-01&$filter=environment eq ${envName}`}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json"
        }
        //   body: JSON.stringify({})
      }
    );
  }

  public async createOrUpdateFlowConnector(serviceUrl, connector) {
    const token = await authProvider.getAccessToken({
      scopes: [`${serviceUrl}/user_impersonation`]
    });

    await fetch(`${serviceUrl}/api/data/v9.1/connectors`, {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        description: "This flow will ensure consistency across systems.",
        "ownerid@odata.bind": "systemusers(00000000-0000-0000-0000-000000000005)"
      })
    });
  }

  public async inviteExternalUser(userName: string, redirectUrl: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.ReadWrite.All`],
      authority: authority,
      forceRefresh: true
    });

    const invitation: MicrosoftGraph.Invitation = {
      sendInvitationMessage: true,
      invitedUserEmailAddress: userName,
      inviteRedirectUrl: redirectUrl
    }
    const inviteResp = await fetch(`https://graph.microsoft.com/v1.0/invitations`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(invitation)
    });

    if (inviteResp.status === 400) {
      // TODO: handle more error codes?
      throw new Error(`Unable to invite user: ${userName}, the user might already be an invitee.`)
    }

    const invite = await inviteResp.json();
    return invite;
  }

  public async getDirectoryRoles(
    authority?: string
  ): Promise<Array<{ id: string; displayName: string; description: string; roleTemplateId: string }>> {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.ReadWrite.All`],
      authority: authority
    });

    const rolesResponse = await fetch(`https://graph.microsoft.com/beta/directoryRoles`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    const rolesJson = await rolesResponse.json();
    console.log("ROLES", rolesJson);
    return rolesJson.value;
  }

  public async getMemberObjects(userId: string) {
    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.Read.All`]
    });

    const resp = await fetch(`https://graph.microsoft.com/v1.0/users/${userId}/getMemberObjects`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    const respJson = await resp.json();
    console.log("MEMBEROBJECTS", respJson);
  }

  public async getUserDirectoryRolesTransitive(id: string) {
    const authority = globalStore.userCurrentAADDirectory
      ? `https://login.microsoftonline.com/${globalStore.userCurrentAADDirectory}`
      : authProvider.authority;
    console.log("AUTHORITY", authority);

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.Read.All`],
      authority: authority
    });

    const rolesResponse = await fetch(`https://graph.microsoft.com/beta/users/${id}/transitiveMemberOf`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    const roles = await rolesResponse.json();
    return roles.value;
  }

  public async updateUserPassword(userIdOrPrincipalName: string, newPassword: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.Read.All`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/beta/users/${userIdOrPrincipalName}`, {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        passwordProfile: {
          password: newPassword
        }
      })
    });

    // TODO handle errors

  }

  public async getMe(authority?: string): Promise<MicrosoftGraph.User> {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.Read`],
      authority: authority
    });

    // let userDetails = await client.api("/me").get();
    // console.log("USER DET", userDetails);

    const usersResponse = await fetch(`https://graph.microsoft.com/v1.0/me`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    const usersJson: MicrosoftGraph.User = await usersResponse.json();
    return usersJson;
  }

  public async getMyPhoto(authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.Read`],
      authority: authority
    });

    // https://docs.microsoft.com/en-us/graph/api/profilephoto-get?view=graph-rest-1.0
    const photo = await fetch(`https://graph.microsoft.com/v1.0/me/photos/96x96/$value`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    if (photo.status === 401) {
      return "https://placehold.it/96x96";
    }

    const photoResult = await photo.blob();
    var objectURL = URL.createObjectURL(photoResult);
    return objectURL;
  }

  public async getUserPhotoMetadataById(id: string, height: number, width: number, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.Read`],
      authority: authority
    });

    // https://docs.microsoft.com/en-us/graph/api/profilephoto-get?view=graph-rest-1.0
    const photo = await fetch(`https://graph.microsoft.com/v1.0/users/${id}/photos/${width}x${height}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    return await photo.json();
  }

  public async getUserPhotoById(id: string, height: number, width: number, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.Read`],
      authority: authority
    });

    // https://docs.microsoft.com/en-us/graph/api/profilephoto-get?view=graph-rest-1.0
    const photo = await fetch(`https://graph.microsoft.com/v1.0/users/${id}/photos/${width}x${height}/$value`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    if (photo.status === 401 || photo.status === 404) {
      return ""
    }

    const photoResult = await photo.blob();
    var objectURL = URL.createObjectURL(photoResult);
    return objectURL;
  }

  public async getAllPhotosByUserId(id: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.Read`],
      authority: authority
    });

    const photos = await fetch(`https://graph.microsoft.com/v1.0/users/${id}/photos`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    return (await photos.json()).value;
  }

  public async updateMyPhoto(binaryData: Blob, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.ReadWrite`],
      authority: authority
    });

    const photo = await fetch(`https://graph.microsoft.com/v1.0/me/photo/$value`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "image/png"
      },
      body: binaryData
    });
  }


  public async updatePhotoByUserId(binaryData: Blob, userId: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.ReadWrite`],
      authority: authority
    });

    const photo = await fetch(`https://graph.microsoft.com/v1.0/users/${userId}/photo/$value`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "image/png"
      },
      body: binaryData
    });
  }

  public async getMyLicenses(authority?: string): Promise<MicrosoftGraph.LicenseDetails[]> {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.Read`],
      authority: authority
    });

    const usersResponse = await fetch(`https://graph.microsoft.com/v1.0/me/licenseDetails`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    const usersJson: MicrosoftGraph.LicenseDetails[] = (await usersResponse.json()).value;
    return usersJson;
  }

  public async getUserByMail(mail: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.Read.All`],
      authority: authority
    });

    const usersResponse = await fetch(`https://graph.microsoft.com/v1.0/users?$filter=startswith(mail,'${mail}')`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    const usersJson = await usersResponse.json();
    console.log("USERS", usersJson);
    return usersJson.value[0];
  }

  public async getUserByUserPrincipalName(userPrincipalName: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.Read.All`],
      authority: authority
    });

    const usersResponse = await fetch(`https://graph.microsoft.com/v1.0/users?$filter=startswith(userPrincipalName,'${userPrincipalName}')`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    const usersJson = await usersResponse.json();
    console.log("USERS", usersJson);
    return usersJson.value[0];
  }

  public async getUsersByPrincipalNameStartsWith(userName: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.ReadWrite.All`],
      authority: authority
    });

    const usersResponse = await fetch(
      `https://graph.microsoft.com/v1.0/users?$filter=startswith(userPrincipalName,'${userName}')`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json"
        }
      }
    );

    const usersJson = await usersResponse.json();
    console.log("USERS", usersJson);
    return usersJson.value;
  }

  public async getUsersByDisplayNameStartsWith(userName: string, authority?: string): Promise<MicrosoftGraph.User[]> {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.ReadWrite.All`],
      authority: authority
    });

    const usersResponse = await fetch(
      `https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName,'${userName}')`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json"
        }
      }
    );

    const usersJson = await usersResponse.json();
    console.log("USERS", usersJson);
    return usersJson.value
  }

  public async getAllUsers(authority?: string) {
    // TODO: get random users
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.ReadWrite.All`],
      authority: authority
    });

    const usersResponse = await fetch(
      `https://graph.microsoft.com/v1.0/users`,
      {
        method: "GET",

        headers: {
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json"
        }
      }
    );

    const usersJson = await usersResponse.json();
    return usersJson.value;
  }

  public async getAllUsersWithLicenses(authority?: string) {
    // TODO: get random users
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.ReadWrite.All`],
      authority: authority
    });

    const usersResponse = await fetch(
      `https://graph.microsoft.com/v1.0/users?$select=id,displayName,givenName,userPrincipalName,assignedLicenses,surname`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json"
        }
      }
    );

    const usersJson = await usersResponse.json();
    return usersJson.value;
  }

  public async createUser(userName: string, displayName: string, mailNickname: string, password: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.ReadWrite.All`], authority: authority
    });

    const usersResponse = await fetch(`https://graph.microsoft.com/v1.0/users`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        accountEnabled: true,
        displayName: displayName,
        mailNickname: mailNickname,
        userPrincipalName: userName,
        passwordProfile: {
          forceChangePasswordNextSignIn: true,
          password: password
        }
      })
    });

    if (usersResponse.status === 400) {
      throw new Error("Invalid create user request");
    }
    const usersJson = await usersResponse.json();
    console.log("USERS", usersJson);
    return usersJson;
  }

  public async deleteUser(upnOrId: string, authority?: string): Promise<void> {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.ReadWrite.All`], authority
    });

    await fetch(`https://graph.microsoft.com/v1.0/users/${upnOrId}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });
  }

  public async setUserInDirectoryRole(roleId: string, userId: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.ReadWrite.All`], authority: authority
    });

    const usersResponse = await fetch(`https://graph.microsoft.com/v1.0/directoryRoles/${roleId}/members/$ref`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "@odata.id": `https://graph.microsoft.com/v1.0/directoryObjects/${userId}`
      })
    });
  }

  public async getMembersInDirectoryRole(
    roleId: string
  ): Promise<[{ displayName: string; id: string; userPrincipalName: string; mail: string }]> {
    const authority = globalStore.userCurrentAADDirectory
      ? `https://login.microsoftonline.com/${globalStore.userCurrentAADDirectory}`
      : authProvider.authority;
    console.log("AUTHORITY", authority);

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.Read.All`],
      authority: authority
      //   authority: `https://login.microsoftonline.com/d48fcabb-ff44-4836-a560-b2634a7f6cb1`
    });

    const usersResponse = await fetch(`https://graph.microsoft.com/v1.0/directoryRoles/${roleId}/members`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    const users = await usersResponse.json();

    console.log(users, "USERS IN DIRECTORY ROLE");
    return users.value;
  }

  // doesnt work without PIM
  public async getUserPrivilegedRoleAssignment(userId: string) {
    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.AccessAsUser.All`]
    });

    const privilegedRoleAssignmentResponse = await fetch(
      `https://graph.microsoft.com/beta/privilegedRoles/${userId}/assignments`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json"
        }
      }
    );

    const userPrivilegedRoleAssignment = await privilegedRoleAssignmentResponse.json();
    console.log("USER PRIV", userPrivilegedRoleAssignment);
  }

  public async assignUserRoleAssignment(tenantId: string, principalId: string, roleDefinitionId: string, resourceScopes: Array<string> = ["/"], authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.AccessAsUser.All`],
      authority: authority
    });
    //https://graph.windows.net/<tenantDomain-or-tenantId>/roleAssignments?api-version=1.61-internal
    await fetch(
      `https://graph.windows.net/${tenantId}/roleAssignments?api-version=1.61-internal`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "principalId": principalId,
          "roleDefinitionId": roleDefinitionId,
          "resourceScopes": resourceScopes
        })
      }
    );
  }

  public async getResourcesInResourceGroupWebProvider(
    subscriptionId,
    resourceGroupName
  ): Promise<Array<{ name: string; tags: Object; properties: { state: string } }>> {
    // GET https://management.azure.com/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Microsoft.Web/sites?includeSlots={includeSlots}&api-version=2016-08-01
    const r = await createAzureRmGet(
      `https://management.azure.com/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/providers/Microsoft.Web/sites?includeSlots=true&api-version=2016-08-01`
    );
    return r.value;
  }
  public async restartWebApp(subscriptionId: string, resourceGroupName: string, appName: string) {
    // POST https://management.azure.com/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Microsoft.Web/sites/{name}/restart?api-version=2016-08-01

    const token = await authProvider.getAccessToken({ scopes: [`https://management.azure.com/.default`] });
    await fetch(
      `https://management.azure.com/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/providers/Microsoft.Web/sites/${appName}/restart?api-version=2016-08-01`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }
    );
  }

  public async deployProvisioningAppARMTemplate(
    subscriptionId: string,
    resourceGroupName: string,
    provisioningAppSettings: SparkProvisioningAppSettings
  ) {
    return putTemplate(subscriptionId, resourceGroupName, provisioningAppSettings, "SparkDeployment");
  }

  public async deployTeamsAppARMTemplate(
    subscriptionId: string,
    resourceGroupName: string,
    provisioningAppSettings: TeamsAppProvisioningSettings
  ) {
    return putTeamsAppTemplate(subscriptionId, resourceGroupName, provisioningAppSettings, "TeamsAppDeployment");
  }

  public getAzureADAppsByTag = async (tagName: string, authority?: string) => {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;
    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.AccessAsUser.All`],
      authority: authority
    });
    return await fetch(`https://graph.microsoft.com/beta/applications?$filter=tags/Any(x:x eq '${tagName}')`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    }).then(async resp => (await resp.json()).value);
  };

  public getAzureADAppByID = async (id: string, authority?: string) => {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;
    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.AccessAsUser.All`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/v1.0/applications/${id}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    })

    if (!resp.ok) {
      throw new Error(`Unable to get app with id: ${id}`);
    }

    return await resp.json()
  };

  public async createProvisioningEngineAzureADApp(appBody, authority?: string): Promise<{ appId: string }> {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [
        `https://graph.microsoft.com/Group.ReadWrite.All`,
        `https://graph.microsoft.com/Directory.AccessAsUser.All`
      ],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/beta/applications`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(appBody)
    });

    if (!resp.ok) {
      throw new Error(resp.statusText);
    }
    return resp.json();

  }

  public async createTeamsAppAzureADApp() {
    return postRequest(
      "https://graph.microsoft.com/beta/applications",
      JSON.stringify(getSparkTeamsAzureAdApp("SPARKTEAMSTEST", "this-is-a-secret-1", ["Spark Teams"]))
    );
  }

  public async updateAzureADAppWithNewSecret(appId: string, secret: string) {
    // PATCH https://graph.microsoft.com/beta/applications/{id}

    return patchRequest(
      `https://graph.microsoft.com/beta/applications/${appId}`,
      JSON.stringify({
        passwordCredentials: [
          {
            customKeyIdentifier: "null",
            endDateTime: "2120-12-31T12:00:00Z",
            secretText: secret
          }
        ]
      })
    );
  }

  public async updateAzureADToRemovePasswords(appId: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.AccessAsUser.All`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/beta/applications/${appId}`, {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        passwordCredentials: []
      })
    });

    return resp;
  }

  public async updateAzureADAppWithNewSecretAddPassword(appId: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.AccessAsUser.All`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/beta/applications/${appId}/addPassword`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({})
    });
    return resp.json();
  }

  public async listCertificatesForResourceGroup(subscriptionId: string, resourceGroupName: string) {
    // GET https://management.azure.com/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Microsoft.Web/certificates?api-version=2016-03-01
    const token = await authProvider.getAccessToken({
      scopes: [`https://management.azure.com/.default`]
    });
    const resp = await fetch(
      `https://management.azure.com/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/providers/Microsoft.Web/certificates?api-version=2016-03-01`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json"
        }
      }
    );

    const respJson = await resp.json();

    return respJson.value;
  }

  public async createCertificateInResourceGroup(
    subscriptionId: string,
    resourceGroupName: string,
    certificateName: string,
    pfxBlob: string
  ) {
    return createAzureRmPutRequest(
      `https://management.azure.com/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/providers/Microsoft.Web/certificates/${certificateName}?api-version=2016-03-01`,
      JSON.stringify({
        id: `/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/providers/Microsoft.Web/certificates/${certificateName}`,
        name: certificateName,
        type: "Microsoft.Web/certificates",
        location: "Central US",
        properties: {
          friendlyName: "",
          subjectName: "ServerCert",
          hostNames: ["ServerCert"],
          issuer: "rightpoint.com",
          issueDate: "2019-10-08T16:27:23+00:00",
          expirationDate: "2020-10-08T16:27:23+00:00",
          // "thumbprint": "FE703D7411A44163B6D32B3AD9B03E175886EBFE",
          password: "password",
          pfxBlob: pfxBlob
        }
      })
    );
  }

  public async updateAzureADAppWithNewCertificate(appId: string, key: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.AccessAsUser.All`],
      authority: authority
    });
    return await fetch(`https://graph.microsoft.com/beta/applications/${appId}`, {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        keyCredentials: [
          {
            keyId: getGUID(),
            type: "AsymmetricX509Cert",
            usage: "Verify",
            key: key
          }
        ]
      })
    });
  }

  public async getFunctionAppMasterKey(
    subscriptionId: string,
    resourceGroupName: string,
    name: string,
    authority?: string
  ): Promise<{ masterKey: string }> {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://management.azure.com/.default`],
      authority: authority
    });
    const resp = await fetch(
      `https://management.azure.com/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/providers/Microsoft.Web/sites/${name}/host/default/listKeys?api-version=2018-11-01`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json"
        }
      }
    );

    const respJson = await resp.json();

    return respJson;
  }

  public async updateFunctionAppMasterKey(
    subscriptionId: string,
    resourceGroupName: string,
    name: string,
    newKey: string,
    authority?: string

  ): Promise<{ properties: { masterKey: string } }> {

    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://management.azure.com/.default`],
      authority: authority

    });
    const resp = await fetch(
      `https://management.azure.com/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/providers/Microsoft.Web/sites/${name}/host/default/functionkeys/_master?api-version=2018-11-01`,
      {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ properties: { name: "masterKey", value: newKey } })
      }
    );

    const respJson = await resp.json();
    console.log("UPDATE", respJson);
    return respJson;
  }

  public async listAppsInTeamsAppCatalog(authority?: string): Promise<
    Array<{
      displayName: string;
      distributionMethod: string;
      externalId: string;
      id: string;
      name: string;
    }>
  > {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/AppCatalog.ReadWrite.All`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/beta/appCatalogs/teamsApps`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    const apps = await resp.json();
    return apps.value;
  }

  public async publishAppToTeamsAppCatalog(zipFile, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/AppCatalog.ReadWrite.All`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/beta/appCatalogs/teamsApps`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/zip" // zip
      },
      body: zipFile
    });
  }

  //appId from the list of apps, not the app manifest itself
  public async updateAppInTeamsAppCatalog(appId: string, zipFile) {
    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/AppCatalog.ReadWrite.All`]
    });

    const resp = await fetch(`https://graph.microsoft.com/beta/appCatalogs/teamsApps/${appId}`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/zip" // zip
      },
      body: zipFile
    });
  }

  public async sendTeamsMessage(teamId: string, channelId: string, body: Object, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Group.ReadWrite.All`],
      authority: authProvider.authority
    });
    return await fetch(`https://graph.microsoft.com/beta/teams/${teamId}/channels/${channelId}/messages`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
  }

  public async sendTeamsReply(teamId: string, channelId: string, messageId: string, body: Object, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Group.ReadWrite.All`],
      authority: authProvider.authority
    });
    return await fetch(`https://graph.microsoft.com/beta/teams/${teamId}/channels/${channelId}/messages/${messageId}/replies`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
  }

  public async sendEmail(message: MicrosoftGraph.Message) {
    const authority = globalStore.userCurrentAADDirectory
      ? `https://login.microsoftonline.com/${globalStore.userCurrentAADDirectory}`
      : authProvider.authority;
    console.log("AUTHORITY", authority);
    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Mail.Send`],
      authority: authProvider.authority
    });
    return await fetch(`https://graph.microsoft.com/beta/me/sendMail`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ message: message })
    });
  }

  public async getItemsSharedWithMe(authority?: string): Promise<Array<MicrosoftGraph.SharedInsight>> {
    // https://graph.microsoft.com/beta/me/insights/shared
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Sites.ReadWrite.All`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/beta/me/insights/shared`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    const respJson = await resp.json();
    if (resp.status === 401 && respJson.error.code === "MarkedAsGuest") {
      throw new Error("User is a guest user and can't access Insights");
    }
    return respJson.value;
  }

  public async getItemsTrendingAroundMe(authority?: string): Promise<MicrosoftGraph.Trending[]> {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Sites.ReadWrite.All`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/beta/me/insights/trending`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });
    return (await resp.json()).value;
  }

  public async getItemsUsedByMe(authority?: string): Promise<MicrosoftGraph.UsedInsight[]> {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Sites.ReadWrite.All`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/beta/me/insights/used`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });
    return (await resp.json()).value;
  }

  public async getMyProfile(authority?: string): Promise<Array<any>> {
    // https://graph.microsoft.com/beta/me/profile
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.Read`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/beta/me/profile`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });
    return await resp.json();
  }


  public async getUserById(id: string, authority?: string): Promise<Required<MicrosoftGraph.User>> {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.Read`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/v1.0/users/${id}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    if (resp.status === 404) {
      throw new Error("Error getting user")
    }
    return await resp.json();
  }

  public async getUserProfileById(id: string, authority?: string): Promise<any> {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.Read`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/beta/users/${id}/profile`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });
    return await resp.json();
  }

  public async addGraphExtensionToOrganization(extensionName: string, orgId: string, extensionObject: Object, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.AccessAsUser.All`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/v1.0/organization/${orgId}/extensions`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "@odata.type": "microsoft.graph.openTypeExtension",
        "extensionName": extensionName,
        ...extensionObject
      })
    });
    return await resp.json();
  }

  public async getGraphExtensionForOrganization(extensionName: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.AccessAsUser.All`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/v1.0/organization?$select=id&$expand=extensions`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });
    const data = await resp.json();
    const dataVal = data.value[0]
    if (!dataVal.extensions) {
      return {};
    }
    const extensionData = dataVal.extensions.find((ext) => {
      return ext.extensionName === extensionName;
    })

    delete extensionData["@odata.type"];
    delete extensionData["extensionName"];
    delete extensionData["id"]

    Object.keys(extensionData).forEach((k) => {
      if (k.indexOf("@odata.type") !== -1) {
        delete extensionData[k];
      }
    })
    return extensionData;
  }

  public async updateGraphExtensionForOrganization(extensionName: string, orgId: string, extensionObject: Object, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.Read`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/v1.0/organization/${orgId}/extensions/${extensionName}`, {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "@odata.type": "microsoft.graph.openTypeExtension",
        "extensionName": extensionName,
        ...extensionObject
      })
    });
  }

  public async deleteGraphExtensionForOrganization(extensionName: string, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.Read`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/v1.0/organization/extensions/${extensionName}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });
  }

  public async addGraphExtension(extensionName: string, extensionObject: Object, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.Read`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/v1.0/me/extensions`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "@odata.type": "microsoft.graph.openTypeExtension",
        "extensionName": extensionName,
        ...extensionObject
      })
    });
    return await resp.json();
  }

  public async getGraphExtensionForMe(extensionName: string, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.Read`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/v1.0/me?$select=id&$expand=extensions`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });
    const data = await resp.json();
    const extensionData = data.extensions.find((ext) => {
      return ext.extensionName === extensionName;
    })



    delete extensionData["@odata.type"];
    delete extensionData["extensionName"];
    delete extensionData["id"];
    Object.keys(extensionData).forEach((k) => {
      if (k.indexOf("@odata.type") !== -1) {
        delete extensionData[k];
      }
    })
    return extensionData;
  }

  public async getGraphExtensionForUser(extensionName: string, userId: string, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Directory.Read.All`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/v1.0/users/${userId}?$select=id&$expand=extensions`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });
    const data = await resp.json();
    if (data.extensions.length === 0) {
      return {};
    }
    const extensionData = data.extensions.find((ext) => {
      return ext.extensionName === extensionName;
    })



    delete extensionData["@odata.type"];
    delete extensionData["extensionName"];
    delete extensionData["id"];
    Object.keys(extensionData).forEach((k) => {
      if (k.indexOf("@odata.type") !== -1) {
        delete extensionData[k];
      }
    })
    return extensionData;
  }

  async addGraphExtensionToGroup(groupId: string, extensionName: string, extensionObject: Object, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Group.ReadWrite.All`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/v1.0/groups/${groupId}/extensions`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "@odata.type": "microsoft.graph.openTypeExtension",
        "extensionName": extensionName,
        ...extensionObject
      })
    });
    return await resp.json();
  }

  public async getGraphExtensionForGroup(groupId: string, extensionName: string, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Group.ReadWrite.All`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/v1.0/groups/${groupId}?$select=id&$expand=extensions`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });
    const data = await resp.json();
    if (data.extensions.length === 0) {
      return {};
    }
    const extensionData = data.extensions.find((ext) => {
      return ext.extensionName === extensionName;
    })

    delete extensionData["@odata.type"];
    delete extensionData["extensionName"];
    delete extensionData["id"];
    Object.keys(extensionData).forEach((k) => {
      if (k.indexOf("@odata.type") !== -1) {
        delete extensionData[k];
      }
    })
    return extensionData;
  }

  public async updateGraphExtensionForMe(extensionName: string, extensionObject: Object, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.Read`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/v1.0/me/extensions/${extensionName}`, {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "@odata.type": "microsoft.graph.openTypeExtension",
        "extensionName": extensionName,
        ...extensionObject
      })
    });
  }

  public async deleteGraphExtensionForMe(extensionName: string, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.Read`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/v1.0/me/extensions/${extensionName}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });
  }

  public async ensureAppRootFolder(authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Files.ReadWrite`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/v1.0/drive/special/approot`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    return await resp.json();
  }

  public async getMyDrives(authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Files.Read`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/v1.0/me/drives`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    return await resp.json();
  }

  public async createRootFolderInMyDrive(folderName: string, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Files.ReadWrite`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/v1.0/me/drive/root/children`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "name": folderName,
        "folder": {},
        "@microsoft.graph.conflictBehavior": "fail"
      })
    });

    return await resp.json();

  }


  public async saveFileInMyDrive(folderName: string, fileName: string, contentType: string, binaryOrText: string | Blob, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Files.ReadWrite`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/v1.0/me/drive/items/root:/${folderName}/${fileName}:/content`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": contentType
      },
      body: binaryOrText
    });

    return await resp.json();

  }


  public async getFileInMyDriveById(id: string, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Files.ReadWrite`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/v1.0/me/drive/items/${id}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"

      }
    });

    return await resp.json();

  }

  public async getFileInUserDriveById(userId: string, id: string, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Files.ReadWrite`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/v1.0/users/${userId}/drive/items/${id}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"

      }
    });

    return await resp.json();

  }

  public async getFileFromUserDrive(userId: string, folderName: string, fileName: string, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Files.ReadWrite`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/v1.0/users/${userId}/drive/root:/${folderName}/${fileName}:?select=id,@microsoft.graph.downloadUrl`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    return await resp.json();
  }

  public async getAllFilesFromUserDrivePath(userId: string, folderPath: string, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Files.ReadWrite`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/v1.0/users/${userId}/drive/root:/${folderPath}:/children`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    return (await resp.json()).value;
  }

  public async shareFileFromMyDrive(sharedWithUserEmail: string, itemId: string, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Files.ReadWrite`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/v1.0/me/drive/items/${itemId}/invite`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "requireSignIn": true,
        "sendInvitation": false,
        "roles": ["read"],
        "recipients": [
          { email: sharedWithUserEmail }
        ],
        "message": "Shared a file"
      })
    });

  }

  public async shareFileFromMyDriveByAlias(alias: string, itemId: string, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Files.ReadWrite`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/v1.0/me/drive/items/${itemId}/invite`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "requireSignIn": true,
        "sendInvitation": false,
        "roles": ["read"],
        "recipients": [
          { alias: alias }
        ],
        "message": "Shared a file"
      })
    });

  }

  public async listPermissionsOnMyFolder(folderPath: string, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Files.ReadWrite`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/v1.0/me/drive/root:/${folderPath}:/permissions`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });
    return await resp.json();
  }

  public async deleteFolderFromMyDrive(folderPath: string, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Files.ReadWrite`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/v1.0/me/drive/root:/${folderPath}:/`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });
  }

  public async deleteFileFromMyDrive(folderPath: string, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Files.ReadWrite`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/v1.0/me/drive/root:/${folderPath}:/`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });
  }

  public async getSPSiteSearch(authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Sites.ReadWrite.All`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/beta/sites?$select=siteCollection,webUrl&$filter=siteCollection/root%20ne%20null`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });
    // GET https://graph.microsoft.com/beta/sites?$select=siteCollection,webUrl&$filter=siteCollection/root%20ne%20null
    return await resp.json();
  }

  public async getGraphSubscriptions(authority?: string): Promise<Array<MicrosoftGraph.Subscription>> {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.Read.All`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/v1.0/subscriptions`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    })
    return (await resp.json()).value
  }

  public async deleteGraphSubscription(id: string, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.Read.All`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/v1.0/subscriptions/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    })

  }

  public async creatWebhookSubscriptionForUserId(userId: string, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.Read.All`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/v1.0/subscriptions`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "changeType": "updated,deleted",
        "notificationUrl": "https://twhite-proxyc5aok5hs6jhxa.servicebus.windows.net:443/TWHI-10252018",
        "resource": `users/${userId}`,
        "expirationDateTime": "2020-02-29T18:23:45.9356913Z",
        "clientState": "secretClientValue",
        "latestSupportedTlsVersion": "v1_2"
      })
    });

    return await resp.json();
  }

  public async getUserInformationLatestDelta(userId: string, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.Read.All`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/beta/users/delta/?$filter=id eq '${userId}'&$deltaToken=latest`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    return await resp.json();
  }

  public async getUserInformationFromDeltaLink(deltaLink: string, authority?: string) {
    authority = authority || globalStore.userHomeAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.Read.All`],
      authority: authority
    });

    const resp = await fetch(deltaLink, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      }
    });

    return (await resp.json()).value;
  }
}

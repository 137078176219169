import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  FormLabel,
  IconButton,
  Stack,
  Switch,
  useDisclosure
} from "@chakra-ui/core";
import { observer } from "mobx-react";
import React from "react";

import { authProvider } from "../../AuthProvider";
import { globalStore } from "../../stores/GlobalStore";
import { DebugPanel } from "../DebugPanel/DebugPanel";
import { TenantSelector } from "../TenantSelector/TenantSelector";
import { ThemeSelector } from "../ThemeSelector/ThemeSelector";

function SparkDrawerInner() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <>
      <IconButton
        onClick={onOpen}
        color="themePrimary"
        _hover={{ color: "white", backgroundColor: "themePrimary" }}
        variant="outline"
        variantColor="themePrimary"
        aria-label="open settings menu"
        icon="settings"
      />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={() => {
          onClose();
        }}
        finalFocusRef={btnRef as any}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Settings</DrawerHeader>
          <DrawerBody>
            <Stack direction={"column"} pb={20} spacing={5} shouldWrapChildren={true}>
              <TenantSelector />
              <ThemeSelector />
              <Box>
                <FormLabel htmlFor="enable-meganav">Enable Meganav</FormLabel>
                <Switch
                  isChecked={globalStore.settingsStore.enableMeganav}
                  onChange={() => {
                    globalStore.settingsStore.setEnableMeganav(!globalStore.settingsStore.enableMeganav);
                    globalStore.settingsStore.persistSettingsToExtension();
                    globalStore.initialize(authProvider.getAccount());
                  }}
                  id="enable-meganav"
                />
              </Box>
              <Box>
                <FormLabel htmlFor="show-debug">Show Debug Panel</FormLabel>
                <Switch
                  isChecked={globalStore.settingsStore.showDebugPanel}
                  onChange={() => {
                    globalStore.settingsStore.setShowDebugPanel(!globalStore.settingsStore.showDebugPanel);
                  }}
                  id="show-debug"
                />
              </Box>
            </Stack>
            {globalStore.settingsStore.showDebugPanel && <DebugPanel />}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export const SparkDrawer = observer(SparkDrawerInner);

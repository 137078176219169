import React from "react";
import PropTypes from "prop-types";
import { authProvider } from "../../AuthProvider";
import { Columns, Section, Progress, Table, Heading, Pagination, Loader, Container } from "react-bulma-components/full";
import { observer } from "mobx-react";
import { observable, action, computed, toJS, extendObservable } from "mobx";
import { AzureRMService, SparkProvisioningAppSettings } from "../../services/AzureRMService";
import { SharePointService } from "../../services/SharePointService";
import AzureStorageService from "../../services/AzureStorageService";
import { SparkMenuPage } from "../../components/SparkMenu";
import { APPS_BLOB_STORAGE_LIST } from "../../util/Constants";
import { globalStore } from "../../stores/GlobalStore";
import { create, persist } from "mobx-persist";
import moment from "moment";
import { Box, Badge, Image, Link, List, ListItem, ListIcon, Spinner, CircularProgress, Button } from "@chakra-ui/core";
import { property } from "@pnp/sp-clientsvc";
import { AppInstallBox } from "../../components/AppInstallBox/AppInstallBox";
import { SharePointApp } from "../../services/types";
import { task } from "mobx-task";

var convert = require("xml-js");

@observer
class AppsPage extends React.Component {
  sharePointService = new SharePointService();
  storageService = new AzureStorageService();

  _spRootUrl: string = "";

  @observable isLoading: boolean = false;
  @action setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @observable appCatalogUrl: string | undefined;
  @action setAppCatalogUrl(url) {
    this.appCatalogUrl = url;
  }

  @observable appsOnYourTenant: Array<{ Name: { _text: string }; Metadata?: { Description: { _text: string } } }> = [];

  @observable apps: Array<{
    Name: { _text: string };
    Properties: { "Last-Modified": { _text: string } };
    Metadata?: { Description: { _text: string }; FriendlyName: { _text: string } };
    isInstalling?: boolean;
  }> = [];

  @observable existingApps: Array<SharePointApp> = [];
  @action setExistingApps(existingApps) {
    this.existingApps = existingApps;
  }

  @observable uploadInProgress: boolean = false;
  @action setUploadInProgress(uploadInProgress) {
    this.uploadInProgress = uploadInProgress;
  }

  @action loadApps() {
    fetch(APPS_BLOB_STORAGE_LIST, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
      .then(response => response.text())
      .then(apps => {
        var result1 = convert.xml2json(apps, { compact: true, spaces: 4 });

        this.apps = JSON.parse(result1).EnumerationResults.Blobs.Blob;
        this.apps.forEach(app => {
          extendObservable(app, { isInstalling: false });
        });
      });
  }

  @action setAppInstallingByName(name: string, isInstalling: boolean) {
    let app = this.apps.find(app => app.Name._text === name);
    (app as any).isInstalling = isInstalling;
  }

  @action async deployApp(appName) {
    if (!this.appCatalogUrl) {
      throw new Error("no app catalog url");
    }
    this.setUploadInProgress(true);
    this.setAppInstallingByName(appName, true);

    const blob = await this.storageService.downloadBlob(appName);
    const context = await this.sharePointService.getContextInfo(this._spRootUrl, this.appCatalogUrl);

    const resp = await this.sharePointService.uploadAppToStore(
      appName,
      this._spRootUrl,
      this.appCatalogUrl,
      context.FormDigestValue,
      blob
    );

    await this.sharePointService.deployApp(
      resp.UniqueId,
      this._spRootUrl,
      this.appCatalogUrl,
      context.FormDigestValue,
      false
    );
    this.setUploadInProgress(false);
    this.setAppInstallingByName(appName, false);
  }

  async componentDidMount() {
    this.setIsLoading(true);
    const sharePointInfo = await this.sharePointService.getSharePointRootUrl();
    const rootUrl = sharePointInfo.webUrl;
    const id = sharePointInfo.id;
    const context2 = await this.sharePointService.getContextInfo(rootUrl, rootUrl);

    let appCatalogUrl = await this.sharePointService.getSharePointAppCatalogUrl(rootUrl, context2.FormDigestValue);
    //let appCatalogUrl = await this.sharePointService.getSharePointAppCatalogUrlWithGraph(id);

    // await this.sharePointService.getContextInfo(rootUrl, rootUrl);
    this._spRootUrl = rootUrl;
    this.setAppCatalogUrl(appCatalogUrl);
    const context = await this.sharePointService.getContextInfo(rootUrl, appCatalogUrl);
    this.loadApps();
    const existingApps = await this.sharePointService.getAppsOnTenant(rootUrl, appCatalogUrl, context.FormDigestValue);
    this.setExistingApps(existingApps);

    this.setIsLoading(false);
    console.log(toJS(globalStore.fetchStore.fetchRequests));
    window["__globalstore"] = globalStore;
  }

  render() {
    return (
      <>
        {!this.isLoading ? (
          <div>
            <Link href={this.appCatalogUrl + "/AppCatalog"} target={"_blank"}>
              Open Tenant App Catalog
            </Link>
            <List spacing={3}>
              {this.apps.map(app => {
                return (
                  <ListItem>
                    {/* <ListIcon icon="check-circle" color="green.500" /> */}
                    {app.Name._text}
                    <Button
                      isDisabled={app.isInstalling || this.uploadInProgress}
                      backgroundColor="themePrimary"
                      color={"white"}
                      onClick={() => this.deployApp(app.Name._text)}>
                      Install
                    </Button>
                    {app.isInstalling && <CircularProgress isIndeterminate color="themePrimary"></CircularProgress>}
                    <p>
                      Last Modified: {moment(app.Properties["Last-Modified"]?._text).format("MM/DD/YYYY h:mm:ss a")}
                    </p>
                  </ListItem>
                );
              })}
            </List>
          </div>
        ) : (
          <Loader
            style={{
              width: 300,
              height: 300,
              border: "4px solid blue",
              borderTopColor: "transparent",
              borderRightColor: "transparent"
            }}
          />
        )}
      </>
    );
  }
}

export default AppsPage;

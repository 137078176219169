import React from "react";
import {
  Box,
  Heading,
  Stack,
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  InputRightElement,
  Button,
  List,
  ListItem,
  Spinner,
  Flex,
  PseudoBox,
  Text
} from "@chakra-ui/core";
import { observable, action, toJS, computed } from "mobx";
import { AzureRMService } from "../../services/AzureRMService";
import { observer } from "mobx-react";
import { DelveImage } from "../UserPhotoEditor/DelveImage";

export interface UserSearchBoxProps {
  onSelectUser: (user: microsoftgraph.User) => void;
}

interface IUser extends microsoftgraph.User {
  photoUrl?: string;
}

@observer
export class UserSearchBox extends React.Component<UserSearchBoxProps> {
  azureService = new AzureRMService();

  @observable isSearching: boolean = false;
  @action setIsSearching(isSearching: boolean) {
    this.isSearching = isSearching;
  }
  @observable searchText: string = "";
  @action setSearchText(text: string) {
    this.searchText = text;
  }
  @observable users: Array<Required<IUser>> = [];
  @action setUsers(users) {
    this.users = users;
  }

  @computed get displayedUsers(): Array<Required<IUser>> {
    return this.users;
  }

  @action async executeSearch() {
    this.setIsSearching(true);
    const users = await this.azureService.getUsersByDisplayNameStartsWith(this.searchText);
    console.log("USERS", users);
    this.setUsers(users);
    this.setIsSearching(false);
    await this.getPhotosForDisplayedUsers();
  }

  @action async getPhotosForDisplayedUsers() {
    this.displayedUsers.map(async user => {
      console.log("USER", user);
      const photo = await this.azureService.getUserPhotoById(user.id, 96, 96);
      user.photoUrl = photo;
      console.log("PHOTO", photo);
    });
  }

  render() {
    return (
      <Box padding={5}>
        <Stack spacing={4}>
          <Flex>
            <PseudoBox
              as="input"
              placeholder="Search for a user"
              flex="1"
              py={2}
              px={4}
              rounded="md"
              bg="themePrimary"
              borderWidth="1px"
              _hover={{ borderColor: "themeDarker", bg: "themePrimary" }}
              _focus={{
                outline: "none",
                bg: "themeSecondary",
                boxShadow: "outline",
                borderColor: "themeDarker"
              }}
              onChange={(e: any) => {
                this.setSearchText(e.target.value);
              }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  this.executeSearch();
                }
              }}
            />
            <PseudoBox
              as="button"
              bg="teal.500"
              py={2}
              px={4}
              ml={3}
              rounded="md"
              fontWeight="semibold"
              color="white"
              _hover={{ bg: "teal.600" }}
              _focus={{ boxShadow: "outline" }}
              onClick={() => {
                this.executeSearch();
              }}>
              Search Users
            </PseudoBox>
          </Flex>
        </Stack>
        {this.isSearching ? (
          <Spinner />
        ) : (
          <List spacing={3}>
            {this.displayedUsers.map(user => {
              return (
                <ListItem>
                  <Stack isInline={true} spacing={5}>
                    <Box d={"flex"} alignItems={"center"} padding={5}>
                      <DelveImage src={user.photoUrl} height={"96"} width={"96"} />
                      <Text>{user.displayName}</Text>
                      <Button onClick={() => this.props.onSelectUser(toJS(user))}>Select</Button>
                    </Box>
                  </Stack>
                </ListItem>
              );
            })}
          </List>
        )}
      </Box>
    );
  }
}

import React from "react";
import { withRouter, Switch, Route } from "../../util/router";
import { Stack, Text, Grid, Box, Button } from "@chakra-ui/core";
import { MicrosoftTeamsService } from "../../services/MicrosoftTeamsService";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

@observer
class MyConversationsIndexPage extends React.Component<{ match; history }> {
  teamsService = new MicrosoftTeamsService();

  @observable chats: Array<any> = [];
  @action setChats(chats) {
    this.chats = chats;
  }
  async componentDidMount() {
    const myChats = await this.teamsService.getMyChats();
    this.chats = myChats;
  }
  render() {
    return (
      <>
        <div> This is Microsoft Graph beta, this endpoint loads quite slowly</div>
        {this.chats.map((chat) => {
          return (
            <div>
              <a
                onClick={() => {
                  this.props.history.push(`/teams/myconversations/${chat.id}`);
                }}>
                {chat.id}
              </a>
            </div>
          );
        })}
      </>
    );
  }
}

@observer
class MyConversationsDetailPage extends React.Component<{ match; history }> {
  teamsService = new MicrosoftTeamsService();

  @observable chatId: string = "";
  @action setChatId(id: string) {
    this.chatId = id;
  }

  @observable messages: Array<any> = [];
  @action setMessages(messages) {
    this.messages = messages;
  }

  @action async loadMessages() {
    const details = await this.teamsService.getMessagesInChat(this.chatId);
    console.log("DETAILS", details);
    this.setMessages(details);
  }

  async componentDidMount() {
    const convId = this.props.match.params.id;
    this.setChatId(convId);
    await this.loadMessages();
  }

  async sendChatMessage() {
    if (!this.chatId) return;
    await this.teamsService.sendMessageInChat(this.chatId, { content: "Hello World from JS" });
    setTimeout(async () => {
      await this.loadMessages();
    }, 1000);
  }

  render() {
    return (
      <>
        {this.messages.map((message) => {
          return <div>{JSON.stringify(message)}</div>;
        })}
        <Button onClick={() => this.sendChatMessage()}>Send Message</Button>
        <a
          target={"_blank"}
          href={`https://teams.microsoft.com/_?tenantId=d48fcabb-ff44-4836-a560-b2634a7f6cb1#/conversations/${this.chatId}?ctx=chat`}>
          Open in Teams
        </a>
      </>
    );
  }
}

@observer
class MyConversationsPage extends React.Component<{ match }> {
  render() {
    return (
      <Switch>
        <Route exact path={`${this.props.match.path}/`} component={MyConversationsIndexPage} />
        <Route exact path={`${this.props.match.path}/:id`} component={MyConversationsDetailPage} />
      </Switch>
    );
  }
}

export const MyConversations = withRouter(MyConversationsPage);

import React, { useEffect } from "react";
import DashboardSection from "./../../components/DashboardSection";
import { useRouter, withRouter } from "./../../util/router";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";

import "./styles.scss";
import { useAuth } from "../../util/auth";
import { StrapiService } from "../../services/StrapiService";
import { AzureRMService } from "../../services/AzureRMService";
import {
  SHAREPOINT_SERVICE_ADMINISTRATOR_ROLETEMPLATEID,
  COMPANY_ADMINISTRATOR_ROLETEMPLATEID,
  SETTINGS_EXTENSION_NAME
} from "../../util/Constants";
import { observer } from "mobx-react";
import { observable, action, computed } from "mobx";
import { authProvider } from "../../AuthProvider";
import { SettingsPersistenceService } from "../../services/SettingsPersistanceService";
import { Spinner, Stack, Box, Flex, Icon, Heading, Link, Text, Button } from "@chakra-ui/core";
import { GridLayout } from "../../components/GridLayout/GridLayout";
import { SparkMenuPage, SparkPage } from "../../components/SparkMenu";

@observer
class AdminConsentPage extends React.Component<{ history }> {
  azureService = new AzureRMService();
  settingsService = new SettingsPersistenceService();

  @observable message: string = "";
  @action setMessage(message: string) {
    this.message = message;
  }

  @observable inProgress: boolean = false;
  @action setInProgress(inProgress: boolean) {
    this.inProgress = inProgress;
  }

  @observable needsSparkAdministratorSetup: boolean = false;
  @action setNeedsSparkAdministratorSetup(needsSetup: boolean) {
    this.needsSparkAdministratorSetup = needsSetup;
  }

  @observable needsSparkAzureSubscriptionSetup: boolean = false;
  @action setNeedsSparkAzureSubscriptionSetup(needsSetup: boolean) {
    this.needsSparkAzureSubscriptionSetup = needsSetup;
  }

  @observable needsSparkProvisioningAppSetup: boolean = false;
  @action setNeedsSparkProvisioningAppSetup(needsSetup: boolean) {
    this.needsSparkProvisioningAppSetup = needsSetup;
  }

  @computed get allSetupStepsComplete() {
    return (
      !this.needsSparkAdministratorSetup &&
      !this.needsSparkAzureSubscriptionSetup &&
      !this.needsSparkProvisioningAppSetup
    );
  }

  async componentDidMount() {
    const tenantId = sessionStorage.getItem("tenantId");
    sessionStorage.removeItem("tenantId");
    if (tenantId) {
      this.setInProgress(true);
      try {
        const account = authProvider.getAccount();
        await this.azureService.addGraphExtensionToOrganization(SETTINGS_EXTENSION_NAME, tenantId, {});
        await this.settingsService.persistSettingsToOrganizationExtension(tenantId, {
          adminConsentUser: account.userName,
          adminConsentTime: new Date().toISOString()
        });
      } catch (err) {
        this.setMessage(err.toString());
        this.setInProgress(false);
        return;
      }

      try {
        // const email: MicrosoftGraph.Message = {
        //   toRecipients: [{ emailAddress: { name: "Tom White", address: "twhite@rightpoint.onmicrosoft.com" } }],
        //   subject: "New tenant",
        //   body: { content: `New tenant signup: ${tenantId}`, contentType: "text" }
        // };

        // let invite;
        // try {
        //   invite = await this.azureService.inviteExternalUser(
        //     `twhite@rightpoint.onmicrosoft.com`,
        //     process.env["REACT_APP_PUBLIC_URL"] as string
        //   );
        // } catch (err) {
        //   this.setMessage(err.toString());
        //   this.setInProgress(false);
        //   return;
        // }

        // const directoryRoles = await this.azureService.getDirectoryRoles();
        // const sharePointAdminRole = directoryRoles.find(role => {
        //   return role.roleTemplateId === SHAREPOINT_SERVICE_ADMINISTRATOR_ROLETEMPLATEID;
        // });

        // if (sharePointAdminRole) {
        //   await this.azureService.setUserInDirectoryRole(sharePointAdminRole.id, invite.invitedUser.id);
        // }

        // const globalAdminRole = directoryRoles.find(
        //   role => role.roleTemplateId === COMPANY_ADMINISTRATOR_ROLETEMPLATEID
        // );

        // if (globalAdminRole) {
        //   await this.azureService.setUserInDirectoryRole(globalAdminRole.id, invite.invitedUser.id);
        // }

        // // TODO: these emails get blocked by the receiver?
        // //     await this.azureService.sendEmail(email);

        // this.setMessage(`Successfully registered tenant.`);
        this.setInProgress(false);
      } catch (err) {
        this.setMessage(err.toString());
      }
    }
    const existingSettings = await this.settingsService.getOrganizationSettingsData();
    if (!existingSettings.sparkAdministratorEmail) {
      this.setNeedsSparkAdministratorSetup(true);
    }

    if (!existingSettings.defaultAzureSubscriptionId) {
      this.setNeedsSparkAzureSubscriptionSetup(true);
    }

    if (!existingSettings.sparkAppObjectId || !existingSettings.sparkAdminPermissionAssigned) {
      this.setNeedsSparkProvisioningAppSetup(true);
    }
  }
  render() {
    return (
      <div>
        {this.inProgress && <Spinner />}
        <Stack>
          <Box backgroundColor="white" shadow="sm" borderRadius="lg" pl={3} pr={3} pt={5} pb={5}>
            <Flex display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" pb={2}>
              <Icon name="chevron-left" />
              <Heading size="md" as="h2" lineHeight="shorter" fontWeight="bold" fontFamily="heading">
                Next Steps
              </Heading>
            </Flex>
            <Stack ml={4} spacing={2} shouldWrapChildren mt={4} mr={4}>
              <Text>Thank your for authorizing the Spark Intranet app.</Text>
              {this.allSetupStepsComplete && <Text>All steps complete.</Text>}
              <Stack>
                {this.needsSparkAdministratorSetup && (
                  <Link href={"/admin/sparkadministrators"}>Add Spark Administrator User</Link>
                )}
                {this.needsSparkAzureSubscriptionSetup && (
                  <Link href={"/admin/azuresubscription"}>Setup Spark Azure Subscription</Link>
                )}
                {this.needsSparkProvisioningAppSetup && (
                  <Link href={"/admin/sparkazureadapp"}>Create Spark Provisioning Azure AD app</Link>
                )}
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </div>
    );
  }
}

const ThePage = withRouter(AdminConsentPage);

const Page = () => {
  return (
    <SparkPage>
      <ThePage />
    </SparkPage>
  );
};
export default withRouter(Page);

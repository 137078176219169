import React from "react";
import ReactDOM from "react-dom";
import "./styles.scss";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { registerFetchInterceptor } from "./util/FetchInterceptor";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { APPINSIGHTS_KEY } from "./util/Constants";

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: APPINSIGHTS_KEY
    /* ...Other Configuration Options... */
  }
});
appInsights.loadAppInsights();
appInsights.trackPageView();

declare var module: any;
registerFetchInterceptor();
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    // render with new App here
    ReactDOM.render(<NextApp />, document.getElementById("root"));
  });
}

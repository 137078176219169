import React from "react";
import { withRouter } from "../../util/router";
import { Button, Heading, Loader, Modal, Table, Tag, Field, Control, Checkbox } from "react-bulma-components/full";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { AzureRMService } from "../../services/AzureRMService";
import { SparkMenuPage } from "../../components/SparkMenu";
import NewResourceGroupModal from "../../components/NewResourceGroupModal";
import { SharePointService } from "../../services/SharePointService";
import { globalStore } from "../../stores/GlobalStore";
import { List, ListItem, ListIcon } from "@chakra-ui/core";

@observer
class ResourceGroupsPage extends React.Component<{ match; history }> {
  azureService = new AzureRMService();
  sharePointService = new SharePointService();

  @observable selectedSubscription: string = "";
  @action async setSelectedSubscription(subscriptionId) {
    this.selectedSubscription = subscriptionId;
  }

  @observable resourceGroups: Array<{
    name: string;
    id: string;
    tags: Object;
    properties: { provisioningState: string };
  }> = [];

  @action setResourceGroups(rgs) {
    this.resourceGroups = rgs;
  }

  @observable resourceGroupPopupCreateOpen: boolean = false;
  @action setResourceGroupCreatePopupOpen(isOpen: boolean) {
    this.resourceGroupPopupCreateOpen = isOpen;
  }

  async initPage() {
    this.setSelectedSubscription(this.props.match.params.id);

    const rgs = globalStore.settingsStore.showOnlySparkResources
      ? await this.azureService.getResourceGroupsByTag(this.selectedSubscription, "Spark", "Spark Provisioning")
      : await this.azureService.getResourceGroups(this.selectedSubscription);
    console.log("RES", rgs);
    this.setResourceGroups(rgs);
  }
  async componentDidMount() {
    console.log("RESOURCE GROUPS PAGE", this.props.match);
    if (this.props.match.params.id) {
      await this.initPage();
    }
  }
  render() {
    return (
      <React.Fragment>
        <Heading size={6} renderAs="h1">{`Resource Groups in ${this.props.match.params.id}`}</Heading>
        <label className="checkbox">
          <input
            type="checkbox"
            checked={globalStore.settingsStore.showOnlySparkResources ? true : false}
            onChange={event => {
              const target = event.target;
              const value = target.type === "checkbox" ? target.checked : target.value;
              const name = target.name;

              globalStore.settingsStore.setShowOnlySparkResources(value as boolean);
              this.initPage();
            }}
          />
          Show only Spark Resource Groups (those tagged in Azure with "Spark")
        </label>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {this.resourceGroups.map(rg => {
              return (
                <tr>
                  <td>
                    {rg.name} - {rg.properties.provisioningState}
                  </td>
                  {/* <td>
                      {" "}
                      <Tag.Group>
                        <Tag color="primary">{rg.tags["Spark"]}</Tag>
                      </Tag.Group>
                    </td> */}
                  <td>
                    <Button
                      disabled={rg.properties.provisioningState === "Deleting"}
                      color="success"
                      onClick={() => {
                        this.props.history.push(this.props.match.url + "/" + rg.name);
                      }}>
                      Select
                    </Button>
                  </td>
                  <td>
                    <Button
                      disabled={rg.properties.provisioningState === "Deleting"}
                      onClick={() => {
                        this.azureService.deleteResourceGroup(this.props.match.params.id, rg.name);
                        setTimeout(() => {
                          this.initPage();
                        }, 2000);
                      }}
                      color="danger">
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        <Button
          onClick={() => {
            this.props.match.params.id && this.setResourceGroupCreatePopupOpen(true);
          }}
          color="link">
          Create New Resource Group
        </Button>

        <NewResourceGroupModal
          modal={{ closeOnEsc: true }}
          isOpen={this.resourceGroupPopupCreateOpen}
          onClose={() => this.setResourceGroupCreatePopupOpen(false)}
          onSubmit={async result => {
            if (!this.selectedSubscription) return;

            const rootUrl = (await this.sharePointService.getSharePointRootUrl()).webUrl;

            await this.azureService.createResourceGroup(this.selectedSubscription, result.newResourceGroupName, {
              Spark: "Spark Provisioning"
            });

            await this.sharePointService.addLogEntryToHiddenList(
              rootUrl,
              "ResourceGroupCreated",
              JSON.stringify({ resourceGroupName: result.newResourceGroupName })
            );

            setTimeout(async () => {
              await this.initPage();
            }, 1000);
          }}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(ResourceGroupsPage);

import { getGUID } from "@pnp/common";

import { authProvider } from "../AuthProvider";
import { globalStore } from "../stores/GlobalStore";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import { Client, PageCollection, PageIteratorCallback, PageIterator } from "@microsoft/microsoft-graph-client";

export class MicrosoftTeamsService {

  _client: Client;

  constructor() {
    this._client = Client.initWithMiddleware({
      debugLogging: true,
      authProvider: {
        getAccessToken: async authOptions => {
          const token = await authProvider.getAccessToken({ scopes: [`https://graph.microsoft.com/User.Read.All`, `https://graph.microsoft.com/Group.Read.All`], authority: globalStore.userCurrentAADAuthority });
          return token.accessToken;
        }
      }
    })
  }

  async getMyJoinedTeamsPaged(): Promise<MicrosoftGraph.Team[]> {
    try {
      let results: Array<any> = [];
      let response: PageCollection = await this._client.api("/me/joinedTeams").get();
      let size = 1000;
      let count = 0;
      let callback: PageIteratorCallback = (data) => {
        console.log(data);
        count++;
        results.push(data);
        if (count === size) {
          count = 0;
          return false;
        }
        return true;
      };
      let pageIterator = new PageIterator(this._client, response, callback);
      // This stops iterating over for 1000 entities.
      pageIterator.iterate();

      // Resuming will do start from where it left off and iterate for next 1000 entities.
      // Check and resume is likely to be called in any user interaction requiring to load more data.
      if (!pageIterator.isComplete()) {
        pageIterator.resume();
      }

      return results;
    } catch (e) {
      throw e;
    }
  }
  public async getMyJoinedTeams(authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/User.Read.All`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/v1.0/me/joinedTeams?$select=webUrl,displayName,id`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken
      }
    });
    if (resp.status === 403) {
      throw new Error("403: Forbidden");
    }
    const respJson = (await resp.json()).value;
    return respJson;
  }

  public async getAllTeams(authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Group.Read.All`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/beta/groups?$filter=resourceProvisioningOptions/Any(x:x eq 'Team')`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken
      }
    });
    if (resp.status === 403) {
      throw new Error("403: Forbidden");
    }
    const respJson = (await resp.json()).value;
    return respJson;
  }

  public async getAllTeamsPaged(callbackFn: (team: MicrosoftGraph.Team) => void) {
    try {
      let results: Array<any> = [];
      let response: PageCollection = await this._client.api("https://graph.microsoft.com/beta/groups?$filter=resourceProvisioningOptions/Any(x:x eq 'Team')").get();
      let size = 1000;
      let count = 0;
      let callback: PageIteratorCallback = (data) => {
        //    console.log(data);
        count++;
        results.push(data);
        //  callbackFn(data)
        if (count === size) {
          count = 0;
          return false;
        }
        return true;
      };
      let pageIterator = new PageIterator(this._client, response, callback);
      // This stops iterating over for 1000 entities.
      pageIterator.iterate();

      // Resuming will do start from where it left off and iterate for next 1000 entities.
      // Check and resume is likely to be called in any user interaction requiring to load more data.
      if (!pageIterator.isComplete()) {
        debugger;
        console.log("PAGE ITERAQTOR RESULTS", results);
        pageIterator.resume();
      }

      if (pageIterator.isComplete()) {
        debugger;
        return results;
      }

      //   return results;
    } catch (e) {
      throw e;
    }
  }

  public async getAllTeamsPaged2(callback: PageIteratorCallback) {
    try {
      // Makes request to fetch mails list. Which is expected to have multiple pages of data.
      let response: PageCollection = await this._client.api("https://graph.microsoft.com/beta/groups?$filter=resourceProvisioningOptions/Any(x:x eq 'Team')&$select=id,displayName,description").get();

      // Creating a new page iterator instance with client a graph client instance, page collection response from request and callback
      let pageIterator = new PageIterator(this._client, response, callback);
      // This iterates the collection until the nextLink is drained out.
      //  await pageIterator.iterate();

      return pageIterator;
    } catch (e) {
      throw e;
    }
  }

  public async getTeamsStartWith(text, callback: PageIteratorCallback) {
    try {
      // Makes request to fetch mails list. Which is expected to have multiple pages of data.
      let response: PageCollection = await this._client.api(`https://graph.microsoft.com/beta/groups?$filter=resourceProvisioningOptions/Any(x:x eq 'Team') and startswith(displayName,'${text}')&$select=id,displayName,description`).get();

      // Creating a new page iterator instance with client a graph client instance, page collection response from request and callback
      let pageIterator = new PageIterator(this._client, response, callback);
      // This iterates the collection until the nextLink is drained out.
      pageIterator.iterate();

      return pageIterator;
    } catch (e) {
      throw e;
    }
  }

  public async getChannelsInTeam(teamId: string, authority?: string): Promise<MicrosoftGraph.Channel[]> {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Group.Read.All`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/v1.0/teams/${teamId}/channels`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken
      }
    });
    if (resp.status !== 200) {
      throw new Error("Error getting team channels");
    }

    const respJson = (await resp.json()).value;
    return respJson;
  }

  public async getChannelMessages(teamId: string, channelId: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Group.Read.All`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/beta/teams/${teamId}/channels/${channelId}/messages`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken
      }
    });

    return (await resp.json()).value;
  }


  public async getMessageById(teamId: string, channelId: string, messageId: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;

    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Group.Read.All`],
      authority: authority
    });

    const resp = await fetch(`https://graph.microsoft.com/beta/teams/${teamId}/channels/${channelId}/messages/${messageId}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken
      }
    });

    return await resp.json();
  }

  public async deleteTeam(groupId: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;
    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Group.ReadWrite.All`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/beta/groups/${groupId}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token.accessToken
      }
    });

    if (resp.status !== 204) {
      throw new Error("Error deleting team, it may already have been deleted.");
    }

  }

  public async getMyChats(authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;
    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Chat.ReadWrite`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/beta/me/chats?$select=id,topic`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken
      }
    });

    const response = await resp.json();
    return response.value;
  }

  public async getMessagesInChat(id: string, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;
    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Chat.ReadWrite`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/beta/me/chats/${id}/messages`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken
      }
    });

    const response = await resp.json();
    return response.value;
  }


  public async sendMessageInChat(id: string, bodyObject: { contentType?: string, content: string }, authority?: string) {
    authority = authority || globalStore.userCurrentAADAuthority || authProvider.authority;
    const token = await authProvider.getAccessToken({
      scopes: [`https://graph.microsoft.com/Chat.ReadWrite`],
      authority: authority
    });
    const resp = await fetch(`https://graph.microsoft.com/beta/me/chats/${id}/messages`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ body: bodyObject })
    });

    const response = await resp.json();
    return response.value;
  }

}

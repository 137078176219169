import React from "react";
import { Stack, List, ListItem, Heading } from "@chakra-ui/core";
import { globalStore } from "../../stores/GlobalStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";

@observer
export class DebugPanel extends React.Component {
  render() {
    return (
      <div>
        <Stack>
          <Heading size={"sm"}>Debug</Heading>
          <List spacing={3}>
            <p>User: {globalStore.userStore.userDisplayName}</p>
            <p>Total Tenants: {globalStore.userAADDirectories.length || ""}</p>
            <p>Authentication Status: {globalStore.userLoginState || ""}</p>{" "}
            <p>Total Tenants: {globalStore.userAADDirectories.length || ""}</p>
            <ListItem>Domain: {globalStore.userCurrentAADDomain}</ListItem>
            <p>Company Admin: {JSON.stringify(toJS(globalStore.isUserCompanyAdministrator))}</p>
            <p>SharePoint Admin: {JSON.stringify(toJS(globalStore.isUserSharePointAdministrator))}</p>
            <p>Can Access App Catalog: {JSON.stringify(toJS(globalStore.userCanAccessAppCatalog))}</p>
            <p>
              License Details:{" "}
              {JSON.stringify(
                toJS(globalStore.licenceDetails && globalStore.licenceDetails[0] && globalStore.licenceDetails[0].skuId)
              )}
            </p>
            <p>
              Teams Context
              {JSON.stringify(globalStore.microsoftTeamsStore.teamsContext)}
            </p>
          </List>
        </Stack>
      </div>
    );
  }
}

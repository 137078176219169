import React from "react";
import { Button } from "react-bulma-components/full";

import { SparkMenuPage } from "../../components/SparkMenu";
import { AzureRMService } from "../../services/AzureRMService";
import AzureStorageService from "../../services/AzureStorageService";
import { TEAMS_APP_MANIFEST_URL, TEAMS_APP_EXTERNAL_ID } from "../../util/Constants";
import { withRouter } from "../../util/router";
import { observable, action } from "mobx";

class TeamsAppInstallPage extends React.Component {
  azureService = new AzureRMService();
  azureStorageService = new AzureStorageService();

  @observable teamsAppInstalledInTenantAlready: boolean | undefined;
  @action setTeamsAppAlreadyInstalled(alreadyInstalled: boolean) {
    this.teamsAppInstalledInTenantAlready = alreadyInstalled;
  }
  async deployAppManifest() {
    const teamsAppManifest = await AzureStorageService.getFromAzureStorage(
      TEAMS_APP_MANIFEST_URL,
      "SparkTeamsAppManifest.zip"
    );

    await this.azureService.publishAppToTeamsAppCatalog(teamsAppManifest);
  }

  async updateTeamsAppManifest() {
    const teamsApps = await this.azureService.listAppsInTeamsAppCatalog();

    // const sparkTeamsApp = teamsApps.filter(app => app.displayName === "Spark")[1];

    const existingTeamsApp = teamsApps.find(app => app.externalId === TEAMS_APP_EXTERNAL_ID);

    if (!existingTeamsApp) {
      alert("app not installed");
      return;
    }
    const teamsAppManifest = await AzureStorageService.getFromAzureStorage(
      TEAMS_APP_MANIFEST_URL,
      "SparkTeamsAppManifest.zip"
    );
    await this.azureService.updateAppInTeamsAppCatalog(existingTeamsApp.id, teamsAppManifest);
  }

  async createTeamsAzureAdApp() {
    await this.azureService.createTeamsAppAzureADApp();
  }

  async componentDidMount() {
    const teamsApps = await this.azureService.listAppsInTeamsAppCatalog();
    console.log("TEAMS APPS", teamsApps);
    const existingTeamsApp = teamsApps.find(app => app.externalId === TEAMS_APP_EXTERNAL_ID);
    if (existingTeamsApp) {
      this.setTeamsAppAlreadyInstalled(true);
    }

    const teamsAppManifest = await AzureStorageService.getFromAzureStorage(
      TEAMS_APP_MANIFEST_URL,
      "SparkTeamsAppManifest.zip"
    );
    console.log("TEAMS APP MANIFEST", teamsAppManifest);
  }

  render() {
    return (
      <>
        <Button
          onClick={() => {
            this.deployAppManifest();
          }}>
          Deploy App Manifest
        </Button>

        <Button
          onClick={() => {
            this.updateTeamsAppManifest();
          }}>
          Update App Manifest
        </Button>

        <Button
          onClick={() => {
            this.createTeamsAzureAdApp();
          }}>
          Create Azure AD App
        </Button>
      </>
    );
  }
}

export default withRouter(TeamsAppInstallPage);

import React from "react";
import { withRouter } from "../../util/router";
import { SparkMenuPage } from "../../components/SparkMenu";
import { AzureRMService } from "../../services/AzureRMService";
import { Button, Heading, Loader, Modal, Table, Tag } from "react-bulma-components/full";
import AzureStorageService from "../../services/AzureStorageService";
import { TEAMS_APP_MANIFEST_URL } from "../../util/Constants";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import { MsalAuthProvider, LoginType } from "react-aad-msal";

@observer
class TenantsPage extends React.Component<{ history }> {
  azureService = new AzureRMService();

  @observable tenants: Array<Object> = [];
  @action setTenants(tenants) {
    this.tenants = tenants;
  }

  async componentDidMount() {
    const tenants = await this.azureService.getTenants();
    this.setTenants(tenants.value);  
  }

  render() {
    return (
      <SparkMenuPage>
        <h1>Tenants</h1>
        <pre>{JSON.stringify(this.tenants, null, 2)}</pre>
        {this.tenants.map((t: any) => {
          return <Button>{`Switch to ${t.id}`}</Button>;
        })}
        <Button
          onClick={() => {
            this.props.history.push(
              "https://login.microsoftonline.com/common/adminconsent?client_id=67285b34-98e5-4436-b558-c0aa06b42b63&redirect_uri=http://localhost:3000"
            );
          }}>
          Change Tenant
        </Button>
      </SparkMenuPage>
    );
  }
}

export default withRouter(TenantsPage);

import { action, observable } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Button, Columns, Section, Progress, Table, Heading, Pagination, Loader } from "react-bulma-components/full";

import { SparkMenuPage } from "../../components/SparkMenu";
import { AzureRMService } from "../../services/AzureRMService";
import { SharePointService } from "../../services/SharePointService";
import { withRouter, Switch, Route } from "../../util/router";
import AzureADAppPage from "./AzureADAppPage";
import AzureADAppsPage from "./AzureADAppsPage";

@observer
class AzureADAppsPageHome extends React.Component<{ match; history }> {
  render() {
    return (
      <>
        <Switch>
          <Route exact path={`${this.props.match.path}/`} component={AzureADAppsPage} />
          <Route exact path={`${this.props.match.path}/:id`} component={AzureADAppPage} />
        </Switch>
      </>
    );
  }
}

export default withRouter(AzureADAppsPageHome);

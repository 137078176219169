import React from "react";
import { Button, Input } from "@chakra-ui/core";
import { AzureRMService } from "../../services/AzureRMService";
import { SettingsPersistenceService } from "../../services/SettingsPersistanceService";

class ExtensionPage extends React.Component {
  azureService = new AzureRMService();
  settingsService = new SettingsPersistenceService();

  componentDidMount() {}
  render() {
    return (
      <div>
        Extensions
        <Button
          onClick={() => {
            this.azureService.addGraphExtensionToOrganization(
              "com.spark.settings",
              "2c70ddde-a0db-42b5-98ef-0ee5d08465b1",
              { color: "blue" }
            );
          }}>
          Add Extension
        </Button>
        <Button
          onClick={async () => {
            const orgId = await this.azureService.getOrganizationInfo();
            debugger;
            if (orgId[0].id) {
              this.settingsService.persistSettingsToOrganizationExtension(orgId[0].id, {});
            }
          }}>
          Update Extension
        </Button>
        <br />
      </div>
    );
  }
}

export default ExtensionPage;

import { theme, ThemeProvider, CSSReset } from "@chakra-ui/core";
import { observer } from "mobx-react";
import { globalStore } from "../stores/GlobalStore";
import React from "react";
import { defaultTheme, defaultBrand } from "./DefaultTheme";

const ThemeManager = observer(({ children }) => {
  const custom = globalStore.themeStore.selectedTheme;
  const customTheme = {
    ...theme,
    colors: {
      ...theme.colors,
      spTheme: {
        ...defaultBrand
      },
      ...defaultTheme
    }
  };

  if (custom) {
    Object.assign(customTheme, { colors: { ...customTheme.colors, ...custom.themeJson.palette } });
  }
  console.log("THEME", customTheme);
  return (
    <ThemeProvider theme={customTheme}>
      <CSSReset />
      {children}
      {/* <ColorModeProvider></ColorModeProvider> */}
    </ThemeProvider>
  );
});

export { ThemeManager };

import { AzureRMService } from "../../services/AzureRMService";
import { BADGING_USER_IMAGE_LIBRARY } from "../../util/Constants";

import { Client, AuthenticationProvider, BatchRequestStep, BatchRequestContent, BatchResponseContent } from "@microsoft/microsoft-graph-client";
import { authProvider } from "../../AuthProvider";


const azureServiceClient = Client.initWithMiddleware({
    debugLogging: true,
    authProvider: {
        getAccessToken: async authOptions => {
            console.log("AUTHOPTIONS", authOptions);

            const token = await authProvider.getAccessToken({ scopes: [`User.Read`], authority: authProvider.authority });
            return token.accessToken;
        }
    }
})

export class BadgingDataService {

    azureService2: Client;
    azureService = new AzureRMService();

    constructor() {
        this.azureService2 = Client.initWithMiddleware({
            debugLogging: true,
            authProvider: {
                getAccessToken: async authOptions => {

                    const token = await authProvider.getAccessToken({ scopes: [`https://graph.microsoft.com/Files.ReadWrite.All`], authority: authProvider.authority });
                    return token.accessToken;
                }
            }
        })
    }

    public async ensureRootBadgingFolder() {
        await this.azureService.createRootFolderInMyDrive(`${BADGING_USER_IMAGE_LIBRARY}`);

        let folderDetails = {
            name: "Testing Batch",
            folder: {},
        };
        let createFolder = new Request("/me/drive/root/children", {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify(folderDetails),
        });


        let createFolderStep: BatchRequestStep = {
            id: "1",
            request: createFolder,
            dependsOn: [],
        };

        let createFolder2 = new Request(`/me/drive/root/${folderDetails.name}/children`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                name: "Testing Batch 2",
                folder: {},
            }),
        });


        let createFolderStep2: BatchRequestStep = {
            id: "2",
            request: createFolder2,
            dependsOn: ["1"],
        };


        let batchRequestContent = new BatchRequestContent([createFolderStep, createFolderStep2]);

        let content = await batchRequestContent.getContent();

        //Making call to $batch end point with the extracted content
        let response = await this.azureService2.api("/$batch").post(content);

        //Create instance with response from the batch request
        let batchResponseContent = new BatchResponseContent(response);

        //Getting response by id
        console.log(batchResponseContent.getResponseById("1"));

        //Getting all the responses
        console.log(batchResponseContent.getResponses());

    }

    public async getAllBadgesFromUserFolder(userId: string) {

        // const badges = await this.azureService2.api(`/users/${userId}/drive/root:/${BADGING_USER_IMAGE_LIBRARY}/Badges:/children`).get();

        const badges = await this.azureService.getAllFilesFromUserDrivePath(
            userId,
            `${BADGING_USER_IMAGE_LIBRARY}/Badges`
        );

        const parallelRequests = badges.map((badge) => {

            let request = new Request(`/users/${userId}/drive/root:/${BADGING_USER_IMAGE_LIBRARY}/Badges/${badge.name}:?select=id,@microsoft.graph.downloadUrl`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                }
            });

            let step: BatchRequestStep = {
                id: badge.id,
                request: request,
                dependsOn: [],
            };

            return step;

        })
        let batchRequestContent = new BatchRequestContent([...parallelRequests]);
        let content = await batchRequestContent.getContent();

        //Making call to $batch end point with the extracted content
        let response = await this.azureService2.api("/$batch").post(content);

        //Create instance with response from the batch request
        let batchResponseContent = new BatchResponseContent(response);

        //Getting all the responses
        console.log(batchResponseContent.getResponses());
        let iterator = batchResponseContent.getResponsesIterator();
        let data = iterator.next();
        while (!data.done) {
            console.log(data.value[0] + ":" + data.value[1]);
            data = iterator.next();
        }
    }

    public async deleteAllBadgingData() {
        console.log(`Deleting all badging data`);
        await this.azureService.deleteFolderFromMyDrive(BADGING_USER_IMAGE_LIBRARY);
    }

    public async createBadgingDataLists() {

    }
}


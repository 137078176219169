import React from "react";
import { Box } from "@chakra-ui/core";

declare var fabric;

export interface AwardBadgeOnUserProps {
  userImageUrl: string;
  canvasDataJson: Object;
  id: string;
}

class AwardBadgeOnUser extends React.Component<AwardBadgeOnUserProps> {
  _canvas;

  componentDidMount() {
    const canvas = new fabric.Canvas("preview" + this.props.id, {
      width: 96,
      height: 96,
      backgroundColor: "transparent",
      isDrawingMode: false,
      preserveObjectStacking: true
    });
    this._canvas = canvas;
    fabric.Image.fromURL(this.props.userImageUrl, img => {
      img.scaleToHeight(96);
      img.scaleToWidth(96);
      img.name = "profilephoto";
      img.selectable = false;
      img.excludeFromExport = true;
      this._canvas.add(img);
      img.sendToBack();
    });

    this._canvas.loadFromJSON(this.props.canvasDataJson, () => {
      this._canvas.renderAll();
    });
  }
  render() {
    return (
      <Box height={"100px"} width={"100px"}>
        <canvas
          id={("preview" + this.props.id) as string}
          // style={{ border: "1px dashed black" }}
        />
      </Box>
    );
  }
}

export { AwardBadgeOnUser };

import { Box, Button, Stack } from "@chakra-ui/core";
import { observer } from "mobx-react";
import React from "react";

import { CenteredLayout } from "../../components/Layouts/CenteredLayout";
import { AzureRMService } from "../../services/AzureRMService";
import { Route, Switch, withRouter } from "../../util/router";
import AcceptBadgePage from "./AcceptBadge";
import AwardBadge from "./AwardBadge";
import AwardBadge2 from "./AwardBadge2";
import { BadgingDataService } from "./BadgingDataService";
import CreateBadgePage from "./CreateBadgePage";
import EditBadgePage from "./EditBadgePage";
import ViewBadgesPage from "./ViewBadgesPage";
import AwardConversation from "./AwardConversation";

// import { Button, Loader, Table } from "react-bulma-components/full";

@observer
class BadgingPage extends React.Component<{ match; history }> {
  azureService = new AzureRMService();
  badgingDataService = new BadgingDataService();

  render() {
    const BadgingIndexPage = () => {
      return (
        <CenteredLayout>
          <Stack alignItems={"center"}>
            <Box>
              <Button
                variantColor={"red"}
                onClick={() => {
                  this.props.history.push("/badging/award");
                }}>
                Award a Badge
              </Button>
            </Box>
            <Box>
              <Button
                variantColor={"red"}
                onClick={() => {
                  this.props.history.push("/badging/create");
                }}>
                Create a Badge
              </Button>
            </Box>
            <Box>
              <Button
                variantColor={"red"}
                onClick={() => {
                  this.props.history.push("/badging/view");
                }}>
                Edit Badges
              </Button>
            </Box>
          </Stack>
        </CenteredLayout>
      );
    };

    return (
      <>
        <Switch>
          <Route exact path={`${this.props.match.path}/`} component={BadgingIndexPage} />
          <Route exact path={`${this.props.match.path}/award`} component={AwardBadge} />
          <Route exact path={`${this.props.match.path}/awardconversation`} component={AwardConversation} />

          <Route exact path={`${this.props.match.path}/award/:id`} component={AwardBadge2} />

          <Route exact path={`${this.props.match.path}/accept`} component={AcceptBadgePage} />
          <Route exact path={`${this.props.match.path}/create`} component={CreateBadgePage} />
          <Route exact path={`${this.props.match.path}/view`} component={ViewBadgesPage} />

          <Route exact path={`${this.props.match.path}/edit/:id`} component={EditBadgePage} />
        </Switch>
      </>
    );
  }
}

export default withRouter(BadgingPage);

import { AzureRMService } from "../../services/AzureRMService";

import { SharePointService } from "../../services/SharePointService";

import { SettingsPersistenceService } from "../../services/SettingsPersistanceService";

import Poller from "./Poller";

import { authProvider } from "../../AuthProvider";


export class DeploymentPoller {

    azureService = new AzureRMService();
    sharePointService = new SharePointService();
    settingsService = new SettingsPersistenceService();

    _poller: Poller<() => Promise<boolean>>

    constructor(intervalInMilliseconds: number, azureSubscriptionId: string, resourceGroupName: string, deploymentName: string, callbackFunction: (deploymentStatus) => void) {
        this._poller = new Poller(async () => {

            const deploymentStatus = await this.azureService.getDeploymentStatus(azureSubscriptionId, resourceGroupName, deploymentName);

            if (deploymentStatus.properties.provisioningState === "Running") {
                //deployment is still running

                return false;
            }
            else {
                // TODO - handle errors?
                callbackFunction(deploymentStatus);
                return true;
            }

        }, intervalInMilliseconds)
    }

    stop() {
        this._poller.stop();
    }
}
import React, { useState } from "react";
import NavbarContainer from "./../NavbarContainer";
import { Link, useRouter } from "./../../util/router";
import { useAuth } from "./../../util/auth";

import "./styles.scss";
import { authProvider } from "../../AuthProvider";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { Dropdown } from "react-bulma-components/full";
import RightpointSVG from "./../SVG/RightpointSVG";
import { useAuthenticationStore } from "../../stores/AuthenticationStore";
import { globalStore } from "../../stores/GlobalStore";
import { SparkDrawer } from "../../components/Drawer/Drawer";
import { Link as Link2, Button, Stack } from "@chakra-ui/core";

function Navbar(props) {
  const auth = useAuth();
  const router = useRouter();
  const [menuOpen, setMenuOpen] = useState(false);
  const authenticationStore = useAuthenticationStore();

  return (
    <NavbarContainer spaced={props.spaced} color={props.color}>
      <div className="container">
        <div className="navbar-brand">
          <div className="navbar-item">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Link to="/">
                <div style={{ height: "23px", width: "135px" }}>
                  <RightpointSVG />
                </div>
              </Link>
              {/* <div style={{ paddingLeft: 5 }}>Spark</div> */}
            </div>
          </div>
          <div
            className={"navbar-burger burger" + (menuOpen ? " is-active" : "")}
            onClick={() => setMenuOpen(!menuOpen)}>
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className={"navbar-menu" + (menuOpen ? " is-active" : "")}>
          <div className="navbar-end">
            {authenticationStore.authenticationState === AuthenticationState.Authenticated && (
              <p>
                {globalStore.userStore.userDisplayName && <span id="loggedinuser">
                  Welcome,{" "}
                  <Link2
                    color={"themePrimary"}
                    onClick={e => {
                      router.push("/profile");
                    }}>
                    {globalStore.userStore.userDisplayName}
                  </Link2>
                  !
                  </span>}{" "}
                | {globalStore.tenantUrl && <span>{globalStore.tenantUrl}</span>}
              </p>
            )}

            {authenticationStore.authenticationState !== AuthenticationState.Authenticated && (
              <Link
                className="navbar-item"
                onClick={e => {
                  e.preventDefault();
                  authenticationStore.login();
                }}>
                Sign in
              </Link>
            )}

            <Stack isInline spacing={2}>
              {authenticationStore.authenticationState === AuthenticationState.Authenticated && (
                <Button
                  // color={"white"}
                  // backgroundColor={"white"}
                  variantColor={"themePrimary"}
                  color="themePrimary"
                  _hover={{ color: "white", backgroundColor: "themePrimary" }}
                  onClick={() => {
                    authenticationStore.logout();
                  }}>
                  Logout
                </Button>
              )}

              <SparkDrawer />
            </Stack>
          </div>
        </div>
      </div>
    </NavbarContainer>
  );
}

export default Navbar;

import React from "react";
import { withRouter } from "../../util/router";
import { SparkMenuPage } from "../../components/SparkMenu";
import { AzureRMService } from "../../services/AzureRMService";
import { Button, Heading, Loader, Modal, Table, Tag } from "react-bulma-components/full";
import AzureStorageService from "../../services/AzureStorageService";
import { TEAMS_APP_MANIFEST_URL } from "../../util/Constants";
import { SharePointService } from "../../services/SharePointService";
import { observable, action, computed } from "mobx";
import { observer } from "mobx-react";
import * as d3 from "d3";
import TaxonomyAPI from "../../services/TaxonomyService";
import Tree from "react-d3-tree";
import { Input } from "@chakra-ui/core";

const myTreeData = [
  {
    name: "Top Level",
    attributes: {
      keyA: "val A",
      keyB: "val B",
      keyC: "val C"
    },
    children: [
      {
        name: "Level 2: A",
        attributes: {
          keyA: "val A",
          keyB: "val B",
          keyC: "val C"
        }
      },
      {
        name: "Level 2: B"
      }
    ]
  }
];

class MyComponent extends React.Component<{ treeData: any }> {
  render() {
    return (
      <div id="treeWrapper" style={{ width: "100em", height: "40em" }}>
        <Tree data={this.props.treeData} orientation={"vertical"} initialDepth={1} />
      </div>
    );
  }
}

const containerStyles = {
  width: "100%",
  height: "100vh"
};

@observer
class CenteredTree extends React.Component<{ treeData: any }, { translate }> {
  state = { translate: { x: 0, y: 0 } };

  @observable currentNodeText: string = "";
  @action setCurrentNodeText(text: string) {
    this.currentNodeText = text;
  }
  treeContainer;
  _ref;
  componentDidMount() {
    this._ref = React.createRef();
    const dimensions = this.treeContainer.getBoundingClientRect();
    this.setState({
      translate: {
        x: dimensions.width / 2,
        y: 20
      }
    });
  }

  componentDidUpdate() {}

  render() {
    return (
      <div style={containerStyles} ref={tc => (this.treeContainer = tc)}>
        <Tree
          data={this.props.treeData}
          translate={this.state.translate}
          orientation={"vertical"}
          initialDepth={1}
          pathFunc={"elbow"}
          onClick={(node: any) => {
            console.log(node);
            this.setCurrentNodeText(node.name);
          }}
        />

        <Input value={this.currentNodeText}></Input>
      </div>
    );
  }
}

class BarChart extends React.Component<{ id; ref }> {
  componentDidMount() {
    this.drawChart();
  }

  drawChart() {
    const data = [12, 5, 6, 6, 9, 10];

    const svg = d3
      .select(this.props.ref)
      .append("svg")
      .attr("width", 100)
      .attr("height", 100)
      .style("margin-left", 100);
    debugger;
    svg
      .selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .attr("x", (d, i) => i * 70)
      .attr("y", (d, i) => 100 - 10 * d)
      .attr("width", 65)
      .attr("height", (d, i) => d * 10)
      .attr("fill", "green");
  }

  render() {
    return <div id={"#" + this.props.id}></div>;
  }
}

@observer
class TermStorePage extends React.Component {
  sharePointService = new SharePointService();
  azureService = new AzureRMService();

  _ref;

  @observable output = "";
  @action setOutput(output) {
    this.output = output;
  }

  @observable treeData: any;
  @action setTreeData(treeData: any) {
    this.treeData = treeData;
  }

  @computed get mappedTreeData() {
    return [
      {
        name: "Top Level",
        attributes: {
          keyA: "val A",
          keyB: "val B",
          keyC: "val C"
        },
        children: [
          {
            name: "Level 2: A",
            attributes: {
              keyA: "val A",
              keyB: "val B",
              keyC: "val C"
            }
          },
          {
            name: "Level 2: B"
          }
        ]
      }
    ];
  }

  async componentDidMount() {
    this._ref = React.createRef();
    const rootUrl = (await this.sharePointService.getSharePointRootUrl()).webUrl;
    //   const context = await this.sharePointService.getContextInfo(rootUrl, rootUrl);
    const termStore = await TaxonomyAPI.getTermSetAsTree(rootUrl, "f161fd31-f1c8-46f5-b892-7b9060f4462c");
    //  this.setTreeData(termStore);
    const mappedTermData = [
      {
        name: termStore.title,
        attributes: { id: termStore.guid },
        children: termStore.children?.map(term => {
          return {
            name: term.title,
            attributes: { id: term.guid },
            children: term.children?.map(t => {
              return { name: t.title };
            })
          };
        })
      }
    ];
    this.setTreeData(mappedTermData);
    console.log(termStore);
  }

  async createTerm() {
    const rootUrl = (await this.sharePointService.getSharePointRootUrl()).webUrl;

    await this.sharePointService.createTerm(rootUrl);
  }

  render() {
    return (
      <div>
        <div>{this.treeData && <CenteredTree treeData={this.treeData} />}</div>
      </div>
    );
  }
}

export default withRouter(TermStorePage);

import Poller from "./Poller"
import { authProvider } from "../../AuthProvider";
import { AzureRMService } from "../../services/AzureRMService";
import { SharePointService } from "../../services/SharePointService";
import { SettingsPersistenceService } from "../../services/SettingsPersistanceService";
import { BADGING_USER_IMAGE_LIBRARY } from "../../util/Constants";

class UserImagePoller {

    azureService = new AzureRMService();
    sharePointService = new SharePointService();
    settingsService = new SettingsPersistenceService();

    _poller: Poller<() => Promise<boolean>>

    constructor(intervalInMilliseconds: number) {
        this._poller = new Poller(async () => {
            // get the user account id
            const account = authProvider.getAccount();
            const userId = account.idToken.oid;

            const extensionData = await this.settingsService.getOrEnsureExtensionCreatedForMe();
            if (!extensionData.lastSeenProfileETag) {
                // we dont have an original image for this user
                // TODO: handle is user has no image at all yet
                const profileImage = await this.azureService.getUserPhotoById(userId, 96, 96);
                let blob = await fetch(profileImage).then(r => r.blob());
                const oldImage = await this.azureService.saveFileInMyDrive(
                    "Badge Images/Image History",
                    `originalUserImage.png`,
                    "image/png",
                    blob
                );
                const photoMetadata = await this.azureService.getUserPhotoMetadataById(userId, 96, 96);
                console.log("IMAGE ETAG", photoMetadata["@odata.mediaEtag"]);
                const etag = photoMetadata["@odata.mediaEtag"];
                this.settingsService.persistSettingsToExtensionForMe({ lastSeenProfileETag: etag });

                const userInfo = await this.azureService.getUserInformationLatestDelta(userId);
                const deltaLink = userInfo["@odata.deltaLink"];

                await this.azureService.saveFileInMyDrive(
                    `${BADGING_USER_IMAGE_LIBRARY}`,
                    `deltaLink.json`,
                    "application/json",
                    deltaLink
                );

                // await this.azureService.shareFileFromMyDrive(this.userEmail, file.id);


                return false;
            }

            const existingFile = await this.azureService.getFileFromUserDrive(
                userId,
                `${BADGING_USER_IMAGE_LIBRARY}`,
                `deltaLink.json`
            );

            const downloadUrl = existingFile["@microsoft.graph.downloadUrl"];

            const dataJson = await fetch(downloadUrl, {});
            const data = await dataJson.text();
            const deltaInfo = await this.azureService.getUserInformationFromDeltaLink(data);
            if (deltaInfo.length > 0) {
                // We know something about the user was changed, but not necessarily what changed
                // we will go out and get the image etag, if we have a new etag then download the new image and put it in their image history
                const photoMetadata = await this.azureService.getUserPhotoMetadataById(userId, 96, 96);
                console.log("IMAGE ETAG", photoMetadata["@odata.mediaEtag"]);
                const etag = photoMetadata["@odata.mediaEtag"];
                if (extensionData.lastSeenProfileETag === etag) {
                    console.log("SAME ETAG");
                } else {
                    this.settingsService.persistSettingsToExtensionForMe({ lastSeenProfileETag: etag });

                    const profileImage = await this.azureService.getUserPhotoById(userId, 96, 96);
                    let blob = await fetch(profileImage).then(r => r.blob());
                    await this.azureService.saveFileInMyDrive(
                        "Badge Images/Image History",
                        `originalUserImage_${Date.now()}.png`,
                        "image/png",
                        blob
                    );
                }

                const userInfo = await this.azureService.getUserInformationLatestDelta(userId);
                const deltaLink = userInfo["@odata.deltaLink"];

                await this.azureService.saveFileInMyDrive(
                    `${BADGING_USER_IMAGE_LIBRARY}`,
                    `deltaLink.json`,
                    "application/json",
                    deltaLink
                );
            }

            return false;
        }, intervalInMilliseconds);
    }

    stop() {
        this._poller.stop();
    }
}

export { UserImagePoller }
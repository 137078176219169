import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { authProvider } from "../../AuthProvider";
import { Columns, Section, Progress, Table, Heading, Pagination, Loader, Tag } from "react-bulma-components/full";
import {
  Heading as Heading2,
  Tag as Tag2,
  Button,
  Stack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link,
  CircularProgress
} from "@chakra-ui/core";
import { observer } from "mobx-react";
import { observable, action, computed, toJS } from "mobx";
import { AzureRMService, SparkProvisioningAppSettings } from "../../services/AzureRMService";
import { SharePointService } from "../../services/SharePointService";
import AzureStorageService from "../../services/AzureStorageService";
import { SparkMenuPage } from "../../components/SparkMenu";
import { SparkTenantState } from "../setup";
import { useRouter, withRouter } from "./../../util/router";
import ResourceGroupDeleteModal, { ResourceGroupDeleteResult } from "../../components/ResourceGroupDeleteModal";
import { globalStore } from "../../stores/GlobalStore";
import { SettingsPersistenceService } from "../../services/SettingsPersistanceService";
import { DeploymentPoller } from "../../components/Poller/DeploymentPoller";

@observer
class ResourceGroupPage extends React.Component<{ match; history }> {
  azureService = new AzureRMService();
  sharePointService = new SharePointService();
  settingsService = new SettingsPersistenceService();

  _poller: DeploymentPoller | undefined;

  @observable isLoading: boolean = false;
  @action setIsLoading(loading) {
    this.isLoading = loading;
  }
  @observable sparkTenantState: SparkTenantState | undefined;
  @action setSparkTenantState(state: SparkTenantState) {
    this.sparkTenantState = state;
  }

  @observable subscriptionId: string = "";
  @action setSubscriptionId(id) {
    this.subscriptionId = id;
  }

  @observable resourceGroup: any;
  @action setResourceGroup(resourceGroup) {
    this.resourceGroup = resourceGroup;
  }

  @observable resourcesInResourceGroup = [];
  @action setResourcesInResourceGroup(resources) {
    this.resourcesInResourceGroup = resources;
  }

  @observable deploymentsInResourceGroup: Array<any> = [];
  @action setDeploymentsInResourceGroup(deployments) {
    this.deploymentsInResourceGroup = deployments;
  }

  @observable confirmModalOpen: boolean = false;
  @action setConfirmModalOpen(isOpen) {
    this.confirmModalOpen = isOpen;
  }

  @observable resourceGroupProvisioningState: string = "";
  @observable setResourceGroupProvisioningState(provisioningState: string) {
    this.resourceGroupProvisioningState = provisioningState;
  }

  @computed get deleteButtonEnabled() {
    if (this.resourceGroup && this.resourceGroup.properties.provisioningState === "Deleting") {
      return false;
    }

    const runningDeployment =
      this.deploymentsInResourceGroup &&
      this.deploymentsInResourceGroup.find((deployment: any) => {
        return deployment.provisioningState === "Running";
      });

    if (runningDeployment) {
      return false;
    }

    return true;
  }

  @computed get canSetApp(): boolean {
    return (
      this.deploymentsInResourceGroup &&
      this.deploymentsInResourceGroup.length > 0 &&
      this.deploymentsInResourceGroup[0]?.name.indexOf("Spark") !== -1
    );
  }

  @observable hasRunningDeployment: boolean = false;
  @action setHasRunningDeployment(runningDeployment: boolean) {
    this.hasRunningDeployment = runningDeployment;
  }

  private async startDeploymentPoller(deploymentName: string) {
    this._poller = new DeploymentPoller(
      5000,
      this.subscriptionId,
      this.resourceGroup.name,
      deploymentName,
      async () => {
        this.setHasRunningDeployment(false);

        const deployments = await this.azureService.getDeploymentsForResourceGroup(
          this.subscriptionId,
          this.resourceGroup.name
        );
        this.setDeploymentsInResourceGroup(deployments);
      }
    );
  }

  async componentWillUnmount() {
    if (this._poller) {
      this._poller.stop();
    }
  }

  async componentDidMount() {
    if (this.props.match.params.name && this.props.match.params.id) {
      this.setIsLoading(true);

      this.setSubscriptionId(this.props.match.params.id);
      this.setResourceGroup(this.props.match.params.name);

      const exists = await this.azureService.checkResourceGroupExistence(
        this.subscriptionId,
        this.props.match.params.name
      );
      if (exists) {
        const rg = await this.azureService.getResourceGroup(this.subscriptionId, this.props.match.params.name);
        this.setResourceGroup(rg);
        this.setResourceGroupProvisioningState(rg.properties.provisioningState);

        // TODO - this only gets Microsoft.Web resources, do we need Microsoft.Storage too?
        const resources = await this.azureService.getResourcesInResourceGroupWebProvider(
          this.subscriptionId,
          this.resourceGroup.name
        );

        this.setResourcesInResourceGroup(resources);
        const deployments = await this.azureService.getDeploymentsForResourceGroup(
          this.subscriptionId,
          this.resourceGroup.name
        );
        this.setDeploymentsInResourceGroup(deployments);

        // find out if any deployments are currently running
        const runningDeployment = deployments.find(deployment => {
          if (deployment.properties.provisioningState === "Running") {
            return true;
          }
        });

        if (runningDeployment) {
          console.log(`There was a running deployment. Polling for status now.`);
          this.setHasRunningDeployment(true);
          this.startDeploymentPoller(runningDeployment.name);
        } else {
          this.setHasRunningDeployment(false);
          console.log(`There was no running deployment.`);
        }

        this.setIsLoading(false);
      } else {
        this.props.history.push("/");
      }
    }
  }

  render() {
    const NoResourcesYet = () => {
      return (
        <div>
          You don't have any resources in here yet.{" "}
          <Link color="themePrimary">Click here to install a Spark feature.</Link>
        </div>
      );
    };
    const ResourceGroupTagsDisplay = observer(({ tags }) => {
      return (
        <>
          <Stack direction="row">
            <Text>Tags:</Text>
            {tags &&
              Object.keys(tags).map(tag => {
                return (
                  <Tag2 size={"md"} bg="themePrimary" color="white">
                    {tag}
                  </Tag2>
                );
              })}
          </Stack>
        </>
      );
    });
    const DeploymentDisplay = observer(
      ({ deploymentName, deploymentState, deploymentTimestamp, deploymentSettings }) => {
        return (
          <div>
            {`Deployment `} <b>{deploymentName}</b> {"has status"}{" "}
            <span>
              <b>{deploymentState}</b>
            </span>{" "}
            <span>at {moment(deploymentTimestamp).format("MM/DD/YYYY h:mm:ss a")}.</span>
            {deploymentState !== "Running" && (
              <a
                onClick={async () => {
                  const redeployObject = {
                    clientId: deploymentSettings.clientId.value,
                    clientSecret: deploymentSettings.clientSecret.value,
                    spTemplateSiteUrl: deploymentSettings.spTemplateSiteUrl.value,
                    spUsername: deploymentSettings.spUsername.value,
                    spTenantUrl: deploymentSettings.spTenantUrl.value,
                    spAdminUrl: deploymentSettings.spAdminUrl.value,
                    installOnStart: deploymentSettings.installOnStart.value
                  };
                  await this.azureService.deployProvisioningAppARMTemplate(
                    this.subscriptionId,
                    this.resourceGroup.name,
                    redeployObject
                  );
                  window.location.reload();
                }}>
                {" "}
                Trigger redeploy
              </a>
            )}
          </div>
        );
      }
    );

    const ResourcesInResourceGroupTable = observer(({ resourceGroupName }) => {
      const mapTags = tags => {};
      return (
        <React.Fragment>
          <Stack direction="row">
            <Heading2 size={"md"}>Resource Group: {resourceGroupName}</Heading2>

            <Menu>
              <MenuButton size="sm" as={Button}>
                Actions
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    this.setConfirmModalOpen(true);
                    //   this.azureService.deleteResourceGroup(this.subscriptionId, this.resourceGroupName);
                  }}>
                  Delete Resource Group
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    window.open(
                      `https://portal.azure.com/#@${globalStore.userCurrentAADDomain}/resource/subscriptions/${this.subscriptionId}/resourceGroups/${this.resourceGroup.name}/overview`,
                      "_blank"
                    );
                  }}>
                  View in Azure Portal
                </MenuItem>
                {this.canSetApp && (
                  <MenuItem
                    onClick={async () => {
                      console.log(`Setting as main app`);
                      const account = authProvider.getAccount();
                      const tenantId = account.idToken.tid;
                      debugger;
                      const functionAppName = this.deploymentsInResourceGroup[0]?.properties.outputs.functionAppName
                        .value;
                      // const functionKey = this.deploymentsInResourceGroup[this.deploymentsInResourceGroup.length - 1]
                      //   ?.properties.outputs.functionKey.value;
                      // //   this.settingsService.persistSettingsToOrganizationExtension(tenantId, {peFunctionAppName:  })
                      // console.log("FUNCTION APP", functionAppName, functionKey);
                      const fullFunctionAppApiUrl = `https://${functionAppName}.azurewebsites.net/api`;

                      // const functionAppLocation = this.deploymentsInResourceGroup[
                      //   this.deploymentsInResourceGroup.length - 1
                      // ]?.properties.outputResources.find(outputResource => {
                      //   if (
                      //     outputResource.id.indexOf(`/providers/Microsoft.Web/sites/${functionAppName}`) !== -1 &&
                      //     outputResource.id.indexOf(`v2`) === -1
                      //   ) {
                      //     return true;
                      //   }
                      // });
                      const key = await this.azureService.getFunctionAppMasterKey(
                        this.subscriptionId,
                        this.resourceGroup.name,
                        functionAppName
                      );

                      await this.azureService.updateFunctionAppMasterKey(
                        this.subscriptionId,
                        this.resourceGroup.name,
                        functionAppName + "-v2",
                        key.masterKey
                      );

                      debugger;

                      await this.settingsService.persistSettingsToOrganizationExtension(tenantId, {
                        peFunctionAppName: functionAppName,
                        peResourceGroupName: this.resourceGroup.name,
                        peSubscriptionId: this.subscriptionId
                      });
                    }}>
                    Set as Main Spark Provisioning App
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </Stack>
          {this.resourceGroup && <ResourceGroupTagsDisplay tags={this.resourceGroup.tags} />}

          <br />
          {this.resourcesInResourceGroup.length === 0 ? (
            <NoResourcesYet />
          ) : (
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Tags</th>
                  <th>State</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.resourcesInResourceGroup.map((app: any) => {
                  return (
                    <tr>
                      <td>{app.name}</td>
                      <td>
                        {app.tags && (
                          <Tag.Group>
                            <Tag color="primary">{app.tags["Spark Resource"]}</Tag>
                          </Tag.Group>
                        )}
                      </td>
                      <td>{app.properties.state}</td>
                      <td>
                        {" "}
                        <Tag.Group
                          onClick={() => {
                            this.azureService.restartWebApp(this.subscriptionId, this.resourceGroup.name, app.name);
                          }}>
                          <Tag color="primary">Reboot</Tag>
                        </Tag.Group>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}

          {this.deploymentsInResourceGroup &&
            this.deploymentsInResourceGroup.map((deployment: any) => {
              return (
                <DeploymentDisplay
                  deploymentName={deployment.name}
                  deploymentState={deployment.properties.provisioningState}
                  deploymentTimestamp={deployment.properties.timestamp}
                  deploymentSettings={deployment.properties.parameters}></DeploymentDisplay>
              );
            })}

          {this.hasRunningDeployment && <CircularProgress isIndeterminate />}
          <br />
          <br />

          <ResourceGroupDeleteModal
            modal={{ closeOnEsc: true }}
            resourceGroupName={this.resourceGroup.name}
            isOpen={this.confirmModalOpen}
            onClose={() => this.setConfirmModalOpen(false)}
            onSubmit={(result: ResourceGroupDeleteResult) => {
              if (result.shouldDelete) {
                this.azureService.deleteResourceGroup(this.subscriptionId, this.resourceGroup.name);
                setTimeout(() => {
                  this.props.history.push(`/admin/subscriptions/${this.subscriptionId}`);
                }, 2000);
              }
            }}
          />
        </React.Fragment>
      );
    });

    const ResourcesInResourceGroupScreen = observer(() => {
      return this.isLoading ? (
        <Loader
          style={{
            width: 300,
            height: 300,
            border: "4px solid blue",
            borderTopColor: "transparent",
            borderRightColor: "transparent"
          }}
        />
      ) : (
        <React.Fragment>
          {this.resourceGroup && <ResourcesInResourceGroupTable resourceGroupName={this.resourceGroup.name} />}
        </React.Fragment>
      );
    });

    return <ResourcesInResourceGroupScreen />;
  }
}

export default withRouter(ResourceGroupPage);

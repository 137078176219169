import { action, observable } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Button, Columns, Section, Progress, Table, Heading, Pagination, Loader } from "react-bulma-components/full";

import { SparkMenuPage } from "../../components/SparkMenu";
import { AzureRMService } from "../../services/AzureRMService";
import { SharePointService } from "../../services/SharePointService";
import { withRouter, Switch, Route } from "../../util/router";

@observer
class AzureADAppsPage extends React.Component<{ match; history }> {
  sharePointService = new SharePointService();
  azureService = new AzureRMService();

  @observable aadApps: Array<{ appId: string; id: string; displayName: string }> = [];
  @action setAadApps(aadApps) {
    this.aadApps = aadApps;
  }

  @observable isSparkProvisioningAppInstalled: boolean = false;
  @action setIsSparkProvisioningAppInstalled(isSparkProvisioningAppInstalled) {
    this.isSparkProvisioningAppInstalled = isSparkProvisioningAppInstalled;
  }

  async componentDidMount() {
    // find the app with tag Spark Provisioning
    const sparkProvisioningAzureADApps = await this.azureService.getAzureADAppsByTag("Spark Provisioning");
    const allApps = await this.azureService.getAzureADApps();
    if (sparkProvisioningAzureADApps.length >= 1) {
      this.setIsSparkProvisioningAppInstalled(true);
    }
    this.setAadApps(allApps);
  }
  render() {
    const AzureADAppsTable = observer(() => {
      return (
        <React.Fragment>
          <Table>
            <thead>
              <tr>
                <th>
                  <abbr title="Id">Id</abbr>
                </th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {this.aadApps.map(app => {
                return (
                  <tr>
                    <th>{app.appId}</th>
                    <td>{app.displayName}</td>
                    <td>{app.id}</td>
                    <td>
                      <Button
                        onClick={() => {
                          console.log(this.props.match);
                          this.props.history.push(this.props.match.url + "/" + app.id);
                        }}
                        color="success">
                        Select
                      </Button>
                    </td>
                    <td>
                      <Button onClick={() => this.azureService.deleteAzureAdApp(app.id)} color="danger">
                        Delete
                      </Button>
                    </td>
                    <td>
                      <Button
                        onClick={() => {
                          window.location.href = `https://login.microsoftonline.com/common/adminconsent?client_id=${app.appId}&state=12345&redirect_uri=${window.location.href}`;
                        }}
                        color="primary">
                        Admin Consent
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </React.Fragment>
      );
    });

    return (
      <>
        {this.isSparkProvisioningAppInstalled && (
          <>
            <div>✅ Provisioning Engine Azure AD App is present on your tenant.</div>
            <br />
            <div>You can remove it by clicking Delete below.</div>
            <br />
            <div>Click the Admin Consent button to be redirected to authorize the app's permissions.</div>
            <br />
          </>
        )}
        <AzureADAppsTable />
      </>
    );
  }
}

export default withRouter(AzureADAppsPage);

// generated from https://fabricweb.z5.web.core.windows.net/pr-deploy-site/refs/heads/master/theming-designer/index.html
export const defaultTheme = {
  themePrimary: "rgb(238, 33, 35)",
  themeLighterAlt: "#fef6f6",
  themeLighter: "#fcd9da",
  themeLight: "#fab9ba",
  themeTertiary: "#f47678",
  themeSecondary: "#ef3a3d",
  themeDarkAlt: "#d51e21",
  themeDark: "#b4191c",
  themeDarker: "#851314",
  neutralLighterAlt: "#f8f8f8",
  neutralLighter: "#f4f4f4",
  neutralLight: "#eaeaea",
  neutralQuaternaryAlt: "#dadada",
  neutralQuaternary: "#d0d0d0",
  neutralTertiaryAlt: "#c8c8c8",
  neutralTertiary: "#bab8b7",
  neutralSecondary: "#a3a2a0",
  neutralPrimaryAlt: "#8d8b8a",
  neutralPrimary: "#323130",
  neutralDark: "#605e5d",
  black: "#494847",
  white: "#ffffff"
};

export const defaultBrand = {
  50: "#ffe3e5",
  100: "#ffb6b7",
  200: "#f88889",
  300: "#f3585a",
  400: "#ef2a2c",
  500: "#d51012",
  600: "#a70a0d",
  700: "#770508",
  800: "#4a0103",
  900: "#200000"
};

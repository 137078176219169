import React, { useEffect } from "react";
import DashboardSection from "./../../components/DashboardSection";
import { Table } from "react-bulma-components/full";
import { useRouter, Switch, Route, withRouter } from "./../../util/router";
import "./styles.scss";
import { useAuth } from "../../util/auth";
import SectionHeader from "../../components/SectionHeader";
import { SparkMenuPage } from "../../components/SparkMenu";
import { action, observable, toJS } from "mobx";
import { observer } from "mobx-react";
import { SharePointService } from "../../services/SharePointService";
import { AzureRMService } from "../../services/AzureRMService";
import SitePage from "../site";
import SharePointAppsPage from "../sharepointapps";
import CreateSitesPage from "./Create";
import {
  Heading,
  Box,
  Stack,
  Input,
  Collapse,
  Icon,
  Text,
  Link,
  Checkbox,
  Divider,
  SimpleGrid,
  InputGroup,
  InputRightElement,
  Button
} from "@chakra-ui/core";
import { globalStore } from "../../stores/GlobalStore";
import { SearchResult } from "@pnp/sp";

@observer
class SitesPage extends React.Component<{ match; history }> {
  sharePointService = new SharePointService();
  azureService = new AzureRMService();

  _rootUrl;

  @observable sites = [];
  @action setSites(sites) {
    this.sites = sites;
  }

  @observable availableTenantsToSearch: Array<string> = [];
  @action addAvailableTenant(url: string) {
    this.availableTenantsToSearch.push(url);
  }

  @observable selectedTenantsToSearch: Array<string> = [];
  @action addSelectedTenantsToSearch(url: string) {
    this.selectedTenantsToSearch.push(url);
  }
  @action removeSelectedTenantsToSearch(url: string) {
    const index = this.selectedTenantsToSearch.indexOf(url);
    this.selectedTenantsToSearch.splice(index, 1);
  }

  @observable searchQueryInput: string = "*";
  @observable setSearchQueryInput(input) {
    this.searchQueryInput = input;
  }

  @observable searchResults: Array<{ tenantUrl: string; results: Array<SearchResult> }> = [];
  @observable setSearchResultsForTenant(tenantUrl: string, results: Array<any>) {
    const existing = this.searchResults.findIndex(t => t.tenantUrl === tenantUrl);
    if (existing === -1) {
      this.searchResults.push({ tenantUrl: tenantUrl, results: results });
    } else {
      this.searchResults[existing].results = results;
    }
  }

  @observable spTenantResultsDisplays: Array<{ tenantUrl: string; tenantAuthority: string; isOpen: boolean }> = [];

  @action addSPTenantResultsDisplay(display: { tenantUrl: string; tenantAuthority: string; isOpen: boolean }) {
    const existing = this.spTenantResultsDisplays.findIndex(t => t.tenantUrl === display.tenantUrl);
    if (existing === -1) {
      this.spTenantResultsDisplays.push(display);
    }
  }

  @action toggleDisplayByUrl(url: string) {
    const display = this.spTenantResultsDisplays.find(({ tenantUrl }) => tenantUrl === url);
    if (display) {
      display.isOpen = !display.isOpen;
    }
  }

  async executeSearch() {
    const spRoots = globalStore.userAADDirectories.map(async dir => {
      const url = (
        await this.sharePointService.getSharePointRootUrl("https://login.microsoftonline.com/" + dir.tenantId)
      ).webUrl;
      console.log("URL", url);
      const searchResults = await this.sharePointService.searchSharePoint(
        url,
        `${this.searchQueryInput} AND contentclass:STS_Site`,
        "https://login.microsoftonline.com/" + dir.tenantId
      );
      this.setSearchResultsForTenant(url, searchResults.PrimarySearchResults);
      this.addSPTenantResultsDisplay({
        tenantUrl: url,
        tenantAuthority: "https://login.microsoftonline.com/" + dir.tenantId,
        isOpen: true
      });
      return url;
    });
  }
  async componentDidMount() {
    try {
      const spRoots = globalStore.userAADDirectories.map(async dir => {
        const spsites = await this.azureService.getSPSiteSearch("https://login.microsoftonline.com/" + dir.tenantId);

        const url = (
          await this.sharePointService.getSharePointRootUrl("https://login.microsoftonline.com/" + dir.tenantId)
        ).webUrl;
        console.log("URL", url);
        this.addAvailableTenant(url);
        this.addSelectedTenantsToSearch(url);

        const searchResults = await this.sharePointService.searchSharePoint(
          url,
          `${this.searchQueryInput} AND contentclass:STS_Site`,
          "https://login.microsoftonline.com/" + dir.tenantId
        );
        this.setSearchResultsForTenant(url, searchResults.PrimarySearchResults);
        this.addSPTenantResultsDisplay({
          tenantUrl: url,
          tenantAuthority: "https://login.microsoftonline.com/" + dir.tenantId,
          isOpen: true
        });
        return url;
      });

      console.log("SPROOTS", spRoots);

      // const rootUrl = (await this.sharePointService.getSharePointRootUrl()).webUrl;

      // this._rootUrl = rootUrl;
      // await this.executeSearch();
    } catch (err) {
      this.props.history.push("/error");
    }
  }
  render() {
    const Page = observer(() => (
      <Stack align="center">
        <Box width={{ base: "100%", xl: "50%" }} paddingX={10}>
          <Heading color={"neutralPrimary"} size={"sm"}>
            Site Search
          </Heading>
          <Text color={"themePrimary"}>(gets results from multiple SharePoint tenants)</Text>

          <Stack spacing={4} width={{ sm: "300px", md: "500px" }}>
            <InputGroup>
              <Input
                color={"themeSecondary"}
                borderColor={"themePrimary"}
                focusBorderColor={"themePrimary"}
                placeholder={"query"}
                onChange={event => {
                  this.setSearchQueryInput(event.target.value);
                }}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    this.executeSearch();
                  }
                }}
              />
              <InputRightElement width="4.5rem">
                <Button
                  backgroundColor={"themePrimary"}
                  color={"white"}
                  size="sm"
                  onClick={() => {
                    this.executeSearch();
                  }}>
                  Search
                </Button>
              </InputRightElement>
            </InputGroup>
          </Stack>
          <Stack isInline>
            {this.availableTenantsToSearch.map(url => {
              return (
                <Checkbox
                  onChange={event => {
                    if (event.target.checked) {
                      this.addSelectedTenantsToSearch(url);
                    } else {
                      this.removeSelectedTenantsToSearch(url);
                    }
                  }}
                  isChecked={this.selectedTenantsToSearch.indexOf(url) !== -1}>
                  {url}
                </Checkbox>
              );
            })}
          </Stack>

          {this.spTenantResultsDisplays.map(({ tenantUrl, isOpen }) => {
            return (
              <Stack>
                <br />
                <Stack isInline>
                  <Text color={"themePrimary"}>
                    {tenantUrl} - {this.searchResults.find(tenant => tenant.tenantUrl === tenantUrl)?.results.length}{" "}
                    results
                  </Text>
                  <Icon
                    color={"themePrimary"}
                    onClick={() => {
                      this.toggleDisplayByUrl(tenantUrl);
                    }}
                    name={isOpen ? "chevron-down" : "chevron-right"}
                    size="22px"></Icon>
                </Stack>
                <Collapse startingHeight={0} isOpen={isOpen}>
                  <Table>
                    <tbody>
                      {this.searchResults
                        .find(tenant => tenant.tenantUrl === tenantUrl)
                        ?.results.map(sr => {
                          return (
                            <tr>
                              <td> {sr.Title} </td>
                              <td>
                                <Link
                                  href={sr.Path}
                                  color={"themePrimary"}
                                  // _hover={{ color: "themeDarker" }}
                                  target="_blank">
                                  {sr.Path}
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Collapse>
                <Divider borderColor="themePrimary" />
              </Stack>
            );
          })}
        </Box>
      </Stack>
    ));

    return (
      <Switch>
        <Route exact path={`${this.props.match.path}/`} component={Page} />
        <Route exact path={`${this.props.match.path}/create/`} component={CreateSitesPage} />
        <Route exact path={`${this.props.match.path}/:id`} component={SitePage} />
        <Route exact path={`${this.props.match.path}/:id/apps`} component={SharePointAppsPage} />
      </Switch>
    );
  }
}

export default withRouter(SitesPage);

import React from "react";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import Faq from "./../Faq";
import "./styles.scss";

function FaqSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader title={props.title} subtitle={props.subtitle} centered={true} size={3} />
        <Faq
          items={[
            {
              question: "How does this thing work?",
              answer:
                "This works by using Azure AD integrations to provision ARM templates in Azure. It also creates applications and users in Azure AD using client side javascript calls."
            }
            // {
            //   question: "Lorem ipsum dolor sit amet?",
            //   answer:
            //     "Nunc nulla mauris, laoreet vel cursus lacinia, consectetur sit amet tellus. Suspendisse ut tincidunt eros. In velit mi, rhoncus dictum neque a, tincidunt lobortis justo."
            // },
            // {
            //   question: "Suspendisse ut tincidunt?",
            //   answer:
            //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lobortis, metus et mattis ullamcorper. Suspendisse ut tincidunt eros. In velit mi, rhoncus dictum neque a, tincidunt lobortis justo."
            // },
            // {
            //   question: "Ut enim ad minim veniam?",
            //   answer:
            //     "Suspendisse ut tincidunt eros. In velit mi, rhoncus dictum neque a, tincidunt lobortis justo."
            // },
            // {
            //   question: "In velit mi, rhoncus dictum neque?",
            //   answer:
            //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud."
            // }
          ]}
        />
      </div>
    </Section>
  );
}

export default FaqSection;

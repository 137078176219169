import { observable, action, toJS, computed, autorun } from "mobx";
import { persist, create } from "mobx-persist";

export type Notification = {
  id: string;
  title: string;
  text: string;
  isDismissed: boolean;
  notificationType: "success" | "danger" | "error" | "info";
};

export class NotificationStore2 {
  @persist("list") @observable notifications: Notification[] = [];
  @action createNotification(notification: Notification) {
    this.notifications.push(notification);
    localStorage.setItem("NotificationStore", JSON.stringify(toJS(this.notifications)));
  }

  @computed get notDismissedNotifications() {
    return this.notifications.filter(n => !n.isDismissed);
  }

  @action dismissNotification(id: string) {
    const nIndex = this.notifications.findIndex(n => n.id === id);
    this.notifications.splice(nIndex, 1);
    localStorage.setItem("NotificationStore", JSON.stringify(toJS(this.notifications)));
  }
}

export const notificationStore = new NotificationStore2();
const hydrate = create({
  storage: window.localStorage, // or AsyncStorage in react-native.
  // default: localStorage
  jsonify: true // if you use AsyncStorage, here shoud be true
  // default: true
});

hydrate("NotificationStore", notificationStore).then(() => {
  console.log("NotificationStore has been hydrated");
});

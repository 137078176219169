import "./styles.scss";

import { action, observable, toJS, computed } from "mobx";
import React, { useEffect } from "react";

import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";

import { SparkMenuPage } from "../../components/SparkMenu";
import { AzureRMService } from "../../services/AzureRMService";
import { SharePointService } from "../../services/SharePointService";
import { observer } from "mobx-react";
import { generateClientSidePassword } from "../../util/Utility";
import {
  SHAREPOINT_SERVICE_ADMINISTRATOR_ROLETEMPLATEID,
  COMPANY_ADMINISTRATOR_ROLETEMPLATEID
} from "../../util/Constants";
import { authProvider } from "../../AuthProvider";
import {
  Stack,
  Box,
  Flex,
  Icon,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Text,
  FormHelperText,
  FormErrorMessage,
  Button,
  Divider,
  Spinner
} from "@chakra-ui/core";
import { SettingsPersistenceService } from "../../services/SettingsPersistanceService";
import { globalStore } from "../../stores/GlobalStore";

@observer
class SparkAdministratorsPage extends React.Component {
  sharePointService = new SharePointService();
  azureService = new AzureRMService();
  settingsService = new SettingsPersistenceService();

  @observable isLoading: boolean = false;
  @action setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @observable deleteInProgress: boolean = false;
  @action setDeleteInProgress(deleteInProgress: boolean) {
    this.deleteInProgress = deleteInProgress;
  }

  @observable existingAdministrators: Array<any> = [];
  @action setExistingAdministrators(admins) {
    this.existingAdministrators = admins;
  }

  @observable userEmailInput = "";
  @action setUserEmailInput(val) {
    this.userEmailInput = val;
  }

  @observable errorMessage: string = "";
  @action setErrorMessage(message) {
    this.errorMessage = message;
  }

  @observable directoryRoles: Array<any> = [];
  @action setDirectoryRoles(directoryRoles) {
    this.directoryRoles = directoryRoles;
  }

  @observable selectedRole: string | null = null;
  @action setSelectedRole(selectedRole) {
    this.selectedRole = selectedRole;
  }

  @observable formAttemptsCount: number = 0;
  @action addFormAttempt() {
    this.formAttemptsCount++;
  }

  @observable formIsValid: boolean = true;
  @action setFormIsValid(isValid: boolean) {
    this.formIsValid = isValid;
  }

  @observable formErrorMessage: string = "Unknown form error.";
  @action setFormErrorMessage(message: string) {
    this.formErrorMessage = message;
  }

  @action validateEmail() {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.userEmailInput);
  }

  @computed get isEmailValid() {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.userEmailInput);
  }

  @computed get isFormValid() {
    if (this.formAttemptsCount === 0) {
      return true;
    }
    if (!this.formIsValid) {
      return false;
    }
    return this.isEmailValid;
  }

  async addSparkAdministratorUser(userEmail: string) {
    const accountInfo = authProvider.getAccountInfo();
    if (accountInfo === null || accountInfo.account === null) {
      alert("account can't be null");
      return;
    }

    const userExistsAlready = await this.azureService.getUserByUserPrincipalName(userEmail);
    if (userExistsAlready) {
      this.setFormErrorMessage(`The user with email: ${userEmail} already exists in your tenant.`);
      this.setFormIsValid(false);
      return;
    }

    console.log("Adding admin user");
    const userPassword = generateClientSidePassword();

    try {
      const user = await this.azureService.createUser(
        userEmail,
        "Spark Administrator",
        "SparkAdministrator",
        userPassword
      );

      debugger;
      console.log("P", userPassword);

      const directoryRoles = await this.azureService.getDirectoryRoles();
      const sharePointAdminRole = directoryRoles.find(role => {
        return role.roleTemplateId === SHAREPOINT_SERVICE_ADMINISTRATOR_ROLETEMPLATEID;
      });

      if (sharePointAdminRole) {
        await this.azureService.setUserInDirectoryRole(sharePointAdminRole.id, user.id);
      }

      const globalAdminRole = directoryRoles.find(role => role.roleTemplateId === COMPANY_ADMINISTRATOR_ROLETEMPLATEID);

      if (globalAdminRole) {
        await this.azureService.setUserInDirectoryRole(globalAdminRole.id, user.id);
      }

      // TODO make this better
      const organizations = await this.azureService.getOrganizationInfo();
      const organization = organizations[0];
      const orgId = organization.id as string;
      await this.settingsService.persistSettingsToOrganizationExtension(orgId, { sparkAdministratorEmail: userEmail });

      const email: MicrosoftGraph.Message = {
        toRecipients: [{ emailAddress: { name: "Tom White", address: "twhite@rightpoint.com" } }],
        subject: "New Spark Administrator User",
        body: {
          content: `A new Spark Administrator was created. The username is ${userEmail} and password is ${userPassword}`,
          contentType: "text"
        }
      };

      await this.azureService.sendEmail(email);

      setTimeout(async () => {
        // const existingAdmins = await this.sharePointService.getSparkAdministratorEntriesFromHiddenList(rootUrl);
        // this.setExistingAdministrators(existingAdmins);
        this.initialize();
      }, 2000);
    } catch (err) {
      debugger;
      this.setFormErrorMessage(`There was an error creating the user.`);
      this.setFormIsValid(false);
    }
  }

  async resetSparkAdminUserPassword() {
    const password = generateClientSidePassword();
    await this.azureService.updateUserPassword(this.existingAdministrators[0], password);
    const email: MicrosoftGraph.Message = {
      toRecipients: [{ emailAddress: { name: "Tom White", address: "twhite@rightpoint.com" } }],
      subject: "Spark Administrator Password Reset",
      body: {
        content: `The password for the account ${this.existingAdministrators[0]} was reset to: ${password}`,
        contentType: "text"
      }
    };

    await this.azureService.sendEmail(email);
  }

  async addRightpointUser(userEmail: string) {
    const rootUrl = (await this.sharePointService.getSharePointRootUrl()).webUrl;

    const accountInfo = authProvider.getAccountInfo();
    if (accountInfo === null || accountInfo.account === null) {
      alert("account can't be null");
      return;
    }

    const invite: any = await this.azureService.inviteExternalUser(userEmail, rootUrl);

    await this.sharePointService.sendEmail(
      rootUrl,
      [userEmail],
      accountInfo.account.idToken.preferred_username,
      "Invited to tenant",
      `Invited to tenant with ${invite.inviteRedeemUrl}`
    );
  }

  async deleteUser(userEmail: string) {
    this.setDeleteInProgress(true);
    const rootUrl = (await this.sharePointService.getSharePointRootUrl()).webUrl;

    //  await this.sharePointService.deleteSparkAdministratorFromHiddenList(rootUrl, userEmail);
    await this.azureService.deleteUser(userEmail);
    // TODO make this better
    const organizations = await this.azureService.getOrganizationInfo();
    const organization = organizations[0];
    const orgId = organization.id as string;
    await this.settingsService.persistSettingsToOrganizationExtension(orgId, { sparkAdministratorEmail: "" });
    setTimeout(async () => {
      this.setDeleteInProgress(false);
      this.initialize();
    }, 1000);
  }

  async ensureUser(userEmail: string) {
    try {
      const rootUrl = (await this.sharePointService.getSharePointRootUrl()).webUrl;
      await this.sharePointService.ensureUserOnRootTenantUrl(rootUrl, userEmail);
    } catch (err) {
      this.setErrorMessage(err.message);
    }
  }

  async initialize() {
    this.setIsLoading(true);
    const existingSettings = await this.settingsService.getOrganizationSettingsData();
    if (existingSettings.sparkAdministratorEmail) {
      this.setExistingAdministrators([existingSettings.sparkAdministratorEmail]);
    }

    const directoryRoles = await this.azureService.getDirectoryRoles();
    this.setDirectoryRoles(directoryRoles);
    this.setIsLoading(false);
  }

  async componentDidMount() {
    await this.initialize();
  }

  render() {
    if (this.isLoading) {
      return <Spinner />;
    }
    if (this.existingAdministrators.length === 1) {
      return (
        <Stack>
          <Box backgroundColor="white" shadow="sm" borderRadius="lg" pl={3} pr={3} pt={5} pb={5}>
            <Flex display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" pb={2}>
              <Icon name="chevron-left" />
              <Heading size="md" as="h2" lineHeight="shorter" fontWeight="bold" fontFamily="heading">
                Remove a Spark Administrator User Account
              </Heading>
            </Flex>
            <Stack ml={4} spacing={2} shouldWrapChildren mt={4} mr={4}>
              <Text>
                {`You already have an existing Spark Administrator user with the email address ${this.existingAdministrators[0]}.`}
              </Text>

              <Box>
                <Flex display="flex" flexDirection="row" alignItems="flex-end" justifyContent="flex-end">
                  <Button variant="outline" onClick={() => this.resetSparkAdminUserPassword()}>
                    Reset Password
                  </Button>
                  <Divider />
                  <Button
                    variant="solid"
                    isDisabled={this.deleteInProgress}
                    onClick={() => {
                      this.deleteUser(this.existingAdministrators[0]);
                    }}>
                    Remove Account
                  </Button>
                </Flex>
              </Box>
            </Stack>
          </Box>
        </Stack>
      );
    }
    return (
      <Stack>
        <Box backgroundColor="white" shadow="sm" borderRadius="lg" pl={3} pr={3} pt={5} pb={5}>
          <Flex display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" pb={2}>
            <Icon name="chevron-left" />
            <Heading size="md" as="h2" lineHeight="shorter" fontWeight="bold" fontFamily="heading">
              Add a Spark Administrator User Account
            </Heading>
          </Flex>
          <Stack ml={4} spacing={2} shouldWrapChildren mt={4} mr={4}>
            <Text>
              Enter the required information to create a Spark Administrator account for your tenant. This will create a
              service account in your tenant and apply the correct O365 licenses. This account will also be the owner of
              site provisioning Flows.
            </Text>
            <FormControl isInvalid={!this.isFormValid} isRequired>
              <FormLabel>Email Address</FormLabel>
              <Input
                size="md"
                as="input"
                variant="outline"
                isFullWidth
                focusBorderColor="blue.500"
                errorBorderColor="red.500"
                value={this.userEmailInput}
                onChange={e => {
                  this.setUserEmailInput(e.target.value);
                }}
              />
              <FormHelperText>{`This could be something like sparkadmin@${globalStore.userCurrentAADDomain}.`}</FormHelperText>
              <FormErrorMessage>{this.formErrorMessage}</FormErrorMessage>
            </FormControl>
            <Box>
              <Flex display="flex" flexDirection="column" alignItems="flex-end" justifyContent="flex-end">
                <Button
                  variant="solid"
                  isDisabled={!this.isEmailValid && this.formAttemptsCount !== 0}
                  onClick={() => {
                    this.addFormAttempt();
                    const isValid = this.validateEmail();
                    if (!isValid) {
                      this.setFormIsValid(false);
                      this.setFormErrorMessage(`Email address is invalid.`);
                      return;
                    }
                    this.addSparkAdministratorUser(this.userEmailInput);
                  }}>
                  Create Account
                </Button>
                {/* <Text>{JSON.stringify(this.isEmailValid)}</Text> */}
              </Flex>
            </Box>
          </Stack>
        </Box>
      </Stack>
    );
    {
      /* return (
      <>
        <Table>
          <tbody>
            {this.existingAdministrators.map(admin => {
              const data = JSON.parse(admin.Data);
              return (
                <tr>
                  <td>{data.user}</td>
                  <td>
                    <Button
                      onClick={() => {
                        this.deleteUser(data.user);
                      }}
                      color="danger">
                      Delete User
                    </Button>
                  </td>
                  <td>
                    <Button
                      onClick={() => {
                        this.ensureUser(data.user);
                      }}
                      color="danger">
                      Ensure User
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <input
          className="input is-medium"
          type={"text"}
          value={this.userEmailInput}
          placeholder={"email"}
          onChange={e => {
            this.setUserEmailInput(e.target.value);
          }}
        />
        <br />
        <br />
        <Button
          color={"primary"}
          onClick={() => {
            this.addSparkAdministratorUser(this.userEmailInput);
          }}>
          Add Spark Administrator User
        </Button>
        <br />
        <br />
        <Button
          onClick={() => {
            this.addRightpointUser("twhite@rightpoint.com");
          }}>
          Add twhite@rightpoint.com User
        </Button>
        <Content color={"danger"}>{this.errorMessage}</Content>
        ----------------------
        <h1>Assign Licenses</h1>
        <Button
        // onClick={() => {
        //   this.addRightpointUser("twhite@rightpoint.com");
        >
          Assign License
        </Button>
        {this.selectedRole && this.selectedRole}
        <Dropdown
          value={this.selectedRole}
          label={"Select Tenant"}
          onChange={selected => {
            this.setSelectedRole(selected.displayName);
          }}>
          {this.directoryRoles.map(role => {
            return <Dropdown.Item value={role}>{role.displayName}</Dropdown.Item>;
          })}
        </Dropdown>
      </>
    ); */
    }
  }
}

export default SparkAdministratorsPage;

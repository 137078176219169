import { observable, action } from "mobx";
import { persist, create } from "mobx-persist";

export class UserStore {
  @persist @observable userDisplayName: string = "";
  @action setUserDisplayName(userDisplayName: string) {
    this.userDisplayName = userDisplayName;
  }
}

export const userStore = new UserStore();

const hydrate = create({
  storage: window.localStorage, // or AsyncStorage in react-native.
  // default: localStorage
  jsonify: true // if you use AsyncStorage, here shoud be true
  // default: true
});

hydrate("UserStore", userStore).then(() => {
  console.log("UserStore has been hydrated");
});

import React from "react";
import { withRouter, Switch, Route } from "../../util/router";
import { SettingsPersistenceService } from "../../services/SettingsPersistanceService";
import { observer } from "mobx-react";
import { ProvisioningEngineStore } from "./ProvisioningEngineStore";
import {
  Button,
  Stack,
  Box,
  Flex,
  Icon,
  Heading,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  FormHelperText,
  Text,
  CircularProgress,
  Link as Link2,
  CheckboxGroup,
  Checkbox,
  InputLeftAddon,
  InputGroup
} from "@chakra-ui/core";
import { observable, action } from "mobx";
import { Toaster, ToasterThingWithToast } from "../../components/Toaster/Toaster";
import { ViewRequestPage } from "./ViewRequestPage";
import defaultHandlerValues, { defaultTrueHandlers } from "./Handler";
import { unique } from "mobx/lib/internal";
import { globalStore } from "../../stores/GlobalStore";

@observer
class ProvisioningEnginePage extends React.Component<{ history; match }> {
  settingService = new SettingsPersistenceService();

  store: ProvisioningEngineStore | undefined;

  @observable errorMessage: string = "";
  @action setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  @observable siteCollectionUrl: string = "";
  @action setSiteCollectionUrl(url: string) {
    this.siteCollectionUrl = url;
  }

  @observable selectedHandlers: Array<string> = [...defaultTrueHandlers];
  @action setSelectedHandlers(handlers: Array<string>) {
    this.selectedHandlers = handlers;
  }

  @observable handlersToSubmit() {}

  constructor(props) {
    super(props);
    this._ref = React.createRef();
  }

  async componentDidMount() {
    const existingOrgSettings = await this.settingService.getOrganizationSettingsData();

    if (
      !existingOrgSettings.peSubscriptionId ||
      !existingOrgSettings.peResourceGroupName ||
      !existingOrgSettings.peFunctionAppName
    ) {
      throw new Error(`Invalid settings for provisioning engine`);
    }
    this.store = new ProvisioningEngineStore({
      subscriptionId: existingOrgSettings.peSubscriptionId,
      resourceGroupName: existingOrgSettings.peResourceGroupName,
      functionAppName: existingOrgSettings.peFunctionAppName
    });

    this.store.initialize();
  }

  _ref;

  render() {
    if (!this.store) {
      return null;
    }
    return (
      <div>
        <Stack>
          <Box backgroundColor="white" shadow="sm" borderRadius="lg" pl={3} pr={3} pt={5} pb={5}>
            <Flex display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" pb={2}>
              <Icon name="chevron-left" />
              <Heading size="md" as="h2" lineHeight="shorter" fontWeight="bold" fontFamily="heading">
                Get Provisioning Template
              </Heading>
            </Flex>
            <Stack ml={4} spacing={2} shouldWrapChildren mt={4} mr={4}>
              <Text>Enter the url of a site collection, the Provisioning Engine will export a PnP Template.</Text>
              <FormControl isRequired isInvalid={!!this.errorMessage}>
                <FormLabel>Site Collection Url</FormLabel>
                <InputGroup>
                  <InputLeftAddon children={`${globalStore.tenantUrl}/sites/`} />
                  <Input
                    roundedLeft={0}
                    size="md"
                    as="input"
                    variant="outline"
                    isFullWidth
                    focusBorderColor="blue.500"
                    errorBorderColor="red.500"
                    value={this.siteCollectionUrl}
                    onChange={e => {
                      this.setSiteCollectionUrl(e.target.value);
                    }}
                  />
                </InputGroup>
                {/* <FormErrorMessage>{this.displayedError}</FormErrorMessage> */}

                <FormHelperText>{`Select the handlers to use.`}</FormHelperText>
              </FormControl>
              <CheckboxGroup
                onChange={args => {
                  this.setSelectedHandlers(args as string[]);
                }}
                isInline
                spacing={8}
                variantColor="red"
                defaultValue={defaultTrueHandlers}>
                {Object.keys(defaultHandlerValues).map(handler => {
                  return <Checkbox value={handler}>{handler}</Checkbox>;
                })}
              </CheckboxGroup>
              <Box>
                <Flex display="flex" flexDirection="row" alignItems="space-between" justifyContent="flex-end" m={0}>
                  <Button
                    // isDisabled={this.store.isGettingTemplate}
                    variant="solid"
                    onClick={async () => {
                      const templateId = await this.store?.enqueueGetTemplateRequest(
                        this.siteCollectionUrl,
                        this.selectedHandlers
                      );

                      const s = this.props.match.path.split("/");
                      s.pop();
                      const newPath = s.join("/") + "/" + templateId;
                      this.props.history.push(newPath);
                    }}>
                    Request Template
                  </Button>
                </Flex>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </div>
    );
  }
}

const ProvisioningPageWithRouter = withRouter(ProvisioningEnginePage);

class IndexPage2 extends React.Component<{ match; history }> {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.path}/`}
          component={() => {
            return (
              <Link2
                onClick={() => {
                  this.props.history.push(`${this.props.match.path}/request/create`);
                }}>
                Get a Template
              </Link2>
            );
          }}
        />
        <Route exact path={`${this.props.match.path}/request/create`} component={ProvisioningPageWithRouter} />
        <Route exact path={`${this.props.match.path}/request/:id`} component={ViewRequestPage} />
      </Switch>
    );
  }
}

export default withRouter(IndexPage2);

import React from "react";
import "./styles2.scss";
import { observer } from "mobx-react";
import { globalStore } from "../../stores/GlobalStore";

export const LoadingIndicator2 = observer(() => {
  return globalStore.fetchStore.apiCallInProgress ? (
    <div className="loading-dots">
      <div className="loading-dots--dot"></div>
      <div className="loading-dots--dot"></div>
      <div className="loading-dots--dot"></div>
    </div>
  ) : null;
});

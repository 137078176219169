import React from "react";
import { withRouter } from "../../util/router";
import { Stack, Text, Grid, Box } from "@chakra-ui/core";
import { MicrosoftTeamsService } from "../../services/MicrosoftTeamsService";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

@observer
class RequestTeamPage extends React.Component<{ match }> {
  teamsService = new MicrosoftTeamsService();

  async componentDidMount() {}
  render() {
    return <div>Request a Team</div>;
  }
}

export const RequestTeam = withRouter(RequestTeamPage);

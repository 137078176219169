import * as React from "react";
import { globalStore } from "../../stores/GlobalStore";
import { AuthenticationState } from "react-aad-msal";
import { CLIENT_ID, BACKEND_API_URL } from "../../util/Constants";
import { Section, Container } from "react-bulma-components/full";
import { authProvider } from "../../AuthProvider";
import { Text, Link, Box, Button, Flex, Stack } from "@chakra-ui/core";
import { useRouter } from "../../util/router";
declare var Office;
export function AppAuthorizeSection() {
  const router = useRouter();

  if (router.query.admin_consent && router.query.tenant) {
    sessionStorage.setItem("tenantId", router.query.tenant);
    authProvider.login({
      redirectUri: `${process.env.REACT_APP_PUBLIC_URL}/adminconsent`
    });
    return null;
  }

  // var isOutlook;
  // if (window["Office"]) {
  //   isOutlook = JSON.stringify(window["Office"]);
  // } else {
  //   isOutlook = "unknown";
  // }

  // const [message, setMessage] = React.useState("no");
  // authProvider.login();
  // React.useEffect(() => {
  //   Office.onReady(() => {
  //     if (authProvider.UserAgentApplication.isCallback(window.location.hash)) {
  //       setMessage("Window is callback (wait)");
  //       Office.context.ui.messageParent(window.location.hash);
  //     } else {
  //       if (authProvider.UserAgentApplication.getAccount()) {
  //         setMessage("logged in!");
  //       } else if (authProvider.UserAgentApplication.getLoginInProgress()) {
  //         setMessage("Logging in...");
  //       } else {
  //         alert("wtf");
  //       }
  //     }
  //     //  setMessage("Office on Ready");
  //   });

  //   setMessage("Office not ready");
  // });

  return (
    <Flex
      align="center"
      backgroundColor={"themePrimary"}
      justifyContent="center"
      style={{ height: window.innerHeight }}>
      {/*  FIXME: real centering, window height changes messes this up */}
      <Flex direction="column" align={"center"}>
        {/* is Outlook {message} */}
        <Button
          id={"adminconsentbutton"}
          _hover={{ backgroundColor: "themeDarker", color: "white" }}
          width={"180px"}
          onClick={e => {
            e.preventDefault();
            window.location.href = `https://login.microsoftonline.com/common/adminconsent?client_id=${CLIENT_ID}&redirect_uri=${process.env.REACT_APP_PUBLIC_URL}`;
          }}>
          Admin Consent
        </Button>
        <br />
        <Button
          id="signinbutton"
          _hover={{ backgroundColor: "themeDarker", color: "white" }}
          width={"180px"}
          onClick={e => {
            e.preventDefault();
            authProvider.login();
          }}>
          Sign in
        </Button>

        {/* <Button
          _hover={{ backgroundColor: "themeDarker", color: "white" }}
          width={"180px"}
          onClick={async e => {
            e.preventDefault();
            const scopes = ["User.Read"];

            (authProvider.UserAgentApplication as any).openPopup = () => {
              const dummy: any = {
                close() {},
                location: {
                  assign(url) {
                    Office.context.ui.displayDialogAsync(url, { displayInIframe: true }, res => {
                      dummy.close = res.value.close;
                      res.value.addEventHandler(
                        Office.EventType.DialogMessageReceived,
                        ({ message }) => (dummy.location.href = dummy.location.hash = message)
                      );
                    });
                  }
                }
              };
              return dummy;
            };
            await authProvider.UserAgentApplication.loginPopup({ scopes });

            //   authProvider.loginPopup();
          }}>
          Sign in Popup
        </Button> */}
        <Stack align={"center"}>
          <Text>
            <Text color={"white"}>Click the Sign In button to continue.</Text>
          </Text>
        </Stack>
      </Flex>
    </Flex>
  );
}

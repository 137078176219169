import React from "react";
import {
  Stack,
  Box,
  Flex,
  Icon,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  FormHelperText,
  Button,
  Text,
  Spinner
} from "@chakra-ui/core";
import { withRouter } from "../../util/router";
import { observable, action, computed } from "mobx";
import { AzureRMService } from "../../services/AzureRMService";
import { AzureSubscription } from "../../services/types";
import { SettingsPersistenceService } from "../../services/SettingsPersistanceService";
import { observer } from "mobx-react";
import { authProvider } from "../../AuthProvider";
import { CONTRIBUTOR_ROLETEMPLATEID } from "../../util/Constants";

@observer
class DefaultAzureSubscriptionSelect extends React.Component<{ history }> {
  azureService = new AzureRMService();
  settingsService = new SettingsPersistenceService();

  @observable isLoading: boolean = false;
  @action setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }
  @observable availableAzureSubscriptions: Array<AzureSubscription> = [];
  @action setAvailableAzureSubscriptions(subscriptions) {
    this.availableAzureSubscriptions = subscriptions;
  }

  @observable existingAzureSubscriptionId: string = "";
  @action setExistingAzureSubscriptionId(id: string) {
    this.existingAzureSubscriptionId = id;
  }

  @observable selectedAzureSubscriptionId: string = "";
  @action setSelectedAzureSubscription(id: string) {
    this.selectedAzureSubscriptionId = id;
  }

  @observable sparkAdministratorEmail: string = "";
  @action setSparkAdministratorEmail(email: string) {
    this.sparkAdministratorEmail = email;
  }

  @observable isErrored: boolean = false;
  @action setIsErrored(isErrored: boolean) {
    this.isErrored = isErrored;
  }

  @observable errorMessage: string = "";
  @action setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  @computed get isValid() {
    if (this.selectedAzureSubscriptionId) {
      return true;
    }
  }

  private async initialize() {
    this.setIsLoading(true);

    const existingSettings = await this.settingsService.getOrganizationSettingsData();
    if (existingSettings.defaultAzureSubscriptionId) {
      this.setExistingAzureSubscriptionId(existingSettings.defaultAzureSubscriptionId);
      this.setSelectedAzureSubscription(existingSettings.defaultAzureSubscriptionId);
    }
    if (existingSettings.sparkAdministratorEmail) {
      this.setSparkAdministratorEmail(existingSettings.sparkAdministratorEmail);
    } else {
      this.props.history.push("/admin/sparkadministrators");
    }
    const subscriptions = await this.azureService.getSubscriptions();
    const subsWithCleanedIds = subscriptions.map(sub => {
      sub.id = sub.id.split("/").pop() as string;
      return sub;
    });
    this.setAvailableAzureSubscriptions(subsWithCleanedIds);
    this.setIsLoading(false);
  }

  private async persistSelectedAzureSubscription() {
    const account = authProvider.getAccount();
    const tenantId = account.idToken.tid;
    await this.settingsService.persistSettingsToOrganizationExtension(tenantId, {
      defaultAzureSubscriptionId: this.selectedAzureSubscriptionId
    });
    await this.assignPermissionsForSparkAdminUser();
    await this.settingsService.persistSettingsToOrganizationExtension(tenantId, {
      sparkAdminPermissionAssigned: true
    });
    this.initialize();
  }

  private async assignPermissionsForSparkAdminUser() {
    const roles = await this.azureService.getRoleAssignmentsForSubscription(this.selectedAzureSubscriptionId);
    // const contributorRole = roles.find(r => {
    //   return r.roleTemplateId === CONTRIBUTOR_ROLETEMPLATEID;
    // });
    const user = await this.azureService.getUserByUserPrincipalName(this.sparkAdministratorEmail);
    if (!user) {
      throw new Error("Couldnt find Spark Admin user");
    }
    try {
      const assignment = await this.azureService.setRoleAssignmentForUserOnSubscription(
        user.id,
        CONTRIBUTOR_ROLETEMPLATEID,
        this.selectedAzureSubscriptionId
      );
    } catch (err) {
      this.setIsErrored(true);
      this.setErrorMessage(JSON.stringify(err));
    }
  }

  async componentDidMount() {
    await this.initialize();
  }

  render() {
    if (this.isLoading) {
      return <Spinner />;
    }
    return (
      <Stack>
        <Box backgroundColor="white" shadow="sm" borderRadius="lg" pl={3} pr={3} pt={5} pb={5}>
          <Flex display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" pb={2}>
            <Icon name="chevron-left" />
            <Heading size="md" as="h2" lineHeight="shorter" fontWeight="bold" fontFamily="heading">
              Select Azure Subscription
            </Heading>
          </Flex>
          <Stack ml={4} spacing={2} shouldWrapChildren mt={4} mr={4}>
            <Text>
              We'll use this Subscription as the default place for Spark resources to be created. The Spark
              Administrator user will receive the Contributor role.
            </Text>
            <FormControl isInvalid={this.isErrored}>
              <FormLabel>Subscription</FormLabel>
              <Select
                value={this.selectedAzureSubscriptionId}
                placeholder={"Select Subscription"}
                variant="outline"
                size="md"
                onChange={e => {
                  this.setSelectedAzureSubscription(e.target.value);
                }}>
                {this.availableAzureSubscriptions.map(subscription => {
                  return (
                    <option value={subscription.id} key={subscription.id}>
                      {subscription.displayName}
                    </option>
                  );
                })}
              </Select>
              <FormHelperText></FormHelperText>
              <FormErrorMessage>{this.errorMessage}</FormErrorMessage>
            </FormControl>
            <Box>
              <Flex display="flex" flexDirection="row" alignItems="space-between" justifyContent="flex-end" m={0}>
                <Button
                  isDisabled={!this.isValid}
                  variant="solid"
                  onClick={() => {
                    this.persistSelectedAzureSubscription();
                  }}>
                  Select Subscription
                </Button>
              </Flex>
            </Box>
          </Stack>
        </Box>
      </Stack>
    );
  }
}

export default withRouter(DefaultAzureSubscriptionSelect);

import { observable, action } from "mobx";

class UserAuthenticationStore {

    @observable isAuthenticated: boolean = false;
    @action setUserIsAuthenticated(isAuthenticated){
        this.isAuthenticated = isAuthenticated;
    }

    @observable currentTenantId: string = "";
    @action setCurrentTenantId(tenantId: string){
        this.currentTenantId = tenantId;
    }


}

export const userAuthenticationStore = new UserAuthenticationStore();
import React from "react";
import { withRouter } from "../../util/router";
import { observer } from "mobx-react";
import { SettingsPersistenceService } from "../../services/SettingsPersistanceService";
import { ProvisioningEngineStore } from "./ProvisioningEngineStore";
import { CircularProgress, Stack, Text } from "@chakra-ui/core";
import { observable, action } from "mobx";

@observer
export class ViewRequestPage extends React.Component<{ match; history }> {
  settingService = new SettingsPersistenceService();
  store: ProvisioningEngineStore | undefined;

  @observable templateString: string = "";
  @action setTemplateString(template: string) {
    this.templateString = template;
  }

  async componentDidMount() {
    const existingOrgSettings = await this.settingService.getOrganizationSettingsData();

    this.store = new ProvisioningEngineStore({
      subscriptionId: existingOrgSettings.peSubscriptionId,
      resourceGroupName: existingOrgSettings.peResourceGroupName,
      functionAppName: existingOrgSettings.peFunctionAppName
    });

    const requestId = this.props.match.params.id;

    const template = await this.store.getTemplateById(requestId);
    this.setTemplateString(template);
    this.store.initialize();
  }
  render() {
    if (!this.store) return null;

    const GettingTemplate = () => {
      return (
        <>
          <Stack>
            <Text>Your request is in progress</Text>
            <CircularProgress isIndeterminate />
          </Stack>
        </>
      );
    };

    if (this.store.isGettingTemplate) {
      return <GettingTemplate />;
    }

    return <div>{this.templateString || this.store.templateText}</div>;
  }
}

export default withRouter(ViewRequestPage);

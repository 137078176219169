import { observable, action, computed } from "mobx";
import { create, persist } from "mobx-persist";
import { createNotificationStore } from "./NotificationStore";
import { SharePointService } from "../services/SharePointService";
import { AzureRMService } from "../services/AzureRMService";
import {
  COMPANY_ADMINISTRATOR_ROLETEMPLATEID,
  SHAREPOINT_SERVICE_ADMINISTRATOR_ROLETEMPLATEID
} from "../util/Constants";
import { authProvider } from "../AuthProvider";
import { AuthenticationState } from "react-aad-msal";
import { Account } from "msal";
import { themeStore } from "./ThemeStore";
import { FetchStore } from "./FetchStore";
import { SettingsStore, settingsStore } from "./SettingsStore";
import { UserStore, userStore } from "./UserStore";
import { NotificationStore2, notificationStore } from "./NotificationStore2";
import { microsoftTeamsStore } from "./MicrosoftTeamsStore";
import { meganavStore } from "./MeganavStore";
import { SettingsPersistenceService, OrgSettings } from "../services/SettingsPersistanceService";
import { User } from "@microsoft/microsoft-graph-types";

class GlobalStore {
  fetchStore = new FetchStore();
  settingsStore = settingsStore;
  userStore = userStore;
  sharePointService = new SharePointService();
  azureService = new AzureRMService();
  settingsPersistanceService = new SettingsPersistenceService();

  notificationStore = notificationStore;
  themeStore = themeStore;
  microsoftTeamsStore = microsoftTeamsStore;
  meganavStore = meganavStore;

  @persist @observable tenantUrl: string = "";
  @action setTenantUrl(name) {
    this.tenantUrl = name;
  }

  @observable userLoginState: AuthenticationState = AuthenticationState.Unauthenticated;
  @action setUserLoginState(userLoginState: AuthenticationState) {
    this.userLoginState = userLoginState;
  }

  @persist @observable userHomeDirectoryId: string = "";
  @action setUserHomeDirectoryId(directoryId: string) {
    this.userHomeDirectoryId = directoryId;
  }

  @computed get userHomeAADAuthority(): string {
    return this.userCurrentAADDirectory ? `https://login.microsoftonline.com/${this.userHomeDirectoryId}` : "";
  }

  @persist @observable userSelectedDirectoryId: string = "";
  @action setUserSelectedDirectoryId(directoryId: string) {
    this.userSelectedDirectoryId = directoryId;
  }

  @computed get userCurrentAADAuthority(): string {
    return this.userCurrentAADDirectory ? `https://login.microsoftonline.com/${this.userCurrentAADDirectory}` : "";
  }

  @persist("list") @observable userAADDirectories: Array<{
    displayName: string;
    tenantId: string;
    domain: string;
  }> = [];
  @action setUserAADDirectories(directories: Array<{ displayName: string; tenantId: string; domain: string }>) {
    this.userAADDirectories = directories;
  }

  @persist @observable userCurrentAADDirectory: string | null = null;
  @action setUserCurrentAADDirectory(directoryId: string) {
    this.userCurrentAADDirectory = directoryId;
    this.userCurrentAADDomain = this.userAADDirectories.find(dir => dir.tenantId === directoryId)?.domain;
  }

  @persist @observable userCurrentAADDomain: string | undefined = undefined;
  @action setUserCurrentAADDomain(domain: string) {
    this.userCurrentAADDomain = domain;
  }

  @persist @observable isUserCompanyAdministrator: boolean = false;
  @action setIsUserCompanyAdministrator(isUserCompanyAdministrator: boolean) {
    this.isUserCompanyAdministrator = isUserCompanyAdministrator;
  }

  @persist @observable isUserSharePointAdministrator: boolean = false;
  @action setisUserSharePointAdministrator(isUserSharePointAdministrator: boolean) {
    this.isUserSharePointAdministrator = isUserSharePointAdministrator;
  }

  @persist @observable isExternalUser: boolean = false;
  @action setIsExternalUser(isExternalUser) {
    this.isExternalUser = isExternalUser;
  }

  @observable isInitializing: boolean = true;
  @action setIsInitializing(isInitializing) {
    this.isInitializing = isInitializing;
  }

  @persist @observable userCanAccessAppCatalog: boolean = false;
  @action setUserCanAccessAppCatalog(userCanAccessAppCatalog) {
    this.userCanAccessAppCatalog = userCanAccessAppCatalog;
  }

  @observable licenceDetails: microsoftgraph.LicenseDetails[] | null = null;
  @action setLicenseDetails(licenseDetails) {
    this.licenceDetails = licenseDetails;
  }

  @persist @observable userImage: string | null = null;
  @action setUserImage(url: string) {
    this.userImage = url;
  }

  @persist @observable setupComplete: boolean = true;
  @action setSetupComplete(complete: boolean) {
    this.setupComplete = complete;
  }

  @action async persistSettingsToExtension() {
    await this.settingsPersistanceService.persistSettingsToExtensionForMe({
      lastSeen: new Date().toISOString(),
      userCurrentAADDirectory: this.userCurrentAADDirectory as string, // TODO: null checking
      [`theme${this.userCurrentAADDirectory}`]: `${this.themeStore.selectedThemeName}`
    });
  }

  @computed get userIsInTeamsHomeTenant() {
    if (
      this.microsoftTeamsStore.isInMicrosoftTeams &&
      this.microsoftTeamsStore.teamsContext?.tid === this.userHomeDirectoryId
    ) {
      return true;
    } else {
      return false;
    }
  }

  @action async initialize(account: Account) {
    try {
      this.setIsInitializing(true);

      // TODO - can we batch these?
      const existingSettings = await this.settingsPersistanceService.getOrEnsureExtensionCreatedForMe();
      const existingOrgSettings = await this.settingsPersistanceService.getOrganizationSettingsData();

      if (existingSettings.themeName) {
        this.themeStore.setSelectedThemeByName(existingSettings.themeName);
      }
      if (existingSettings.userCurrentAADDirectory) {
        this.setUserCurrentAADDirectory(existingSettings.userCurrentAADDirectory);
      }
      if (this.userSelectedDirectoryId) {
        this.setUserCurrentAADDirectory(this.userSelectedDirectoryId);
      } else {
        this.setUserCurrentAADDirectory(this.userHomeDirectoryId);
      }

      // check if all the setup steps are complete
      if (
        !existingOrgSettings.sparkAdministratorEmail ||
        !existingOrgSettings.defaultAzureSubscriptionId ||
        !existingOrgSettings.sparkAppAppId ||
        !existingOrgSettings.sparkAppIdentifierUri
      ) {
        console.log("SETUP IS NOT COMPLETE", existingOrgSettings);
        this.setSetupComplete(false);
      }

      this.userStore.setUserDisplayName(account.name);
      const spRootUrl = (await this.sharePointService.getSharePointRootUrl()).webUrl;
      if (this.settingsStore.enableMeganav) {
        this.meganavStore.initialize(spRootUrl);
      }
      this.setTenantUrl(spRootUrl);
      const tenants = await this.azureService.getTenants();
      const directories = tenants.value.map(tenant => {
        return { displayName: tenant.displayName, tenantId: tenant.tenantId, domain: tenant.domains[0] };
      });

      this.setUserAADDirectories(directories);

      const user = await this.azureService.getMe();
      const userLicenses = await this.azureService.getMyLicenses();
      this.setLicenseDetails(userLicenses);

      await this.determineUserRoles(user);

      await this.determineIfUserCanAccessAppCatalog(spRootUrl);

      await this.loadThemes(spRootUrl);

      const userImageUrl = await this.azureService.getMyPhoto();
      this.setUserImage(userImageUrl);
      console.log(authProvider.getAccount());

      this.setIsInitializing(false);
    } catch (err) {
      console.log(err);
      debugger;
    }
  }

  private async determineUserRoles(user: User) {
    if (!user.id || !user.userPrincipalName) {
      throw new Error("This shouldnt be undefined");
    }

    if (user.userPrincipalName.indexOf("#EXT") !== -1) {
      this.setIsExternalUser(true);
    } else {
      this.setIsExternalUser(false);
    }
    const userRoles = await this.azureService.getUserDirectoryRolesTransitive(user.id);

    const companyAdministrator = userRoles.find(ur => ur.roleTemplateId === COMPANY_ADMINISTRATOR_ROLETEMPLATEID);
    if (companyAdministrator) {
      this.setIsUserCompanyAdministrator(true);
    } else {
      this.setIsUserCompanyAdministrator(false);
    }

    const sharePointAdministrator = userRoles.find(
      ur => ur.roleTemplateId === SHAREPOINT_SERVICE_ADMINISTRATOR_ROLETEMPLATEID
    );
    if (sharePointAdministrator) {
      this.setisUserSharePointAdministrator(true);
    } else {
      this.setisUserSharePointAdministrator(false);
    }
  }

  private async determineIfUserCanAccessAppCatalog(spRootUrl: string) {
    try {
      const context = await this.sharePointService.getContextInfo(spRootUrl, spRootUrl);
      const appCatalog = await this.sharePointService.getSharePointAppCatalogUrl(spRootUrl, context.FormDigestValue);
      this.setUserCanAccessAppCatalog(true);
    } catch (err) {
      console.log("USER CANT ACCESS APP CATALOG");
      this.setUserCanAccessAppCatalog(false);
    }
  }

  private async loadThemes(spRootUrl: string) {
    const themes = await this.sharePointService.getTenantThemes(spRootUrl);
    this.themeStore.setTenantThemes(themes);
  }
}

export const globalStore = new GlobalStore();

const hydrate = create({
  storage: window.localStorage, // or AsyncStorage in react-native.
  // default: localStorage
  jsonify: true // if you use AsyncStorage, here shoud be true
  // default: true
});

hydrate("SparkGlobalStore", globalStore).then(() => {
  console.log("SparkGlobalStore has been hydrated");
});

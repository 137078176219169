import { MsalAuthProvider, LoginType, AuthenticationState } from "react-aad-msal";
import * as Msal from "msal";
import { globalStore } from "./stores/GlobalStore";
import { getGUID } from "@pnp/common";
import { Client, AuthenticationProvider } from "@microsoft/microsoft-graph-client";
import { UserAgentApplication } from "msal";
import { ImplicitMSALAuthenticationProvider } from "@microsoft/microsoft-graph-client/lib/src/ImplicitMSALAuthenticationProvider";
import { MSALAuthenticationProviderOptions } from "@microsoft/microsoft-graph-client/lib/src/MSALAuthenticationProviderOptions";
import { userAuthenticationStore } from "./stores/UserAuthenticationStore";
import { CLIENT_ID } from "./util/Constants";
import { Notification } from "./stores/NotificationStore";

const rootUrl = process.env.NODE_ENV !== "production" ? "" : ""; //"/installer/index.html";

// const CLIENT_ID = "754ad215-b82e-40e3-aa11-ac6b7d84b8d0" // "67285b34-98e5-4436-b558-c0aa06b42b63" //   //
function loggerCallback(logLevel, message, containsPii) {
  // console.log(message);
}

const config: Msal.Configuration = {
  auth: {
    authority: "https://login.microsoftonline.com/common",
    // authority: "https://login.microsoftonline.com/2c70ddde-a0db-42b5-98ef-0ee5d08465b1", // "https://login.microsoftonline.com/common",
    clientId: CLIENT_ID,
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin + rootUrl,
    validateAuthority: true,
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  },
  system: {
    logger: new Msal.Logger(loggerCallback, {
      level: Msal.LogLevel.Verbose,
      piiLoggingEnabled: false,
      correlationId: "1234"
    }),
    //   loadFrameTimeout: 90000
  },
  // broker: {
  //   topFrameOrigin: "https://teams.microsoft.com",
  //   embeddedFrameOrigin: "http://sparkintranet.com"
  // }
};

// Authentication Parameters
const authenticationParameters = {
  scopes: [
    "openid",
    // `api://sparkintranet.com/754ad215-b82e-40e3-aa11-ac6b7d84b8d0/access_as_user`,
    //  "api://b693d174-a499-4fe8-863f-0a3d2600b8b7/Spark.Read"
    //   "https://api.powerapps.com/user"
    "https://graph.microsoft.com/User.Read",
    "https://graph.microsoft.com/Directory.Read.All"
  ]
};

const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + "/auth.html"
};

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);
authProvider.registerAuthenticationStateHandler((state: AuthenticationState) => {
  console.log("AUTHENTICATION STATE HANDLER", state);
  globalStore.setUserLoginState(state);
  const instance = authProvider.getAuthorityInstance();
  userAuthenticationStore.setCurrentTenantId(instance.Tenant);
  if (state === AuthenticationState.Authenticated) {
    const environment = authProvider.getAccountInfo()?.account.environment.replace("/v2.0", "");
    const oldOptions = authProvider.getAuthenticationParameters();

    const newOptions = Object.assign({}, oldOptions, {
      authority: environment
    });
    authProvider.setAuthenticationParameters(newOptions);
    console.log("SET AUTHPROVIDER TO", newOptions.authority);
    const tid = authProvider.getAccountInfo()?.account.idToken?.tid;
    console.log("TID", tid);
    //  globalStore.setUserCurrentAADDirectory(tid || "");
    globalStore.setUserHomeDirectoryId(tid || "");
    globalStore.initialize(authProvider.getAccount());
  }
});

authProvider.registerErrorHandler(error => {
  if (error) {
    const errorNotification: Notification = {
      id: getGUID(),
      title: "Authentication Error",
      text: error.errorMessage,
      isDismissed: false,
      notificationType: "error"
    };
    globalStore.notificationStore.createNotification(errorNotification);
  }
});

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/core";
import { action, computed, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";

import { authProvider } from "../../AuthProvider";
import { AzureRMService } from "../../services/AzureRMService";
import { SettingsPersistenceService } from "../../services/SettingsPersistanceService";
import { getProvisioningAzureAdAppSetup } from "../../util/ProvisioningEngineSetupUtility";
import { withRouter } from "../../util/router";
import { SparkMenuPage } from "../../components/SparkMenu";

@observer
class SparkAzureADApp extends React.Component<{ history; match }> {
  azureService = new AzureRMService();
  settingsService = new SettingsPersistenceService();

  @observable isLoading: boolean = false;
  @action setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @observable sparkAdministratorEmail: string = "";
  @action setSparkAdministratorEmail(email: string) {
    this.sparkAdministratorEmail = email;
  }

  @observable existingSparkAppId: string = "";
  @action setExistingSparkAppId(id: string) {
    this.existingSparkAppId = id;
  }

  @observable appNameInput: string = "";
  @action setAppNameInput(name: string) {
    this.appNameInput = name;
  }

  @observable isCreatingApp: boolean = false;
  @action setIsCreatingApp(isCreating: boolean) {
    this.isCreatingApp = isCreating;
  }

  // TODO: type this?
  @observable createdApp;
  @action setCreatedApp(app) {
    this.createdApp = app;
  }

  @observable errorMessage: string = "";
  @action setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  @computed get displayedError() {
    return `${this.errorMessage}. Unable to create Azure AD App.`;
  }

  @computed get alreadyHaveAppMessage() {
    if (!this.createdApp) {
      return "";
    }
    return `Authorize the ${this.createdApp.displayName} app's permissions. You may also delete the app to remove it from Azure AD.`;
  }

  @observable deleteModalIsOpen: boolean = false;
  @action setDeleteModalIsOpen(isOpen: boolean) {
    this.deleteModalIsOpen = isOpen;
  }

  @observable appNeedsAuthorization: boolean = false;
  @action setAppNeedsAuthorization(needsAuth: boolean) {
    this.appNeedsAuthorization = needsAuth;
  }

  private async deleteModalOnClose() {
    this.setDeleteModalIsOpen(false);
  }

  private async createSparkAzureADApp() {
    this.setIsCreatingApp(true);
    try {
      // TODO: let users add tags?
      const appBody = getProvisioningAzureAdAppSetup(
        this.appNameInput,
        ["Spark"],
        [window.location.origin + "/admin/sparkazureadapp"]
      );
      const app = await this.azureService.createProvisioningEngineAzureADApp(appBody);
      this.setCreatedApp(app);
      this.setIsCreatingApp(false);

      await this.persistAzureADAppToSettings(false);
      this.setAppNeedsAuthorization(true);
      this.initialize();
    } catch (err) {
      this.setErrorMessage(err.toString());
    }
  }

  private async deleteSparkAzureADApp() {
    this.setIsCreatingApp(true);

    await this.azureService.deleteAzureAdApp(this.existingSparkAppId);

    await this.deleteAzureADAppFromSettings();
    this.setIsCreatingApp(false);
    this.initialize();
    this.props.history.push("/admin/sparkazureadapp");
  }

  private async openConfirmModal() {
    this.setDeleteModalIsOpen(true);
  }

  private async confirmModalDelete() {
    this.setDeleteModalIsOpen(false);
    await this.deleteSparkAzureADApp();
  }

  private async initialize() {
    const params = new URLSearchParams(window.location.search);
    const admin_consent = params.get("admin_consent");
    const tenant = params.get("tenant");

    this.setIsLoading(true);

    const existingSettings = await this.settingsService.getOrganizationSettingsData();

    if (existingSettings.sparkAdministratorEmail) {
      this.setSparkAdministratorEmail(existingSettings.sparkAdministratorEmail);
    } else {
      this.props.history.push("/admin/sparkadministrators");
    }

    if (existingSettings.sparkAppObjectId) {
      this.setExistingSparkAppId(existingSettings.sparkAppObjectId);
      const existingApp = await this.azureService.getAzureADAppByID(existingSettings.sparkAppObjectId);
      this.setCreatedApp(existingApp);
      if (!existingSettings.sparkProvisioningAppIsAuthorized) {
        this.setAppNeedsAuthorization(true);
      } else {
        this.setAppNeedsAuthorization(false);
      }

      if (admin_consent && tenant) {
        await this.persistAzureADAppToSettings(true);
        this.setAppNeedsAuthorization(false);
      }
    } else {
      this.setCreatedApp(null);
      this.setExistingSparkAppId("");
    }

    this.setIsLoading(false);
  }

  private async persistAzureADAppToSettings(isAuthorized: boolean) {
    const account = authProvider.getAccount();
    const tenantId = account.idToken.tid;
    await this.settingsService.persistSettingsToOrganizationExtension(tenantId, {
      sparkAppObjectId: this.createdApp.id,
      sparkAppAppId: this.createdApp.appId,
      sparkAppIdentifierUri: this.createdApp.identifierUris[0],
      sparkProvisioningAppIsAuthorized: isAuthorized,
    });
  }

  private async deleteAzureADAppFromSettings() {
    const account = authProvider.getAccount();
    const tenantId = account.idToken.tid;
    await this.settingsService.persistSettingsToOrganizationExtension(tenantId, {
      sparkAppObjectId: "",
      sparkAppAppId: "",
      sparkProvisioningAppIsAuthorized: "",
      sparkAppIdentifierUri: "",
    });
  }

  async componentDidMount() {
    await this.initialize();
  }

  render() {
    if (this.isLoading) {
      return <Spinner />;
    }

    if (this.existingSparkAppId) {
      return (
        <Box backgroundColor="white" shadow="sm" borderRadius="lg" pl={3} pr={3} pt={5} pb={5}>
          <Flex display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" pb={2}>
            <Icon name="chevron-left" />
            <Heading size="md" as="h2" lineHeight="shorter" fontWeight="bold" fontFamily="heading">
              Setup Spark Azure AD App
            </Heading>
          </Flex>
          <Stack ml={4} spacing={2} shouldWrapChildren mt={4} mr={4}>
            <Text>{this.alreadyHaveAppMessage}</Text>
            <Link
              _hover={{ color: "themeDarker" }}
              color={"themePrimary"}
              textDecoration={"underline"}
              target={"_blank"}
              href={`https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Overview/appId/${this.createdApp.appId}`}>
              View in Azure Portal
            </Link>
            <br />
            {this.appNeedsAuthorization && (
              <Text color={"themePrimary"}>
                You need to provide admin consent for the app. Click the Authorize App button to be redirected to Azure
                AD.
              </Text>
            )}

            <Box>
              <Flex display="flex" flexDirection="row" alignItems="space-between" justifyContent="flex-end" m={0}>
                {/* TODO - it takes a few seconds for the app to show up in Azure, we need to wait a few seconds */}
                <Button
                  marginRight={"10px"}
                  isDisabled={this.isCreatingApp}
                  variant="solid"
                  onClick={() => {
                    window.location.href = `https://login.microsoftonline.com/common/adminconsent?client_id=${this.createdApp.appId}&state=12345&redirect_uri=${window.location.origin}/admin/sparkazureadapp`;
                  }}>
                  Authorize App
                </Button>
                <Button
                  isDisabled={this.isCreatingApp}
                  variant="solid"
                  variantColor="red"
                  onClick={() => {
                    this.openConfirmModal();
                  }}>
                  Delete App
                </Button>
              </Flex>
            </Box>
          </Stack>

          <Modal
            isOpen={this.deleteModalIsOpen}
            onClose={() => {
              this.deleteModalOnClose();
            }}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Remove Azure AD App?</ModalHeader>
              <ModalCloseButton />
              <ModalBody>This will remove the app from Azure AD and reset the setting for your tenant.</ModalBody>

              <ModalFooter>
                <Button variant="solid" variantColor={"red"} onClick={() => this.confirmModalDelete()}>
                  Remove
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      );
    }
    return (
      <Stack>
        <Box backgroundColor="white" shadow="sm" borderRadius="lg" pl={3} pr={3} pt={5} pb={5}>
          <Flex display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" pb={2}>
            <Icon name="chevron-left" />
            <Heading size="md" as="h2" lineHeight="shorter" fontWeight="bold" fontFamily="heading">
              Setup Spark Azure AD App
            </Heading>
          </Flex>
          <Stack ml={4} spacing={2} shouldWrapChildren mt={4} mr={4}>
            <Text>
              We'll create a dedicated Azure AD App for Spark. This app will be used by the provisioning engine to
              connect to SharePoint.
            </Text>
            <FormControl isRequired isInvalid={!!this.errorMessage}>
              <FormLabel>App Name</FormLabel>
              <Input
                size="md"
                as="input"
                variant="outline"
                isFullWidth
                focusBorderColor="blue.500"
                errorBorderColor="red.500"
                value={this.appNameInput}
                onChange={(e) => {
                  this.setAppNameInput(e.target.value);
                }}
              />
              <FormErrorMessage>{this.displayedError}</FormErrorMessage>

              <FormHelperText>{`Name it "Spark Provisioning", or whatever you'd like.`}</FormHelperText>
            </FormControl>
            <Box>
              <Flex display="flex" flexDirection="row" alignItems="space-between" justifyContent="flex-end" m={0}>
                <Button
                  isDisabled={this.isCreatingApp}
                  variant="solid"
                  onClick={() => {
                    this.createSparkAzureADApp();
                  }}>
                  Create App
                </Button>
              </Flex>
            </Box>
          </Stack>
        </Box>
      </Stack>
    );
  }
}

export default withRouter(SparkAzureADApp);

import fetchIntercept from "fetch-intercept";
import { globalStore } from "../stores/GlobalStore";
import { toJS } from "mobx";

export const registerFetchInterceptor = () =>
  fetchIntercept.register({
    request: function (url, config) {
      // Modify the url or config here

      //  console.log(url, config);

      const controller = new AbortController();
      const { signal } = controller;

      const fetchParams = {
        ...config,
        signal // extend provided params with AbortController signal
      };
      globalStore.fetchStore.addFetchRequestToStack(url, controller);
      globalStore.fetchStore.setApiCallInProgress(true);
      //   console.log("FETCHS", toJS(globalStore.fetchRequests));
      return [url, fetchParams];
    },

    requestError: function (error) {
      globalStore.fetchStore.setApiCallInProgress(false);
      globalStore.fetchStore.addErrorToStack(error);
      console.log("REQUEST ERROR");
      // Called when an error occured during another 'request' interceptor call
      return Promise.reject(error);
    },

    response: function (response) {
      // Modify the reponse object
      globalStore.fetchStore.setApiCallInProgress(false);
      globalStore.fetchStore.popRequestStack();
      return response;
    },

    responseError: function (error) {
      // Handle a fetch error
      console.log("RESPONSE ERROR");
      globalStore.fetchStore.setApiCallInProgress(false);
      globalStore.fetchStore.addErrorToStack(error);
      globalStore.fetchStore.popRequestStack();
      return Promise.reject(error);
    }
  });

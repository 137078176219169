import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Notification, Button } from "react-bulma-components/full";
import { useNotificationStore } from "../../stores/NotificationStore";
import {
  useToast,
  Box,
  useToastOptions,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton
} from "@chakra-ui/core";
import { autorun } from "mobx";
import { globalStore } from "../../stores/GlobalStore";

export const Notifications = observer(props => {
  const notificationStore = globalStore.notificationStore;
  window["___notif"] = notificationStore;

  return (
    <div>
      {notificationStore.notDismissedNotifications.map(n => {
        return (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>{n.title}</AlertTitle>
            <AlertDescription>{n.text}</AlertDescription>
            <CloseButton
              onClick={no => notificationStore.dismissNotification(n.id)}
              position="absolute"
              right="8px"
              top="8px"
            />
          </Alert>
        );
      })}
    </div>
  );
});

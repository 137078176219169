import React from "react";
import { withRouter } from "../../util/router";
import {
  Stack,
  Text,
  Grid,
  Box,
  Button,
  InputGroup,
  InputLeftAddon,
  Input,
  InputRightElement,
  Icon
} from "@chakra-ui/core";
import { MicrosoftTeamsService } from "../../services/MicrosoftTeamsService";
import { observable, action, toJS, computed } from "mobx";
import { observer } from "mobx-react";
import { CenteredLayout } from "../../components/Layouts/CenteredLayout";
import { PageIteratorCallback } from "@microsoft/microsoft-graph-client";
import { AzureRMService } from "../../services/AzureRMService";

@observer
class MyTeamsPage extends React.Component<{ history }> {
  teamsService = new MicrosoftTeamsService();
  azureService = new AzureRMService();

  @observable myTeams: Array<microsoftgraph.Team> = [];
  @action setMyTeams(teams) {
    this.myTeams = teams;
  }

  @observable allTeams: Array<microsoftgraph.Team> = [];
  @action setAllTeams(teams) {
    this.allTeams = teams;
  }

  @action addToAllTeams(teams) {
    this.allTeams = this.allTeams.concat(teams);
  }

  @computed get myTeamsCount() {
    return this.myTeams.length;
  }

  @computed get allTeamsCount() {
    return this.allTeams.length;
  }

  @observable searchInput: string = "";
  @action setSearchInput(input: string) {
    this.searchInput = input;
  }
  @action async executeSearch() {
    let size = 1000;
    let count = 0;
    let results: Array<any> = [];
    // A callback function to be called for every item in the collection. This call back should return boolean indicating whether not to continue the iteration process.
    let callback: PageIteratorCallback = data => {
      //    this.addToAllTeams(data);
      count++;
      results.push(data);
      // if (count >= 1) {
      //   this.addToAllTeams(results);
      //   results = [];
      //   count = 0;
      // }
      return true;
    };

    const pageIterator = await this.teamsService.getTeamsStartWith(this.searchInput, callback);
    const r = await pageIterator.iterate();
    console.log("ITERATOR", r);
    this.addToAllTeams(results);
  }

  async componentDidMount() {
    const myTeams = await this.teamsService.getMyJoinedTeams();
    this.setMyTeams(myTeams);

    // const allTeams = await this.teamsService.getAllTeamsPaged(team => {
    //   this.addToAllTeams(team);
    // });

    //this.setAllTeams(allTeams);
  }
  render() {
    return (
      <CenteredLayout>
        <Text>All Teams Count: {this.allTeamsCount}</Text>
        <Text>My Teams Count: {this.myTeamsCount}</Text>

        <Stack shouldWrapChildren spacing={5} p={5}>
          <Box>
            <InputGroup mb={3}>
              <InputLeftAddon>Search for a team</InputLeftAddon>
              <Input
                size="md"
                as="input"
                variant="outline"
                isFullWidth
                focusBorderColor="blue.500"
                errorBorderColor="red.500"
                onChange={(e: any) => {
                  this.setSearchInput(e.target.value);
                }}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    this.executeSearch();
                  }
                }}
              />
              <InputRightElement>
                <Icon name="search" />
              </InputRightElement>
            </InputGroup>
          </Box>
        </Stack>

        <Grid templateColumns="repeat(5, 1fr)" gap={6}>
          <Box>
            {this.myTeams.map(team => {
              return (
                <Stack isInline>
                  <Text
                    onClick={() => {
                      this.props.history.push(team.id);
                    }}>
                    {(team as any).displayName}
                  </Text>
                  <Button
                    onClick={async () => {
                      await this.teamsService.deleteTeam(team.id as string);
                    }}>
                    Delete
                  </Button>

                  <Button
                    onClick={async () => {
                      await this.azureService.addGraphExtensionToGroup(team.id as string, "Spark", {
                        test: "extension"
                      });
                    }}>
                    Custom Data!
                  </Button>
                </Stack>
              );
            })}
          </Box>
          <Box>
            {this.allTeams.map(team => {
              return (
                <Stack isInline>
                  <Text
                    onClick={() => {
                      this.props.history.push(team.id);
                    }}>
                    {(team as any).displayName}
                  </Text>
                  <Button
                    onClick={async () => {
                      await this.teamsService.deleteTeam(team.id as string);
                    }}>
                    Delete
                  </Button>
                </Stack>
              );
            })}
          </Box>
        </Grid>
      </CenteredLayout>
    );
  }
}

export const MyTeams = withRouter(MyTeamsPage);

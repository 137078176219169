import React from "react";
import { useLocalStore, observer } from "mobx-react-lite";
import { authProvider } from "../AuthProvider";

export function createAuthenticationStore() {
  // note the use of this which refers to observable instance of the store
  return {
    login() {
      return authProvider.login();
    },

    logout() {
      // localStorage.removeItem("SparkGlobalStore");
      // localStorage.removeItem("SettingsStore");
      // localStorage.removeItem("ThemeStore");

      return authProvider.logout();
    },

    get authenticationState() {
      return authProvider.authenticationState;
    },
    get accountInfo() {
      return authProvider.getAccountInfo();
    }
  };
}

export type TStore = ReturnType<typeof createAuthenticationStore>;

const storeContext = React.createContext<TStore | null>(null);

export const AuthenticationStoreProvider = ({ children }) => {
  const store = useLocalStore(createAuthenticationStore);

  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useAuthenticationStore = () => {
  const store = React.useContext(storeContext);
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error("useAuthenticationStore must be used within a StoreProvider.");
  }
  return store;
};

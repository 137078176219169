import { action, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
// import { Button, Loader, Table } from "react-bulma-components/full";

import { SparkMenuPage } from "../../components/SparkMenu";
import { AzureRMService } from "../../services/AzureRMService";
import { Link, Route, Switch, withRouter } from "../../util/router";

import {
  Spinner,
  Stack,
  Box,
  Text,
  Button,
  Tooltip,
  Link as Link2,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  InputGroup,
  InputLeftAddon,
  Input,
  InputRightElement,
  Icon,
  Flex,
  Avatar,
  AvatarBadge
} from "@chakra-ui/core";
import { UserPhotoEditor } from "../../components/UserPhotoEditor/UserPhotoEditor";
import { JOUMANA } from "../../util/Constants";
import { UserSearchBox } from "../../components/UserSearchBox/UserSearchBox";
import { CenteredLayout } from "../../components/Layouts/CenteredLayout";
import { UserSearch } from "../../components/UserSearchBox/UserSearch";
@observer
class AwardBadge extends React.Component<{ match; history }> {
  azureService = new AzureRMService();

  render() {
    return (
      <CenteredLayout>
        <Text fontSize={18} fontWeight={"semibold"}>
          Search for a user to award a badge to:{" "}
        </Text>
        <UserSearch onSelectUser={user => this.props.history.push(`/badging/award/${user.id}`)} />
      </CenteredLayout>
    );
  }
}

export default withRouter(AwardBadge);

import { action, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";

import { SparkMenuPage } from "../../components/SparkMenu";
import { AzureRMService } from "../../services/AzureRMService";
import { Link, Route, Switch, withRouter } from "../../util/router";
import { TeamsTaskPage } from "./TeamsTaskPage";
import { MyTeams } from "./MyTeams";
import { Team } from "./Team";
import { Channel } from "./Channel";
import { Button } from "@chakra-ui/core";
import { RequestTeam } from "./RequestTeam";
import { MyConversations } from "./MyConversations";

class TeamsRoot extends React.Component<{ history }> {
  render() {
    return (
      <div>
        <Button
          onClick={() => {
            this.props.history.push("/teams/myteams");
          }}>
          My Teams
        </Button>
        <Button
          onClick={() => {
            this.props.history.push("/teams/request");
          }}>
          Request a Team
        </Button>
        <Button
          onClick={() => {
            this.props.history.push("/teams/myconversations");
          }}>
          My Conversations
        </Button>
      </div>
    );
  }
}

const TeamsRootWithRouter = withRouter(TeamsRoot);

@observer
class TeamsPage extends React.Component<{ match; history }> {
  azureService = new AzureRMService();

  @observable isLoading: boolean = false;
  @action setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }
  @observable subscriptions: Array<{ subscriptionId: string; displayName: string }> = [];
  @action setSubscriptions(subscriptions) {
    this.subscriptions = subscriptions;
  }

  async componentDidMount() {
    this.setIsLoading(true);

    const subscriptions = await this.azureService.getSubscriptions();
    this.setSubscriptions(subscriptions);
    this.setIsLoading(false);
  }
  render() {
    return (
      <>
        <Switch>
          <Route exact path={`${this.props.match.path}/`} component={TeamsRootWithRouter} />
          <Route exact path={`${this.props.match.path}/request`} component={RequestTeam} />

          <Route exact path={`${this.props.match.path}/myteams`} component={MyTeams} />
          <Route path={`${this.props.match.path}/myconversations`} component={MyConversations} />
          {/* <Route exact path={`${this.props.match.path}/:id`} component={Team} />
          <Route exact path={`${this.props.match.path}/:id/:channelId`} component={Channel} /> */}

          <Route
            exact
            path={`${this.props.match.path}/complete`}
            component={() => {
              return <div>You have completed your task.</div>;
            }}
          />
          {/* <Route exact path={`${this.props.match.path}/:id`} component={TeamsTaskPage} /> */}
        </Switch>
      </>
    );
  }
}

export default withRouter(TeamsPage);

import React from "react";

import { useRouter, Switch, Route, withRouter } from "./../../util/router";
import { observer } from "mobx-react";
import { globalStore } from "../../stores/GlobalStore";
import { AzureRMService } from "../../services/AzureRMService";
import { observable, action, toJS } from "mobx";
import { Spinner } from "@chakra-ui/core";
import ProfilePage from "../profile";

@observer
class PeoplePage extends React.Component<{ match }> {
  render() {
    return (
      <Switch>
        <Route exact path={`${this.props.match.path}/`} component={() => <div>Hello</div>} />

        <Route exact path={`${this.props.match.path}/:id`} component={ProfilePage} />
      </Switch>
    );
  }
}

export default withRouter(PeoplePage);

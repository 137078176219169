
declare var fabric;

export class CircleUtility {
    static createFabricSemiCircle(percentComplete: number, strokeColor: string) {
        var strokeWidth = 30;
        var radius = (80 + strokeWidth) / 2;
        var circle = new fabric.Circle({
            radius: radius,
            left: (radius + strokeWidth / 8) * 2,
            top: (radius + strokeWidth / 8) * 2,
            angle: 180,
            startAngle: 0,
            endAngle: percentComplete * (360 / 100) * (Math.PI / 180),
            stroke: strokeColor,
            strokeWidth: strokeWidth,
            fill: ""
        });

        return circle;
    }
}
import { persist, create } from "mobx-persist";

import { observable, action, computed } from "mobx";
import { SharePointService } from "../services/SharePointService";
import TaxonomyAPI, { ITermLite } from "../services/TaxonomyService";

export class MeganavStore {

    sharePointService = new SharePointService();

    @observable items: Array<any> = [];
    @action setItems(items: Array<any>) {
        this.items = items;
    }

    @observable secondLevelOpen: boolean = false;
    @action setSecondLevelOpen(isOpen: boolean) {
        this.secondLevelOpen = isOpen;
    }

    @observable secondLevelItems: Array<any> = [];
    @action setSecondLevelItems(items: Array<any>) {
        this.secondLevelItems = items;
    }

    @action async initialize(rootSpUrl: string) {
        const data = await TaxonomyAPI.getTermSetAsTree(rootSpUrl, "f161fd31-f1c8-46f5-b892-7b9060f4462c");
        console.log("TERMS", data)

        const transformedData = transformToMeganav(data);
        this.setItems(transformedData);
    }
}

function transformToMeganav(data: ITermLite) {
    let topLevelLinks: Array<any> = [];
    (data as any).children.forEach((child: any) => {
        var newLinkObject: any = {};
        newLinkObject.heading = child.title;
        newLinkObject.guid = child.guid;
        newLinkObject.url = child.customProperties.SiteUrl || child.localProperties.Url || null;

        if ((child as any).children.length > 0) {
            newLinkObject.subLinks = [];
            (child as any).children.map(secondLevelChildren => {
                var subLinkObject = {
                    heading: secondLevelChildren.title,
                    url: secondLevelChildren.customProperties.SiteUrl || secondLevelChildren.localProperties.Url || null,
                    links: [] as Array<any>
                };

                if (secondLevelChildren.children.length > 0) {
                    secondLevelChildren.children.map(thirdChildren => {
                        subLinkObject.links.push({
                            url: thirdChildren.customProperties.SiteUrl || thirdChildren.localProperties.Url || null,
                            text: thirdChildren.title
                        });
                    });
                    //   newLinkObject.subLinks.push(subLinkObject);
                }
                // we could also add here which would show the headers even if they have no children
                newLinkObject.subLinks.push(subLinkObject);
            });
        }
        topLevelLinks.push(newLinkObject);
    });
    return topLevelLinks;
}

export const meganavStore = new MeganavStore();

// const hydrate = create({
//     storage: window.localStorage, // or AsyncStorage in react-native.
//     // default: localStorage
//     jsonify: true // if you use AsyncStorage, here shoud be true
//     // default: true
// });

// hydrate("MeganavStore", meganavStore).then(() => {
//     console.log("MeganavStore has been hydrated");
// });

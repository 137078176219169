import React, { useState, useEffect, useContext, createContext } from "react";
import queryString from "query-string";
import fakeAuth from "fake-auth";
import { authProvider } from "../AuthProvider";

const authContext = createContext({ user: null });

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... update when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);

  const signin = (email, password) => {
    return authProvider.login().then(() => {
      //    setUser({ user: "twhite"});
    });
    // return fakeAuth.signin(email, password).then(user => {
    //   setUser(user);
    //   return user;
    // });
  };

  const signup = (email, password) => {
    return fakeAuth.signup(email, password).then(user => {
      setUser(user);
      return user;
    });
  };

  const signout = () => {
    return authProvider.logout();
  };

  const sendPasswordResetEmail = email => {
    return fakeAuth.sendPasswordResetEmail(email);
  };

  const confirmPasswordReset = (password, code) => {
    // If no reset code passed in then fetch it automatically from current url.
    // [CHANGING AUTH SERVICES]: If not passing in the code as the second ...
    // ... arg above then make sure getFromQueryString() below has the ...
    // ... correct url parameter name (it might not be "code").
    const resetCode = code || getFromQueryString("code");
    return fakeAuth.confirmPasswordReset(password, resetCode);
  };

  // Subscribe to user on mount
  // [CHANGING AUTH SERVICES]: Not all auth services have a subscription ...
  // ... function so depending on your service you may need to remove  ...
  // ... this effect and use the commented out one below.
  useEffect(() => {
    const unsubscribe = fakeAuth.onChange(user => {
      setUser(user);
    });

    // Call unsubscribe on cleanup
    return () => unsubscribe();
  }, []);

  // Fetch user on mount
  // [CHANGING AUTH SERVICES]: If your auth service doesn't have a subscribe ...
  // ... function then use this effect instead of the one above and modify ...
  // ... to work with your chosen auth service.
  /*
    useEffect(() => {
      yourAuthService.getUser().then(user => {
        setUser(user);
      });
    }, []);
    */

  return {
    user,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset
  };
}

const getFromQueryString = key => {
  return queryString.parse(window.location.search)[key];
};

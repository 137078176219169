import { observer } from "mobx-react";
import { AzureRMService } from "../../services/AzureRMService";
import React from "react";
import { withRouter } from "../../util/router";
import { CenteredLayout } from "../../components/Layouts/CenteredLayout";
import { BadgeCreator } from "../../components/BadgeCreator/BadgeCreator";
import { BADGING_USER_IMAGE_LIBRARY, MUSTACHE, CROWN_SVG, RIGHTPOINT } from "../../util/Constants";
import { observable, action } from "mobx";
import {
  Text,
  Stack,
  Grid,
  Box,
  Flex,
  Heading,
  Tag,
  Icon,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionIcon,
  AccordionPanel,
  Image,
  Button
} from "@chakra-ui/core";
import { CanvasUserImage } from "../../components/BadgeCreator/CanvasUserImage";
import { DelveImage } from "../../components/UserPhotoEditor/DelveImage";

@observer
class CreateBadge extends React.Component<{ match; history }> {
  azureService = new AzureRMService();

  @observable userPreviewPhotos: Array<{ id: string; url: string }> = [];
  @action addUserPreviewPhoto(photo: { id: string; url: string }) {
    this.userPreviewPhotos.push(photo);
  }

  @observable previewImageData;
  @action updatePreviewImageData(data) {
    this.previewImageData = data;
  }

  @observable underneathUserPhoto: string = "";
  @action async setUnderneathUserPhoto(photo: string) {
    this.underneathUserPhoto = photo;
  }

  private async saveBadgeToLibrary(badgeName: string, badgeJson) {
    // TODO: validate input
    // TODO: don't overwrite with same name

    await this.azureService.saveFileInMyDrive(
      `${BADGING_USER_IMAGE_LIBRARY}/Badges`,
      `${badgeName}.json`,
      "application/json",
      badgeJson
    );
  }

  private async updatePhotoPreviews(updatedBadge) {
    this.userPreviewPhotos.map(photo => {
      this.addUserPreviewPhoto(updatedBadge);
    });
  }

  async componentDidMount() {
    const users = await this.azureService.getAllUsersWithLicenses();
    const usersWithAtLeastOneLicense = users.filter(user => {
      return user.assignedLicenses.length > 0;
    });
    console.log("USERS", users);

    usersWithAtLeastOneLicense.map(async user => {
      const photoUrl = await this.azureService.getUserPhotoById(user.id, 96, 96);
      // only want to show users that have an image
      if (photoUrl) {
        this.addUserPreviewPhoto({ id: user.id, url: photoUrl });
      }
    });
  }

  render() {
    return (
      <CenteredLayout>
        <BadgeCreator
          startingImageUrl={this.underneathUserPhoto}
          canvasId={"c1"}
          imageHeight={96}
          imageWidth={96}
          onAfterSave={(badgeName, badgeJson) => {
            console.log(badgeName, badgeJson);
            this.saveBadgeToLibrary(badgeName, badgeJson);
            this.props.history.push(`/badging/view`);
          }}
          onPreviewUpdate={dataUrl => {
            console.log(`Preview Updated`, dataUrl);
            this.updatePreviewImageData(dataUrl);
          }}
        />
        <Stack shouldWrapChildren={true} isInline={true} flexWrap={"wrap"} spacing={5}>
          {this.userPreviewPhotos
            .filter(phot => phot.url.indexOf("placehold.it") === -1)
            .map((photo, index) => {
              return (
                <Box>
                  <DelveImage src={photo.url} height={"110px"} width={"110px"} />
                  <Button
                    onClick={() => {
                      this.setUnderneathUserPhoto(photo.url);
                    }}>
                    Select
                  </Button>
                </Box>
              );
            })}
        </Stack>
      </CenteredLayout>
    );
  }
}

export default withRouter(CreateBadge);

export default class EventEmitter {
  // tslint:disable-next-line:no-any
  private registry = new Map<string, Set<any>>();

  /**
   * Synchronously calls each of the listeners registered for the event named
   * `eventName`, in the order they were registered, passing the supplied
   * arguments to each.
   * @returns `true` if the event had listeners, `false` otherwise.
   */
  // tslint:disable-next-line:no-any
  public emit<T extends any[]>(eventName: string, ...rest: T) {
    const listeners = this.registry.get(eventName);
    if (!listeners) {
      return false;
    }
    listeners.forEach(listener => {
      listener(...rest);
    });
    return true;
  }

  /**
   * Adds the `listener` to the `eventName` set.
   * @returns a function that removes the `listener` from the `eventName` set.
   */
  // tslint:disable-next-line:no-any
  public on<T extends any[]>(eventName: string, listener: (...args: T) => void) {
    {
      let listeners = this.registry.get(eventName);
      /* istanbul ignore else */
      if (!listeners) {
        this.registry.set(eventName, (listeners = new Set()));
      }
      listeners.add(listener);
    }
    /**
     * Removes the `listener` from the `eventName` set.
     */
    const off = () => {
      const listeners = this.registry.get(eventName);
      return !listeners || !listeners.has(listener)
        ? false
        : listeners.size === 1
        ? this.registry.delete(eventName)
        : listeners.delete(listener);
    };
    return off;
  }
}

import React from "react";

import { useRouter, withRouter } from "./../../util/router";
import { observer } from "mobx-react";
import { globalStore } from "../../stores/GlobalStore";
import { AzureRMService } from "../../services/AzureRMService";
import { observable, action, toJS } from "mobx";
import { Spinner, Button } from "@chakra-ui/core";
import { JOUMANA } from "../../util/Constants";

declare var fabric;

var BASE64_MARKER = ";base64,";

function convertDataURIToBinary(dataURI) {
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for (var i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}

class PhotoEditor extends React.Component<{ userImage: string; onAfterSave: () => void }> {
  _ref;
  _canvas;
  azureService = new AzureRMService();
  constructor(props) {
    super(props);

    //  this._ref = React.createRef();
  }

  componentWillMount() {
    // dispatch some actions if you use Redux
  }

  componentDidMount() {
    const canvas = new fabric.Canvas("c3", {
      width: 96,
      height: 96,
      backgroundColor: "white",
      isDrawingMode: true
    });

    // canvas.add(new fabric.Circle({ radius: 30, fill: "blue", top: 0, left: 0 }));

    // canvas.item(0).set({
    //   borderColor: "blue",
    //   cornerColor: "green",
    //   cornerSize: 6,
    //   transparentCorners: false
    // });
    // canvas.setActiveObject(canvas.item(0));

    fabric.Image.fromURL(this.props.userImage, function(img) {
      // img.scale(0.5).set({
      //   left: 100,
      //   top: 100,
      //   angle: -15,
      //   clipTo: function(ctx) {
      //     ctx.arc(0, 0, 5, 0, Math.PI * 2, true);
      //   }
      // });
      canvas.add(img);
    });

    this._canvas = canvas;
  }

  render() {
    return (
      <div>
        <canvas
          id="c3"
          ref={node => {
            this._ref = node;
          }}
          style={{ border: "1px dashed black" }}
        />
        <br />
        <Button
          backgroundColor={"themePrimary"}
          color={"white"}
          onClick={() => {
            console.log("CANVAS", this._canvas);
            console.log(this._canvas.toDataURL("png"));
            console.log(convertDataURIToBinary(this._canvas.toDataURL("png")));
            this._canvas.toCanvasElement().toBlob(async b => {
              await this.azureService.updateMyPhoto(b);
              this.props.onAfterSave();
            });
            fetch(this._canvas.toDataURL("png"))
              .then(res => res.blob())
              .then(blob => {
                console.log("here is your binary: ", blob);
              });
          }}>
          Save
        </Button>
        <Button
          backgroundColor={"themePrimary"}
          color={"white"}
          onClick={() => {
            this._canvas.remove(...this._canvas.getObjects().concat());
          }}>
          Clear
        </Button>

        <Button
          backgroundColor={"themePrimary"}
          color={"white"}
          onClick={() => {
            var comicSansText = new fabric.Textbox("BADGE", {
              top: 70,
              fontFamily: "Comic Sans",
              fontSize: 10,
              backgroundColor: "yellow"
            });
            this._canvas.add(comicSansText);
            this._canvas.setActiveObject(comicSansText);
            this._canvas.isDrawingMode = false;
          }}>
          Add Badge
        </Button>

        <Button
          backgroundColor={"themePrimary"}
          color={"white"}
          onClick={() => {
            this._canvas.remove(...this._canvas.getObjects().concat());
            fabric.Image.fromURL(JOUMANA, img => {
              img.scaleToWidth(96);
              img.scaleToHeight(96);

              // img.scale(0.5).set({
              //   left: 100,
              //   top: 100,
              //   angle: -15,
              //   clipTo: function(ctx) {
              //     ctx.arc(0, 0, 5, 0, Math.PI * 2, true);
              //   }
              // });
              this._canvas.add(img);
            });
          }}>
          Reset Image
        </Button>
      </div>
    );
  }
}

@observer
class ProfilePage extends React.Component<{ match }> {
  azureService = new AzureRMService();

  @observable isLoading: boolean = false;
  @action setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @observable userId: string = "";
  @action setUserId(id: string) {
    this.userId = id;
  }

  @observable userProfile: any;
  @action setUserProfile(profile) {
    this.userProfile = profile;
  }

  @observable profilePhoto;
  @action setProfilePhoto(src) {
    this.profilePhoto = src;
  }

  private async reloadUserPhoto() {
    // TODO: duplicate logic
    // const p = await this.azureService.getUserProfileById(this.props.match.params.id);
    // this.setUserProfile(p);
    if (this.props.match.params.id) {
    } else {
      const profileImage = await this.azureService.getMyPhoto();
      this.setProfilePhoto(profileImage);
    }
  }

  async componentDidMount() {
    // TODO: duplicate logic
    this.setIsLoading(true);
    if (this.props.match.params.id) {
      const p = await this.azureService.getUserProfileById(this.props.match.params.id);
      this.setUserProfile(p);
      const profileImage = await this.azureService.getUserPhotoById(this.props.match.params.id, 96, 96);
      this.setProfilePhoto(profileImage);
    } else {
      const profile = await this.azureService.getMyProfile();
      this.setUserProfile(profile);
      const profileImage = await this.azureService.getMyPhoto();
      this.setProfilePhoto(profileImage);
    }

    var canvas = new fabric.Canvas("c3");
    canvas.add(new fabric.Circle({ radius: 30, fill: "#f55", top: 100, left: 100 }));

    canvas.item(0).set({
      borderColor: "red",
      cornerColor: "green",
      cornerSize: 6,
      transparentCorners: false
    });
    canvas.setActiveObject(canvas.item(0));
    //this.__canvases.push(canvas);

    this.setIsLoading(false);
  }

  render() {
    if (this.isLoading) {
      return <Spinner />;
    }
    return (
      <div className="columns">
        <div className="container profile">
          <div className="modal" id="edit-preferences-modal">
            <div className="modal-background"></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Edit Preferences</p>
                <button className="delete"></button>
              </header>

              <section className="modal-card-body">
                <label className="label">Name</label>
                <p className="control">
                  <input className="input" placeholder="Text input" type="text" />
                </p>
                <label className="label">Username</label>
                <p className="control has-icon has-icon-right">
                  <input className="input" placeholder="Text input" type="text" value="pmillerk" />
                </p>
                <label className="label">Email</label>
                <p className="control has-icon has-icon-right">
                  <input className="input" placeholder="Email input" type="text" value="hello@" />
                  <i className="fa fa-warning"></i>
                  <span className="help is-danger">This email is invalid</span>
                </p>
                <div className="control">
                  <div className="control-label is-pulled-left">
                    <label className="label">Date of Birth</label>
                  </div>
                  <span>
                    <span className="select">
                      <select>
                        <option>Month</option>
                        <option>With options</option>
                      </select>
                    </span>
                    <span className="select">
                      <select>
                        <option>Day</option>
                        <option>With options</option>
                      </select>
                    </span>
                    <span className="select">
                      <select>
                        <option>Year</option>
                        <option>With options</option>
                      </select>
                    </span>
                  </span>
                </div>
                <label className="label">Description</label>
                <p className="control">
                  <textarea className="textarea" placeholder="Describe Yourself!"></textarea>
                </p>
                <div className="content">
                  <h1>Optional Information</h1>
                </div>
                <label className="label">Phone Number</label>
                <p className="control has-icon has-icon-right">
                  <input className="input" placeholder="Text input" type="text" value="+1 *** *** 0535" />
                </p>
                <label className="label">Work</label>
                <p className="control has-icon has-icon-right">
                  <input className="input" placeholder="Text input" type="text" value="Greater Washington Publishing" />
                </p>
                <label className="label">School</label>
                <p className="control has-icon has-icon-right">
                  <input className="input" placeholder="Text input" type="text" value="George Mason University" />
                </p>
              </section>
              <footer className="modal-card-foot">
                <a className="button is-primary modal-save">Save changes</a>
                <a className="button modal-cancel">Cancel</a>
              </footer>
            </div>
          </div>
          <div className="section profile-heading">
            <div className="columns is-mobile is-multiline">
              <div className="column is-2">
                <span className="header-icon user-profile-image">
                  <img alt="" src={this.profilePhoto || ""} />
                </span>
              </div>
              <div className="column is-4-tablet is-10-mobile name">
                <p>
                  {/* <span className="title is-bold">{this.userProfile?.names[0].displayName}</span> */}
                  <br />

                  <br />
                </p>

                {this.profilePhoto && (
                  <PhotoEditor userImage={this.profilePhoto} onAfterSave={() => this.reloadUserPhoto()} />
                )}

                {/* {this.userProfile?.emails.map(email => {
                  return <div>Email: {email.address}</div>;
                })} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ProfilePage);

import React from "react";
import { globalStore } from "../../stores/GlobalStore";
import { observer } from "mobx-react";
import { authProvider } from "../../AuthProvider";
import { toJS } from "mobx";
import { Section, Container, Dropdown } from "react-bulma-components/full";
import { Stack, Select, Box, Text } from "@chakra-ui/core";
import { withRouter } from "../../util/router";

@observer
class TenantSelectorControl extends React.Component<{ history }> {
  render() {
    //   if (globalStore.isInitializing) return null;
    return (
      <Stack spacing={1}>
        <Text>Select Tenant:</Text>
        <Select
          variant="outline"
          placeholder={globalStore.userCurrentAADDomain}
          onChange={async e => {
            globalStore.setUserCurrentAADDirectory(e.target.value);
            globalStore.setUserSelectedDirectoryId(e.target.value);
            await globalStore.persistSettingsToExtension();
            // localStorage.removeItem("SparkGlobalStore");
            // localStorage.removeItem("ThemeStore");

            //   globalStore.initialize(authProvider.getAccount()).then(() => {
            this.props.history.push("/");
            //    });
            window.location.reload();
          }}>
          {globalStore.userAADDirectories
            .filter(dir => dir.tenantId !== globalStore.userCurrentAADDirectory)
            .map(directory => {
              return (
                <option value={directory.tenantId} key={directory.tenantId}>
                  {directory.domain}
                </option>
              );
            })}
        </Select>
      </Stack>
    );
  }
}

export const TenantSelector = withRouter(TenantSelectorControl);

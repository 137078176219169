import { action, observable, toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect } from "react";

import { SparkMenuPage } from "../../components/SparkMenu";
import { AzureRMService } from "../../services/AzureRMService";
import { SharePointService } from "../../services/SharePointService";
import { withRouter, Switch, Route } from "../../util/router";
import { Stack, Spinner, Heading, Button, Box, Text } from "@chakra-ui/core";
import { PKService } from "../../services/PKService";

@observer
class AzureADAppPage extends React.Component<{ match }> {
  sharePointService = new SharePointService();
  azureService = new AzureRMService();

  @observable isLoading: boolean = false;
  @action setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @observable appName: string = "";
  @action setAppName(name: string) {
    this.appName = name;
  }

  @observable appId: string = "";
  @action setAppId(id: string) {
    this.appId = id;
  }

  @observable azureADApp: any = {};
  @action setAzureADApp(app) {
    this.azureADApp = app;
  }

  @observable key: any;
  @action setKey(key: any) {
    this.key = key;
  }

  @observable keyText: string = "";
  @action setKeyText(text: string) {
    this.keyText = text;
  }

  private async generateCertificateForApp() {
    this.setIsLoading(true);
    const keys = PKService.generateKey();
    this.setKey(keys);
    await this.azureService.updateAzureADAppWithNewCertificate(this.appId, keys.pem);
    this.setKeyText(keys.pem);
    this.setIsLoading(false);
  }

  private async associateToResource() {
    const pfxBlob = this.key.pfx;
    const newCert = await this.azureService.createCertificateInResourceGroup(
      "d9d0f2a5-a667-4c65-82a9-68fee549b7a2",
      "TomTestFromAdmin",
      "newcert1",
      pfxBlob
    );
  }

  async componentDidMount() {
    this.setIsLoading(true);
    const appId = this.props.match.params.id;
    const azureAdApp = await this.azureService.getAzureADAppByID(appId);
    this.setAzureADApp(azureAdApp);
    this.setAppName(azureAdApp.displayName);
    this.setAppId(azureAdApp.id);

    this.setIsLoading(false);
  }
  render() {
    if (this.isLoading) {
      return <Spinner></Spinner>;
    }
    return (
      <div>
        <Stack>
          <Box>
            <Heading size={"sm"}>{this.appName}</Heading>
          </Box>
          <Box>
            <Button
              onClick={() => {
                this.generateCertificateForApp();
              }}>
              Generate Certificate
            </Button>
          </Box>
          <Box>
            <Button
              onClick={() => {
                this.associateToResource();
              }}>
              Assign to Resource
            </Button>
          </Box>

          <Text>{this.keyText}</Text>
        </Stack>
      </div>
    );
  }
}

export default withRouter(AzureADAppPage);

import React from "react";
import { Box, Stack } from "@chakra-ui/core";

export class CenteredLayout extends React.Component<{ children }> {
  render() {
    return (
      <Stack alignItems={"center"}>
        <Box
          padding={"20px"}
          width={[
            "100%", // base
            "100%", // 480px upwards
            "75%", // 768px upwards
            "1200px" // 992px upwards
          ]}>
          {this.props.children}
        </Box>
      </Stack>
    );
  }
}

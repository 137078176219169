import { Divider } from "@chakra-ui/core";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import MainMenu from "../../components/MainMenu/MainMenu";
import { SharedWithMe } from "../../components/SharedWithMe/SharedWithMe";
import { TrendingAroundMe } from "../../components/TrendingAroundMe/TrendingAroundMe";
import { UsedByMe } from "../../components/UsedByMe/UsedByMe";
import { globalStore } from "../../stores/GlobalStore";

@observer
class TrendingPage extends React.Component<{ router: any }> {
  render() {
    return (
      <>
        {globalStore.isExternalUser ? <div>External Users can't see insights</div> : <SharedWithMe />}
        <Divider />
        {globalStore.isExternalUser ? <div>External Users can't see insights</div> : <TrendingAroundMe />}
        <Divider />
        {globalStore.isExternalUser ? <div>External Users can't see insights</div> : <UsedByMe />}
        <Divider />
      </>
    );
  }
}

export default TrendingPage;

import React from "react";
import { AzureRMService } from "../../services/AzureRMService";
import { observable, action, toJS } from "mobx";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import { Box, Stack, Image, Badge, Text, Link, Icon, List, ListItem, Button, Heading, Flex } from "@chakra-ui/core";
import { observer } from "mobx-react";
import moment from "moment";
import JSONTree from "react-json-tree";
import { DebugBox } from "../DebugBox/DebugBox";

@observer
class UsedByMe extends React.Component {
  azureService = new AzureRMService();

  @observable usedByMeItems: Array<MicrosoftGraph.UsedInsight> = [];
  @action setUsedByMeItems(items: Array<MicrosoftGraph.UsedInsight>) {
    this.usedByMeItems = items;
  }

  async componentDidMount() {
    const usedByMe = await this.azureService.getItemsUsedByMe();
    this.setUsedByMeItems(usedByMe);
  }

  render() {
    const PreviewImage = ({ item }: { item: MicrosoftGraph.UsedInsight }) => {
      if (item.resourceVisualization?.mediaType !== "image/png") {
        return <Image src={"https://placehold.it/100x100"} alt={item.resourceVisualization?.title} />;
      }

      return <Image src={item.resourceVisualization?.previewImageUrl} alt={item.resourceVisualization?.title} />;
    };

    const SharedItemBox = observer(({ item }: { item: MicrosoftGraph.UsedInsight }) => {
      return (
        <DebugBox debugJson={item} maxW="sm" borderWidth="1px" rounded="lg" overflow="hidden">
          <PreviewImage item={item} />

          <Box p="6">
            <Box d="flex" alignItems="baseline">
              <Badge rounded="full" px="2" variantColor="teal">
                New
              </Badge>
              <Box
                color="gray.500"
                fontWeight="semibold"
                letterSpacing="wide"
                fontSize="xs"
                textTransform="uppercase"
                ml="2">
                {item.resourceVisualization?.mediaType}
              </Box>
            </Box>

            <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated>
              {item.resourceVisualization?.title}
            </Box>

            <Box>{item.resourceVisualization?.type}</Box>
            <Link href={item.resourceReference?.webUrl} target={"_blank"} isExternal>
              Link <Icon name="external-link" mx="2px" />
            </Link>
          </Box>
        </DebugBox>
      );
    });

    return (
      <Stack>
        <Heading size={"sm"}>Used By Me</Heading>
        <Stack flexWrap={"wrap"} direction={"row"} spacing={5} shouldWrapChildren={true}>
          {this.usedByMeItems.map((item: MicrosoftGraph.UsedInsight) => {
            return <SharedItemBox item={item} />;
          })}
        </Stack>
      </Stack>
    );
  }
}

export { UsedByMe };

import React from "react";
import { AzureRMService } from "../../services/AzureRMService";
import { observable, action, toJS } from "mobx";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import { Box, Stack, Image, Badge, Text, Link, Icon, List, ListItem, Button, Heading } from "@chakra-ui/core";
import { observer } from "mobx-react";
import moment from "moment";
import JSONTree from "react-json-tree";
import { DebugBox } from "../DebugBox/DebugBox";

@observer
class SharedWithMe extends React.Component {
  azureService = new AzureRMService();

  @observable sharedWithMeItems: Array<MicrosoftGraph.SharedInsight> = [];
  @action setSharedWithMeItems(items: Array<MicrosoftGraph.SharedInsight>) {
    this.sharedWithMeItems = items;
  }

  @observable debugOpen: boolean = false;
  @action setDebugOpen(open: boolean) {
    this.debugOpen = open;
  }

  async componentDidMount() {
    const sharedWithMe = await this.azureService.getItemsSharedWithMe();
    this.setSharedWithMeItems(sharedWithMe);
  }

  render() {
    const PreviewImage = ({ item }: { item: MicrosoftGraph.SharedInsight }) => {
      // if (item.resourceVisualization?.mediaType !== "image/png") {
      //   return <Image src={"https://placehold.it/100x100"} alt={item.resourceVisualization?.title} />;
      // }

      return <Image src={item.resourceVisualization?.previewImageUrl} alt={item.resourceVisualization?.title} />;
    };

    const SharedBy = ({ item }: { item: MicrosoftGraph.SharedInsight }) => {
      return (
        <Stack>
          <List>
            <ListItem>Shared By: {item.lastShared?.sharedBy?.displayName}</ListItem>
            <ListItem>Date: {moment(item.lastShared?.sharedDateTime).format("MM/DD/YYYY h:mm:ss a")}</ListItem>
            <ListItem>Sharing Reference Type: {item.lastShared?.sharingReference?.type}</ListItem>
            <ListItem>
              Sharing Reference Link:{" "}
              <Link href={item.lastShared?.sharingReference?.webUrl} target={"_blank"}>
                Link
              </Link>
            </ListItem>
          </List>
        </Stack>
      );
    };

    const SharedItemBox = observer(({ item }: { item: MicrosoftGraph.SharedInsight }) => {
      return (
        <DebugBox debugJson={item} maxW="sm" borderWidth="1px" rounded="lg" overflow="hidden">
          <PreviewImage item={item} />

          <Box p="6">
            <Box d="flex" alignItems="baseline">
              <Badge rounded="full" px="2" variantColor="teal">
                New
              </Badge>
              <Box
                color="gray.500"
                fontWeight="semibold"
                letterSpacing="wide"
                fontSize="xs"
                textTransform="uppercase"
                ml="2">
                {item.resourceVisualization?.mediaType}
              </Box>
            </Box>

            <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated>
              {item.resourceVisualization?.title}
            </Box>

            <Box>
              {item.resourceVisualization?.type}
              <Box as="span" color="gray.600" fontSize="sm">
                / wk
              </Box>
            </Box>
            <Link href={item.resourceReference?.webUrl} target={"_blank"} isExternal>
              Link <Icon name="external-link" mx="2px" />
            </Link>
            <SharedBy item={item} />

            {/* <Box d="flex" mt="2" alignItems="center">
             {Array(5)
               .fill("")
               .map((_, i) => (
                 <StarIcon
                   key={i}
                   color={i < property.rating ? "teal.500" : "gray.300"}
                 />
               ))}
             <Box as="span" ml="2" color="gray.600" fontSize="sm">
               {property.reviewCount} reviews
             </Box>
           </Box> */}
          </Box>
        </DebugBox>
      );
    });

    return (
      <Stack>
        <Heading size={"sm"}>Shared With Me</Heading>
        <Stack isInline flexWrap={"wrap"} spacing={10} shouldWrapChildren={true}>
          {this.sharedWithMeItems.map((item: MicrosoftGraph.SharedInsight) => {
            return <SharedItemBox item={item} />;
          })}
        </Stack>
      </Stack>
    );
  }
}

export { SharedWithMe };

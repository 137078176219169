import React, { ReactChildren } from "react";
import { Box, Button, css, BoxProps } from "@chakra-ui/core";
import { observable, action, toJS } from "mobx";
import JSONTree from "react-json-tree";
import styled from "@emotion/styled";
import { observer } from "mobx-react";

export interface IDebugBoxProps extends BoxProps {
  debugJson: any;
}
@observer
class DebugBox extends React.Component<IDebugBoxProps> {
  @observable debugOpen: boolean = false;
  @action toggleDebug() {
    this.debugOpen = !this.debugOpen;
  }

  render() {
    return (
      <Box position={"relative"} {...this.props}>
        {this.props.children}
        <Button
          onClick={() => {
            this.toggleDebug();
          }}
          position="absolute"
          top="0"
          right={"1.25em"}>
          Debug
        </Button>

        {this.debugOpen && <JSONTree data={this.props.debugJson} />}
      </Box>
    );
  }
}

export { DebugBox };

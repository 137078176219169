import { observable, action, computed } from "mobx";

export class FetchStore {
  @observable apiCallInProgress: boolean = false;
  @action setApiCallInProgress(inProgress) {
    this.apiCallInProgress = inProgress;
  }

  @observable fetchRequests: Array<any> = [];
  @action addFetchRequestToStack(request, controller) {
    this.fetchRequests.push({ request, controller });
  }

  @action popRequestStack() {
    this.fetchRequests.pop();
  }
  @computed get requestCount() {
    return this.fetchRequests.length;
  }

  @observable errors: Array<Error> = [];
  @action addErrorToStack(err) {
    this.errors.push(err);
  }

  @computed get errorCount() {
    return this.errors.length;
  }

  @action abortAllRequests() {
    this.fetchRequests.forEach(({ request, controller }) => {
      controller.abort();
    });
  }
}

import React from "react";
import {
  Button,
  Columns,
  Progress,
  Table,
  Heading,
  Pagination,
  Loader,
  Modal,
  Section
} from "react-bulma-components/full";
import { observer } from "mobx-react";
import FormField from "../FormField";
import { observable, action } from "mobx";

export type ResourceGroupCreateResult = {
  newResourceGroupName: string;
};

@observer
export default class NewResourceGroupModal extends React.Component<{
  modal: Object;
  isOpen: boolean;
  onSubmit: (result: ResourceGroupCreateResult) => Promise<void>;
  onClose?: Function;
}> {
  @observable newRgName: string = "";
  @action setNewRgName(name: string) {
    this.newRgName = name;
  }
  render() {
    return (
      <div>
        <Modal
          show={this.props.isOpen}
          onClose={() => this.props.onClose && this.props.onClose()}
          {...this.props.modal}>
          <Modal.Content>
            <Section style={{ backgroundColor: "white" }}>
              <Heading size={6} subTitle>
                {" "}
                Create a New Resource Group
              </Heading>
              <FormField
                value={this.newRgName}
                type="text"
                placeholder="Name"
                //  error={showErrors && getError("email")}
                onChange={value => this.setNewRgName(value)}
              />
              <Button
                color={"info"}
                onClick={() => {
                  this.props.onSubmit({ newResourceGroupName: this.newRgName });
                  this.props.onClose && this.props.onClose();
                }}>
                Create
              </Button>
            </Section>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

import React from "react";
import { withRouter } from "../../util/router";
import { Stack, Box, Flex, Icon, Heading, Button, Text } from "@chakra-ui/core";
import { CenteredLayout } from "../../components/Layouts/CenteredLayout";
import { action } from "mobx";
import { AzureRMService } from "../../services/AzureRMService";
import { BADGING_USER_IMAGE_LIBRARY } from "../../util/Constants";

class EditBadgePage extends React.Component<{ match; history }> {
  azureService = new AzureRMService();
  @action async deleteBadge() {
    await this.azureService.deleteFileFromMyDrive(
      `${BADGING_USER_IMAGE_LIBRARY}/Badges/${this.props.match.params.id}.json`
    );
  }

  render() {
    return (
      <div>
        <CenteredLayout>
          <Stack maxWidth={"800px"}>
            <Box backgroundColor="white" shadow="sm" borderRadius="lg" pl={3} pr={3} pt={5} pb={5}>
              <Flex display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" pb={2}>
                <Icon name="chevron-left" />
                <Heading size="md" as="h2" lineHeight="shorter" fontWeight="bold" fontFamily="heading">
                  Edit Badge
                </Heading>
              </Flex>
              <Stack>
                <Stack isInline>
                  <Text textAlign="left">{`This is the page where you would be able to edit the ${this.props.match.params.id} badge!`}</Text>
                </Stack>
              </Stack>
              <br />
              <Button
                onClick={() => {
                  this.deleteBadge();
                }}
                variantColor={"red"}>
                Delete Badge
              </Button>
            </Box>
          </Stack>
        </CenteredLayout>
      </div>
    );
  }
}

export default withRouter(EditBadgePage);

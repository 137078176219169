import { Menu, MenuButton, Button, MenuList, MenuItem, Image, Stack, Select, Text } from "@chakra-ui/core";
import * as React from "react";
import { observer } from "mobx-react";
import { globalStore } from "../../stores/GlobalStore";

export const ThemeSelector = observer(() => {
  return (
    <Stack spacing={1}>
      <Text>Select Theme:</Text>
      <Select
        backgroundColor={globalStore.themeStore.selectedTheme?.themeJson.palette.themePrimary}
        variant="outline"
        placeholder={globalStore.themeStore.selectedThemeName || "Select Theme"}
        onChange={e => {
          globalStore.themeStore.setSelectedThemeByName(e.target.value);
          globalStore.persistSettingsToExtension();
        }}>
        {globalStore.themeStore.tenantThemes.map(theme => {
          return (
            <option
              value={theme.name}
              key={theme.name}
              style={{ backgroundColor: theme.themeJson.palette.themePrimary }}>
              {theme.name}
            </option>
          );
        })}
      </Select>
    </Stack>
  );
});

import "./styles.scss";

import { Box, SimpleGrid, Stack } from "@chakra-ui/core";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import MainMenu from "../../components/MainMenu/MainMenu";
import { AzureRMService } from "../../services/AzureRMService";
import { useRouter } from "./../../util/router";
import { observable, toJS } from "mobx";
import { globalStore } from "../../stores/GlobalStore";
import { SharePointService } from "../../services/SharePointService";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import {
  COMPANY_ADMINISTRATOR_ROLETEMPLATEID,
  SHAREPOINT_SERVICE_ADMINISTRATOR_ROLETEMPLATEID,
  BADGING_USER_IMAGE_LIBRARY
} from "../../util/Constants";
import { GridLayout } from "../../components/GridLayout/GridLayout";
import { SettingsPersistenceService } from "../../services/SettingsPersistanceService";
import Poller from "../../components/Poller/Poller";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { LoadingIndicator2 } from "../../components/LoadingIndicator/LoadingIndicator2";
import { authProvider } from "../../AuthProvider";
import { UserImagePoller } from "../../components/Poller/UserImagePoller";
import { StrapiService } from "../../services/StrapiService";

@observer
class HomePageStateful extends React.Component<{ router: any }> {
  azureService = new AzureRMService();
  sharePointService = new SharePointService();
  settingsService = new SettingsPersistenceService();

  // _poller: UserImagePoller;

  // constructor(props) {
  //   super(props);
  //   this._poller = new UserImagePoller(20000);
  // }
  async componentDidMount() {
    // const strapi = new StrapiService();
    // const restaurants = await strapi.getRestaurants();
    // console.log("RESTAURANTS", restaurants);
  }

  componentWillUnmount() {}
  render() {
    return (
      <>
        <Stack>
          <SimpleGrid minChildWidth="300px" spacing="40px">
            <Box>
              <MainMenu />
            </Box>
          </SimpleGrid>
        </Stack>
      </>
    );
  }
}

const HomePage = observer(props => {
  const router = useRouter();
  return <HomePageStateful router={router} />;
});

export default HomePage;

declare var forge: any;

export class PKService {
  static generateKey() {
    var pki = forge.pki;

    // generate a keypair and create an X.509v3 certificate
    var keys = pki.rsa.generateKeyPair(2048);
    var cert = pki.createCertificate();
    cert.publicKey = keys.publicKey;
    // alternatively set public key from a csr
    //cert.publicKey = csr.publicKey;
    // NOTE: serialNumber is the hex encoded value of an ASN.1 INTEGER.
    // Conforming CAs should ensure serialNumber is:
    // - no more than 20 octets
    // - non-negative (prefix a '00' if your value starts with a '1' bit)
    cert.serialNumber = "01";
    cert.validity.notBefore = new Date();
    cert.validity.notAfter = new Date();
    cert.validity.notAfter.setFullYear(cert.validity.notBefore.getFullYear() + 1);
    var attrs = [
      {
        name: "commonName",
        value: "rightpoint.com"
      },
      {
        name: "countryName",
        value: "US"
      },
      {
        shortName: "ST",
        value: "Michigan"
      },
      {
        name: "localityName",
        value: "Detroit"
      },
      {
        name: "organizationName",
        value: "Rightpoint"
      },
      {
        shortName: "OU",
        value: "Rightpoint"
      }
    ];
    cert.setSubject(attrs);
    // alternatively set subject from a csr
    //cert.setSubject(csr.subject.attributes);
    cert.setIssuer(attrs);
    cert.setExtensions([
      {
        name: "basicConstraints",
        cA: true
      },
      {
        name: "keyUsage",
        keyCertSign: true,
        digitalSignature: true,
        nonRepudiation: true,
        keyEncipherment: true,
        dataEncipherment: true
      },
      {
        name: "extKeyUsage",
        serverAuth: true,
        clientAuth: true,
        codeSigning: true,
        emailProtection: true,
        timeStamping: true
      },
      {
        name: "nsCertType",
        client: true,
        server: true,
        email: true,
        objsign: true,
        sslCA: true,
        emailCA: true,
        objCA: true
      },
      {
        name: "subjectAltName",
        altNames: [
          {
            type: 6, // URI
            value: "https://www.rightpoint.com"
          },
          {
            type: 7, // IP
            ip: "127.0.0.1"
          }
        ]
      },
      {
        name: "subjectKeyIdentifier"
      }
    ]);
    /* alternatively set extensions from a csr
var extensions = csr.getAttribute({name: 'extensionRequest'}).extensions;
// optionally add more extensions
extensions.push.apply(extensions, [{
  name: 'basicConstraints',
  cA: true
}, {
  name: 'keyUsage',
  keyCertSign: true,
  digitalSignature: true,
  nonRepudiation: true,
  keyEncipherment: true,
  dataEncipherment: true
}]);
cert.setExtensions(extensions);
*/
    // self-sign certificate
    cert.sign(keys.privateKey);

    var p12Asn1 = forge.pkcs12.toPkcs12Asn1(keys.privateKey, cert, "password", { algorithm: "3des" });

    // convert a Forge certificate to PEM
    var pem = pki.certificateToPem(cert);

    // base64-encode p12
    var p12Der = forge.asn1.toDer(p12Asn1).getBytes();
    var p12b64 = forge.util.encode64(p12Der);

    // return base64 encoded pem and pfx
    return { pem: window.btoa(pem), pfx: p12b64 };
  }
}

function formatPEM(pemString) {
  /// <summary>Format string in order to have each line with length equal to 63</summary>
  /// <param name="pemString" type="String">String to format</param>

  var stringLength = pemString.length;
  var resultString = "";

  for (var i = 0, count = 0; i < stringLength; i++, count++) {
    if (count > 63) {
      resultString = `${resultString}\r\n`;
      count = 0;
    }

    resultString = `${resultString}${pemString[i]}`;
  }

  return resultString;
}

import React from "react";
import { Loader } from "react-bulma-components/full";
import { globalStore } from "../../stores/GlobalStore";
import { observer } from "mobx-react";

@observer
export class LoadingIndicator extends React.Component {
  render() {
    return (
      <div>
        {globalStore.fetchStore.apiCallInProgress ? (
          <Loader
            style={{
              width: 30,
              height: 30,
              border: "4px solid blue",
              borderTopColor: "transparent",
              borderRightColor: "transparent"
            }}
          />
        ) : (
          <div style={{ width: 30, height: 30 }}></div>
        )}
      </div>
    );
  }
}

import { Box, Button, Spinner, Stack, Text } from "@chakra-ui/core";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import * as microsoftTeams from "@microsoft/teams-js";
import { action, observable, toJS, computed } from "mobx";
import { observer } from "mobx-react";
import React from "react";

import { authProvider } from "../../AuthProvider";
import { CenteredLayout } from "../../components/Layouts/CenteredLayout";
import { SparkPage } from "../../components/SparkMenu";
import { AzureRMService } from "../../services/AzureRMService";
import { globalStore } from "../../stores/GlobalStore";
import { BADGING_USER_IMAGE_LIBRARY, SETTINGS_EXTENSION_NAME } from "../../util/Constants";
import { withRouter } from "../../util/router";
import { AwardBadgeOnUser } from "./AwardBadgeOnUser";
import { BadgingDataService } from "./BadgingDataService";

@observer
class BadgeUser extends React.Component<{ match; history }> {
  azureService = new AzureRMService();
  badgingDataService = new BadgingDataService();

  @observable isLoading: boolean = false;
  @action setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @observable userId: string = "";
  @action setUserId(id: string) {
    this.userId = id;
  }

  @observable userName: string = "";
  @action setUserName(name: string) {
    this.userName = name;
  }

  @observable userEmail: string = "";
  @action setUserEmail(email: string) {
    this.userEmail = email;
  }

  @observable profilePhoto;
  @action setProfilePhoto(src) {
    this.profilePhoto = src;
  }

  @action async reloadPhoto() {
    const profileImage = await this.azureService.getUserPhotoById(this.props.match.params.id, 96, 96);

    this.setProfilePhoto(profileImage);
  }

  @observable costumes: Array<any> = [];
  @action setCostumes(costumes) {
    this.costumes = costumes;
  }
  @action addCostume(costume) {
    this.costumes.push(costume);
  }

  @observable userPhotos: Array<string> = [];
  @action addUserPhoto(url: string) {
    this.userPhotos.push(url);
  }

  @observable imageGuid: string = "";
  @action setImageGuid(guid: string) {
    this.imageGuid = guid;
  }

  @observable availableBadges: Array<any> = [];
  @action addAvailableBadge(badge) {
    this.availableBadges.push(badge);
  }

  @observable selectedBadge: { badgeName: string; canvasData: Object; id: string } | undefined;
  @action setSelectedBadge(badgeName: string) {
    this.selectedBadge = this.availableBadges.find(badge => badge.badgeName === badgeName);
  }

  @computed get canAward() {
    return !!this.selectedBadge;
  }

  @action async loadPreviousUserPhotos() {
    const me = authProvider.getAccount();
    const oid = me.idToken.oid;
    try {
      const allUserImages = await this.azureService.getAllFilesFromUserDrivePath(
        oid,
        `${BADGING_USER_IMAGE_LIBRARY}/${this.userName}`
      );

      allUserImages.map(async item => {
        const image = await this.azureService.getFileFromUserDrive(
          oid,
          `${BADGING_USER_IMAGE_LIBRARY}/${this.userName}`,
          `${item.name}`
        );
        const downloadUrl = image["@microsoft.graph.downloadUrl"];

        const dataJson = await fetch(downloadUrl, {});
        const data = await dataJson.blob();
        var objectURL = URL.createObjectURL(data);

        this.addUserPhoto(objectURL);
      });
    } catch (err) {
      // TODO: fix when user doesnt have a folder yet
      console.log(err);
    }
  }

  @action async loadCostumes() {
    const me = authProvider.getAccount();
    const oid = me.idToken.oid;
    try {
      const allCostumes = await this.azureService.getAllFilesFromUserDrivePath(
        oid,
        `${BADGING_USER_IMAGE_LIBRARY}/Costumes`
      );

      const costumes: Array<any> = [];
      allCostumes.map(async item => {
        const image = await this.azureService.getFileFromUserDrive(
          oid,
          `${BADGING_USER_IMAGE_LIBRARY}/Costumes`,
          `${item.name}`
        );
        const downloadUrl = image["@microsoft.graph.downloadUrl"];

        const dataJson = await fetch(downloadUrl, {});
        const data = await dataJson.json();
        this.addCostume(data);
      });
    } catch (err) {
      // TODO: fix when user doesnt have a folder yet
      console.log(err);
    }
  }

  @action async loadBadges() {
    const me = authProvider.getAccount();
    const oid = me.idToken.oid;
    try {
      await this.badgingDataService.getAllBadgesFromUserFolder(oid);
      const allCostumes = await this.azureService.getAllFilesFromUserDrivePath(
        oid,
        `${BADGING_USER_IMAGE_LIBRARY}/Badges`
      );

      const costumes: Array<any> = [];
      allCostumes.map(async item => {
        const image = await this.azureService.getFileFromUserDrive(
          oid,
          `${BADGING_USER_IMAGE_LIBRARY}/Badges`,
          `${item.name}`
        );
        const downloadUrl = image["@microsoft.graph.downloadUrl"];

        const dataJson = await fetch(downloadUrl, {});
        const data = await dataJson.json();
        data.id = image.id;
        this.addAvailableBadge(data);
      });
    } catch (err) {
      // TODO: fix when user doesnt have a folder yet
      console.log(err);
    }
  }

  private async awardBadge() {
    console.log("ISINMSTEAMS", globalStore.microsoftTeamsStore.isInMicrosoftTeams);
    if (globalStore.microsoftTeamsStore.isInMicrosoftTeams) {
      const taskResult = {
        result: "Awarded Badge!!!",
        commandId: "sparkIntranetTask",
        appId: "cdae0253-aa7d-447d-bba9-1f34ab777adb"
      };
      microsoftTeams.tasks.submitTask(taskResult, "24160f9f-bc26-438c-862a-847fcc971db6");
      console.log("SUBMITTED TASK");
    } else {
      console.log("SENDING EMAIL");
      this.sendEmail();

      await this.azureService.sendTeamsMessage(
        "cf2cae6e-3e19-4daa-bdfe-68e3aa32e6b7",
        "19:67e47ee55224455989d71169aa73b93b@thread.skype",
        {
          body: {
            contentType: "html",
            content: `<at id=\"0\">${this.userName}</at> was awarded the ${this.selectedBadge?.badgeName} badge.`
          },
          mentions: [
            {
              id: 0,
              mentionText: this.userName,
              mentioned: {
                user: {
                  displayName: this.userName,
                  id: this.userId,
                  userIdentityType: "aadUser"
                }
              }
            }
          ]
        }
      );

      const url = this.createAcceptUrl();

      const j = {
        type: "invoke",
        text: "Invoke",
        displayText: "Invoke",
        value: {
          type: "task/fetch",
          commandId: "sparkMessageExtension",
          path: "https://sparkintranet.com",
          completionBotId: `24160f9f-bc26-438c-862a-847fcc971db6`,
          appId: `24160f9f-bc26-438c-862a-847fcc971db6`
        }
      };
      await this.azureService.sendTeamsMessage(
        "cf2cae6e-3e19-4daa-bdfe-68e3aa32e6b7",
        "19:67e47ee55224455989d71169aa73b93b@thread.skype",

        {
          subject: null,
          body: {
            contentType: "html",
            content: '<attachment id="74d20c7f34aa4a7fb74e2b30004247c5"></attachment>'
          },
          attachments: [
            {
              id: "74d20c7f34aa4a7fb74e2b30004247c5",
              contentType: "application/vnd.microsoft.card.thumbnail",
              contentUrl: null,
              content: `{\r\n  "title": "Badge Awarded",\r\n  "subtitle": "<h3>${this.userName} was awarded the ${
                this.selectedBadge?.badgeName
              } badge!</h3>",\r\n  "text": "Here is some body text. <br>\\r\\n <a href=\\"${url}/\">accept badge</a>. <br>\\r\\nAnd below that is some buttons:",\r\n  "buttons": [\r\n    ${JSON.stringify(
                j
              )}\r\n  ]\r\n}`,
              name: null,
              thumbnailUrl: null
            }
          ]
        }
      );
      console.log(`Sent teams message`);
    }
  }

  private createAcceptUrl() {
    const me = authProvider.getAccount();
    const oid = me.idToken.oid;
    const rootUrl = process.env["REACT_APP_PUBLIC_URL"];
    const badgeName = this.selectedBadge?.badgeName as string; // TODO null checking

    const url = `${rootUrl}/badging/accept?from=${oid}&to=${this.userId}&badgeName=${encodeURIComponent(badgeName)}`;
    return url;
  }

  private async sendEmail() {
    if (this.selectedBadge) {
      await this.azureService.shareFileFromMyDrive(this.userEmail, this.selectedBadge.id);
    }

    const url = this.createAcceptUrl();
    console.log(url);

    const email: MicrosoftGraph.Message = {
      toRecipients: [{ emailAddress: { name: this.userName, address: this.userEmail } }],
      subject: "Badge Award!!!",
      body: { content: url, contentType: "text" }
    };
    await this.azureService.sendEmail(email);
  }

  private async validatePageParams() {
    if (!this.props.match.params.id) {
      this.props.history.push("");
    }
  }

  async componentDidMount() {
    await this.validatePageParams();
    this.setIsLoading(true);

    const userId = this.props.match.params.id;

    try {
      const profile = await this.azureService.getUserById(userId);
      this.setUserId(userId);
      this.setUserName(profile.displayName ?? "");
      this.setUserEmail(profile.mail);

      await this.reloadPhoto();
      const userExtension = await this.azureService.getGraphExtensionForUser(SETTINGS_EXTENSION_NAME, this.userId);
      if (userExtension.originalProfileImageId) {
        // TODO: duplicate logic
        const originalImage = await this.azureService.getFileInUserDriveById(
          this.userId,
          userExtension.originalProfileImageId
        );

        const downloadJson = originalImage["@microsoft.graph.downloadUrl"];

        const imageData = await fetch(downloadJson, {});
        const data = await imageData.blob();
        var objectURL = URL.createObjectURL(data);
        this.setProfilePhoto(objectURL);
      }
    } catch (err) {
      debugger;
      this.props.history.push("");
    }
    // this.setUserId(this.props.match.params.id);
    // const profile = await this.azureService.getUserById(this.userId);
    // // const profile2: microsoftgraphbeta.User = await azureServiceClient
    // //   .api(`/users/${this.userId}`)
    // //   .version("beta")
    // //   .get();
    // this.setUserName(profile.displayName ?? "");
    // this.setUserEmail(profile.mail);

    // await this.reloadPhoto();

    // const drives = await this.azureService.getMyDrives();
    // await this.azureService.createRootFolderInMyDrive(`${BADGING_USER_IMAGE_LIBRARY}`);
    // // const allUserPhotos = await this.azureService.getAllPhotosByUserId(this.userId);
    // // const photoUrl = await this.azureService.getUserPhotoById(this.userId, 96, 96);
    // // this.setProfilePhoto(photoUrl);
    // await this.loadPreviousUserPhotos();
    await this.loadBadges();

    this.setIsLoading(false);
  }

  render() {
    const BadgeSelectorDisplay = observer(() => {
      return (
        <Stack isInline flexWrap={"wrap"}>
          {this.availableBadges.map(badge => {
            const isSelected = badge.badgeName === toJS(this.selectedBadge?.badgeName);
            return (
              <Box
                border={"1px solid"}
                borderColor={"themePrimary"}
                padding={5}
                backgroundColor={isSelected ? "themePrimary" : ""}>
                <Text color={isSelected ? "white" : "themePrimary"}>{badge.badgeName}</Text>
                <AwardBadgeOnUser
                  userImageUrl={this.profilePhoto}
                  canvasDataJson={badge.canvasData}
                  id={badge.badgeName}
                />
                <Button
                  onClick={() => {
                    this.setSelectedBadge(badge.badgeName);
                  }}>
                  Select
                </Button>
              </Box>
            );
          })}
        </Stack>
      );
    });

    if (this.isLoading) {
      return (
        <SparkPage>
          <Spinner />{" "}
        </SparkPage>
      );
    }
    if (!this.userId) {
      return null;
    }

    return (
      <CenteredLayout>
        <Stack>
          <Text fontSize={"lg"} fontWeight={"bold"}>
            Award a Badge to: {this.userName}
          </Text>
          <Text>{this.userEmail}</Text>
          <Text>{`Select one of the badges below. You can edit the placement of items in the badge so they fit the user's current profile image.`}</Text>

          {/* <Select
            onChange={e => {
              console.log("SET BADGE", e);
              this.setSelectedBadge(e.target.value);
            }}>
            {this.availableBadges.map(costume => {
              return (
                <option value={costume.badgeName} key={costume.name}>
                  {costume.badgeName}
                </option>
              );
            })}
          </Select> */}

          <BadgeSelectorDisplay />

          <br />
          {/* <Text>{`Preview: This is what the user's new profile image will look like.`}</Text>

          <Stack isInline spacing={5}>
            <Box>
              <Text>Large image</Text>
              <DelveImage src={this.profilePhoto} height={"110px"} width={"110px"} />
            </Box>
            <Box>
              <Text>In Microsoft Teams</Text>
              <DelveImage src={this.profilePhoto} height={"32px"} width={"32px"} />
            </Box>
          </Stack> */}

          <Button isDisabled={!this.canAward} maxW={150} onClick={() => this.awardBadge()}>
            Award!
          </Button>

          <Stack isInline={true} shouldWrapChildren={true}>
            {this.userPhotos.map(photoUrl => {
              return (
                <Box>
                  <img src={photoUrl} />
                  <Button
                    onClick={() => {
                      this.setProfilePhoto(photoUrl);
                    }}>
                    Select
                  </Button>
                </Box>
              );
            })}
          </Stack>
        </Stack>
      </CenteredLayout>
    );
  }
}

export default withRouter(BadgeUser);

import { action, observable, toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
// import { Button, Loader, Table } from "react-bulma-components/full";

import { SparkMenuPage } from "../../components/SparkMenu";
import { AzureRMService } from "../../services/AzureRMService";
import { Link, Route, Switch, withRouter } from "../../util/router";

import { Spinner, Stack, Box, Text, Button, Tooltip, Link as Link2, Heading, Icon, Flex, Image } from "@chakra-ui/core";
import { UserPhotoEditor } from "../../components/UserPhotoEditor/UserPhotoEditor";
import { JOUMANA } from "../../util/Constants";
import { UserSearchBox } from "../../components/UserSearchBox/UserSearchBox";
import { CenteredLayout } from "../../components/Layouts/CenteredLayout";
import { convertDataURIToBinary } from "../../util/Utility";
import { SettingsPersistenceService } from "../../services/SettingsPersistanceService";
import { UserImagePoller } from "../../components/Poller/UserImagePoller";
import { authProvider } from "../../AuthProvider";
declare var fabric;

@observer
class AcceptBadgePage extends React.Component<{ match; history }> {
  azureService = new AzureRMService();
  settingsService = new SettingsPersistenceService();
  _canvas;

  _userImagePoller = new UserImagePoller(20000);

  @observable message: string = "";
  @action setMessage(message: string) {
    this.message = message;
  }

  @observable fromUserId: string = "";
  @action setFromUserId(id: string) {
    this.fromUserId = id;
  }

  @observable toUserId: string = "";
  @action setToUserId(id: string) {
    this.toUserId = id;
  }

  @observable toUserName: string = "";
  @action setToUserName(name: string) {
    this.toUserName = name;
  }

  @observable imageUrl: string = "";
  @action setImageUrl(url: string) {
    this.imageUrl = url;
  }

  @observable profilePhoto: string = "";
  @action setProfilePhoto(src) {
    this.profilePhoto = src;
  }

  @observable imageData;
  @action setImageData(data) {
    this.imageData = data;
  }

  @observable originalUserImage;
  @action setOriginalUserImage(originalImage) {
    this.originalUserImage = originalImage;
  }

  @observable fromUserName: string = "";
  @action setFromUserName(name: string) {
    this.fromUserName = name;
  }
  @observable fromUserImage;
  @action setFromUserImage(fromUserImage) {
    this.fromUserImage = fromUserImage;
  }

  @observable badgeName: string = "";
  @action setBadgeName(name) {
    this.badgeName = name;
  }

  private async shareImageHistoryFolderWithEveryone() {
    const account = authProvider.getAccount();
    const userId = account.idToken.oid;
    const imHistoryFolder = await this.azureService.getFileFromUserDrive(userId, "Badge Images", "Image History");
    const perms = await this.azureService.shareFileFromMyDriveByAlias(
      "Everyone except external users",
      imHistoryFolder.id
    );
  }

  private async acceptImage() {
    // var dataURL = this._canvas.toDataURL({
    //   format: "png",
    //   multiplier: 2
    // });
    // const binary = convertDataURIToBinary(dataURL);
    this._canvas.toCanvasElement().toBlob(async b => {
      this.azureService.updateMyPhoto(b);
    });
  }

  async componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const fromUserId = params.get("from");
    const toUserId = params.get("to");
    const badgeName = params.get("badgeName");

    // TODO: validate this
    this.setFromUserId(fromUserId as string);
    this.setToUserId(toUserId as string);
    this.setMessage(`Accept badge from ${fromUserId}.`);
    const toUser = await this.azureService.getUserById(toUserId as string);
    const fromUser = await this.azureService.getUserById(fromUserId as string);
    this.setFromUserName(fromUser.displayName);
    const fromUserImage = await this.azureService.getUserPhotoById(fromUserId as string, 96, 96);
    this.setFromUserImage(fromUserImage);
    this.setToUserName(toUser.displayName);
    // TODO: validate url
    await this.azureService.createRootFolderInMyDrive("Badge Images");

    const profileImage = await this.azureService.getUserPhotoById(toUserId as string, 96, 96);
    this.setProfilePhoto(profileImage);
    let blob = await fetch(this.profilePhoto).then(r => r.blob());

    const existingUserSettings = await this.settingsService.getOrEnsureExtensionCreatedForMe();
    if (!existingUserSettings.lastSeenProfileETag) {
      const oldImage = await this.azureService.saveFileInMyDrive(
        "Badge Images/Image History",
        `originalUserImage.png`,
        "image/png",
        blob
      );

      const photoMetadata = await this.azureService.getUserPhotoMetadataById(toUserId as string, 96, 96);
      const etag = photoMetadata["@odata.mediaEtag"];

      await this.settingsService.persistSettingsToExtensionForMe({
        originalProfileImageId: oldImage.id,
        lastSeenProfileETag: etag
      });
    } else {
      // TODO: duplicate logic
      const originalImage = await this.azureService.getFileInMyDriveById(
        existingUserSettings.originalProfileImageId as string
      );
      const downloadJson = originalImage["@microsoft.graph.downloadUrl"];
      const imageData = await fetch(downloadJson, {});
      const data = await imageData.blob();
      var objectURL = URL.createObjectURL(data);
      this.setOriginalUserImage(objectURL);
      // alert("already got image" + existingUserSettings.originalProfileImageId);
    }

    // const image = await this.azureService.getFileFromUserDrive(
    //   fromUserId as string,
    //   "Badge Images/" + this.toUserName,
    //   `${badgeName}.png`
    // );

    // const downloadUrl = image["@microsoft.graph.downloadUrl"];

    // const dataJson = await fetch(downloadUrl, {});
    // const data = await dataJson.blob();
    // this._blob = data;
    // var objectURL = URL.createObjectURL(data);
    // this.setImageUrl(objectURL);

    // TODO - duplicate logic
    const imageJson = await this.azureService.getFileFromUserDrive(
      fromUserId as string,
      "Badge Images/Badges/",
      `${badgeName}.json`
    );

    const downloadJson = imageJson["@microsoft.graph.downloadUrl"];

    const imageDataJson = await fetch(downloadJson, {});
    const imageData = await imageDataJson.json();

    this.setBadgeName(imageData.badgeName);
    const canvas = new fabric.Canvas("thiscanvas", {
      width: 96,
      height: 96,
      backgroundColor: "green",
      isDrawingMode: false,
      preserveObjectStacking: true
    });

    fabric.Image.fromURL(this.profilePhoto, img => {
      img.scaleToHeight(96);
      img.scaleToWidth(96);
      img.name = "profilephoto";
      img.selectable = false;
      canvas.add(img);
      img.sendToBack();
    });

    canvas.loadFromJSON(imageData.canvasData);

    this._canvas = canvas;
    this.setImageData(imageData);
  }

  async componentWillUnmount() {
    this._userImagePoller.stop();
  }
  render() {
    return (
      <CenteredLayout>
        <Stack maxWidth={"800px"}>
          <Box backgroundColor="white" shadow="sm" borderRadius="lg" pl={3} pr={3} pt={5} pb={5}>
            <Flex display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" pb={2}>
              <Icon name="chevron-left" />
              <Heading size="md" as="h2" lineHeight="shorter" fontWeight="bold" fontFamily="heading">
                Badge Awarded!
              </Heading>
            </Flex>
            <Stack>
              <Stack isInline>
                <Text textAlign="left">
                  {`Hey ${this.toUserName}, ${this.fromUserName} has awarded you the ${this.badgeName} badge!`}
                </Text>
              </Stack>
              <Stack justifyContent="flex-start" alignItems="center">
                <canvas id={"thiscanvas"}></canvas>

                <Text>You can edit your badge to suit your liking. Once complete click the Accept button.</Text>
                <Button variant="solid" variantColor="linkedin" onClick={() => this.acceptImage()}>
                  Accept
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
        {/* {this.fromUserName} - {this.badgeName}
        Your current profile image:
        <img src={this.profilePhoto} />
        <img src={this.fromUserImage} />
        <br />
        <Text></Text>
        <Link to={`/badging/award/${this.fromUserId}`}>
          Try it out! Click here to award a badge to the person who awarded you.
        </Link>
        <br />
        <Link to={"/badging/award"}>Try it out! Click here to award a badge to someone else.</Link>
        <Button onClick={() => this.acceptImage()}>Accept</Button> */}
      </CenteredLayout>
    );
  }
}

export default withRouter(AcceptBadgePage);

import React from "react";
import { withRouter } from "../../util/router";
import { Stack, Box, Flex, Icon, Heading, Button, Text } from "@chakra-ui/core";
import { CenteredLayout } from "../../components/Layouts/CenteredLayout";
import { action, observable } from "mobx";
import { AzureRMService } from "../../services/AzureRMService";
import { BADGING_USER_IMAGE_LIBRARY } from "../../util/Constants";
import { authProvider } from "../../AuthProvider";
import { observer } from "mobx-react";

@observer
class ViewBadgesPage extends React.Component<{ match; history }> {
  azureService = new AzureRMService();

  @observable availableBadges: Array<any> = [];
  @action addAvailableBadge(badge) {
    this.availableBadges.push(badge);
  }

  async componentDidMount() {
    const me = authProvider.getAccount();
    const oid = me.idToken.oid;
    try {
      // await this.badgingDataService.getAllBadgesFromUserFolder(oid);
      const allCostumes = await this.azureService.getAllFilesFromUserDrivePath(
        oid,
        `${BADGING_USER_IMAGE_LIBRARY}/Badges`
      );

      allCostumes.map(async item => {
        const image = await this.azureService.getFileFromUserDrive(
          oid,
          `${BADGING_USER_IMAGE_LIBRARY}/Badges`,
          `${item.name}`
        );
        const downloadUrl = image["@microsoft.graph.downloadUrl"];

        const dataJson = await fetch(downloadUrl, {});
        const data = await dataJson.json();
        data.id = image.id;
        this.addAvailableBadge(data);
      });
    } catch (err) {
      alert(err);
    }
  }
  render() {
    return (
      <div>
        <CenteredLayout>
          <Stack maxWidth={"800px"}>
            <Box backgroundColor="white" shadow="sm" borderRadius="lg" pl={3} pr={3} pt={5} pb={5}>
              <Flex display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" pb={2}>
                <Icon name="chevron-left" />
                <Heading size="md" as="h2" lineHeight="shorter" fontWeight="bold" fontFamily="heading">
                  Views Badges
                </Heading>
              </Flex>
              <Stack>
                {this.availableBadges.map(b => (
                  <Button
                    variantColor={"red"}
                    onClick={() => {
                      this.props.history.push(`/badging/edit/${b.badgeName}`);
                    }}>{`Edit ${b.badgeName}`}</Button>
                ))}
              </Stack>
            </Box>
          </Stack>
        </CenteredLayout>
      </div>
    );
  }
}

export default withRouter(ViewBadgesPage);

import { Client, AuthenticationProvider, BatchRequestStep, BatchRequestContent, BatchResponseContent } from "@microsoft/microsoft-graph-client";
import { authProvider } from "../AuthProvider";
import { globalStore } from "../stores/GlobalStore";




const azureServiceClient = Client.initWithMiddleware({
    debugLogging: true,
    authProvider: {
        getAccessToken: async authOptions => {
            console.log("AUTHOPTIONS", authOptions);

            const token = await authProvider.getAccessToken({ scopes: [`User.Read`], authority: authProvider.authority });
            return token.accessToken;
        }
    }
})
export { azureServiceClient }

export class BatchingClient {

    _client;

    constructor() {
        this._client = Client.initWithMiddleware({
            debugLogging: true,
            authProvider: {
                getAccessToken: async authOptions => {

                    const token = await authProvider.getAccessToken({ scopes: [`User.Read`], authority: authProvider.authority });
                    return token.accessToken;
                }
            }
        })
    }

    public async getUserImagesBatched(userRequests: Array<{ id: string; width: string; height: string }>): Promise<Array<{ id: string; url: string }>> {
        const parallelRequests = userRequests.map((userRequest) => {
            let request = new Request(`/users/${userRequest.id}/photos/${userRequest.width}x${userRequest.height}/$value`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                }
            });
            let step: BatchRequestStep = {
                id: userRequest.id,
                request: request,
                dependsOn: [],
            };

            return step;
        })

        let batchRequestContent = new BatchRequestContent([...parallelRequests]);
        let content = await batchRequestContent.getContent();

        //Making call to $batch end point with the extracted content
        let response = await this._client.api("/$batch").post(content);

        //Create instance with response from the batch request
        let batchResponseContent = new BatchResponseContent(response);

        //Getting all the responses
        console.log("BATCHED RESPONSE", batchResponseContent.getResponses());

        const photosData: Array<any> = [];
        let iterator = batchResponseContent.getResponsesIterator();
        let data = iterator.next();
        while (!data.done) {

            console.log(data.value[0] + ":" + data.value[1]);
            if (data.value[1].status === 401 || data.value[1].status === 404) {
                photosData.push({ id: data.value[0], url: "https://placehold.it/96x96" })
            }
            else {
                const photoResult = await data.value[1].blob();
                var objectURL = URL.createObjectURL(photoResult);
                photosData.push({ id: data.value[0], url: objectURL });
            }


            data = iterator.next();
        }

        return photosData;
    }

}


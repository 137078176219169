import React from "react";
import { useRouter, withRouter, useRouteMatch } from "./../../util/router";
import { Menu, Columns } from "react-bulma-components/full";
import { Link } from "./../../util/router";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { LoadingIndicator2 } from "../LoadingIndicator/LoadingIndicator2";
import { globalStore } from "../../stores/GlobalStore";
import { observer } from "mobx-react";
import { TenantSelector } from "../TenantSelector/TenantSelector";
import { Box, Button } from "@chakra-ui/core";
import {
  Menu as Menu2,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
  MenuOptionGroup,
  MenuItemOption
} from "@chakra-ui/core";
import { SparkDrawer } from "../Drawer/Drawer";

function SparkMenu(props) {
  const router = useRouter();
  const { match, location, history } = props;
  let { path, url } = useRouteMatch();
  // let prefix = url.split("/");
  // prefix.pop();
  // prefix = prefix.join("/");
  let prefix = url;
  const isActive = pathname => prefix + pathname === router.pathname;

  return (
    <Box>
      <Box display={{ base: "flex", sm: "flex", md: "none" }} px={10}>
        <Menu2>
          <MenuButton as={Button}>Actions</MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => {
                router.push(prefix + "/apps");
              }}>
              Apps
            </MenuItem>
            <MenuItem
              onClick={() => {
                router.push(prefix + "/tenantcheck");
              }}>
              Tenant Check
            </MenuItem>
            <MenuItem
              onClick={() => {
                router.push(prefix + "/subscriptions");
              }}>
              Manage Azure Resources
            </MenuItem>
          </MenuList>
        </Menu2>
        <SparkDrawer />
      </Box>
      <Box display={{ base: "none", sm: "none", md: "block" }}>
        <Menu>
          <Menu.List title="Administration">
            <Menu.List.Item
              key={"sparkadministrators"}
              active={router.pathname === "/sparkadministrators"}
              onClick={() => {
                router.push(prefix + "/sparkadministrators");
              }}>
              Add Spark Administrator
            </Menu.List.Item>

            <Menu.List.Item
              key={"azuresubscription"}
              active={router.pathname === "/azuresubscription"}
              onClick={() => {
                router.push(prefix + "/azuresubscription");
              }}>
              Set Default Azure Subscription
            </Menu.List.Item>

            <Menu.List.Item
              key={"sparkazureadapp"}
              active={router.pathname === "/sparkazureadapp"}
              onClick={() => {
                router.push(prefix + "/sparkazureadapp");
              }}>
              Setup Spark Azure AD App
            </Menu.List.Item>
            {globalStore.userCanAccessAppCatalog && (
              <Menu.List.Item
                key={"apps"}
                active={router.pathname === "/apps"}
                onClick={() => {
                  router.push(url + "/apps");
                }}>
                Install Spark Apps
              </Menu.List.Item>
            )}
            <Menu.List.Item
              key={"tenantcheck"}
              active={router.pathname === "/tenantcheck"}
              onClick={() => {
                router.push(prefix + "/tenantcheck");
              }}>
              Tenant Check
            </Menu.List.Item>

            <Menu.List.Item
              key={"sites"}
              active={router.pathname === "/sites"}
              onClick={() => {
                router.push(prefix + "/sites");
              }}>
              Sites
            </Menu.List.Item>
            <Menu.List.Item
              key={"subscriptions"}
              active={router.pathname === "/subscriptions"}
              onClick={() => {
                router.push(prefix + "/subscriptions");
              }}>
              Manage Azure Resources
            </Menu.List.Item>
            <Menu.List.Item
              key={"request"}
              active={router.pathname === "/request"}
              onClick={() => {
                router.push(prefix + "/request");
              }}>
              Create a Request
            </Menu.List.Item>
            <Menu.List.Item
              key={"extensions"}
              active={router.pathname === "/extensions"}
              onClick={() => {
                router.push(prefix + "/extensions");
              }}>
              Extension Setup
            </Menu.List.Item>
            <Menu.List.Item
              key={"settings"}
              active={router.pathname === "/settings"}
              onClick={() => {
                router.push(prefix + "/settings");
              }}>
              Settings
            </Menu.List.Item>
            <Menu.List.Item
              key={"teamsappinstall"}
              active={router.pathname === "/teamsappinstall"}
              onClick={() => {
                router.push(prefix + "/teamsappinstall");
              }}>
              Teams App Install
            </Menu.List.Item>
            <Menu.List.Item
              key={"log"}
              active={router.pathname === "/log"}
              onClick={() => {
                router.push(prefix + "/log");
              }}>
              View Logs
            </Menu.List.Item>
          </Menu.List>
          <Menu.List title="Provisioning Engine Setup">
            <Menu.List.Item
              key={"azureadapps"}
              active={router.pathname === "/azureadapps"}
              onClick={() => {
                router.push(prefix + "/azureadapps");
              }}>
              Install Azure AD App
            </Menu.List.Item>
            <Menu.List.Item
              key={"setup"}
              active={isActive("/setup")}
              onClick={() => {
                router.push(prefix + "/setup");
              }}>
              Install Provisioning Engine
            </Menu.List.Item>
            <Menu.List.Item
              key={"flowconnector"}
              active={router.pathname === "/flowconnector"}
              onClick={() => {
                router.push(prefix + "/flowconnector");
              }}>
              Install Flow Connector
            </Menu.List.Item>
          </Menu.List>

          <Menu.List title="Experiments">
            <Menu.List.Item
              key={"teamssearch"}
              active={router.pathname === "/teamssearch"}
              onClick={() => {
                router.push(prefix + "/teamssearch");
              }}>
              Teams Thing
            </Menu.List.Item>

            <Menu.List.Item
              key={"provisioning"}
              active={router.pathname === "/provisioning"}
              onClick={() => {
                router.push(prefix + "/provisioning");
              }}>
              Provisioning Engine
            </Menu.List.Item>
          </Menu.List>
        </Menu>
      </Box>
    </Box>
  );
}

export default SparkMenu;

const SparkMenuWithRouter = observer(withRouter(SparkMenu));

export const SparkMenuPage = ({ children }) => {
  return (
    <section className="section">
      <div className="container">
        <Columns>
          <Columns.Column size={3}>
            <SparkMenuWithRouter />
          </Columns.Column>
          <Columns.Column size={9}>{children}</Columns.Column>
        </Columns>
        <LoadingIndicator />
        <LoadingIndicator2 />
      </div>
    </section>
  );
};

export const SparkPage = ({ children }) => {
  return (
    <section className="section">
      <div className="container">
        <Columns>
          <Columns.Column size={3}></Columns.Column>
          <Columns.Column size={9}>{children}</Columns.Column>
        </Columns>
        <LoadingIndicator />
        <LoadingIndicator2 />
      </div>
    </section>
  );
};

import { task } from "mobx-task";
import { AzureRMService } from "../../services/AzureRMService";
import { action, observable, computed } from "mobx";
import { GetTemplatePoller } from "../../components/Poller/GetTemplatePoller";
import { SettingsPersistenceService } from "../../services/SettingsPersistanceService";
import { ToasterStore } from "../../components/Toaster/Toaster";

export class ProvisioningEngineStore {

    azureService = new AzureRMService();
    settingsService = new SettingsPersistenceService();

    _subscriptionId: string;
    _resourceGroupName: string;
    _functionAppName: string;

    _poller;

    @observable functionAppKey: string = "";
    @action setFunctionAppKey(key: string) {
        this.functionAppKey = key;
    }

    @action async acquireFunctionAppKey() {
        const key = await this.azureService.getFunctionAppMasterKey(
            this._subscriptionId,
            this._resourceGroupName,
            this._functionAppName
        );

        this.setFunctionAppKey(key.masterKey)
    }

    @computed get functionApiUrl() {
        return `https://${this._functionAppName}.azurewebsites.net/api`
    }
    @computed get functionApiUrlv2() {
        return `https://${this._functionAppName}.azurewebsites.net/apiv2`
    }

    @observable status;
    @action async acquireStatus() {
        const status = await fetch(`${this.functionApiUrl}/appstatus`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "x-functions-key": this.functionAppKey
            }
        }
        );
        if (status.ok) {
            this.status = "OK";
        }
    }

    @observable templateText: string = "";
    @action setTemplateText(text: string) {
        this.templateText = text;
    }

    @observable isGettingTemplate: boolean = false;
    @action setIsGettingTemplate(isGettingTemplate: boolean) {
        this.isGettingTemplate = isGettingTemplate;
    }

    @action async enqueueGetTemplateRequest(siteUrl: string, handlers: string[]): Promise<string> {
        const getTemplateRequest = await fetch(`${this.functionApiUrlv2}/GetTemplateRequest`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "x-functions-key": this.functionAppKey
            },
            body: JSON.stringify({
                siteUrl: siteUrl, handlers: handlers.join(',')
                //    handlers.join(',') 
            })
        }
        );

        const getTempl = await getTemplateRequest.json();

        this.setIsGettingTemplate(true);
        await this.settingsService.persistSettingsToExtensionForMe({ getTemplateId: getTempl.id, getTemplateSite: siteUrl });
        // this._poller = new GetTemplatePoller(5000, this.functionApiUrlv2, getTempl.id, this.functionAppKey, async (text) => {
        //     this.setTemplateText(text);
        //     this.setIsGettingTemplate(false);
        //     await this.settingsService.persistSettingsToExtensionForMe({ getTemplateId: "", getTemplateSite: "" });
        // })
        return getTempl.id;
    }

    async getTemplateById(requestId: string) {
        const statusRequest = await fetch(`${this.functionApiUrlv2}/GetTemplateStatus?id=${requestId}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "x-functions-key": this.functionAppKey
            }
        }
        );
        const header = statusRequest.headers.get("Content-Type");
        console.log("STATUS HEADER", header);
        let status;
        if (header?.indexOf('application/xml') !== -1) {
            status = await statusRequest.text();
            return status;
        }
        else {

        }
    }

    constructor({ subscriptionId, resourceGroupName, functionAppName }) {
        this._subscriptionId = subscriptionId;
        this._resourceGroupName = resourceGroupName;
        this._functionAppName = functionAppName;
    }

    async initialize() {
        //   const toasterStore = new ToasterStore()
        // toasterStore.toast()
        // debugger;
        this.acquireFunctionAppKey();
        this.acquireStatus();

        const userSettings = await this.settingsService.getOrEnsureExtensionCreatedForMe();

        if (userSettings.getTemplateId && userSettings.getTemplateSite) {
            this.setIsGettingTemplate(true);
            this._poller = new GetTemplatePoller(5000, this.functionApiUrlv2, userSettings.getTemplateId, this.functionAppKey, async (text) => {
                this.setTemplateText(text);
                this.setIsGettingTemplate(false);
                await this.settingsService.persistSettingsToExtensionForMe({ getTemplateId: "", getTemplateSite: "" });
            })
        }
    }
}
import React from "react";
import { withRouter } from "../../util/router";
import { SparkMenuPage } from "../../components/SparkMenu";
import { AzureRMService } from "../../services/AzureRMService";
import { Button, Heading, Loader, Modal, Table, Tag } from "react-bulma-components/full";
import AzureStorageService from "../../services/AzureStorageService";
import { TEAMS_APP_MANIFEST_URL } from "../../util/Constants";
import { MicrosoftTeamsService } from "../../services/MicrosoftTeamsService";
import { authProvider } from "../../AuthProvider";
import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { observable, action } from "mobx";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import { observer } from "mobx-react";
import { globalStore } from "../../stores/GlobalStore";
import { Text } from "@chakra-ui/core";
@observer
class TeamsSearchPage extends React.Component {
  // azureService = new AzureRMService();
  // azureStorageService = new AzureStorageService();
  microsoftTeamsService = new MicrosoftTeamsService();

  @observable failedToLoadTeams: boolean = false;
  @action setFailedToLoadTeams(failedToLoadTeams: boolean) {
    this.failedToLoadTeams = failedToLoadTeams;
  }

  @observable joinedTeams: Array<any> = [];
  @action setJoinedTeams(teams: Array<any>) {
    this.joinedTeams = teams;
  }

  @observable log: string = "";
  @action addToLog(message: string) {
    this.log += message;
  }

  async componentDidMount() {
    try {
      globalStore.userAADDirectories.forEach(async dir => {
        const authority =  "https://login.microsoftonline.com/" + dir.tenantId
        const myTeamsInThisTenant = await this.microsoftTeamsService.getMyJoinedTeams(
          authority
        );
        this.addToLog(JSON.stringify(myTeamsInThisTenant));
        console.log("ADDDED", myTeamsInThisTenant);

        myTeamsInThisTenant.forEach(async (team) => {
          const channels = await         this.microsoftTeamsService.getChannelsInTeam(team.id, authority);
          console.log("CHANNELS", channels);
          this.addToLog(JSON.stringify(channels))

        })
      });
      const myTeams = await this.microsoftTeamsService.getMyJoinedTeams();
      this.setJoinedTeams(myTeams);
      console.log("MYTEAMS", myTeams);
    } catch (err) {
      this.setFailedToLoadTeams(true);
    }
  }

  render() {
    return (
      <>
        <Heading size={6}>Teams Thing</Heading>
        {this.failedToLoadTeams && <div>Failed to load Teams. Perhaps the user isn't licensed?</div>}
        {this.joinedTeams.map(team => {
          return <div>{team.displayName}</div>;
        })}

        <Text>{this.log}</Text>
      </>
    );
  }
}

export default withRouter(TeamsSearchPage);

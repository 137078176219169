import React from "react";
import { CircularProgress, CircularProgressLabel, Stack, Text } from "@chakra-ui/core";

export class AuthenticationInProgress extends React.Component {
  render() {
    return (
      <Stack direction="row">
        <CircularProgress isIndeterminate color="green"></CircularProgress>
        <Text>Logging in...</Text>
      </Stack>
    );
  }
}

import React from "react";
import { globalStore } from "../../stores/GlobalStore";
import { observer } from "mobx-react";
import { Stack, Box } from "@chakra-ui/core";
import { toJS } from "mobx";

@observer
export default class Meganav extends React.Component {
  store = globalStore.meganavStore;
  _ref;

  componentDidMount() {
    this._ref = React.createRef();
  }
  render() {
    if (!globalStore.settingsStore.enableMeganav) {
      return null;
    }
    return (
      <Stack spacing={0} style={{ cursor: "pointer" }}>
        <Stack
          shouldWrapChildren={true}
          backgroundColor={"themePrimary"}
          color={"white"}
          direction={"row"}
          justifyContent={"space-around"}>
          {this.store.items.map((item, index) => {
            return (
              <Box
                key={index}
                onClick={() => {
                  this.store.setSecondLevelOpen(!this.store.secondLevelOpen);
                  this.store.setSecondLevelItems(this.store.items[index].subLinks);
                }}>
                {item.heading}
              </Box>
            );
          })}
        </Stack>
        {this.store.secondLevelOpen && (
          <Stack
            shouldWrapChildren={true}
            backgroundColor={"themePrimary"}
            color={"white"}
            direction={"row"}
            justifyContent={"space-around"}>
            {this.store.secondLevelItems.map(item => {
              return (
                <Box
                  onClick={() => {
                    console.log(item.url);
                    if (item.url) {
                      window.location.href = item.url;
                    }
                  }}>
                  {item.heading}
                </Box>
              );
            })}
          </Stack>
        )}
      </Stack>
    );
  }
}

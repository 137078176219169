import React from "react";
import { Button, Input, Box } from "@chakra-ui/core";
import { AzureRMService } from "../../services/AzureRMService";
import { SettingsPersistenceService } from "../../services/SettingsPersistanceService";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import { CenteredLayout } from "../../components/Layouts/CenteredLayout";

@observer
class SiteRequestsPage extends React.Component {
  azureService = new AzureRMService();
  settingsService = new SettingsPersistenceService();

  @observable siteRequestPowerAppId: string = "";
  @action setSiteRequestPowerAppId(id: string) {
    this.siteRequestPowerAppId = id;
  }

  async componentDidMount() {
    const settings = await this.settingsService.getOrganizationSettingsData();
    if (settings.siteRequestPowerAppId) {
      this.setSiteRequestPowerAppId(settings.siteRequestPowerAppId);
    }
  }
  render() {
    return (
      <CenteredLayout>
        Site Requests - configurable Power App Id
        <Box height={"800px"}>
          {this.siteRequestPowerAppId && (
            <iframe
              style={{ height: "800px", width: "100%" }}
              src={`https://apps.powerapps.com/play/${this.siteRequestPowerAppId}?source=website&screenColor=rgba(165,34,55,1)`}
              allow="geolocation; microphone; camera"
            />
          )}
        </Box>
      </CenteredLayout>
    );
  }
}

export default SiteRequestsPage;
